import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UploadFilesContainer from 'generic/containers/UploadFilesContainer';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';

const UploadFilesDialog = ({
  filesToUpload,
  handleClose,
  handleUploadClick,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      scroll="paper"
      fullWidth
      open
    >
      <DialogTitle>
        {t('form.upload')}
      </DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
        <UploadFilesContainer
          filesToUpload={filesToUpload}
          {...props}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleUploadClick}
          disabled={_.isEmpty(filesToUpload) || _.filter(filesToUpload, (item) => !item.success).length === 0}
          loading={_.find(filesToUpload, { loading: true }) !== undefined}
        >
          {t('form.upload')}
        </LoadingButton>
        <Button
          variant="text"
          onClick={handleClose}
        >
          {t('ui.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadFilesDialog.propTypes = {
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  handleClose: PropTypes.func.isRequired,
  handleUploadClick: PropTypes.func.isRequired,
};

UploadFilesDialog.defaultProps = {
  filesToUpload: [],
};

export default UploadFilesDialog;
