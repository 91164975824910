import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { folderPropType } from 'generic/core/anr/proptypes';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { ExpandMore, Folder, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  noPadding: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  title: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const FilterFolderItem = React.memo(({
  item,
  foldersTitles,
  clickFromResults,
  handleFilterFolder,
}) => {
  const classes = useStyles();
  const newRef = useRef();

  useEffect(() => {
    if (clickFromResults === true && newRef.current) {
      newRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  });

  return (
    item.is_root ? (
      <Box
        id={item.id}
        sx={{
          px: 1.5,
          py: 0.25,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: _.includes(foldersTitles, `${item.title}/`) ? '700' : '400',
          }}
          onClick={() => {
            handleFilterFolder(`${item.title}/`);
          }}
        >
          <ExpandMore
            fontSize="small"
            sx={{ mr: 1, color: 'text.neutral' }}
          />
          <span className={classes.title}>{item.short_title}</span>
        </Box>
        {item.children && (
          <Box
            className={classes.noPadding}
            sx={{
              ml: 2.5,
            }}
          >
              {_.map(item.children, (child) => (
                <FilterFolderItem
                  item={child}
                  foldersTitles={foldersTitles}
                  clickFromResults={clickFromResults}
                  handleFilterFolder={handleFilterFolder}
                  key={child.id}
                />
              ))}
          </Box>
        )}
      </Box>
    ) : (
      <Box
        ref={foldersTitles !== undefined && foldersTitles[foldersTitles.length - 1] === item.title ? newRef : _.noop}
        id={item.id}
        sx={{
          px: 1.5,
          py: 0.25,
          cursor: item.in_glimpse ? 'pointer' : 'initial',
          fontWeight: _.includes(foldersTitles, item.title) ? '700' : '400',
          opacity: !item.in_glimpse ? '0.5' : '1',
        }}
        onClick={() => {
          if (item.in_glimpse) {
            handleFilterFolder(item.title);
          }
        }}
      >
        <Folder
          fontSize="small"
          sx={{ verticalAlign: 'bottom', mr: 1, color: 'text.neutral' }}
        />
        <span className={classes.title}>{item.short_title}</span>
        {!item.in_glimpse && (
          <VisibilityOff
            fontSize="small"
            sx={{ verticalAlign: 'bottom', ml: 1, color: 'text.neutral' }}
          />
        )}
      </Box>
    )
  );
});

FilterFolderItem.propTypes = {
  item: folderPropType.isRequired,
  foldersTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFilterFolder: PropTypes.func.isRequired,
  clickFromResults: PropTypes.bool.isRequired,
};

export default FilterFolderItem;
