/**
 * Permet, à partir d'une valeur fournis d'ouvrir un lien vers AlienVault
 * afin de vérifier des hash MD5 / SHA1
 */

const goToAlienVault = (value) => {
  let externalLink;

  const BASE_URL = 'https://otx.alienvault.com/indicator/file';

  const MD5_MATCHER = /^[a-f0-9]{32}$/;
  const SHA1_MATCHER = /^[a-f0-9]{40}$/;

  // la valeur est tjrs en minuscule dans les URL alienvault
  const valueUpperCase = value.toLowerCase();

  if (MD5_MATCHER.test(valueUpperCase)) {
    externalLink = `${BASE_URL}/${value}`;
  } else if (SHA1_MATCHER.test(valueUpperCase)) {
    externalLink = `${BASE_URL}/${value}`;
  }

  if (externalLink) window.open(externalLink, '_blank');
};

export default goToAlienVault;
