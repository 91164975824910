import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { multipleValueSeparatorByInputFormat } from 'generic/utils/qesUtils';
import Facet from 'generic/components/ui/Facet';
import {
  refineAddFacetsValues,
} from 'generic/core/search/actions';
import FacetSkeleton from 'generic/components/skeletons/FacetSkeleton';

const {
  INPUT_FORMAT,
  FACET_POSITION_MAPPING,
} = QES_CONSTANTS;

/**
 * Permet de filtrer le tableau de facette avec les facettes à afficher à gauche ou à droite.
 * Dans le cas ou la facette ne précise pas sa position, elle sera mise à gauche.
 * @param {Array<Object>} resultsFacets le tableau de facettes reçu via un key-values
 * @param {FACET_POSITION_MAPPING} side `left:1`, `right:2`, `undefined:1000`
 * @returns {Array<Object>} un nouveau tableau avec les facettes nécessaire pour le coté `side` demandé
 */
const filterFacets = (resultsFacets, side) => (
  _.filter(resultsFacets, (facet) => {
    if (side === 'left') {
      return facet.position === FACET_POSITION_MAPPING.left;
    }
    if (side === 'right') {
      return facet.position === FACET_POSITION_MAPPING.right;
    }

    return true;
  })
);

const FacetsContainer = ({
  nbFacets,
  side,
}) => {
  const resultsFacets = useSelector((state) => state.search.results.facets);
  const loadingBlank = useSelector((state) => state.search.loadingBlank);
  const facets = React.useMemo(
    () => filterFacets(resultsFacets, side),
    [resultsFacets, side],
  );

  const dispatch = useDispatch();

  const singleRefine = (facetValue) => {
    dispatch(refineAddFacetsValues([facetValue]));
  };

  const multipleRefine = (facet, checkedFacets) => {
    const ensureQuotes = _.map(checkedFacets, (facetValue) => {
      if (facet.format !== INPUT_FORMAT.dropdown_list
        && facet.format !== INPUT_FORMAT.number
      ) {
        return `"${_.trim(facetValue.strategie, ' "')}"`;
      }
      return facetValue.strategie;
    });

    const multipleValuesSeparator = multipleValueSeparatorByInputFormat(facet.format);
    const multiFacetStrategy = ensureQuotes.join(multipleValuesSeparator);
    const fakeFacetValue = {
      champ: facet.champ,
      strategie: multiFacetStrategy,
    };

    singleRefine(fakeFacetValue);
  };

  /**
   * @param facet est l'objet facette reçu du back
   * @param checkedFacetValues est un hash dont la clé est l'`id` de la `facetValue`
   *        et la valeur est la `facetValue` elle même
   */
  const handleSearch = (facet, checkedFacetValues) => {
    // on s'appuie sur la première valeur pour savoir si le format permet le rafinage ou non.
    const formatFacet = _.get(facet, 'facetValues[0].formatFacet');
    const isMultipleRefineVisible = !['date', 'annee', 'date_annee', 'date_range', 'multibase'].includes(formatFacet);

    if (isMultipleRefineVisible) {
      multipleRefine(facet, checkedFacetValues);
    } else {
      const facetValue = Object.values(checkedFacetValues)[0];
      if (facetValue) {
        singleRefine(facetValue);
      }
    }
  };

  if (loadingBlank) {
    return <FacetSkeleton nbFacets={nbFacets} />;
  }

  if (_.isEmpty(facets)) {
    return null;
  }

  return (
    _.map(facets, (facet) => (
      <Facet
        key={facet.id}
        facet={facet}
        handleSearch={handleSearch}
      />
    ))
  );
};

FacetsContainer.propTypes = {
  side: PropTypes.oneOf(Object.keys(FACET_POSITION_MAPPING)),
  nbFacets: PropTypes.number,
};

FacetsContainer.defaultProps = {
  nbFacets: 6,
  side: 'left',
};

const Memoized = React.memo(FacetsContainer);
export default Memoized;
