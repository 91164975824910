import { post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const addToCartOrNewsletter = ({ ...rest }) => post({ path: '/anr/newsletter', ...rest }).then(handleComplete);

const collect = ({ ...rest }) => post({ path: '/anr/collect', ...rest }).then(handleComplete);

const buildSendToFriend = ({ ...rest }) => post({ path: '/anr/build-send', ...rest }).then(handleComplete);

const submitSendToFriend = ({ ...rest }) => post({ path: '/anr/send', ...rest }).then(handleComplete);

export {
  addToCartOrNewsletter,
  buildSendToFriend,
  collect,
  submitSendToFriend,
};
