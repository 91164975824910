import PropTypes from 'prop-types';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';

const { documentPropType, qesdocumentPropType } = overrideRessource('core/qes/documentProptypes', true);

const { CIVILITY, INPUT_FORMAT, DOCUMENT_FORMAT } = QES_CONSTANTS;

const baseProptype = PropTypes.shape({
  base: PropTypes.number,
  champDate: PropTypes.string,
  champEtat: PropTypes.string,
  champTexte: PropTypes.string,
  champTitre: PropTypes.string,
  commentaire: PropTypes.bool,
  envoi: PropTypes.bool,
  facetPosition: PropTypes.string,
  gedModification: PropTypes.bool,
  idChampDate: PropTypes.string,
  idChampRAG: PropTypes.string,
  libelle: PropTypes.string,
  newsletter: PropTypes.bool,
  panier: PropTypes.bool,
  tranche: PropTypes.number,
  tranches: PropTypes.arrayOf(PropTypes.number),
  triable: PropTypes.bool,
  veille: PropTypes.bool,
});

const utilisateurLightPropType = PropTypes.shape({
  utilisateur: PropTypes.number,
  etat_utilisateur: PropTypes.number,
  langue: PropTypes.number,
  civilite: PropTypes.number,
  nom: PropTypes.string,
  prenom: PropTypes.string,
  email: PropTypes.string,
  email2: PropTypes.string,
  logon: PropTypes.string,
  image: PropTypes.string,
});

const utilisateurPropType = PropTypes.shape({
  accesAnR: PropTypes.bool,
  actualPassword: PropTypes.string,
  admin: PropTypes.bool,
  avec_password_a_changer: PropTypes.bool,
  civilite: PropTypes.oneOf(Object.values(CIVILITY)),
  civiliteLibelle: PropTypes.string,
  codeError: PropTypes.number,
  confPassword: PropTypes.string,
  date_dernier_password: PropTypes.string,
  email: PropTypes.string,
  email2: PropTypes.string,
  etat_utilisateur: PropTypes.number,
  image_json: PropTypes.shape({
    height: PropTypes.number,
    scaleX: PropTypes.number,
    scaleY: PropTypes.number,
    unit: PropTypes.oneOf(['%', 'px', 'rem', 'em', 'vh', 'vw']),
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  image: PropTypes.string,
  langue: PropTypes.number,
  langueLibelle: PropTypes.string,
  logon: PropTypes.string,
  mode_vue: PropTypes.number,
  modeVueLibelle: PropTypes.string,
  nom: PropTypes.string,
  password: PropTypes.string,
  pays: PropTypes.number,
  paysLibelle: PropTypes.string,
  preference1: PropTypes.string,
  preference2: PropTypes.string,
  prenom: PropTypes.string,
  societe: PropTypes.number,
  societeLibelle: PropTypes.string,
  theme_qes: PropTypes.number,
  utilisateur: PropTypes.number,
});

const commentPropType = {
  user: utilisateurPropType,
  comments: PropTypes.arrayOf(PropTypes.shape()),
  alertChecked: PropTypes.bool,
  hasComment: PropTypes.bool,
  handleToggleCheckbox: PropTypes.func.isRequired,
  deleteLoading: PropTypes.bool,
  submitLoading: PropTypes.bool,
  initialValues: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  autoFocusInput: PropTypes.bool,
};

const commentPropTypeDefault = {
  user: {},
  comments: [{}],
  alertChecked: false,
  hasComment: false,
  deleteLoading: false,
  submitLoading: false,
  initialValues: {},
  commentIsVisible: false,
  autoFocusInput: true,
};

const facetValuePropType = PropTypes.shape({
  champ: PropTypes.string.isRequired,
  formatFacet: PropTypes.oneOf(['date', 'annee', 'date_annee', 'date_range', 'multibase', 'texte']).isRequired,
  frequence: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  libelle: PropTypes.string.isRequired,
  strategie: PropTypes.string.isRequired,
});

const facetPropType = PropTypes.shape({
  champ: PropTypes.string.isRequired,
  facetValues: PropTypes.arrayOf(facetValuePropType),
  format: PropTypes.oneOf(Object.values(INPUT_FORMAT)),
  libelle: PropTypes.string.isRequired,
});

const cartOrNewsletterDocumentPropType = PropTypes.shape({
  base: PropTypes.number.isRequired,
  documentitem: PropTypes.number.isRequired,
  format_document: PropTypes.oneOf(Object.values(DOCUMENT_FORMAT)),
  idext: PropTypes.string.isRequired,
  intitule: PropTypes.string,
  ordre: PropTypes.number,
  panier: PropTypes.number.isRequired,
  panier_document: PropTypes.number,
  qesdocument: qesdocumentPropType,
  renduDocument: PropTypes.string,
  texte: PropTypes.string,
  texteDocument: PropTypes.string,
});

const cartOrNewsletterPropType = PropTypes.shape({
  commande_workflow: PropTypes.number,
  diffusion: PropTypes.number,
  panier: PropTypes.number,
  panierDocumentsCount: PropTypes.number,
  plateforme: PropTypes.number,
  theme_panier: PropTypes.number,
  type_panier: PropTypes.number,
  utilisateur: PropTypes.number,
  visibilite_newsletter: PropTypes.number,
  visibilite_panier: PropTypes.number,
  mode_manuel: PropTypes.bool,

  date_creation: PropTypes.string,
  destinataires_groupe: PropTypes.string,
  dossiers: PropTypes.string,
  image: PropTypes.string,
  intitule: PropTypes.string,
  observations: PropTypes.string,
  rendu_html: PropTypes.string,
  texte: PropTypes.string,
  utilisateur_owner_libelle: PropTypes.string,
  visibilite_newsletter_libelle: PropTypes.string,
  visibilite_panier_libelle: PropTypes.string,

  destinataires: PropTypes.arrayOf(PropTypes.string),
  heures: PropTypes.arrayOf(PropTypes.string),
  jours: PropTypes.arrayOf(PropTypes.string),

  panierDocuments: PropTypes.arrayOf(cartOrNewsletterDocumentPropType),
  panierUtilisateurs: PropTypes.arrayOf(PropTypes.shape()),
});

const alertPropType = PropTypes.shape({
  veille: PropTypes.number,
  blocage_veille: PropTypes.number,
  mode_veille: PropTypes.number,
  peremption_veille: PropTypes.number,
  mode_traitement: PropTypes.number,
  mode_purge: PropTypes.number,
  utilisateur: PropTypes.number,
  etat_veille: PropTypes.number,
  type_veille: PropTypes.number,
  mode_regle: PropTypes.number,
  nature_veille: PropTypes.number,
  erreur_mouvement: PropTypes.number,
  langage: PropTypes.number,
  theme_veille: PropTypes.number,
  format_veille: PropTypes.number,
  veille_multibase: PropTypes.number,
  base: PropTypes.number,
  provenance_veille: PropTypes.number,
  qualification_veille: PropTypes.number,
  plateforme: PropTypes.number,
  remplissage_veille: PropTypes.number,
  mode_veille_libelle: PropTypes.string,
  type_veille_libelle: PropTypes.string,
  libelle: PropTypes.string,
  email: PropTypes.string,
  date_depart: PropTypes.string,
  date_arret: PropTypes.string,
  date_dernier_reussi: PropTypes.string,
  date_time_dernier_reussi: PropTypes.string,
  date_dernier_essai: PropTypes.string,
  date_time_dernier_essai: PropTypes.string,
  date_derniere_consommation: PropTypes.string,
  date_suppression: PropTypes.string,
  date_desactivation: PropTypes.string,
  date_prochaine: PropTypes.string,
  date_time_prochaine: PropTypes.string,
  date_creation: PropTypes.string,
  date_time_creation: PropTypes.string,
  date_modification: PropTypes.string,
  nombre_document_dernier_reussi: PropTypes.number,
  nombre_tentative_echoue: PropTypes.number,
  nombre_nouveau_document: PropTypes.number,
  nombre_nouveau_document_preced: PropTypes.number,
  nombre_document_derniere_conso: PropTypes.number,
  taille_dernier_document: PropTypes.number,
  jour_execution: PropTypes.number,
  a_agreger: PropTypes.bool,
  veille_principale: PropTypes.bool,
  veilleChamps: PropTypes.arrayOf(PropTypes.shape({
    veille_champ: PropTypes.number,
    veille: PropTypes.number,
    raffinage: PropTypes.number,
    base: PropTypes.number,
  })),
});

const newsletterHistoricDelivery = PropTypes.shape({
  avec_vidange: PropTypes.bool,
  date_time_envoi: PropTypes.string,
  destinataires: PropTypes.string,
  newsletter: PropTypes.number,
  newsletter_histo: PropTypes.number,
});

const newsletterHistoric = PropTypes.arrayOf(newsletterHistoricDelivery);

const sendToFriendDataPropType = PropTypes.shape({
  sujet: PropTypes.string,
  htmlResultat: PropTypes.string,
  seuilAtteint: PropTypes.bool,
  pjs: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
    selected: PropTypes.string,
    classe: PropTypes.string,
    ordre: PropTypes.number,
  })),
  pieceJointe: PropTypes.string,
});

const exportDataPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  libelle: PropTypes.string.isRequired,
  selected: PropTypes.string,
  classe: PropTypes.string,
  etat: PropTypes.string,
  ordre: PropTypes.number,
  id: PropTypes.number,
});

const actionsPropType = PropTypes.shape({
  action: PropTypes.number,
  classe: PropTypes.string,
  code: PropTypes.string,
  etat: PropTypes.number,
  libelle: PropTypes.string,
  url: PropTypes.string,
});

const newsletterThemePropType = PropTypes.shape({
  avec_edition: PropTypes.bool,
  avec_mode_manuel: PropTypes.bool,
  code: PropTypes.string.isRequired,
  libelle: PropTypes.string.isRequired,
  observations: PropTypes.string,
  page_xslt: PropTypes.string,
  plateforme: PropTypes.number,
  rupture: PropTypes.string,
  template_export: PropTypes.string,
  template_export_doc: PropTypes.string,
  template_mail: PropTypes.string,
  template_mail_doc: PropTypes.string,
  theme_panier: PropTypes.number.isRequired,
  tri: PropTypes.string,
});

const widgetPropType = PropTypes.shape({
  additionalQuery: PropTypes.string,
  aggregates: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  dashboard: PropTypes.number,
  dashboard_widget: PropTypes.number,
  facetmax: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  facetmax2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  facets: PropTypes.string,
  height: PropTypes.number,
  humanizeDocState: PropTypes.bool,
  humanizeLangs: PropTypes.bool,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  monoColors: PropTypes.bool,
  pivots: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  slice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sort: PropTypes.string,
  title: PropTypes.string,
  tones: PropTypes.bool,
  type: PropTypes.string,
  width: PropTypes.number,
});

const scheduleComprehensionPropType = PropTypes.shape({
  schedule_comprehension: PropTypes.number.isRequired,
  mois: PropTypes.number,
  periodicite_schedule: PropTypes.number,
  jour_semaine: PropTypes.number,
  schedule: PropTypes.number.isRequired,
  le: PropTypes.number,
  heure: PropTypes.number,
  minute: PropTypes.number,
  exclure_reprise1: PropTypes.bool,
});

const scheduleExtensionPropType = PropTypes.shape({
  exclure_reprise1: PropTypes.bool,
  date_debut: PropTypes.string,
  date_time_debut: PropTypes.string,
  schedule_extension: PropTypes.number.isRequired,
  schedule: PropTypes.number.isRequired,
  schedule_comprehension: PropTypes.number.isRequired,
  lot: PropTypes.number,
});

const scheduleArgumentsPropType = PropTypes.shape({
  date_creation: PropTypes.string,
  reel: PropTypes.number,
  ordre: PropTypes.number,
  entier: PropTypes.number,
  schedule_argument: PropTypes.number.isRequired,
  traitement: PropTypes.number.isRequired,
  type_donnee: PropTypes.number,
  schedule: PropTypes.number.isRequired,
  schedule_traitement: PropTypes.number.isRequired,
  oui_non: PropTypes.bool,
  est_non_modifiable: PropTypes.bool,
  libelle: PropTypes.string,
  observations: PropTypes.string,
  avant: PropTypes.string,
  apres: PropTypes.string,
});

const scheduleTraitementPropType = PropTypes.shape({
  schedule_traitement: PropTypes.number.isRequired,
  traitement: PropTypes.number.isRequired,
  schedule: PropTypes.number.isRequired,
  mode_traitement: PropTypes.number.isRequired,
  mode_parcours: PropTypes.number.isRequired,
  mode_information: PropTypes.number.isRequired,
  niveau_log: PropTypes.number,
  ordre: PropTypes.number,
  nombre_information: PropTypes.number,
  directory: PropTypes.string,
  observations: PropTypes.string,
  pattern: PropTypes.string,
  pattern_date: PropTypes.string,
  pattern_date_fichier: PropTypes.string,
  champs_a_extraire: PropTypes.string,
  sans_reinit_date_parcours: PropTypes.bool,
  avec_rapport: PropTypes.bool,
  reprise: PropTypes.bool,
  schedule_arguments: PropTypes.arrayOf(scheduleArgumentsPropType),
});

const scheduleRapportPropType = PropTypes.shape();

const schedulePropType = PropTypes.shape({
  date_creation: PropTypes.string,
  date_modification: PropTypes.string,
  date_fin_extension: PropTypes.string,
  code: PropTypes.string.isRequired,
  libelle: PropTypes.string.isRequired,
  observations: PropTypes.string,
  schedule: PropTypes.number.isRequired,
  base: PropTypes.number.isRequired,
  intervenant_creation: PropTypes.number,
  intervenant_modification: PropTypes.number,
  etat_schedule: PropTypes.number.isRequired,
  schedule_erreur: PropTypes.number,
  mode_information: PropTypes.number,
  mode_rapport: PropTypes.number,
  schedule_traitements: PropTypes.arrayOf(scheduleTraitementPropType),
  schedule_comprehension: PropTypes.arrayOf(scheduleComprehensionPropType),
  schedule_extension: PropTypes.arrayOf(scheduleExtensionPropType),
  rapports: PropTypes.arrayOf(scheduleRapportPropType),
});

export {
  actionsPropType,
  alertPropType,
  baseProptype,
  cartOrNewsletterDocumentPropType,
  cartOrNewsletterPropType,
  commentPropType,
  commentPropTypeDefault,
  documentPropType,
  exportDataPropType,
  facetPropType,
  facetValuePropType,
  newsletterHistoric,
  newsletterHistoricDelivery,
  newsletterThemePropType,
  qesdocumentPropType,
  scheduleComprehensionPropType,
  scheduleExtensionPropType,
  schedulePropType,
  scheduleRapportPropType,
  scheduleTraitementPropType,
  sendToFriendDataPropType,
  utilisateurPropType,
  utilisateurLightPropType,
  widgetPropType,
};
