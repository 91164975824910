import PropTypes from 'prop-types';

const userPropType = PropTypes.shape({
  always_open_in_new_window: PropTypes.bool,
  auto_validation: PropTypes.bool,
  compact_list_display: PropTypes.bool,
  deduplicate: PropTypes.bool,
  folders_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  folders_not_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  glimpse: PropTypes.bool,
  max_profiles: PropTypes.number,
  nb_folders: PropTypes.number,
  nb_links: PropTypes.number,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  quotes: PropTypes.bool,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  username: PropTypes.string,
});

const articlePropType = PropTypes.shape({
  atfrom: PropTypes.string,
  atfromurl: PropTypes.string,
  authors: PropTypes.arrayOf(
    PropTypes.string,
  ),
  display_date: PropTypes.number,
  duplicates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  followers_count: PropTypes.number,
  group_count: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.string,
  ),
  is_duplicate: PropTypes.bool,
  is_from_dark_web: PropTypes.bool,
  lang: PropTypes.string,
  quote_from: PropTypes.string,
  quote_type: PropTypes.number,
  sendings: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      emails: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  short_description: PropTypes.string,
  snippet: PropTypes.string,
  source: PropTypes.string,
  title: PropTypes.string,
  validation: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
  ]),
});

const folderPropType = PropTypes.shape({
  children: PropTypes.arrayOf(PropTypes.shape()),
  has_news: PropTypes.bool,
  has_quotes: PropTypes.bool,
  id: PropTypes.string,
  is_editable: PropTypes.bool,
  is_favorite: PropTypes.bool,
  is_his: PropTypes.bool,
  is_root: PropTypes.bool,
  parent: PropTypes.string,
  short_title: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
});

const sourcePropType = PropTypes.shape({
  articles_count: PropTypes.number,
  host: PropTypes.string,
  id: PropTypes.string,
  lang: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
});

export {
  articlePropType,
  folderPropType,
  sourcePropType,
  userPropType,
};
