import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { Box, IconButton } from '@mui/material';
import { Download, PlayArrow, Search } from '@mui/icons-material';

import ImgLoader from 'generic/components/ui/ImgLoader';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import { downloadImage } from 'generic/utils/qesUtils';

const useStyles = makeStyles((theme) => ({
  itemImage: {
    objectFit: 'cover',
    display: 'block',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    overflow: 'auto',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    gap: '2px',
    padding: '2px',
  },
  buttonImg: {
    backgroundColor: theme.palette.common.black,
    border: '1px solid',
    borderColor: '#888888',
    color: theme.palette.common.white,
    padding: '1px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      borderColor: 'initial',
    },
  },
}));

const ImageDetection = ({
  data,
  title,
  handleImageClick,
  handleSimilarityImage,
}) => {
  const classes = useStyles();
  return (
    <Box sx={{ width: '100%', pr: 2 }}>
      <Box>{title}</Box>
      <Box className={classes.imagesContainer}>
        { _.map(data, (item, index) => (
          <Box position="relative" key={index}>
            <ImgLoader
              src={item.image}
              fallbackComponent={(
                <ImageNotFound
                  height={100}
                  width={100}
                />
              )}
              className={classes.itemImage}
              skeletonClassName={classes.itemImage}
              height={100}
              width={100}
              loading="lazy"
            />
            <Box className={classes.buttonsContainer}>
              <IconButton
                className={classes.buttonImg}
                disabled={!item.time}
                onClick={() => handleImageClick(item.time)}
              >
                <PlayArrow fontSize="small" />
              </IconButton>
              {handleSimilarityImage && (
                <IconButton
                  className={classes.buttonImg}
                  disabled={!item.vector}
                  onClick={() => handleSimilarityImage(item.vector)}
                >
                  <Search fontSize="small" />
                </IconButton>
              )}
              <IconButton
                className={classes.buttonImg}
                onClick={() => downloadImage(item.image, item.image.split('file=').pop(), true)}
              >
                <Download fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

ImageDetection.propTypes = {
  handleImageClick: PropTypes.func.isRequired,
  handleSimilarityImage: PropTypes.func,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      time: PropTypes.string,
      vector: PropTypes.string,
    }),
  ).isRequired,
};

ImageDetection.defaultProps = {
  handleSimilarityImage: null,
};

export default ImageDetection;
