import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  fetchActiveItemResults,
  fetchAnrDuplicates,
  openArticle,
  setFiltersParam,
  setOpenFilterNav,
} from 'generic/core/anr/actions';
import { toggleCheckbox } from 'generic/core/selection/actions';
import { checkIfItemHasQuery } from 'generic/utils/anrUtils';
import Results from 'generic/components/pages/anr/Results';
import { useMediaQuery, useTheme } from '@mui/material';

const ResultsContainer = () => {
  const activeItem = useSelector((state) => state.anr.activeItem);
  const loading = useSelector((state) => state.anr.resultsLoading);
  const reponseGoToIndex = useSelector((state) => state.anr.responseData.go_to_index);
  const articles = useSelector((state) => state.anr.articles);
  const filtersParams = useSelector((state) => state.anr.filtersData.filtersParams);
  const checkedItems = useSelector((state) => state.selection.checkedItems);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const resultsTimeout = useRef(null);
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const hasQuery = checkIfItemHasQuery(activeItem);
  const openedArticleID = _.get(_.find(articles, { opened: true }), 'id', null);

  useEffect(() => {
    if (!_.isEmpty(articles) && openedArticleID === null) {
      clearTimeout(resultsTimeout.current);
      resultsTimeout.current = setTimeout(() => {
        dispatch(fetchActiveItemResults());
      }, 500000);
    }

    // Le cleanup est lancé quand le composant est unmount
    // ou avant que ce useEffect soit relancé
    return function cleanup() {
      if (resultsTimeout.current) {
        clearTimeout(resultsTimeout.current);
      }
    };
  }, [articles, openedArticleID, dispatch]);

  const handleOpenArticle = (event, item) => {
    if (event.ctrlKey || event.metaKey) {
      const url = item.quote_url ? item.quote_url : item.url;
      window.open(url);
    } else {
      dispatch(setOpenFilterNav(false));
      dispatch(openArticle(item));
    }
  };

  const handleToggleCheckbox = (item) => {
    dispatch(toggleCheckbox({
      id: item.id,
      item,
    }));
  };

  const handleSourceFilter = (source) => {
    if (!smallerThanLarge) {
      dispatch(setOpenFilterNav(true));
    }
    dispatch(setFiltersParam({ site: source }));
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  const handleFilterFolder = (folderTitle) => {
    const foldersTitles = filtersParams.folders_titles;
    let alteredFoldersTitles = [...foldersTitles];
    if (_.indexOf(foldersTitles, folderTitle) === -1) {
      alteredFoldersTitles = [...foldersTitles.filter((folder) => folder !== 'all'), folderTitle];
    }
    if (!smallerThanLarge) {
      dispatch(setOpenFilterNav(true));
    }
    dispatch(setFiltersParam(
      { folders_titles: alteredFoldersTitles },
      true,
    ));
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  const handleFetchDuplicates = (article) => {
    dispatch(fetchAnrDuplicates(article));
  };

  if (_.isEmpty(activeItem)) {
    return null;
  }

  return (
    <Results
      articles={articles}
      articlesType={t(activeItem.view === 'quotes' ? 'anr.quotes' : 'anr.news')}
      checkedItems={checkedItems}
      handleOpenArticle={handleOpenArticle}
      handleToggleCheckbox={handleToggleCheckbox}
      handleSourceFilter={handleSourceFilter}
      handleFilterFolder={handleFilterFolder}
      handleFetchDuplicates={handleFetchDuplicates}
      loading={loading && hasQuery}
      openedArticleID={openedArticleID}
      reponseGoToIndex={reponseGoToIndex}
      shouldDisplayResults={hasQuery}
      view={activeItem.view}
    />
  );
};

export default ResultsContainer;
