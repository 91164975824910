import { types } from 'generic/core/collections/actions';
import _ from 'lodash';

const collectionsReducer = (
  state = {
    activeCollection: {},
    collections: [],
    dialogCollection: {},
    loadingCollections: true,
  },
  action,
) => {
  switch (action.type) {
    /* Reducer des collections */
    case types.CLEANUP_COLLECTIONS: {
      return {
        ...state,
        collections: [],
      };
    }
    case types.FETCH_COLLECTIONS: {
      return {
        ...state,
        collections: [],
        loadingCollections: true,
      };
    }
    case types.FETCH_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        activeCollection: _.find(_.flatMap(action.collections, 'corpora'), { active: true }) ?? {},
        collections: action.collections,
        loadingCollections: false,
      };
    }
    case types.FETCH_COLLECTIONS_ERROR: {
      return {
        ...state,
        loadingCollections: false,
      };
    }
    case types.ACTIVATE_COLLECTION_SUCCESS: {
      const collectionsUpdated = state.collections.map((collection) => ({
        ...collection,
        corpora: collection.corpora.map((corpus) => ({
          ...corpus,
          active: corpus.collection === action.activeCollection.collection,
          ...(corpus.collection === action.activeCollection.collection
            && { date_dernier_acces: action.activeCollection.date_dernier_acces }),
        })),
      }));

      return {
        ...state,
        collections: collectionsUpdated,
        activeCollection: _.find(_.flatMap(collectionsUpdated, 'corpora'), { active: true }) ?? {},
      };
    }
    case types.OPEN_COLLECTION_DIALOG: {
      return {
        ...state,
        dialogCollection: {
          open: true,
          mode: action.mode,
          typeRessource: action.typeRessource,
          id: action.formValues?.id,
          collectionParente: action.formValues?.collection_parente,
          owner: action.formValues?.owner,
          visibility: action.formValues?.visibility,
          collaborators: action.formValues?.collaborators,
          type: action.formValues?.type,
          libelle: action.formValues?.libelle,
          description: action.formValues?.description,
          fileUploadName: action.formValues?.image,
        },
      };
    }
    case types.CLOSE_COLLECTION_DIALOG: {
      return {
        ...state,
        dialogCollection: {
          open: false,
        },
      };
    }
    case types.UPLOAD_COLLECTION_IMAGE: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploading: true,
        },
      };
    }
    case types.UPLOAD_COLLECTION_IMAGE_SUCCESS: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploadName: action.results.filename,
          fileUploading: false,
        },
      };
    }
    case types.UPLOAD_COLLECTION_IMAGE_ERROR: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploading: false,
        },
      };
    }
    case types.CLEAR_UPLOAD_COLLECTION_IMAGE: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploadName: '',
        },
      };
    }
    case types.CREATE_COLLECTION: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: true,
        },
      };
    }
    case types.CREATE_COLLECTION_SUCCESS: {
      const collectionsUpdated = _.cloneDeep(state.collections);
      const isCollection = action.results.collection_parente === 1000;

      if (isCollection) {
        collectionsUpdated.push(action.results);
      } else {
        const collectionParent = collectionsUpdated.find(
          (coll) => coll.collection === action.results.collection_parente,
        );
        collectionParent?.corpora?.push(action.results);
      }

      return {
        ...state,
        collections: collectionsUpdated,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }
    case types.CREATE_COLLECTION_ERROR: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }
    case types.EDIT_COLLECTION: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: true,
        },
      };
    }

    case types.EDIT_COLLECTION_SUCCESS: {
      let collectionsUpdated = [];
      // Collection
      if (action.results.collection_parente === 1000) {
        collectionsUpdated = state.collections.map((collection) => (
          collection.collection === action.results.collection
            ? { ...action.results }
            : { ...collection }
        ));
      // Corpus
      } else {
        collectionsUpdated = state.collections.map((collection) => ({
          ...collection,
          ...(collection.collection !== action.results.collection_parente
            && _.some(collection.corpora, { collection: action.results.collection })
            && {
              corpora: _.filter(collection.corpora, (corpus) => corpus.collection !== action.results.collection),
            }),
          ...(collection.collection === action.results.collection_parente
            && {
              corpora: [
                ...collection.corpora,
                action.results,
              ],
            }),
          ...(collection.collection === action.results.collection_parente
            && _.some(collection.corpora, { collection: action.results.collection })
              && {
                corpora: collection.corpora.map(
                  (corpus) => (corpus.collection === action.results.collection
                    ? { ...corpus, ...action.results } : corpus),
                ),
              }),
        }));
      }

      return {
        ...state,
        collections: collectionsUpdated,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }

    case types.EDIT_COLLECTION_ERROR: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }

    case types.DELETE_COLLECTIONS_SUCCESS: {
      const collectionsUpdated = [...state.collections];
      // Collection
      if (action.results.collection_parente === 1000) {
        _.remove(collectionsUpdated, (collection) => collection.collection === action.results.collection);
      // Corpus
      } else {
        collectionsUpdated.forEach((collection) => {
          if (!_.isEmpty(collection.corpora)) {
            _.remove(collection.corpora, (corpus) => corpus.collection === action.results.collection);
          }
        });
      }
      return {
        ...state,
        collections: collectionsUpdated,
      };
    }
    default:
      return state;
  }
};

export default collectionsReducer;
