import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UsersMailingList from 'generic/components/pages/UsersMailingList';
import {
  cleanupMailingLists,
  cleanupUsersMailingList,
  createUserMailingList,
  deleteUsersMailingList,
  editUserMailingList,
  fetchMailingLists,
  fetchUsersMailingList,
  openUserMailingListDialog,
  types,
} from 'generic/core/mailingLists/actions';
import UserMailingListDialogContainer from 'generic/containers/anr/UserMailingListDialogContainer';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useLocation } from 'react-router';

const UsersMailingListContainer = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idMailingListFilter = searchParams.get('idMailingList');
  const mailingListFilter = searchParams.get('mailingList');

  const emails = useSelector((state) => state.mailingLists.emails);
  const mailingLists = useSelector((state) => state.mailingLists.mailingLists);
  const goToEmail = useSelector((state) => state.mailingLists.goToEmail);
  const editLoading = useSelector((state) => state.mailingLists.editLoading);

  const [searchUsers, setSearchUsers] = useState('');
  const [filterGroup, setFilterGroup] = useState('all');
  const [filterMailingLists, setFilterMailingLists] = useState(
    idMailingListFilter ? [{
      idMailingList: Number(idMailingListFilter),
      mailingList: mailingListFilter,
    }] : [],
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMailingLists());
    dispatch(fetchUsersMailingList());

    /* Cleanup des Listes de diffusion & des utilisateurs,
    lorsque le composant est "unmount" (donc quand on change de page) */
    return () => {
      dispatch(cleanupMailingLists());
      dispatch(cleanupUsersMailingList());
    };
  }, [dispatch]);

  let tableDatas = _.map(emails, (email) => ({
    email: email.email,
    idsUser: _.map(
      email.listeDiffusionUtilisateurs,
      (mailingListOfUser) => mailingListOfUser.listeDiffusionUtilisateur,
    ),
    mailingLists: _.map(_.orderBy(mailingLists, ['libelle'], ['asc']), (item) => {
      let checked = false;
      let idUser = null;
      _.forEach(email.listeDiffusionUtilisateurs, (mailingListOfUser) => {
        if (mailingListOfUser.listeDiffusion === item.listeDiffusion) {
          checked = true;
          idUser = mailingListOfUser.listeDiffusionUtilisateur;
        }
      });
      return ({
        checked,
        idMailingList: item.listeDiffusion,
        idUser,
        libelle: item.libelle,
      });
    }),
  }));

  /* Filtre sur les mails des listes de diffusions */
  if (!_.isEmpty(searchUsers)) {
    tableDatas = _.filter(tableDatas, (item) => _.includes(item.email.toLowerCase(), searchUsers.trim().toLowerCase()));
  }

  /* Fitlre sur les listes de diffusions choisies à afficher */
  if (filterMailingLists.length > 0) {
    tableDatas = _.map(tableDatas, (data) => ({
      ...data,
      mailingLists: _.filter(data.mailingLists, (item) => {
        let filter = false;
        _.forEach(filterMailingLists, (filterMailinList) => {
          if (filterMailinList.idMailingList === item.idMailingList) {
            filter = true;
          }
        });
        return filter;
      }),
    }));
  }

  /* Fitlre sur l'état des listes de diffusion */
  if (filterGroup === 'nogroup') {
    tableDatas = _.filter(tableDatas, (data) => {
      let noGroup = true;
      _.forEach(data.mailingLists, (item) => {
        if (item.checked === true) {
          noGroup = false;
        }
      });
      return noGroup;
    });
  } else if (filterGroup === 'onegroup') {
    tableDatas = _.filter(tableDatas, (data) => {
      let oneGroup = false;
      _.forEach(data.mailingLists, (item) => {
        if (item.checked === true) {
          oneGroup = true;
        }
      });
      return oneGroup;
    });
  } else if (filterGroup === 'allgroup') {
    tableDatas = _.filter(tableDatas, (data) => {
      let allGroup = true;
      _.forEach(data.mailingLists, (item) => {
        if (item.checked === false) {
          allGroup = false;
        }
      });
      return allGroup;
    });
  }

  const handleOpenUserMailingListDialog = () => {
    dispatch(openUserMailingListDialog());
  };

  const handleEditMail = (formValues) => {
    dispatch(editUserMailingList(formValues));
  };

  const handleAddOrRemoveMailingList = (checked, idMailingList, idUser, data) => {
    if (checked) {
      dispatch(createUserMailingList({ ids: [idMailingList], email: data.email }));
    } else if (data.idsUser.length === 1) {
      dispatch(setConfirmDialogData({
        title: t('users_mailing_list.delete_dialog'),
        message: (<Trans>{t('users_mailing_list.message_delete_dialog')}</Trans>),
        submitColor: 'error',
        waitForActions: [types.DELETE_USERS_MAILING_LIST_SUCCESS, types.DELETE_USERS_MAILING_LIST_ERROR],
        action: {
          type: types.DELETE_USERS_MAILING_LIST,
          ids: [idUser],
        },
      }));
    } else {
      dispatch(deleteUsersMailingList([idUser]));
    }
  };

  const handleOpenDeleteUserMailingListConfirmDialog = (ids) => {
    dispatch(setConfirmDialogData({
      title: t('users_mailing_list.delete_dialog'),
      textButton: 'form.delete',
      message: (t('users_mailing_list.message_delete_user_dialog')),
      submitColor: 'error',
      waitForActions: [types.DELETE_USERS_MAILING_LIST_SUCCESS, types.DELETE_USERS_MAILING_LIST_ERROR],
      action: {
        type: types.DELETE_USERS_MAILING_LIST,
        ids,
      },
    }));
    return false;
  };

  const handleSearchUsers = (event) => {
    setSearchUsers(event.target.value);
  };

  const handleFilterGroup = (event) => {
    setFilterGroup(event.target.value);
  };

  const handleFilterMailingLists = (event, child) => {
    let filterMailingListAltered = [...filterMailingLists];
    const indexFind = _.findIndex(filterMailingLists, { idMailingList: child.props.value.idMailingList });
    if (indexFind > -1) {
      filterMailingListAltered = filterMailingListAltered.filter((element, index) => index !== indexFind);
    } else {
      filterMailingListAltered.push(child.props.value);
    }
    setFilterMailingLists(filterMailingListAltered);
    if (_.isEmpty(filterMailingListAltered)) {
      setFilterGroup('all');
    }
  };

  return (
    <Fragment>
      <UsersMailingList
        tableDatas={tableDatas}
        filterGroup={filterGroup}
        filterMailingLists={filterMailingLists}
        goToEmail={goToEmail}
        mailingLists={mailingLists}
        searchUsers={searchUsers}
        handleAddOrRemoveMailingList={handleAddOrRemoveMailingList}
        handleEditMail={handleEditMail}
        handleFilterGroup={handleFilterGroup}
        handleFilterMailingLists={handleFilterMailingLists}
        handleOpenDeleteUserMailingListConfirmDialog={handleOpenDeleteUserMailingListConfirmDialog}
        handleOpenUserMailingListDialog={handleOpenUserMailingListDialog}
        handleSearchUsers={handleSearchUsers}
        editLoading={editLoading}
      />
      <UserMailingListDialogContainer />
    </Fragment>
  );
};

export default UsersMailingListContainer;
