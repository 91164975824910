import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { closeDialogUploadFiles } from 'generic/core/actions/actions';
import { uploadFileToCreateDocument } from 'generic/core/files/actions';
import UploadFilesDialog from 'generic/components/dialogs/UploadFilesDialog';
import useAuthorizedExtensions from 'generic/core/hooks/useAuthorizedExtensions';
import QES_CONSTANTS from 'generic/core/qes/constants';

const {
  UPLOAD_FILE_SETTINGS,
  FILE_UPLOAD_FORMATS,
} = QES_CONSTANTS;

const UploadFilesDialogContainer = ({
  baseId,
  baseMisePageId,
}) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.actions.dialogUploadFilesOpened);
  const filesToUpload = useSelector((state) => state.files.filesToUpload);

  const type = UPLOAD_FILE_SETTINGS?.UPLOAD_FILE_TYPE || FILE_UPLOAD_FORMATS.technicalDeposit;
  const authorizedExtensions = useAuthorizedExtensions().getFileUpload(type, baseId, baseMisePageId);

  const uploadParams = {
    base: baseId,
    type,
  };

  if (baseMisePageId) {
    uploadParams.base_mise_page = baseMisePageId;
  }

  const handleClose = () => {
    dispatch(closeDialogUploadFiles());
  };

  const handleUploadClick = () => {
    if (_.isEmpty(filesToUpload)) {
      return;
    }
    _.forEach(filesToUpload, (value) => {
      if (!value.success) {
        const fd = new FormData();
        fd.append('file', value.file);
        dispatch(uploadFileToCreateDocument(uploadParams, fd, value.name));
      }
    });
  };

  if (!open) {
    return null;
  }
  return (
    <UploadFilesDialog
      filesToUpload={filesToUpload}
      handleClose={handleClose}
      handleUploadClick={handleUploadClick}
      accept={authorizedExtensions}
    />
  );
};

UploadFilesDialogContainer.propTypes = {
  baseId: PropTypes.number.isRequired,
  baseMisePageId: PropTypes.number,
};

UploadFilesDialogContainer.defaultProps = {
  baseMisePageId: undefined,
};

export default UploadFilesDialogContainer;
