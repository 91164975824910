import React, { Fragment } from 'react';
import {
  alpha,
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { format, formatDistance } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { commentPropType, commentPropTypeDefault } from 'generic/core/qes/proptypes';
import UserAvatar from 'generic/components/ui/UserAvatar';
import TooltipButton from 'generic/components/ui/TooltipButton';
import AutocompleteAjaxComment from 'generic/components/forms/AutocompleteAjaxComment';

const useStyles = makeStyles((theme) => {
  let backgroundOpacity = 0.15;
  let backgroundOpacityOnHover = 0.25;

  if (theme.palette.mode === 'dark') {
    backgroundOpacity = 0.5;
    backgroundOpacityOnHover = 0.6;
  }

  return {
    commentEdit: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: '60ch',
      },
    },
    comment: {
      padding: '0 8px 0 0',
    },
    switchAlert: {
      marginLeft: '0',
      marginRight: '0',
      marginTop: '5px',
    },
    commentContent: {
      color: theme.palette.primary.dark,
      borderRadius: '20px',
      position: 'relative',
      backgroundColor: alpha(theme.palette.primary.main, backgroundOpacity),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, backgroundOpacityOnHover),
      },
      padding: '6px 15px 6px 15px',
      marginTop: '0',
      marginBottom: theme.spacing(1),
      flex: '0 1 auto',
      display: 'linebreak',
    },
  };
});

const Comments = ({
  user,
  comments,
  alertChecked,
  hasComment,
  handleToggleCheckbox,
  deleteLoading,
  submitLoading,
  initialValues,
  handleSubmit,
  handleDeleteComment,
  autoFocusInput,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <List dense sx={{ width: 'auto', pb: 0 }}>
        {
          comments.map(
            (comment) => (
              <ListItem
                key={comment.id}
                alignItems="flex-start"
                className={classes.comment}
              >
                <ListItemAvatar>
                  <UserAvatar
                    user={{
                      utilisateur: comment.user_id,
                      prenom: comment.firstname,
                      nom: comment.name,
                      logon: comment.user,
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.commentContent}
                  primary={(
                    <Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {comment.user}
                      </Typography>
                      <Tooltip
                        title={
                          format(new Date(comment.date_creation), 'P HH:mm')
                        }
                      >
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.neutral"
                        >
                          {` - ${formatDistance(new Date(comment.date_creation), new Date(), { addSuffix: true })}`}
                        </Typography>
                      </Tooltip>
                      {(user.utilisateur === comment.user_id) && (
                        <TooltipButton
                          sx={{ marginLeft: '18px', float: 'right' }}
                          tag="fab"
                          size="extraSmall"
                          color="secondary"
                          onClick={() => handleDeleteComment(comment)}
                          disabled={deleteLoading}
                          title={t('results.comments.delete')}
                        >
                          <Delete />
                        </TooltipButton>
                      )}
                    </Fragment>
                  )}
                  secondary={comment.comment}
                />
              </ListItem>
            ),
          )
        }
      </List>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          handleSubmit(values);
          setSubmitting(false);
          setFieldValue('comment', '');
        }}
      >
        {() => (
          <Form>
            <Box mb={2}>
              <Box className={classes.commentEdit}>
                { user && (
                  <UserAvatar user={user} sx={{ mr: '6px' }} />
                )}
                <Field
                  margin="none"
                  variant="standard"
                  component={AutocompleteAjaxComment}
                  freeSolo
                  fullWidth
                  name="comment"
                  label=""
                  textFieldProps={{
                    placeholder: t('results.comments.write_comment'),
                  }}
                  disabled={deleteLoading || submitLoading}
                  submitLoading={submitLoading}
                  autoFocusInput={autoFocusInput}
                />
              </Box>
              <Tooltip
                enterDelay={500}
                title={!hasComment ? t('results.comments.be_alerted_disabled') : ''}
              >
                <FormControlLabel
                  className={classes.switchAlert}
                  control={(
                    <Switch
                      onClick={(event) => handleToggleCheckbox(event)}
                      checked={alertChecked}
                      size="small"
                      color="primary"
                      ml={0}
                      disabled={deleteLoading || submitLoading || !hasComment}
                    />
                  )}
                  label={t('results.comments.be_alerted')}
                />
              </Tooltip>
            </Box>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

Comments.propTypes = commentPropType;

Comments.defaultProps = commentPropTypeDefault;

export default Comments;
