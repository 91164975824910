import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';
import {
  types,
  fetchMoreLikeThisError,
} from 'generic/core/moreLikeThis/actions';
import { fetchMoreLikeThis as fetchMoreLikeThisApi } from 'generic/api/moreLikeThis';
import {
  setDocumentMoreLikeThisLoading,
  updateDocumentMoreLikeThis,
} from 'generic/core/search/actions';
import { snackActions } from 'generic/utils/snackbar';

function* workFetchMoreLikeThis({ params, reduxDocumentsKey }) {
  try {
    yield put(setDocumentMoreLikeThisLoading(params.id, true, reduxDocumentsKey));
    const morelikethis = yield call(fetchMoreLikeThisApi, { uriParams: { ...params } });
    if (morelikethis.response.data.docs) {
      yield put(updateDocumentMoreLikeThis(params.id, morelikethis.response.data.docs, reduxDocumentsKey));
    }
  } catch (error) {
    yield put(fetchMoreLikeThisError(error));
    console.error(error);
    snackActions.error(i18next.t('results.morelikethis.error_fetching_morelikethis'));
  } finally {
    yield put(setDocumentMoreLikeThisLoading(params.id, false, reduxDocumentsKey));
  }
}

function* watchFetchMoreLikeThis() {
  yield takeLatest(types.FETCH_MORE_LIKE_THIS, workFetchMoreLikeThis);
}

export default {
  watchFetchMoreLikeThis,
};
