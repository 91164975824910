import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import BodyHeader from 'generic/components/pages/anr/BodyHeader';
import {
  openLeftDrawer,
} from 'generic/core/ux/actions';
import ANR_CONSTANTS from 'generic/core/anr/constants';
import { checkIfItemHasQuery } from 'generic/utils/anrUtils';
import { fetchActiveItemResults, unsetFiltersParams } from 'generic/core/anr/actions';

const { ARTICLE_VIEW_ANCHOR, FOLDER_RESULTS_VIEWS } = ANR_CONSTANTS;

const BodyHeaderContainer = () => {
  const dispatch = useDispatch();
  const activeItem = useSelector((state) => state.anr.activeItem);
  const hasOpenedArticle = useSelector((state) => _.some(_.get(state, 'anr.articles', []), { opened: true }));
  const limit = useSelector((state) => state.anr.user.nb_links);
  const responseData = useSelector((state) => state.anr.responseData);

  const handlePageChangePagination = (event, page) => {
    const params = {
      offset: ((page - 1) * limit),
    };
    dispatch(fetchActiveItemResults(params));
  };

  const handleGoToLastValidated = () => {
    const params = {
      go_to_last_validated: true,
    };
    dispatch(unsetFiltersParams());
    dispatch(fetchActiveItemResults(params, { forceClearSelection: true }));
  };

  if (_.isEmpty(activeItem)) {
    return null;
  }

  const showHeaderItemsCondition = (
    activeItem.type !== 'mails'
    && _.includes(FOLDER_RESULTS_VIEWS, activeItem.view)
  );
  const hasQuery = checkIfItemHasQuery(activeItem);
  const showTabs = showHeaderItemsCondition && activeItem.has_quotes && activeItem.has_news;
  const showNoQuery = showHeaderItemsCondition && !activeItem.has_quotes && !activeItem.has_news;
  const filteredCount = _.get(responseData, 'filtered_count', 0);
  const totalCount = _.get(responseData, 'total_items', 0);
  const hasFullTextQuery = !_.isEmpty(activeItem) && activeItem.id !== 'glimpse' && !_.isEmpty(activeItem.query);
  let maxPage = 0;
  if (!_.isEmpty(activeItem) && !_.isEmpty(responseData)) {
    maxPage = Math.ceil(responseData.filtered_count / responseData.limit);

    if (hasFullTextQuery && activeItem.view === 'news' && responseData.filtered_count >= 1000) {
      maxPage = Math.floor(1000 / responseData.limit);
    }
  }
  return (
    <BodyHeader
      activeItem={activeItem}
      articleViewIsOpenedRight={ARTICLE_VIEW_ANCHOR === 'right' && hasOpenedArticle}
      filteredCount={filteredCount}
      handleGoToLastValidated={handleGoToLastValidated}
      handlePageChangePagination={handlePageChangePagination}
      maxPage={maxPage}
      openLeftDrawer={() => dispatch(openLeftDrawer())}
      responseData={responseData}
      shouldDisplayResults={hasQuery && activeItem.view !== 'settings'}
      shouldDisplayResultsCount={hasQuery && !_.isEmpty(responseData)}
      showNoQuery={showNoQuery}
      showTabs={showTabs}
      totalCount={totalCount}
    />
  );
};

export default BodyHeaderContainer;
