import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { fetchResults } from 'generic/core/search/actions';
import { changeActiveBase, resetFormFields } from 'generic/core/config/actions';
import FormSearchSimple from 'generic/components/forms/FormSearchSimple';
import {
  getNormalizedFormValues,
  computeSearchFormInitialValues,
  getActiveBaseDefaultSearch,
} from 'generic/utils/qesUtils';
import FormSearchAdvanced from 'generic/components/forms/FormSearchAdvanced';
import useCheckActiveBase from 'generic/core/hooks/useCheckActiveBase';
import LoadingOverlay from 'generic/components/ui/LoadingOverlay';

const SearchContainer = ({
  isVisible,
  uniqueFieldCode,
  uniqueFieldPlaceholder,
  uniqueFieldIcon,
  version,
}) => {
  const activeBase = useSelector((state) => state.config.activeBase);
  const {
    idChampRAG: RAGFieldId,
  } = activeBase;
  const bases = useSelector((state) => state.config.bases);
  const fields = useSelector((state) => state.config.form.champs);
  const loading = useSelector((state) => state.search.loading);
  const isRAGSearch = useSelector((state) => state.search.isRAGSearch);
  const results = useSelector((state) => state.search.results);
  const fetchError = useSelector((state) => state.search.fetchError);
  const location = useLocation();
  const {
    shouldChangeBase,
  } = useCheckActiveBase();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const locationParams = queryString.parse(_.get(location, 'search', ''));
  const finalFields = uniqueFieldCode ? [{ code: uniqueFieldCode }] : fields;
  const initialValues = computeSearchFormInitialValues(finalFields, locationParams);

  const fieldsAdvanced = [];
  if (!_.isEmpty(initialValues)) {
    _.each(finalFields, (field) => {
      if (field.mode === 1000) {
        // Seuls les champs avec field.mode === 1000 sont valides,
        // les autres servent à remplir les initialValues (champs
        // "masqués" correspondant aux facettes cliquées, par ex.)
        fieldsAdvanced.push(field);
      }
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatSearch = (values) => {
    const {
      tranche: activeBaseDefaultTranche,
      tranches: activeBaseTranches,
    } = activeBase;
    let tranche = activeBaseDefaultTranche;
    const localStorageTranche = +localStorage.getItem('rowsPerPage');
    if (!_.isEmpty(activeBaseTranches)
      && activeBaseTranches.includes(localStorageTranche)) {
      tranche = localStorageTranche;
    }
    const formattedValues = {
      base: activeBase.base,
      premier: 1,
      langue: 1,
      dernier: tranche,
      tranche,
      champs: getNormalizedFormValues(values),
    };
    return formattedValues;
  };

  const handleClear = () => {
    replace(_.get(location, 'pathname', ''));
    dispatch(resetFormFields());
  };

  const handleChangeActiveBase = (newBaseId) => {
    dispatch(changeActiveBase(newBaseId));
    const pathname = _.get(location, 'pathname', '');
    if (pathname.startsWith('/search/') || pathname.startsWith('/ged')) {
      replace(`/search/dashboard/${newBaseId}`);
    }
  };

  const handleSubmit = (values) => {
    const formattedValues = formatSearch(values);
    const isRAGSearchParam = RAGFieldId && !_.isEmpty(_.get(formattedValues, `champs.F_${RAGFieldId}`));
    dispatch(fetchResults({
      bodyItems: formattedValues,
      clearSelection: true,
      clearResults: true,
      refreshForm: true,
      uniqueFieldCode,
      isRAGSearch: isRAGSearchParam,
      checkLocation: true,
    }));
  };

  useEffect(() => {
    if (
      location.pathname.startsWith('/search/')
      && !loading
      && _.isEmpty(results)
      && shouldChangeBase === false
      && !fetchError
      && !uniqueFieldCode
    ) {
      handleSubmit(location?.search ? initialValues : getActiveBaseDefaultSearch(activeBase.base));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, location, results, shouldChangeBase]);

  if (location.pathname.startsWith('/search/') && shouldChangeBase) {
    return null;
  }

  return (
    <Fragment>
      {isRAGSearch && loading && (
        <LoadingOverlay />
      )}
      {version === 'simple' ? (
        <FormSearchSimple
          onSubmit={handleSubmit}
          initialValues={initialValues}
          fieldCode={uniqueFieldCode || 'F_2'}
          fieldPlaceholder={uniqueFieldPlaceholder || t('form.search')}
          fieldIcon={uniqueFieldIcon}
        />
      ) : (
        <FormSearchAdvanced
          activeBaseId={activeBase.base}
          activeBaseLabel={activeBase.libelle || t('config.default_base_label')}
          bases={bases}
          handleChangeActiveBase={handleChangeActiveBase}
          onSubmit={handleSubmit}
          loading={loading}
          initialValues={initialValues}
          handleClear={handleClear}
          fieldsAdvanced={fieldsAdvanced}
          isVisible={isVisible}
        />
      )}
    </Fragment>
  );
};

SearchContainer.propTypes = {
  version: PropTypes.oneOf(['simple', 'advanced']),
  isVisible: PropTypes.bool,
  uniqueFieldCode: PropTypes.string,
  uniqueFieldPlaceholder: PropTypes.string,
  uniqueFieldIcon: PropTypes.node,
};

SearchContainer.defaultProps = {
  version: 'simple',
  isVisible: true,
  uniqueFieldCode: null,
  uniqueFieldPlaceholder: null,
  uniqueFieldIcon: null,
};

export default SearchContainer;
