import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { TextField, Select } from 'formik-mui';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from 'generic/core/constants';
import QES_CONSTANTS from 'generic/core/qes/constants';
import _ from 'lodash';

const { EMAIL_REGEX } = CONSTANTS;
const { CIVILITY } = QES_CONSTANTS;

const UserForm = ({
  user,
  loading,
  handleClose,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  let modalTitle = t('users.create_title');
  if (user.utilisateur !== null) {
    modalTitle = `${t('users.edit_title')} ${user.prenom} ${user.nom}`;
  }

  const textfieldProps = {
    fullWidth: true,
    component: TextField,
    autoComplete: 'off',
  };
  // On ne met dans les initialValues que ce dont on a besoin dans le formulaire
  const initialValues = {
    utilisateur: user.utilisateur,
    civilite: user.civilite,
    nom: user.nom,
    prenom: user.prenom,
    email: user.email,
  };
  const yupSchema = {
    nom: Yup.string().required(t('form.errors.mandatory')),
    prenom: Yup.string().required(t('form.errors.mandatory')),
    email: Yup
      .string()
      .email(t('form.errors.not_valid_email'))
      .matches(EMAIL_REGEX, t('form.errors.not_valid_email'))
      .required(t('form.errors.mandatory')),
  };
  // Si c'est une création d'utilisateur (on a pas d'identifiant), on initialise les values "password"
  // et on ajoute les required dans le schéma Yup
  if (user.utilisateur === null) {
    initialValues.etat_utilisateur = user.etat_utilisateur;
    initialValues.logon = '';
    initialValues.password = '';
    initialValues.passwordConfirm = '';
    yupSchema.logon = Yup.string().required(t('form.errors.mandatory'));
    yupSchema.password = Yup.string().required(t('form.errors.mandatory'));
    yupSchema.passwordConfirm = Yup.string().required(t('form.errors.mandatory'))
      .oneOf([Yup.ref('password'), null], t('users.password_not_matching'));
  }
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape(yupSchema)}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
        >
          <Dialog
            scroll="paper"
            open
            maxWidth="lg"
            PaperProps={{
              sx: { minHeight: '60%' },
            }}
            fullWidth
          >
            <DialogTitle>
              {modalTitle}
            </DialogTitle>
            <DialogContent
              dividers
            >
              {user.utilisateur !== null && (
                <Field
                  sx={{ display: 'none' }}
                  name="utilisateur"
                  type="hidden"
                  component={TextField}
                />
              )}
              <Box display="flex" sx={{ gap: 1 }} flexWrap="wrap" alignItems="center" mb={1}>
                <Box flexGrow="1">
                  <Field
                    required
                    name="civilite"
                    label={t('users.civility')}
                    component={Select}
                    fullWidth
                    // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                    // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                    // override donc juste le onClose pour qu'il ne fasse rien.
                    onClose={_.noop}
                  >
                    {
                      _.map(
                        CIVILITY,
                        (id, gender) => (
                          <MenuItem
                            key={id}
                            value={id}
                          >
                            {t(`users.civilities.${gender}`)}
                          </MenuItem>
                        ),
                      )
                    }
                  </Field>
                </Box>
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="nom"
                    label={t('users.lastname')}
                    required
                  />
                </Box>
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="prenom"
                    label={t('users.firstname')}
                    required
                  />
                </Box>
              </Box>
              <Field
                {...textfieldProps}
                name="email"
                label={t('users.email')}
                required
                sx={{ mb: 1.5 }}
              />

              {(user.utilisateur === null) && (
                <Fragment>
                  <Field
                    {...textfieldProps}
                    name="logon"
                    label={t('users.logon')}
                    required
                    sx={{ mb: 1.5 }}
                  />
                  <Field
                    {...textfieldProps}
                    name="password"
                    label={t('users.password')}
                    InputProps={{
                      type: 'password',
                      inputProps: {
                        maxLength: '50',
                      },
                    }}
                    required
                    sx={{ mb: 1.5 }}
                  />
                  <Field
                    {...textfieldProps}
                    name="passwordConfirm"
                    label={t('users.password_confirm')}
                    InputProps={{
                      type: 'password',
                      inputProps: {
                        maxLength: '50',
                      },
                    }}
                    required
                    sx={{ mb: 1.5 }}
                  />
                </Fragment>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.save')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  user: utilisateurPropType.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserForm;
