import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  restoreStateFromStorage, logon, resetPassword,
} from 'generic/core/auth/actions';

import { Formik, Form } from 'formik';

import { overrideRessource } from 'generic/utils/utils';

const FormLogin = overrideRessource('components/forms/FormLogin');

const FormLoginContainer = () => {
  // Selectors
  const loading = useSelector((state) => state.auth.loading);
  const logonError = useSelector((state) => state.auth.logonError);

  const resetPasswordLoading = useSelector((state) => state.auth.resetPasswordLoading);
  const resetPasswordError = useSelector((state) => state.auth.resetPasswordError);

  const [showResetPassword, setResetPassword] = useState(false);
  const toggleResetPassword = () => setResetPassword(!showResetPassword);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(restoreStateFromStorage());
  }, [dispatch]);

  const formikConfig = {
    initialValues: {
      login: '',
      password: '',
      emailOrLogin: '',
    },
    onSubmit: (values, actions) => {
      const callback = () => { actions.setSubmitting(false); };
      if (showResetPassword) {
        const { emailOrLogin } = values;
        dispatch(resetPassword({ emailOrLogin, callback }));
      } else {
        const { login, password } = values;
        dispatch(logon({ login, password, callback }));
      }
    },
  };

  return (
    <Formik enableReinitialize {...formikConfig}>
      <Form>
        <FormLogin
          loading={loading}
          logonError={logonError}
          resetPasswordError={resetPasswordError}
          resetPasswordLoading={resetPasswordLoading}
          toggleResetPassword={toggleResetPassword}
          variant={showResetPassword ? 'reset' : 'logon'}
        />
      </Form>
    </Formik>
  );
};

export default FormLoginContainer;
