import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutline, ErrorOutline, InfoOutlined, WarningOutlined,
} from '@mui/icons-material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import TaskAdvancementSlider from './TaskAdvancementSlider';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const family = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, 
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

const STATUSES = {
  1: {
    severity: 'success',
    icon: <CheckCircleOutline sx={{ color: 'success.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.1'),
  },
  2: {
    severity: 'info',
    icon: <InfoOutlined sx={{ color: 'info.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.2'),
  },
  3: {
    severity: 'error',
    icon: <ErrorOutline sx={{ color: 'error.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.3'),
  },
  4: {
    severity: 'error',
    icon: <ErrorOutline sx={{ color: 'error.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.4'),
  },
  1000: {
    severity: 'warning',
    icon: <WarningOutlined sx={{ color: 'warning.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.1000'),
  },
};

const CardWithStatus = ({
  selected, onClick, title, message, tag, severity, max, value, rawFile,
}) => {
  const { t } = useTranslation();
  const logon = getLogonFromStorage();
  const token = getTokenFromStorage();
  const withSlider = Boolean(max && value && max > 0);
  const icon = _.find(STATUSES, { severity })?.icon;

  return (
    <Card
      sx={{
        border: selected ? '2px solid #1976d2' : '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: selected ? '0 0 10px rgba(25, 118, 210, 0.5)' : 'none',
        transition: 'all 0.3s ease-in-out',
        mb: 1,
        mx: 1,
      }}
      onClick={onClick}
    >
      <CardActionArea>
        <CardContent>
          <Stack direction="row" alignItems="center" gap={1}>
            { (icon && !withSlider) && icon }
            <Typography
              component="h3"
              sx={{ fontFamily: family, fontSize: 16, marginBottom: 0 }}
            >
              {title}
            </Typography>
          </Stack>

          <Typography sx={{
            fontFamily: family,
            fontSize: 14,
            color: 'grey.600',
            letterSpacing: '1px',
            marginBottom: '4px',
          }}
          >
            {message}
            {tag && (
              <Box
                component="span"
                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
              >
                •
                {tag}
              </Box>
            )}
          </Typography>
          {withSlider && (
            <TaskAdvancementSlider {...{
              max,
              value,
              severity,
            }}
            />
          )}
        </CardContent>
      </CardActionArea>
      {rawFile && (
        <CardActions sx={{ pl: 2 }}>
          <Link
            target="blank"
            href={`${API_ENTRY_POINT}/file?type=8&file=${rawFile}&logon=${logon}&key=${token}`}
            size="small"
          >
            {t('schedules.tabs.reports.view_raw_log')}
          </Link>
        </CardActions>
      )}
    </Card>
  );
};
CardWithStatus.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number,
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  rawFile: PropTypes.string,
  message: PropTypes.string,
  tag: PropTypes.string,
};

CardWithStatus.defaultProps = {
  selected: false,
  rawFile: '',
  message: '',
  tag: '',
  max: null,
  value: null,
};

export default memo(CardWithStatus);
