import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ToggleMobileSearchIcon from 'generic/components/ui/ToggleMobileSearchIcon';

const ToggleMobileSearchIconContainer = ({
  handleClick,
  isVisible,
  headerBackgroundColorIsDark,
}) => {
  const formStrategy = useSelector((state) => state.search.results.strategie);

  return (
    <ToggleMobileSearchIcon
      handleClick={handleClick}
      isVisible={isVisible}
      isBadgeVisible={!_.isEmpty(formStrategy)}
      headerBackgroundColorIsDark={headerBackgroundColorIsDark}
    />
  );
};

ToggleMobileSearchIconContainer.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  headerBackgroundColorIsDark: PropTypes.bool,
};

ToggleMobileSearchIconContainer.defaultProps = {
  headerBackgroundColorIsDark: true,
};

export default ToggleMobileSearchIconContainer;
