/**
 * Permet, à partir d'une valeur fournis de vérifier s'il s'agit d'un code Mitre
 * parmis les tactiques, techniques, campagne, atténuation et logiciel,
 * et si c'est le cas, ouvre une popup vers les détails de ce code Mitre
 */
const goToMitre = (value) => {
  let externalLink;

  const BASE_URL = 'https://attack.mitre.org';
  const TACTICS_MATCHER = /TA\d+(?:.\d+)?/;
  const TECHNIQUES_MATCHER = /T\d+(?:.\d+)?/;
  const CAMPAING_MATCHER = /C\d+(?:.\d+)?/;

  const MITIGATION_MATCHER = /M\d+/;
  const SOFTWARE_MATCHER = /S\d+/;

  // la valeur est tjrs en majuscule dans les URL de mitre.org
  const valueUpperCase = value.toUpperCase();

  if (TECHNIQUES_MATCHER.test(valueUpperCase)) {
    const [techniques, version] = valueUpperCase.split('.');
    externalLink = `${BASE_URL}/techniques/${techniques}/${version || ''}/`;
  } else if (TACTICS_MATCHER.test(valueUpperCase)) {
    const [tactic, version] = valueUpperCase.split('.');
    externalLink = `${BASE_URL}/tactics/${tactic}/${version || ''}/`;
  } else if (CAMPAING_MATCHER.test(valueUpperCase)) {
    const [campaign, version] = valueUpperCase.split('.');
    externalLink = `${BASE_URL}/campaigns/${campaign}/${version || ''}/`;
  } else if (MITIGATION_MATCHER.test(valueUpperCase)) {
    externalLink = `${BASE_URL}/mitigations/${valueUpperCase}`;
  } else if (SOFTWARE_MATCHER.test(valueUpperCase)) {
    externalLink = `${BASE_URL}/software/${valueUpperCase}`;
  }

  if (externalLink) window.open(externalLink, '_blank');
};

export default goToMitre;
