import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  List,
  ListItem,
  MenuItem,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import {
  DeleteOutlined,
  MailOutlined,
  Summarize,
} from '@mui/icons-material';
import {
  FastField,
  Field,
  Form,
  Formik,
} from 'formik';
import { Select, TextField } from 'formik-mui';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

import AutocompleteAjax from 'generic/components/forms/AutocompleteAjax';
import CenteredMessage from 'generic/components/ui/CenteredMessage';
import FileUpload from 'generic/components/forms/FileUpload';
import PageBackAndTitle from 'generic/components/ui/PageBackAndTitle';
import SendToFriendDialogContainer from 'generic/containers/SendToFriendDialogContainer';
import SummarizeContainer from 'generic/containers/SummarizeContainer';
import TooltipButton from 'generic/components/ui/TooltipButton';

import { getLogonFromStorage, getTokenFromStorage, overrideRessource } from 'generic/utils/utils';
import { doAutocompleteUser } from 'generic/api/users';
import { cartOrNewsletterPropType } from 'generic/core/qes/proptypes';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { CONSTANTS } from 'generic/core/constants';
import useAuthorizedExtensions from 'generic/core/hooks/useAuthorizedExtensions';

const Document = overrideRessource('components/documents/Document');

const { CK_EDITOR_CONFIG } = CONSTANTS;
const {
  API_ENTRY_POINT,
  CART_VISIBILITY,
  FILE_UPLOAD_FORMATS,
} = QES_CONSTANTS;

const Cart = ({
  allArticlesChecked,
  cart,
  checkedItems,
  fileUploading,
  fileUploadName,
  handleClearUploadField,
  handleGetDocumentComplete,
  handleOpenDeleteDocumentConfirmDialog,
  handleOpenSendToFriend,
  handleSummarize,
  handleResetUploadField,
  handleToggleCheckbox,
  handleToggleCheckboxAll,
  onSave,
  saveCartLoading,
  uploadActions,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const handleCurrentTab = useCallback(
    (event, newValue) => { setCurrentTab(newValue); },
    [setCurrentTab],
  );

  const visibilityOptions = Object.values(CART_VISIBILITY).map(
    (id) => ({ code: id, libelle: t(`carts.visibility_values.${id}`) }),
  );

  const initialValues = {
    panier: cart.panier,
    intitule: cart.intitule,
    visibilite_panier: cart.visibilite_panier,
    image: cart.image,
    texte: cart.texte,
    panierUtilisateurs: cart.panierUtilisateurs,
  };

  const handleGetOptionLabel = (userObjectOrEmail) => {
    if (typeof userObjectOrEmail === 'string') return userObjectOrEmail;
    const nom = _.get(userObjectOrEmail, 'nom', '');
    const prenom = _.get(userObjectOrEmail, 'prenom', '');
    return _.compact([prenom, nom]).join(' ');
  };

  // Pour differencier les homonymes dans la fenetre contextuelle de selection
  const handleRenderOption = (props, option) => {
    const {
      email,
      nom,
      prenom,
      utilisateur,
    } = option;
    const prenomStr = (prenom) ? `${prenom} ` : '';
    const emailStr = (email && email !== nom) ? ` (${email})` : '';

    return (
      <li {...props} key={utilisateur}>
        <div>
          {`${prenomStr}${nom}${emailStr}`}
        </div>
      </li>
    );
  };

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();
  const authorizedExtensions = useAuthorizedExtensions().getFileUpload(FILE_UPLOAD_FORMATS.cart);

  let imgSrc = '';
  if (fileUploadName) {
    imgSrc = `${API_ENTRY_POINT}/file?type=0&file=${fileUploadName}&logon=${logon}&key=${key}`;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      className="desktopOnlyOverflow"
    >
      <Box
        className="stickyHeader"
        borderBottom="1px solid"
        borderColor="divider"
        zIndex="1200"
      >
        <PageBackAndTitle
          backLink="/carts"
          backBtnTitle={t('carts.back_to_list')}
          title={cart.intitule}
          showImg
          imgSrc={imgSrc}
        />
        <Tabs
          allowScrollButtonsMobile
          onChange={handleCurrentTab}
          scrollButtons="auto"
          sx={{ px: 1 }}
          value={currentTab}
          variant="scrollable"
        >
          <Tab label={t('newsletters.edit.details')} />
          <Tab label={t('newsletters.edit.documents')} />
        </Tabs>
      </Box>
      <Box display="flex" flexGrow="1" className="desktopOnlyOverflow">
        <Box
          className="desktopOnlyOverflow"
          display={currentTab === 0 ? 'flex' : 'none'}
          flexDirection="column"
          width="100%"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              intitule: Yup.string().required(t('form.errors.mandatory')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              onSave(values);
              setSubmitting(false);
            }}
            onReset={handleResetUploadField}
            enableReinitialize
            validateOnMount
          >
            {(form) => (
              <Form
                className={clsx('displayFlex', 'flexGrow1', 'flexDirectionColumn', 'desktopOnlyOverflow')}
              >
                <Box
                  flexGrow="1"
                  className="desktopOnlyOverflow"
                  p={2}
                >
                  <Field
                    component={TextField}
                    label={t('carts.name')}
                    autoFocus
                    name="intitule"
                    fullWidth
                    color="primary"
                    required
                  />
                  <Box
                    sx={{ mt: 1 }}
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    gap={2}
                  >
                    <Box
                      width={210}
                    >
                      <Field
                        component={Select}
                        label={t('carts.visibility')}
                        name="visibilite_panier"
                        // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                        // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                        // override donc juste le onClose pour qu'il ne fasse rien.
                        onClose={_.noop}
                      >
                        {_.map(visibilityOptions, (option) => (
                          <MenuItem key={option.code} value={option.code}>{option.libelle}</MenuItem>
                        ))}
                      </Field>
                    </Box>
                    {form.values.visibilite_panier === CART_VISIBILITY.collaborative && (
                      <Box flexGrow="1">
                        <FastField
                          component={AutocompleteAjax}
                          name="panierUtilisateurs"
                          facet=""
                          multiple
                          highlight={false}
                          doAutocompleteOverride={doAutocompleteUser}
                          getOptionLabel={handleGetOptionLabel}
                          renderOption={handleRenderOption}
                          isOptionEqualToValue={(option, value) => option.utilisateur === value.utilisateur}
                          fullWidth
                          textFieldProps={{
                            label: t('carts.contributors'),
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{ mt: 1 }}
                  >
                    <Field
                      component={FileUpload}
                      name="image"
                      fileUploadName={fileUploadName}
                      label={t('carts.image')}
                      uploadParams={{ type: FILE_UPLOAD_FORMATS.cart }}
                      accept={authorizedExtensions}
                      fileUploading={fileUploading}
                      uploadActions={uploadActions}
                      handleClearField={handleClearUploadField}
                      withThumb
                      thumbDisplayedLeft
                    />
                  </Box>
                  <Box
                    sx={{ mt: 3 }}
                    width="100%"
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      name="texte"
                      data={form.values.texte}
                      config={CK_EDITOR_CONFIG}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        form.setFieldValue('texte', data);
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  className="bottomBtnsContainer"
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Button
                      color="primary"
                      variant="text"
                      type="reset"
                      onClick={form.resetForm}
                    >
                      {t('form.reset')}
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={saveCartLoading || fileUploading || !form.isValid}
                    >
                      {t('form.save')}
                    </Button>
                  </Stack>
                </Box>

              </Form>
            )}
          </Formik>
        </Box>
        <Box
          className="desktopOnlyOverflow"
          display={currentTab === 1 ? 'flex' : 'none'}
          flexDirection="column"
          width="100%"
        >
          {!_.isEmpty(cart.panierDocuments) ? (
            <Fragment>
              <Box m={2} mb={1} display="flex" alignItems="center">
                <Checkbox
                  checked={allArticlesChecked}
                  onChange={(event) => handleToggleCheckboxAll(event)}
                />
                <ButtonGroup
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  disableElevation
                >
                  <TooltipButton
                    color="secondary"
                    disabled={_.isEmpty(checkedItems)}
                    onClick={handleOpenDeleteDocumentConfirmDialog}
                    size="small"
                    title={t('carts.delete_cart_documents', { count: _.keys(checkedItems).length })}
                    startIcon={<DeleteOutlined />}
                  >
                    {t('actions.delete')}
                  </TooltipButton>
                  <TooltipButton
                    color="secondary"
                    disabled={_.isEmpty(checkedItems)}
                    onClick={handleOpenSendToFriend}
                    size="small"
                    title={t('carts.send_cart_documents', { count: _.keys(checkedItems).length })}
                    startIcon={<MailOutlined />}
                  >
                    {t('actions.send')}
                  </TooltipButton>
                  <TooltipButton
                    color="secondary"
                    disabled={_.isEmpty(checkedItems)}
                    onClick={handleSummarize}
                    size="small"
                    title={t('carts.summarize_cart_documents', { count: _.keys(checkedItems).length })}
                    startIcon={<Summarize />}
                  >
                    {t('actions.summarize')}
                  </TooltipButton>
                </ButtonGroup>
              </Box>
              {/*
                Pour éviter tout problème de rendu des Document (par l'utilisation de refs
                ou autre effets à l'affichage, on ne render les documents que si l'utilisateur
                va sur l'onglet)
              */}
              {currentTab === 1 && (
                <List dense className="overflowAuto">
                  {
                    cart.panierDocuments.map(
                      (panierDocument) => (
                        <ListItem
                          alignItems="flex-start"
                          key={panierDocument.idext}
                        >
                          <Checkbox
                            onClick={() => handleToggleCheckbox(
                              panierDocument,
                            )}
                            checked={checkedItems[panierDocument.idext] !== undefined}
                          />
                          <Document
                            disableTagsRefine
                            document={panierDocument}
                            handleGetDocumentComplete={
                              () => handleGetDocumentComplete(panierDocument.idext, panierDocument.base)
                            }
                          />
                        </ListItem>
                      ),
                    )
                  }
                </List>
              )}
            </Fragment>
          ) : (
            <CenteredMessage>
              {t('carts.no_document')}
            </CenteredMessage>
          )}
        </Box>
      </Box>
      <SendToFriendDialogContainer
        scope="cart"
        options={{ cartId: cart.panier, idDocs: _.map(checkedItems, 'documentitem') }}
      />
      <SummarizeContainer
        scope="cart"
        hasDrawer
        options={{ cartId: cart.panier, idDocs: _.map(checkedItems, 'documentitem') }}
      />
    </Box>
  );
};

Cart.propTypes = {
  cart: cartOrNewsletterPropType,
  saveCartLoading: PropTypes.bool,
  fileUploading: PropTypes.bool,
  fileUploadName: PropTypes.string,
  handleToggleCheckbox: PropTypes.func.isRequired,
  handleToggleCheckboxAll: PropTypes.func.isRequired,
  checkedItems: PropTypes.shape(),
  allArticlesChecked: PropTypes.bool,
  handleOpenDeleteDocumentConfirmDialog: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleSummarize: PropTypes.func.isRequired,
  sidenavSummarizeOpened: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  uploadActions: PropTypes.shape({
    loading: PropTypes.shape({ type: PropTypes.string.isRequired }),
    success: PropTypes.shape({ type: PropTypes.string.isRequired }),
    error: PropTypes.shape({ type: PropTypes.string.isRequired }),
  }),
  handleClearUploadField: PropTypes.func.isRequired,
  handleResetUploadField: PropTypes.func.isRequired,
  handleGetDocumentComplete: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  cart: {},
  saveCartLoading: false,
  fileUploading: false,
  fileUploadName: '',
  checkedItems: {},
  allArticlesChecked: false,
  uploadActions: [],
  sidenavSummarizeOpened: false,
};

export default Cart;
