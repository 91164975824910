export const types = {
  SUMMARIZE: '@SUMMARIZE/SUMMARIZE',
  SUMMARIZE_SUCCESS: '@SUMMARIZE/SUMMARIZE_SUCCESS',
  SUMMARIZE_ERROR: '@SUMMARIZE/SUMMARIZE_ERROR',
};

export const summarize = (scope, options) => ({
  type: types.SUMMARIZE,
  scope,
  options,
});

export const summarizeSuccess = (summary) => ({
  type: types.SUMMARIZE_SUCCESS,
  summary,
});

export const summarizeError = (response) => ({
  type: types.SUMMARIZE_ERROR,
  response,
});
