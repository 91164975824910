import React, { Fragment } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import AvatarUploadDialog from 'generic/components/dialogs/AvatarUploadDialog';
import { saveUserImage, uploadUserImageCloseDialog } from 'generic/core/config/actions';
import AvatarUploadImage from 'generic/components/ui/AvatarUploadImage';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const AvatarUploadContainer = () => {
  const user = useSelector((state) => state.config.user);
  const dispatch = useDispatch();

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  const handleSubmit = (crop) => {
    dispatch(saveUserImage({
      utilisateur: user.utilisateur,
      image: user.avatarName,
      image_json: {
        ...crop,
        scaleX: 1,
        scaleY: 1,
      },
    }));
  };

  const handleClose = () => {
    dispatch(uploadUserImageCloseDialog());
  };

  if (!user) {
    return null;
  }

  const cropperImage = `${API_ENTRY_POINT}/file?type=6&file=${user.avatarName}&logon=${logon}&key=${key}`;
  return (
    <Fragment>
      <AvatarUploadImage
        imageUploading={user.imageUploading}
        userId={user.utilisateur}
        userImage={user.image}
        thumbWidth={130}
        thumbHeight={130}
      />
      {user.avatarName && (
        <AvatarUploadDialog
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          cropperImage={cropperImage}
          loading={user.saveUserLoading}
        />
      )}
    </Fragment>
  );
};

export default AvatarUploadContainer;
