import { types } from 'generic/core/mailingLists/actions';

const mailingListReducer = (
  state = {
    mailingLists: [],
    emails: [],
    editLoading: true,
    loading: true,
    dialogMailingList: {},
    dialogUserMailingList: {},
    goToEmail: '',
  },
  action,
) => {
  switch (action.type) {
    /* Reducer des listes de diffusion */
    case types.CLEANUP_MAILING_LISTS: {
      return {
        ...state,
        mailingLists: [],
      };
    }
    case types.FETCH_MAILING_LISTS: {
      return {
        ...state,
        mailingLists: [],
        loading: true,
      };
    }
    case types.FETCH_MAILING_LISTS_SUCCESS: {
      return {
        ...state,
        mailingLists: action.mailingLists,
        loading: false,
      };
    }
    case types.FETCH_MAILING_LISTS_ERROR: {
      return {
        ...state,
        mailingLists: [],
        loading: false,
      };
    }
    case types.OPEN_MAILING_LIST_DIALOG: {
      return {
        ...state,
        dialogMailingList: {
          open: true,
          idMailingList: action.id,
          nameMailingList: action.name,
        },
      };
    }
    case types.CLOSE_MAILING_LIST_DIALOG: {
      return {
        ...state,
        dialogMailingList: {
          open: false,
        },
      };
    }

    case types.CREATE_MAILING_LIST: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: true,
        },
      };
    }

    case types.CREATE_MAILING_LIST_SUCCESS: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
          open: false,
        },
      };
    }

    case types.CREATE_MAILING_LIST_ERROR: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
        },
      };
    }

    case types.EDIT_MAILING_LIST: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: true,
        },
      };
    }

    case types.EDIT_MAILING_LIST_SUCCESS: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
          open: false,
        },
      };
    }

    case types.EDIT_MAILING_LIST_ERROR: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
        },
      };
    }

    /* Reducer des utilisateurs des listes de diffusion */
    case types.CLEANUP_USERS_MAILING_LIST: {
      return {
        ...state,
        emails: [],
      };
    }

    case types.FETCH_USERS_MAILING_LIST: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.FETCH_USERS_MAILING_LIST_SUCCESS: {
      return {
        ...state,
        emails: action.emails,
        editLoading: false,
        loading: false,
        goToEmail: action.goToEmail ? action.goToEmail : '',
      };
    }

    case types.FETCH_USERS_MAILING_LIST_ERROR: {
      return {
        ...state,
        emails: [],
        editLoading: false,
        loading: false,
      };
    }

    case types.OPEN_USER_MAILING_LIST_DIALOG: {
      return {
        ...state,
        dialogUserMailingList: {
          open: true,
        },
      };
    }

    case types.CLOSE_USER_MAILING_LIST_DIALOG: {
      return {
        ...state,
        dialogUserMailingList: {
          open: false,
        },
      };
    }

    case types.CREATE_USER_MAILING_LIST: {
      return {
        ...state,
        editLoading: !action.newUser,
        dialogUserMailingList: {
          ...state.dialogUserMailingList,
          loading: true,
        },
      };
    }

    case types.CREATE_USER_MAILING_LIST_SUCCESS: {
      return {
        ...state,
        dialogUserMailingList: {
          open: false,
          loading: false,
        },
      };
    }

    case types.CREATE_USER_MAILING_LIST_ERROR: {
      return {
        ...state,
        dialogUserMailingList: {
          ...state.dialogUserMailingList,
          loading: false,
        },
      };
    }

    case types.EDIT_USER_MAILING_LIST: {
      return {
        ...state,
        editLoading: true,
        dialogUserMailingList: {
          ...state.dialogUserMailingList,
          loading: true,
        },
      };
    }

    case types.EDIT_USER_MAILING_LIST_SUCCESS: {
      return {
        ...state,
        dialogUserMailingList: {
          open: false,
          loading: false,
        },
      };
    }

    case types.EDIT_USER_MAILING_LIST_ERROR: {
      return {
        ...state,
        dialogUserMailingList: {
          ...state.dialogUserMailingList,
          loading: false,
        },
      };
    }

    case types.DELETE_USERS_MAILING_LIST: {
      return {
        ...state,
        editLoading: true,
      };
    }

    default:
      return state;
  }
};

export default mailingListReducer;
