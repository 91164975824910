import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ConfirmDialog = (props) => {
  const {
    loading,
    handleSubmit,
    handleClose,
    title,
    textButton,
    dialogContent,
    submitColor,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      scroll="paper"
      onClose={handleClose}
      onKeyPress={(event) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
          handleSubmit();
        }
      }}
      open
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent
        dividers
      >
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          color={submitColor}
        >
          {t(textButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  textButton: PropTypes.string,
  dialogContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitColor: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  submitColor: 'primary',
  textButton: 'ui.yes',
};

export default ConfirmDialog;
