import React, { Fragment } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import ContentGrid from 'generic/components/pages/ContentGrid';
import BodyContent from 'generic/components/pages/anr/BodyContent';
import ArticleViewContainer from 'generic/containers/anr/ArticleViewContainer';
import ANR_CONSTANTS from 'generic/core/anr/constants';
import FoldersContainer from 'generic/containers/anr/FoldersContainer';

const { SETTINGS_VIEWS } = ANR_CONSTANTS;

const FoldersWrapper = () => {
  const {
    view,
  } = useParams();
  const showSettings = (_.includes(SETTINGS_VIEWS, view));

  return (
    <Fragment>
      <ContentGrid
        leftChild={<FoldersContainer />}
        middleChild={<BodyContent />}
      />
      {!showSettings && (
        <ArticleViewContainer />
      )}
    </Fragment>
  );
};

export default FoldersWrapper;
