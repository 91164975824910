import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'generic/locales/en';
import fr from 'generic/locales/fr';

const resources = {
  ...en,
  ...fr,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false, // inutile pour React, car echappé par défaut
    },
  });

export default i18n;
