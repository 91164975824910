import { get, post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const buildSendToFriend = ({ ...rest }) => get({ path: '/send', ...rest }).then(handleComplete);

const submitSendToFriend = ({ ...rest }) => post({ path: '/send', ...rest }).then(handleComplete);

export {
  buildSendToFriend,
  submitSendToFriend,
};
