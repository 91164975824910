import { types } from 'generic/core/comment/actions';

const commentReducer = (
  state = {
    loading: false,
    deleteLoading: false,
    submitLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case types.FETCH_COMMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.FETCH_COMMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.FETCH_COMMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.CREATE_COMMENT: {
      return {
        ...state,
        submitLoading: true,
      };
    }
    case types.CREATE_COMMENT_SUCCESS: {
      return {
        ...state,
        submitLoading: false,
      };
    }
    case types.CREATE_COMMENT_ERROR: {
      return {
        ...state,
        submitLoading: false,
      };
    }
    case types.DELETE_COMMENT: {
      return {
        ...state,
        deleteLoading: true,
      };
    }
    case types.DELETE_COMMENT_SUCCESS: {
      return {
        ...state,
        deleteLoading: false,
      };
    }
    case types.DELETE_COMMENT_ERROR: {
      return {
        ...state,
        deleteLoading: false,
      };
    }
    case types.UPDATE_COMMENT_ALERT: {
      return {
        ...state,
        submitLoading: true,
      };
    }
    case types.UPDATE_COMMENT_ALERT_SUCCESS: {
      return {
        ...state,
        submitLoading: false,
      };
    }
    case types.UPDATE_COMMENT_ALERT_ERROR: {
      return {
        ...state,
        submitLoading: false,
      };
    }
    default:
      return state;
  }
};

export default commentReducer;
