import { types } from 'generic/core/newsletters/actions';

const newslettersReducer = (
  state = {
    newsletters: [],
    themes: [],
    loading: true,
    editNewsletter: { loading: true },
    editNewsletterState: {},
    addToNewsletterLoading: false,
    sending: false,
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_NEWSLETTER: {
      return {
        ...state,
        editNewsletter: { loading: true },
      };
    }
    case types.CLEANUP_NEWSLETTERS: {
      return {
        ...state,
        newsletters: [],
      };
    }
    case types.FETCH_NEWSLETTERS: {
      return {
        ...state,
        loading: true,
        newsletters: [],
      };
    }
    case types.FETCH_NEWSLETTERS_SUCCESS: {
      return {
        ...state,
        newsletters: action.newsletters,
        loading: false,
      };
    }
    case types.FETCH_NEWSLETTERS_ERROR: {
      return {
        ...state,
        newsletters: [],
        loading: false,
      };
    }
    case types.FETCH_NEWSLETTER: {
      return {
        ...state,
        editNewsletter: { loading: true },
      };
    }
    case types.FETCH_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        editNewsletter: {
          ...action.newsletter,
          loading: false,
        },
        editNewsletterState: {
          ...state.editNewsletterState,
          fileUploadName: action.newsletter.image,
          fileUploading: false,
        },
      };
    }
    case types.FETCH_NEWSLETTER_ERROR: {
      return {
        ...state,
        editNewsletter: { loading: false },
      };
    }
    case types.SAVE_NEWSLETTER: {
      return {
        ...state,
        editNewsletterState: {
          ...state.editNewsletterState,
          saveNewsletterLoading: true,
        },
      };
    }
    case types.SAVE_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        editNewsletterState: {
          ...state.editNewsletterState,
          saveNewsletterLoading: false,
        },
      };
    }
    case types.SAVE_NEWSLETTER_ERROR: {
      return {
        ...state,
        responseData: action.response,
        editNewsletterState: {
          ...state.editNewsletterState,
          saveNewsletterLoading: false,
        },
      };
    }
    case types.UPLOAD_NEWSLETTER_IMAGE: {
      return {
        ...state,
        editNewsletterState: {
          ...state.editNewsletterState,
          fileUploading: true,
        },
      };
    }
    case types.UPLOAD_NEWSLETTER_IMAGE_SUCCESS: {
      return {
        ...state,
        editNewsletterState: {
          ...state.editNewsletterState,
          fileUploadName: action.results.filename,
          fileUploading: false,
        },
      };
    }
    case types.UPLOAD_NEWSLETTER_IMAGE_ERROR: {
      return {
        ...state,
        editNewsletterState: {
          ...state.editNewsletterState,
          fileUploading: false,
        },
      };
    }
    case types.CLEAR_UPLOAD_NEWSLETTER_IMAGE: {
      return {
        ...state,
        editNewsletterState: {
          ...state.editNewsletterState,
          fileUploadName: '',
        },
      };
    }
    case types.RESET_NEWSLETTER_IMAGE: {
      return {
        ...state,
        editNewsletterState: {
          ...state.editNewsletterState,
          fileUploadName: state.editNewsletter.image,
          fileUploading: false,
        },
      };
    }
    case types.ADD_TO_NEWSLETTER: {
      return {
        ...state,
        addToNewsletterLoading: true,
      };
    }
    case types.ADD_TO_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        addToNewsletterLoading: false,
      };
    }
    case types.ADD_TO_NEWSLETTER_ERROR: {
      return {
        ...state,
        addToNewsletterLoading: false,
      };
    }
    case types.SEND_NEWSLETTER_HISTO:
    case types.SEND_NEWSLETTER: {
      return {
        ...state,
        sending: true,
      };
    }
    case types.SEND_NEWSLETTER_HISTO_SUCCESS:
    case types.SEND_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        editNewsletter: action.newsletter,
        sending: false,
      };
    }
    case types.SEND_NEWSLETTER_HISTO_ERROR:
    case types.SEND_NEWSLETTER_ERROR: {
      return {
        ...state,
        sending: false,
      };
    }

    default:
      return state;
  }
};

export default newslettersReducer;
