import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import { Select, Switch, TextField } from 'formik-mui';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import QES_CONSTANTS from 'generic/core/qes/constants';

const FormDashboardWidget = ({
  initialValues,
  handleClose,
  handleSubmit,
  loading,
  isInDialog,
  formTitle,
  creation,
  canCreateCooccurrencesWidget,
  canCreateRelationsWidget,
}) => {
  const { t } = useTranslation();
  const [widgetType, setWidgetType] = useState(initialValues.type);

  const textfieldProps = {
    fullWidth: true,
    component: TextField,
    autoComplete: 'off',
    sx: { mt: 2 },
  };

  const widgetDefaultParams = QES_CONSTANTS.DEFAULT_WIDGETS_FORMS_PARAMS[widgetType];
  const yupSchema = {
    title: Yup.string().required(t('form.errors.mandatory')),
  };
  _.forOwn(widgetDefaultParams, (value, key) => {
    if (!['width', 'height'].includes(key)) {
      if (typeof value === 'number') {
        yupSchema[key] = Yup.number()
          .typeError(t('form.errors.must_be_integer'))
          .integer(t('form.errors.must_be_integer'))
          .min(0, `${t('form.errors.must_be_more_than')} 0`);
        if (key !== 'list') {
          yupSchema[key] = yupSchema[key].required(t('form.errors.mandatory'));
        }
      } else if (typeof value === 'string') {
        yupSchema[key] = Yup.string();
        if (!['aggregates', 'pivots', 'computeLink'].includes(key)) {
          yupSchema[key] = yupSchema[key].required(t('form.errors.mandatory'));
        }
      } else if (typeof value === 'boolean') {
        yupSchema[key] = Yup.boolean();
      }
    }
  });

  const finalInitialValues = { ...widgetDefaultParams, ...initialValues };
  finalInitialValues.type = widgetType;

  let Container = Box;
  let containerProps = {};
  if (isInDialog) {
    Container = Dialog;
    containerProps = {
      PaperProps: {
        sx: { minHeight: '60%' },
      },
      fullWidth: true,
      scroll: 'paper',
      maxWidth: 'lg',
      open: true,
    };
  } else {
    containerProps = {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    };
  }
  return (
    <Formik
      enableReinitialize
      initialValues={finalInitialValues}
      validationSchema={Yup.object().shape(yupSchema)}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (isInDialog && event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
          className={isInDialog ? '' : 'height100'}
        >
          <Container
            {...containerProps}
          >
            <DialogTitle>
              {formTitle}
            </DialogTitle>
            <DialogContent
              dividers
            >
              {creation && (
                <Field
                  component={Select}
                  name="type"
                  label={t('dashboard.widget.form.type')}
                  sx={{ width: '100%', mb: 3 }}
                  onChange={(event) => {
                    setWidgetType(event.target.value);
                  }}
                  onClose={_.noop}
                  defaultOpen
                >
                  <MenuItem
                    value="pie"
                  >
                    {t('dashboard.widget.type.pie')}
                  </MenuItem>
                  <MenuItem
                    value="documentlist"
                  >
                    {t('dashboard.widget.type.documentlist')}
                  </MenuItem>
                  <MenuItem
                    value="wordcloud"
                  >
                    {t('dashboard.widget.type.wordcloud')}
                  </MenuItem>
                  <MenuItem
                    value="datatable"
                  >
                    {t('dashboard.widget.type.datatable')}
                  </MenuItem>
                  <MenuItem
                    value="treemap"
                  >
                    {t('dashboard.widget.type.treemap')}
                  </MenuItem>
                  <MenuItem
                    value="solidgauge"
                  >
                    {t('dashboard.widget.type.solidgauge')}
                  </MenuItem>
                  <MenuItem
                    value="bar"
                  >
                    {t('dashboard.widget.type.bar')}
                  </MenuItem>
                  {canCreateRelationsWidget && (
                    <MenuItem
                      value="relations"
                    >
                      {t('dashboard.widget.type.relations')}
                    </MenuItem>
                  )}
                  {canCreateCooccurrencesWidget && (
                    <MenuItem
                      value="cooccurrences"
                    >
                      {t('dashboard.widget.type.cooccurrences')}
                    </MenuItem>
                  )}
                  <MenuItem
                    value="map"
                  >
                    {t('dashboard.widget.type.map')}
                  </MenuItem>
                  <MenuItem
                    value="spline"
                  >
                    {t('dashboard.widget.type.spline')}
                  </MenuItem>
                  <MenuItem
                    value="sankey"
                  >
                    {t('dashboard.widget.type.sankey')}
                  </MenuItem>
                  <MenuItem
                    value="sunburst"
                  >
                    {t('dashboard.widget.type.sunburst')}
                  </MenuItem>
                </Field>
              )}
              {// A VENIR > champs "dynamiques" en fonction de ce qui existe dans le formulaire de search
                /* <Field
                component={Select}
                name="fields"
                label="fields"
                sx={{ width: '100%' }}
                // renderValue={(selected) => (_.map(selected, 'libelle').join(', '))}
                // isOptionEqualToValue={(option, value) => option.interrogation === value.interrogation}
                // MenuProps={{
                //   disablePortal: true,
                // }}
                multiple
              >
                {_.map(
                  _.filter(fields, 'interrogation'),
                  (field) => (
                    <MenuItem
                      key={field.interrogation}
                      value={field.interrogation}
                    >
                      {field.libelle}
                    </MenuItem>
                  ),
                )}
              </Field> */}
              <Box flexGrow="1">
                <Field
                  {...textfieldProps}
                  name="title"
                  label={t('dashboard.widget.form.title')}
                  required
                />
              </Box>
              {_.has(widgetDefaultParams, 'slice') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="slice"
                    label={t('dashboard.widget.form.slice')}
                    value={form.values.slice || ''}
                    required
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'additionalQuery') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="additionalQuery"
                    label={t('dashboard.widget.form.additionalQuery')}
                    value={form.values.additionalQuery || ''}
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'sort') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="sort"
                    label={t('dashboard.widget.form.sort')}
                    value={form.values.sort || ''}
                    required
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'facetmax') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="facetmax"
                    label={t('dashboard.widget.form.facetmax')}
                    value={form.values.facetmax || ''}
                    required
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'facetmax2') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="facetmax2"
                    label={t('dashboard.widget.form.facetmax2')}
                    value={form.values.facetmax2 || ''}
                    required
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'monoColors') && (
                <FormControlLabel
                  sx={{ width: '100%', py: '4px', pl: '8px' }}
                  control={(
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="monoColors"
                      color="primary"
                    />
                  )}
                  label={t('dashboard.widget.form.monoColors')}
                />
              )}
              {_.has(widgetDefaultParams, 'tones') && (
                <FormControlLabel
                  sx={{ width: '100%', py: '4px', pl: '8px' }}
                  control={(
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="tones"
                      color="primary"
                    />
                  )}
                  label={t('dashboard.widget.form.tones')}
                />
              )}
              {_.has(widgetDefaultParams, 'humanizeDocState') && (
                <FormControlLabel
                  sx={{ width: '100%', py: '4px', pl: '8px' }}
                  control={(
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="humanizeDocState"
                      color="primary"
                    />
                  )}
                  label={t('dashboard.widget.form.humanizeDocState')}
                />
              )}
              {_.has(widgetDefaultParams, 'totalunique') && (
                <FormControlLabel
                  sx={{ width: '100%', py: '4px', pl: '8px' }}
                  control={(
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="totalunique"
                      color="primary"
                    />
                  )}
                  label={t('dashboard.widget.form.totalunique')}
                />
              )}
              {_.has(widgetDefaultParams, 'humanizeLangs') && (
                <FormControlLabel
                  sx={{ width: '100%', py: '4px', pl: '8px' }}
                  control={(
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="humanizeLangs"
                      color="primary"
                    />
                  )}
                  label={t('dashboard.widget.form.humanizeLangs')}
                />
              )}
              {_.has(widgetDefaultParams, 'withExploration') && (
                <FormControlLabel
                  sx={{ width: '100%', py: '4px', pl: '8px' }}
                  control={(
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="withExploration"
                      color="primary"
                    />
                  )}
                  label={t('dashboard.widget.form.withExploration')}
                />
              )}
              {_.has(widgetDefaultParams, 'facets') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="facets"
                    label={t('dashboard.widget.form.facets')}
                    value={form.values.facets || ''}
                    required
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'aggregates') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="aggregates"
                    label={t('dashboard.widget.form.aggregates')}
                    value={form.values.aggregates || ''}
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'computeLink') && (
                <Field
                  component={Select}
                  name="computeLink"
                  label={t('dashboard.widget.form.computeLink')}
                  sx={{ width: '100%' }}
                  onClose={_.noop}
                  value={form.values.computeLink || ''}
                  displayEmpty
                >
                  <MenuItem
                    value=""
                  >
                    {t('dashboard.widget.form.noComputeLinkMethod')}
                  </MenuItem>
                  <MenuItem
                    value="goToMitre"
                  >
                    {t('dashboard.widget.form.computeLinkWithGoToMitre')}
                  </MenuItem>
                  <MenuItem
                    value="goToAlienVault"
                  >
                    {t('dashboard.widget.form.computeLinkWithGoToAlienVault')}
                  </MenuItem>
                </Field>
              )}
              {_.has(widgetDefaultParams, 'pivots') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="pivots"
                    label={t('dashboard.widget.form.pivots')}
                    value={form.values.pivots || ''}
                  />
                </Box>
              )}
              {_.has(widgetDefaultParams, 'list') && (
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="list"
                    label={t('dashboard.widget.form.list')}
                    value={form.values.list || ''}
                  />
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.save')}
              </LoadingButton>
            </DialogActions>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

FormDashboardWidget.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  isInDialog: PropTypes.bool,
  formTitle: PropTypes.string,
  creation: PropTypes.bool,
  canCreateCooccurrencesWidget: PropTypes.bool,
  canCreateRelationsWidget: PropTypes.bool,
};

FormDashboardWidget.defaultProps = {
  loading: false,
  isInDialog: false,
  formTitle: i18next.t('dashboard.add_new_widget'),
  creation: false,
  canCreateCooccurrencesWidget: false,
  canCreateRelationsWidget: false,
};

export default FormDashboardWidget;
