import React from 'react';
import {
  Box,
  Link,
  Typography,
} from '@mui/material';
import { qesdocumentPropType } from 'generic/core/qes/proptypes';
import { overrideRessource } from 'generic/utils/utils';
import JsxJoinerWithSeparator from 'generic/components/ui/JsxJoinerWithSeparator';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const DocumentMoreLikeThis = ({
  qesdocument,
}) => {
  const {
    title, linkout, date, source,
  } = useDocumentFields({
    base: +qesdocument.BASE_ID,
    qesdocument: { ...qesdocument },
  });

  return (
    <Box display="block">
      <Link
        underline="none"
        href={linkout}
        target="_blank"
        rel="noopener"
        color="text.primary"
        fontWeight="500"
        fontSize="0.8rem"
        display="block"
      >
        {title}
      </Link>

      <Box
        display="flex"
        flexWrap="wrap"
        flexGrow="1"
        lineHeight="1rem"
        sx={{ wordBreak: 'break-word' }}
      >
        <JsxJoinerWithSeparator items={[
          date && (
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              fontSize="0.8rem"
            >
              {date}
            </Typography>
          ),
          source && (
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              fontSize="0.8rem"
            >
              {source}
            </Typography>
          ),
        ]}
        />
      </Box>
    </Box>
  );
};

DocumentMoreLikeThis.propTypes = {
  qesdocument: qesdocumentPropType,
};

DocumentMoreLikeThis.defaultProps = {
  qesdocument: {},
};

export default DocumentMoreLikeThis;
