import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import { getRandomColor } from 'generic/utils/colorUtils';
import _ from 'lodash';

const useComputedTranscription = (rawTextJSON, rawTranslation) => {
  const currentTheme = useColorSchemeDetector();
  if (_.isEmpty(rawTextJSON)) {
    return [];
  }
  const speakersColors = {};
  const { entitiesColors } = currentTheme.HIGHCHARTS;
  let translatedSentences = [];
  if (!_.isEmpty(rawTranslation) && rawTranslation.indexOf('\n') !== -1) {
    translatedSentences = rawTranslation.split('\n');
  }
  const rawJSON = JSON.parse(rawTextJSON);
  return _.map(rawJSON, (sentence, index) => {
    if (!speakersColors[sentence.spkid]) {
      let speakerColor;
      if (index < 10) {
        speakerColor = entitiesColors[index];
      } else {
        speakerColor = getRandomColor(index, 'hexa');
      }
      speakersColors[sentence.spkid] = speakerColor;
    }

    return {
      ...sentence,
      spkColor: speakersColors[sentence.spkid],
      translation: translatedSentences.length === rawJSON.length ? translatedSentences[index] : null,
    };
  });
};

// eslint-disable-next-line import/prefer-default-export
export { useComputedTranscription };
