import {
  get,
  del,
  patch,
  post,
} from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const fetchAlerts = ({ ...rest }) => get({ path: '/veille/retrieve-all', ...rest }).then(handleComplete);

const fetchAlert = (id) => get({ path: `/veille/${id}` }).then(handleComplete);

const deleteAlerts = (ids) => del({ path: `/veille/${ids.join(',')}` }).then(handleComplete);

const updateAlert = ({ ...rest }) => patch({ path: '/veille', ...rest }).then(handleComplete);

// eslint-disable-next-line max-len
const activateDeactivateAlerts = ({ ...rest }) => post({ path: '/veille/activate-deactivate', ...rest }).then(handleComplete);

const createAlert = ({ ...rest }) => post({ path: '/veille/create-from-results', ...rest }).then(handleComplete);

export {
  fetchAlerts,
  fetchAlert,
  deleteAlerts,
  updateAlert,
  activateDeactivateAlerts,
  createAlert,
};
