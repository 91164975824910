/**
 * Permet, à partir d'une valeur fournis de vérifier s'il s'agit d'un code CVE
 * et si c'est le cas, ouvre une popup vers les détails de ce CVE
 */
const goToCVE = (value) => {
  let externalLink;
  const BASE_URL = 'https://www.cvedetails.com/cve';
  const CVE_MATCHER = /CVE-\d+-\d+/i;

  const valueUpperCase = value.toUpperCase();
  if (CVE_MATCHER.test(valueUpperCase)) {
    externalLink = `${BASE_URL}/${valueUpperCase}/`;
  }

  if (externalLink) window.open(externalLink, '_blank');
};

export default goToCVE;
