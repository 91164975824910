export const types = {
  CLEANUP_DASHBOARD: '@DASHBOARD/CLEANUP_DASHBOARD',
  CLEANUP_DASHBOARDS: '@DASHBOARD/CLEANUP_DASHBOARDS',
  CLOSE_DIALOG_CREATE_DASHBOARD: '@DASHBOARD/CLOSE_DIALOG_CREATE_DASHBOARD',
  CLOSE_DIALOG_CREATE_WIDGET: '@DASHBOARD/CLOSE_DIALOG_CREATE_WIDGET',
  DELETE_DASHBOARD: '@DASHBOARD/DELETE_DASHBOARD',
  DELETE_DASHBOARD_ERROR: '@DASHBOARD/DELETE_DASHBOARD_ERROR',
  DELETE_DASHBOARD_SUCCESS: '@DASHBOARD/DELETE_DASHBOARD_SUCCESS',
  DELETE_WIDGET: '@DASHBOARD/DELETE_WIDGET',
  DELETE_WIDGET_ERROR: '@DASHBOARD/DELETE_WIDGET_ERROR',
  DELETE_WIDGET_SUCCESS: '@DASHBOARD/DELETE_WIDGET_SUCCESS',
  FETCH_DASHBOARDS: '@DASHBOARD/FETCH_DASHBOARDS',
  FETCH_DASHBOARDS_ERROR: '@DASHBOARD/FETCH_DASHBOARDS_ERROR',
  FETCH_DASHBOARDS_SUCCESS: '@DASHBOARD/FETCH_DASHBOARDS_SUCCESS',
  FETCH_WIDGETS: '@DASHBOARD/FETCH_WIDGETS',
  FETCH_WIDGET_ERROR: '@DASHBOARD/FETCH_WIDGET_ERROR',
  FETCH_WIDGET_SUCCESS: '@DASHBOARD/FETCH_WIDGET_SUCCESS',
  OPEN_DIALOG_CREATE_WIDGET: '@DASHBOARD/OPEN_DIALOG_CREATE_WIDGET',
  OPEN_DIALOG_EDIT_OR_CREATE_DASHBOARD: '@DASHBOARD/OPEN_DIALOG_EDIT_OR_CREATE_DASHBOARD',
  SAVE_DASHBOARD: '@DASHBOARD/SAVE_DASHBOARD',
  SAVE_DASHBOARD_ERROR: '@DASHBOARD/SAVE_DASHBOARD_ERROR',
  SAVE_DASHBOARD_SUCCESS: '@DASHBOARD/SAVE_DASHBOARD_SUCCESS',
  SAVE_WIDGETS: '@DASHBOARD/SAVE_WIDGETS',
  SAVE_WIDGETS_ERROR: '@DASHBOARD/SAVE_WIDGETS_ERROR',
  SAVE_WIDGETS_SUCCESS: '@DASHBOARD/SAVE_WIDGETS_SUCCESS',
  SET_DASHBOARD_LOADING: '@DASHBOARD/SET_DASHBOARD_LOADING',
  STOP_WIDGET_LOADING: '@DASHBOARD/STOP_WIDGET_LOADING',
  SET_CONTEXT_MENU: '@DASHBOARD/SET_CONTEXT_MENU',
  UNSET_CONTEXT_MENU: '@DASHBOARD/UNSET_CONTEXT_MENU',
};

export const openDialogCreateWidget = () => ({
  type: types.OPEN_DIALOG_CREATE_WIDGET,
});

export const closeDialogCreateWidget = () => ({
  type: types.CLOSE_DIALOG_CREATE_WIDGET,
});

export const openDialogEditOrCreateDashboard = (editDashboard) => ({
  type: types.OPEN_DIALOG_EDIT_OR_CREATE_DASHBOARD,
  editDashboard,
});

export const closeDialogEditOrCreateDashboard = () => ({
  type: types.CLOSE_DIALOG_CREATE_DASHBOARD,
});

export const cleanupDashboards = () => ({
  type: types.CLEANUP_DASHBOARDS,
});

export const fetchDashboards = () => ({
  type: types.FETCH_DASHBOARDS,
});

export const fetchDashboardsSuccess = (dashboards) => ({
  type: types.FETCH_DASHBOARDS_SUCCESS,
  dashboards,
});

export const fetchDashboardsError = (response) => ({
  type: types.FETCH_DASHBOARDS_ERROR,
  response,
});

/**
 * Action de lancement du fetch de tous les widgets
 * @param {String} query la stratégie de recherche
 * @param {Array} widgets paramètres des widgets, exemple [{ type: 'spline', aggregates: ['agg_PUB_DATE'] }, ...]
 * @param {Boolean} keepOtherWidgetsData précise si on veut garder les
 * données des "autres" widgets dans redux
 * @param {Boolean} keepAllWidgetsData précise si on veut garder les
 * données de tous les widgets (permet d'append aux données existantes
 * pour "relations" notamment)
 * @param {String} exploreFrom le noeud depuis lequel on lance une exploration (uniquement pour "relations")
 * @param {Integer} baseId l'ID de base (si non précisé, l'ID de la base active sera utilisé)
 * @returns {Object} l'action
 */
export const fetchWidgets = (
  query,
  widgets,
  keepOtherWidgetsData,
  keepAllWidgetsData,
  exploreFrom,
  baseId,
) => ({
  type: types.FETCH_WIDGETS,
  query,
  widgets,
  keepOtherWidgetsData,
  keepAllWidgetsData,
  exploreFrom,
  baseId,
});

export const fetchWidgetSuccess = (widgetId, widget) => ({
  type: types.FETCH_WIDGET_SUCCESS,
  widgetId,
  widget,
});

export const fetchWidgetError = (widgetId, response) => ({
  type: types.FETCH_WIDGET_ERROR,
  response,
  widgetId,
});

export const saveWidgets = (params, isLayoutChange) => ({
  type: types.SAVE_WIDGETS,
  params,
  isLayoutChange,
});

export const saveWidgetsSuccess = (widgets, addition) => ({
  type: types.SAVE_WIDGETS_SUCCESS,
  widgets,
  addition,
});

export const saveWidgetsError = () => ({
  type: types.SAVE_WIDGETS_ERROR,
});

export const deleteWidgetSuccess = (widget) => ({
  type: types.DELETE_WIDGET_SUCCESS,
  widget,
});

export const deleteWidgetError = () => ({
  type: types.DELETE_WIDGET_ERROR,
});

export const stopWidgetLoading = () => ({
  type: types.STOP_WIDGET_LOADING,
});

export const cleanupDashboard = () => ({
  type: types.CLEANUP_DASHBOARD,
});

export const setDashboardLoading = () => ({
  type: types.SET_DASHBOARD_LOADING,
});

export const saveDashboard = (params) => ({
  type: types.SAVE_DASHBOARD,
  params,
});

export const saveDashboardSuccess = (dashboard, addition) => ({
  type: types.SAVE_DASHBOARD_SUCCESS,
  dashboard,
  addition,
});

export const saveDashboardError = () => ({
  type: types.SAVE_DASHBOARD_ERROR,
});

export const deleteDashboardSuccess = (dashboard) => ({
  type: types.DELETE_DASHBOARD_SUCCESS,
  dashboard,
});

export const deleteDashboardError = () => ({
  type: types.DELETE_DASHBOARD_ERROR,
});

export const setContextMenu = (datas) => ({
  type: types.SET_CONTEXT_MENU,
  datas,
});

export const unsetContextMenu = () => ({
  type: types.UNSET_CONTEXT_MENU,
});
