export const types = {
  OPEN_LEFT_DRAWER: '@UX/OPEN_LEFT_DRAWER',
  CLOSE_LEFT_DRAWER: '@UX/CLOSE_LEFT_DRAWER',
  OPEN_RIGHT_DRAWER: '@UX/OPEN_RIGHT_DRAWER',
  CLOSE_RIGHT_DRAWER: '@UX/CLOSE_RIGHT_DRAWER',
  SET_BACK_PAGE_LOCATION: '@UX/SET_BACK_PAGE_LOCATION',
  UNSET_BACK_PAGE_LOCATION: '@UX/UNSET_BACK_PAGE_LOCATION',
  TOGGLE_IS_RAG_INPUT_OPENED: '@UX/TOGGLE_IS_RAG_INPUT_OPENED',
  TOGGLE_RESULTS_COMPLETE_VISIBILITY_IN_RESULTS: '@UX/TOGGLE_RESULTS_COMPLETE_VISIBILITY_IN_RESULTS',
};

export const openLeftDrawer = () => ({
  type: types.OPEN_LEFT_DRAWER,
});
export const closeLeftDrawer = () => ({
  type: types.CLOSE_LEFT_DRAWER,
});
export const openRightDrawer = () => ({
  type: types.OPEN_RIGHT_DRAWER,
});
export const closeRightDrawer = () => ({
  type: types.CLOSE_RIGHT_DRAWER,
});
export const setBackPageLocation = (location) => ({
  type: types.SET_BACK_PAGE_LOCATION,
  location,
});
export const unsetBackPageLocation = () => ({
  type: types.UNSET_BACK_PAGE_LOCATION,
});
export const toggleIsRAGInputOpened = (isOpened) => ({
  type: types.TOGGLE_IS_RAG_INPUT_OPENED,
  isOpened,
});
export const toggleResultsCompleteVisibilityInResults = (isVisible) => ({
  type: types.TOGGLE_RESULTS_COMPLETE_VISIBILITY_IN_RESULTS,
  isVisible,
});
