import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Sort from 'generic/components/ui/Sort';
import { changeSort } from 'generic/core/search/actions';

const SortContainer = () => {
  const sorts = useSelector((state) => state.search.results.tris);
  const currentSort = useSelector((state) => state.search.results.triActif);
  const dispatch = useDispatch();

  const handleChangeSort = (event) => {
    dispatch(changeSort(event.target.value));
  };

  if (_.isEmpty(sorts)) {
    return null;
  }

  return (
    <Sort
      sorts={sorts}
      currentSort={currentSort}
      handleChangeSort={handleChangeSort}
    />
  );
};

export default SortContainer;
