import React, {
  useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Tab,
  Tabs,
} from '@mui/material';

import PageBackAndTitle from 'generic/components/ui/PageBackAndTitle';
import ScheduleDetails from 'generic/components/schedules/ScheduleDetails';
import SchedulePrograming from 'generic/components/schedules/SchedulePrograming';
import ScheduleReports from 'generic/components/schedules/ScheduleReports';

import { schedulePropType } from 'generic/core/qes/proptypes';

const Schedule = ({
  schedule,
  ...rest
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const handleCurrentTab = useCallback(
    (event, newValue) => { setCurrentTab(newValue); },
    [setCurrentTab],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      className="desktopOnlyOverflow"
    >
      <Box
        className="stickyHeader"
        borderBottom="1px solid"
        borderColor="divider"
        zIndex="1200"
      >
        <PageBackAndTitle
          backLink="/schedules"
          backBtnTitle={t('carts.back_to_list')}
          title={schedule.libelle}
        />
        <Tabs
          allowScrollButtonsMobile
          onChange={handleCurrentTab}
          scrollButtons="auto"
          sx={{ px: 1 }}
          value={currentTab}
          variant="scrollable"
        >
          <Tab label={t('schedules.tabs.details.tab_name')} />
          <Tab label={t('schedules.tabs.reports.tab_name')} />
          <Tab label={t('schedules.tabs.programmation.tab_name')} />
          {/*
          <Tab label={t('schedules.tabs.traitements.tab_name')} />
          */}
        </Tabs>
      </Box>

      <Box display="flex" flexGrow="1" className="desktopOnlyOverflow">
        <Box
          className="desktopOnlyOverflow"
          flexDirection="column"
          display="flex"
          flexGrow="1"
          width="100%"
        >
          {currentTab === 0 && (
            <ScheduleDetails {...{
              schedule,
              ...rest,
            }}
            />
          )}
          {currentTab === 1 && (
            <ScheduleReports
              tasks={schedule.schedule_traitements}
              reports={schedule.rapports}
              {...rest}
            />
          )}
          {currentTab === 2 && (
            <SchedulePrograming schedules={schedule.schedule_comprehensions} {...rest} />
          )}

          {/*
          {currentTab === 3 && (
            <ScheduleTasks tasks={schedule.schedule_traitements} {...rest} />
          )}
          */}
        </Box>
      </Box>
    </Box>
  );
};

Schedule.propTypes = {
  schedule: schedulePropType.isRequired,
  saveLoading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  handleClickActivateDeactivate: PropTypes.func.isRequired,
  handleExecuteNow: PropTypes.func.isRequired,
};

Schedule.defaultProps = {
  saveLoading: false,
};

export default Schedule;
