import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Profile from 'generic/components/pages/Profile';
import { saveProfile } from 'generic/core/config/actions';

const ProfileContainer = () => {
  const user = useSelector((state) => state.config.user);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(saveProfile(values));
  };

  return (
    <Profile
      user={user}
      handleSubmit={handleSubmit}
    />
  );
};

export default ProfileContainer;
