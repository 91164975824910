import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, fetchCarts } from 'generic/core/carts/actions';
import AddToCartOrNewsletterDialog from 'generic/components/dialogs/AddToCartOrNewsletterDialog';
import { closeDialogAddToCart } from 'generic/core/actions/actions';
import { clearSingleItem } from 'generic/core/selection/actions';

const AddToCartDialogContainer = ({ scope }) => {
  const open = useSelector((state) => state.actions.dialogAddToCartOpened);
  const carts = useSelector((state) => state.carts.carts);
  const loading = useSelector((state) => state.carts.loading);
  const addToCartLoading = useSelector((state) => state.carts.addToCartLoading);
  const dispatch = useDispatch();
  const [cartChosen, setCartChosen] = useState(null);

  const handleAddToCart = (cartId) => {
    setCartChosen(cartId);
    dispatch(addToCart({ cartId }, scope));
  };

  const handleAddToNewCart = (newCartName) => {
    setCartChosen('new');
    dispatch(addToCart({ newCartName }, scope));
  };

  const handleClose = () => {
    dispatch(clearSingleItem());
    dispatch(closeDialogAddToCart());
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchCarts());
    }
  }, [open, dispatch]);

  if (!open) {
    return null;
  }
  return (
    <AddToCartOrNewsletterDialog
      addToItemLoading={addToCartLoading}
      handleAddToItem={handleAddToCart}
      handleAddToNewItem={handleAddToNewCart}
      handleClose={handleClose}
      itemChosen={cartChosen}
      items={carts}
      itemsType="carts"
      loading={loading}
    />
  );
};

AddToCartDialogContainer.propTypes = {
  scope: PropTypes.oneOf(['qes', 'anr']).isRequired,
};

export default AddToCartDialogContainer;
