import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import CenteredCircularProgress from 'generic/components/ui/CenteredCircularProgress';
import { getThemeAppLocal } from 'generic/utils/utils';

const GlobalLoading = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeAppLocal = getThemeAppLocal();
  let backgroundColor = '#ffffff';
  if ((themeAppLocal === 1000 && prefersDarkMode) || themeAppLocal === 2) {
    backgroundColor = '#222B32';
  }
  return (
    <Box
      position="relative"
      height="100%"
      width="100%"
      backgroundColor={backgroundColor}
    >
      <Box
        position="absolute"
        height="100%"
        width="100%"
      >
        <CenteredCircularProgress />
      </Box>
    </Box>
  );
};

export default GlobalLoading;
