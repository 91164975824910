import { get, post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const doAutocomplete = ({ ...rest }) => get({ path: '/elastic/search-autocomplete', ...rest }).then(handleComplete);

const doSearch = ({ ...rest }) => post({ path: '/search/key-values', ...rest }).then(handleComplete);

const doSearchComplete = ({ ...rest }) => post({ path: '/search', ...rest }).then(handleComplete);

export {
  doAutocomplete,
  doSearch,
  doSearchComplete,
};
