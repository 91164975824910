import * as types from 'generic/core/auth/actionTypes';

// login actions
export const restoreStateFromStorage = () => ({
  type: types.RESTORE_STATE_FROM_STORAGE,
});

export const restoreStateFromStorageError = () => ({
  type: types.RESTORE_STATE_FROM_STORAGE_FAILURE,
});

export const logon = ({ login, password, callback }) => (
  {
    type: types.LOGON,
    login,
    password,
    callback,
  }
);

export const logonError = (error) => ({
  type: types.LOGON_ERROR,
  error,
});

export const logonSuccess = (token) => (
  {
    type: types.LOGON_SUCCESS,
    token,
  }
);

// logout actions
export const logout = () => (
  {
    type: types.LOGOUT,
  }
);

export const fetchError = (response) => ({
  type: types.FETCH_ERROR,
  response,
});

export const uploadUserImage = (file) => ({
  type: types.UPLOAD_USER_IMAGE,
  file,
});

export const uploadUserImageSuccess = (results) => ({
  type: types.UPLOAD_USER_IMAGE_SUCCESS,
  results,
});

export const uploadUserImageError = () => ({
  type: types.UPLOAD_USER_IMAGE_ERROR,
});

export const saveProfile = (params) => ({
  type: types.SAVE_PROFILE,
  params,
});

export const saveProfileSuccess = (user) => ({
  type: types.SAVE_PROFILE_SUCCESS,
  user,
});

export const saveProfileError = () => ({
  type: types.SAVE_PROFILE_ERROR,
});

export const resetPassword = ({ emailOrLogin, callback }) => ({
  type: types.RESET_PASSWORD,
  emailOrLogin,
  callback,
});

export const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordError = (error) => ({
  type: types.RESET_PASSWORD_ERROR,
  error,
});

export const resetPasswordConfirmation = ({
  a, newPassword, newPasswordConfirmation, callback,
}) => ({
  type: types.RESET_PASSWORD_CONFIRMATION,
  a,
  newPassword,
  newPasswordConfirmation,
  callback,
});

export const resetPasswordConfirmationSuccess = () => ({
  type: types.RESET_PASSWORD_CONFIRMATION_SUCCESS,
});

export const resetPasswordConfirmationError = (error) => ({
  type: types.RESET_PASSWORD_CONFIRMATION_ERROR,
  error,
});
