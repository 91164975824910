// Ce fichier sert a déclarer les fonctions spécifiques de haut niveau pour les projets custom
// Dans le générique, les méthodes déclarées ici seront normalement toujours vides

const computeUserAdditionnalAttrs = () => {
  console.info('Méthode computeUserAdditionnalAttrs() générique');
};

export {
  // eslint-disable-next-line import/prefer-default-export
  computeUserAdditionnalAttrs,
};
