import _ from 'lodash';

/**
 * Permet de constuire la series nécessaire pour afficher un graph Sankey Highcharts sur une profondeur
 * indéfinie d'aggrégation
 * @param {Object} ESResponse l'objet réponse reçu de l'API elastic (en passant par QES)
 * @param {String} firstAggName le nom du premier aggrégat. S'il y a des pivots, ils seront trouvés automatiquement
 * @returns un tableau d'objet `[{ from: 'A', to: 'B', weight: 42 }, { from: 'B', to: 'C', weight: 12 }]`
 */
const buildSankey = (ESResponse, firstAggName) => {
  const goDownAndFill = (parent, node, result) => {
    if (parent !== null) {
      const { key: to, doc_count: weight } = node;
      result.push({ from: parent.key, to, weight });
    }

    _.forEach(node, (objValue, objKey) => {
      if (_.startsWith(objKey, 'agg_') && !_.isEmpty(objValue?.buckets)) {
        const pivotValues = objValue.buckets;
        _.each(pivotValues, (pivotValue) => {
          goDownAndFill(node, pivotValue, result);
        });
      }
    });
  };

  const highchartSerie = [];
  const aggregateValues = _.get(ESResponse, ['response', 'facets', firstAggName], []);
  _.each(aggregateValues, (aggVal) => {
    goDownAndFill(null, aggVal, highchartSerie);
  });

  return highchartSerie;
};

/**
 * Permet de créer la serie nécessaire pour un graph de type Polar/Radar sur un seul aggrégat avec un pivot
 * @param {Object} ESResponse l'objet réponse reçu de l'API elastic (en passant par QES)
 * @param {String} firstAggName le nom du premier aggrégat. S'il y a des pivots, ils seront trouvés automatiquement
 * @returns Un tableau de valeur simple `[a, b, c, d]` ces valeurs seront mises en relations avec les `axisX.categories`
 * de la série courante.
 */
const buildPolar = (ESResponse, firstAggName) => {
  const highchartSerie = [];
  const aggregateValues = _.get(ESResponse, ['response', 'facets', firstAggName], []);

  // on va chercher les valeurs du pivot de cet aggrégat
  aggregateValues.forEach((aggVal) => {
    const datas = _.chain(aggVal)
      .pickBy((v, k) => _.startsWith(k, 'agg_'))
      .map('value')
      .value();

    highchartSerie.push(...datas);
  });

  return highchartSerie;
};

const buildBarStacked = (ESResponse, firstAggName, pivotName) => {
  const aggregateValues = _.get(ESResponse, ['response', 'facets', firstAggName], []);

  const uniqCatX = new Set();
  const uniqCatY = new Set();
  _.each(aggregateValues, (agg1) => {
    uniqCatX.add(agg1.key);
    _.each(agg1[pivotName]?.buckets, (agg2) => {
      uniqCatY.add(agg2.key);
    });
  });

  const result = {
    xAxis: { categories: [...uniqCatX] },
    yAxis: { categories: [...uniqCatY] },
    series: [],
  };

  const bucketsKeys = {};
  aggregateValues.forEach((aggVal, index) => {
    const picked = _.pickBy(aggVal, (v, k) => _.startsWith(k, 'agg_'));
    _.each(picked, ({ buckets }) => _.each(buckets, (bucket) => {
      const serieName = bucket.key;
      if (!Object.hasOwn(bucketsKeys, serieName)) {
        bucketsKeys[serieName] = _.fill(Array(uniqCatX.size), -1);
      }
      bucketsKeys[serieName][index] = bucket.doc_count;
    }));
  });

  result.series = _.map(bucketsKeys, (v, k) => (
    {
      name: k,
      data: v,
    }
  ));

  return result;
};

export {
  buildBarStacked,
  buildSankey,
  buildPolar,
};
