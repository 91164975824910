import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  banSite,
  closeBanSiteDialog,
} from 'generic/core/anr/actions';
import BanSiteDialog from 'generic/components/dialogs/anr/BanSiteDialog';

const BanSiteContainer = () => {
  const user = useSelector((state) => state.anr.user);
  const dialogOpen = useSelector((state) => state.anr.dialogBanSite.open);
  const article = useSelector((state) => state.anr.dialogBanSite.article);
  const loading = useSelector((state) => state.anr.dialogBanSite.loading);
  const dispatch = useDispatch();

  const handleSubmit = (reason) => {
    const params = {
      article_id: article.id,
      host: article.site_host,
      level: 'account',
      profiles_ids: _.map(article.folders, (folder) => folder.id),
      reason,
    };
    dispatch(banSite(params));
  };

  if (!dialogOpen) {
    return null;
  }
  return (
    <BanSiteDialog
      host={article.site_host}
      banReasons={user.ban_reasons}
      loading={loading}
      handleSubmit={handleSubmit}
      handleClose={() => dispatch(closeBanSiteDialog())}
    />
  );
};

export default BanSiteContainer;
