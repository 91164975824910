import { post, get } from 'generic/api/apiGenerator';

const logon = (values) => post({ path: '/authenticate', bodyItems: values, withToken: false })
  .then((xhrResponse) => xhrResponse.json())
  .then((response) => response);

const logonTest = () => post({ path: '/authenticate' }).then((response) => response);

const resetPassword = (values) => post({ path: '/reset-password', uriParams: values, withToken: false })
  .then((xhrResponse) => xhrResponse.json())
  .then((response) => response);

const logout = () => get({ path: '/logout' });

export {
  logon,
  logout,
  logonTest,
  resetPassword,
};
