import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import QES_CONSTANTS from 'generic/core/qes/constants';
import * as Yup from 'yup';

import {
  Formik,
  Form,
  Field,
  FastField,
} from 'formik';
import { Select, TextField } from 'formik-mui';

import {
  Button,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
} from '@mui/material';

import { Description, Folder } from '@mui/icons-material';
import AutocompleteAjax from 'generic/components/forms/AutocompleteAjax';
import FileUpload from 'generic/components/forms/FileUpload';
import { LoadingButton } from '@mui/lab';

import { doAutocompleteUser } from 'generic/api/users';
import useAuthorizedExtensions from 'generic/core/hooks/useAuthorizedExtensions';

const CollectionDialog = ({
  collections,
  fileUploading,
  fileUploadName,
  initialValues,
  isDisabled,
  loading,
  mode,
  typeRessource,
  uploadActions,
  handleClose,
  handleClearUploadField,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const {
    COLLECTION_VISIBILITY,
    FILE_UPLOAD_FORMATS,
    FILE_RETRIEVE_FORMATS,
  } = QES_CONSTANTS;

  const visibilityOptions = Object
    .values(COLLECTION_VISIBILITY)
    .map((id) => ({
      code: id,
      libelle: t(`collections.visibility_values.${id}`),
    }));

  const handleGetOptionLabel = (userObjectOrEmail) => {
    if (typeof userObjectOrEmail === 'string') return userObjectOrEmail;
    const nom = _.get(userObjectOrEmail, 'nom', '');
    const prenom = _.get(userObjectOrEmail, 'prenom', '');
    return _.compact([prenom, nom]).join(' ');
  };

  // Pour differencier les homonymes dans la fenetre contextuelle de selection
  const handleRenderOption = (props, option) => {
    const {
      email,
      nom,
      prenom,
      utilisateur,
    } = option;
    const prenomStr = (prenom) ? `${prenom} ` : '';
    const emailStr = (email && email !== nom) ? ` (${email})` : '';

    return (
      <li {...props} key={utilisateur}>
        <div>
          {`${prenomStr}${nom}${emailStr}`}
        </div>
      </li>
    );
  };

  const authorizedExtensions = useAuthorizedExtensions().getFileUpload(FILE_UPLOAD_FORMATS.collection);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        libelle: Yup.string().required(t('form.errors.mandatory')),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
        >
          <Dialog scroll="paper" open fullWidth maxWidth="lg">
            <DialogTitle
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {typeRessource === 'corpus' ? (<Description color="primary" />) : (<Folder color="primary" />)}
              {isDisabled
                ? t(`collections.${typeRessource}.params`) : t(`collections.${typeRessource}.${mode}_dialog`)}
            </DialogTitle>
            <DialogContent dividers>
              {/* <pre>{JSON.stringify(form.values, 0, 2)}</pre> */}
              <Field
                component={TextField}
                fullWidth
                autoFocus
                name="libelle"
                required
                label={t('collections.libelle')}
                disabled={isDisabled}
              />
              {typeRessource === 'corpus' && (
                <Box
                  sx={{ mt: 1 }}
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  gap={2}
                >
                  <Box width={210}>
                    <Field
                      component={Select}
                      name="collection_parente"
                      label={t('collections.collection.type')}
                      // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                      // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                      // override donc juste le onClose pour qu'il ne fasse rien.
                      onClose={_.noop}
                      disabled={isDisabled}
                    >
                      {_.map(
                        collections,
                        (collection) => (
                          <MenuItem
                            key={collection.collection}
                            value={collection.collection}
                          >
                            {collection.libelle}
                          </MenuItem>
                        ),
                      )}
                    </Field>
                  </Box>
                  <Box width={210}>
                    <Field
                      component={Select}
                      name="visibilite_collection"
                      label={t('collections.visibility')}
                      // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                      // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                      // override donc juste le onClose pour qu'il ne fasse rien.
                      onClose={_.noop}
                      disabled={isDisabled}
                    >
                      {_.map(visibilityOptions, (option) => (
                        <MenuItem key={option.code} value={option.code}>{option.libelle}</MenuItem>
                      ))}
                    </Field>
                  </Box>
                  {form.values.visibilite_collection === COLLECTION_VISIBILITY.collaborative && (
                    <Box flexGrow="1">
                      <FastField
                        component={AutocompleteAjax}
                        name="collaborators"
                        facet=""
                        multiple
                        highlight={false}
                        doAutocompleteOverride={doAutocompleteUser}
                        getOptionLabel={handleGetOptionLabel}
                        renderOption={handleRenderOption}
                        isOptionEqualToValue={(option, value) => option.utilisateur === value.utilisateur}
                        fullWidth
                        textFieldProps={{
                          label: t('collections.contributors'),
                        }}
                        isDisabled={isDisabled}
                      />
                    </Box>
                  )}
                </Box>
              )}
              <Field
                component={TextField}
                fullWidth
                multiline
                minRows="3"
                name="description"
                label={t('collections.description')}
                disabled={isDisabled}
              />
              {typeRessource === 'corpus' && (
                <Field
                  component={FileUpload}
                  name="image"
                  label={t('collections.image')}
                  thumbAlignTop
                  accept={authorizedExtensions}
                  fileUploadName={fileUploadName}
                  fileUploading={fileUploading}
                  fileUploadType={FILE_RETRIEVE_FORMATS.collections}
                  uploadParams={{ type: FILE_UPLOAD_FORMATS.collection }}
                  uploadActions={uploadActions}
                  handleClearField={handleClearUploadField}
                  disabled={isDisabled}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {isDisabled ? t('ui.close') : t('ui.cancel')}
              </Button>
              {!isDisabled && (
                <LoadingButton
                  color="primary"
                  type="submit"
                  onClick={form.submitForm}
                  disabled={loading || !form.isValid}
                  loading={loading}
                >
                  {t('form.save')}
                </LoadingButton>
              )}
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

CollectionDialog.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.shape({
    collection: PropTypes.number,
    libelle: PropTypes.string,
  })).isRequired,
  fileUploading: PropTypes.bool,
  fileUploadName: PropTypes.string,
  initialValues: PropTypes.shape({
    libelle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  typeRessource: PropTypes.oneOf(['collection', 'corpus']).isRequired,
  uploadActions: PropTypes.shape({
    loading: PropTypes.shape({ type: PropTypes.string.isRequired }),
    success: PropTypes.shape({ type: PropTypes.string.isRequired }),
    error: PropTypes.shape({ type: PropTypes.string.isRequired }),
  }),
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClearUploadField: PropTypes.func.isRequired,
};

CollectionDialog.defaultProps = {
  fileUploading: false,
  fileUploadName: '',
  isDisabled: false,
  loading: false,
  uploadActions: [],
};

export default CollectionDialog;
