import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import GlobalLoading from 'generic/components/pages/GlobalLoading';

import { logout } from 'generic/core/auth/actions';

const LogoutContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    push({
      pathname: '/login',
      state: { referrer: '/' },
    });
    dispatch(logout());
  });

  return (
    <GlobalLoading />
  );
};

export default React.memo(LogoutContainer);
