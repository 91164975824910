import React, { Fragment } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import { articlePropType } from 'generic/core/anr/proptypes';

const DuplicatesCount = ({
  item,
}) => {
  const { t } = useTranslation();

  const showDuplicates = !item.is_duplicate && item.group_count > 1;
  const showRetweets = item.quote_type && item.retweet_count > 1 && _.get(item, 'authors', []).length > 1;
  if (!showDuplicates && !showRetweets) {
    return null;
  }
  return (
    <Box
      component="span"
      fontWeight="400"
      ml={0.5}
      fontSize="1rem"
      color="text.neutral"
    >
      {showDuplicates && (
        // Affichage du nombre de doublons
        `x${item.group_count}`
      )}
      {showRetweets && (
        // Affichage du nombre de Retweets
        <Tooltip
          title={(
            <Fragment>
              <b>{t('anr.retweeted_by')}</b>
              <br />
              {_.map(
                item.authors.slice(1, 11),
                (author) => (
                  <Fragment key={author}>
                    {author}
                    <br />
                  </Fragment>
                ),
              )}
              {item.authors.length > 11 && '...'}
            </Fragment>
          )}
        >
          <span>{`+ ${item.retweet_count} RT`}</span>
        </Tooltip>
      )}
    </Box>
  );
};

DuplicatesCount.propTypes = {
  item: articlePropType.isRequired,
};

export default DuplicatesCount;
