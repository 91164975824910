export const types = {
  TOGGLE_CHECKBOX: '@SELECTION/TOGGLE_CHECKBOX',
  TOGGLE_CHECKBOX_ALL: '@SELECTION/TOGGLE_CHECKBOX_ALL',
  SET_SINGLE_ITEM: '@SELECTION/SET_SINGLE_ITEM',
  CLEAR_SINGLE_ITEM: '@SELECTION/CLEAR_SINGLE_ITEM',
  CLEAR: '@SELECTION/CLEAR',
  UPDATE_SELECTION: '@SELECTION/UPDATE_SELECTION',
};

export const toggleCheckbox = (values) => ({
  type: types.TOGGLE_CHECKBOX,
  values,
});

export const toggleCheckboxAll = (values) => ({
  type: types.TOGGLE_CHECKBOX_ALL,
  values,
});

export const setSingleItem = (value) => ({
  type: types.SET_SINGLE_ITEM,
  value,
});

export const clearSingleItem = () => ({
  type: types.CLEAR_SINGLE_ITEM,
});

export const clearSelection = (values) => ({
  type: types.CLEAR,
  values,
});

export const updateSelection = (items) => ({
  type: types.UPDATE_SELECTION,
  items,
});
