import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { openNextArticle, openPreviousArticle } from 'generic/core/anr/actions';
import ArticleViewActions from 'generic/components/pages/anr/ArticleViewActions';

const ArticleViewActionsContainer = () => {
  const articles = useSelector((state) => state.anr.articles);
  const resultsLoading = useSelector((state) => state.anr.resultsLoading);
  const responseData = useSelector((state) => state.anr.responseData);
  const dispatch = useDispatch();
  let openPreviousDisabled = resultsLoading;
  let openNextDisabled = resultsLoading;
  if (!resultsLoading && !_.isEmpty(responseData) && !_.isEmpty(articles)) {
    openPreviousDisabled = responseData.offset === 0
      && _.findIndex(articles, { opened: true }) === 0;
    openNextDisabled = _.findIndex(articles, { opened: true }) === articles.length - 1
      && Math.floor(responseData.filtered_count / responseData.limit)
        === responseData.offset / responseData.limit;
  }

  const handleOpenNextArticle = () => {
    dispatch(openNextArticle());
  };

  const handleOpenPreviousArticle = () => {
    dispatch(openPreviousArticle());
  };

  return (
    <ArticleViewActions
      openPreviousDisabled={openPreviousDisabled}
      openNextDisabled={openNextDisabled}
      handleOpenNextArticle={handleOpenNextArticle}
      handleOpenPreviousArticle={handleOpenPreviousArticle}
    />
  );
};

export default ArticleViewActionsContainer;
