import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import ResultsContainer from 'generic/containers/ResultsContainer';
import { cleanupQuickResults } from 'generic/core/search/actions';
import { clearSelection } from 'generic/core/selection/actions';

const QuickResultsContainer = () => {
  const quickLoadingBlank = useSelector((state) => state.search.quickLoadingBlank);
  const hasEmptyQuickResults = useSelector((state) => _.isEmpty(state.search.quickResults));
  const dispatch = useDispatch();

  if (hasEmptyQuickResults && !quickLoadingBlank) {
    return null;
  }
  return (
    <Drawer
      anchor="right"
      open
      onClose={() => {
        dispatch(cleanupQuickResults());
        dispatch(clearSelection({ quickResultsScope: true }));
      }}
      PaperProps={{ sx: { width: { xs: '85%', md: '80%', lg: '70%' } } }}
    >
      <ResultsContainer quickResultsScope />
    </Drawer>
  );
};

export default QuickResultsContainer;
