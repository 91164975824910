import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { closeDialogCreateWidget, saveWidgets } from 'generic/core/dashboard/actions';
import FormDashboardWidget from 'generic/components/forms/FormDashboardWidget';

const CreateWidgetDialogContainer = ({
  activeDashboardId,
  canCreateCooccurrencesWidget,
  canCreateRelationsWidget,
  maxY,
}) => {
  const open = useSelector((state) => state.dashboard.dialogCreateWidgetOpened);
  const loading = useSelector((state) => state.dashboard.saveWidgetsLoading);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialogCreateWidget());
  };

  const handleSubmit = ({ width, height, ...rest }) => {
    const alteredValues = { ...rest };
    if (alteredValues.aggregates) {
      alteredValues.aggregates = _.map(alteredValues.aggregates.split(','), _.trim);
    } else if (alteredValues.aggregates === '') {
      alteredValues.aggregates = [];
    }
    if (alteredValues.pivots) {
      alteredValues.pivots = _.map(alteredValues.pivots.split(','), _.trim);
    } else if (alteredValues.pivots === '') {
      alteredValues.pivots = [];
    }
    dispatch(saveWidgets({
      dashboard: activeDashboardId,
      pos_x: 0,
      pos_y: maxY + 1,
      width: width || 2,
      height: height || 1,
      json: JSON.stringify(alteredValues),
    }));
    handleClose();
  };

  if (!open) {
    return null;
  }
  return (
    <FormDashboardWidget
      canCreateCooccurrencesWidget={canCreateCooccurrencesWidget}
      canCreateRelationsWidget={canCreateRelationsWidget}
      creation
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValues={{
        title: '',
        type: 'pie',
      }}
      isInDialog
      loading={loading}
    />
  );
};

CreateWidgetDialogContainer.propTypes = {
  activeDashboardId: PropTypes.number.isRequired,
  canCreateCooccurrencesWidget: PropTypes.bool,
  canCreateRelationsWidget: PropTypes.bool,
  maxY: PropTypes.number,
};

CreateWidgetDialogContainer.defaultProps = {
  canCreateCooccurrencesWidget: true,
  canCreateRelationsWidget: true,
  maxY: 0,
};

export default CreateWidgetDialogContainer;
