import {
  put,
  select,
  takeEvery,
  takeLatest,
  all,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import i18next from 'i18next';

import {
  types,
  setFormFields,
  setActiveBase,
  saveProfileSuccess,
  saveProfileError,
  uploadUserImageSuccess,
  uploadUserImageError,
  saveUserImageSuccess,
  saveUserImageError,
  refreshFormfields,
  updateBaseFields,
} from 'generic/core/config/actions';
import { snackActions } from 'generic/utils/snackbar';
import { cleanupResults } from 'generic/core/search/actions';
import { clearSelection } from 'generic/core/selection/actions';
import { patchUser, uploadAvatar } from 'generic/api/users';
import { getForm } from 'generic/api/config';

function* workRefreshFormFields() {
  try {
    const activeBaseId = yield select((state) => state.config.activeBase.base);
    const params = { uriParams: { base: activeBaseId } };
    const results = yield call(getForm, params);
    const movementId = yield select((state) => state.search.results.mouvement);
    if (!movementId) {
      yield put(setFormFields(results.champs));
    }
    yield put(updateBaseFields(activeBaseId, results.champs));
    const bases = yield select((state) => state.config.bases);
    const config = JSON.parse(localStorage.getItem('config'));
    config.bases = bases;
    localStorage.setItem('config', JSON.stringify(config));
  } catch (response) {
    console.error(response);
  }
}

function* watchRefreshFormFields() {
  yield takeLatest(types.REFRESH_FORM_FIELDS, workRefreshFormFields);
}

function* workResetFormFields() {
  const champs = yield select((state) => state.config.activeBase.champs);
  yield put(setFormFields(champs));
}

function* watchResetFormFields() {
  yield takeEvery(types.RESET_FORM_FIELDS, workResetFormFields);
}

function* workSetActiveBase({ baseId }) {
  try {
    const bases = yield select((state) => state.config.bases);
    const defaultBaseId = yield select((state) => state.config.settings.baseInterne_368);

    const base = _.defaultTo(
      _.find(bases, { base: baseId }),
      _.find(bases, { base: defaultBaseId }),
    );
    // On va lancer toutes les actions suivantes dans un seul yield pour
    // éviter les rerender inutiles
    yield all([
      // On vide la sélection
      put(clearSelection()),
      // On vide les résultats
      put(cleanupResults()),
      // On change les champs du formulaire dans le reducer
      put(setFormFields(base.champs)),
      // Enfin, on change la base dans le reducer
      put(setActiveBase(base)),
    ]);
  } catch (error) {
    console.error(error);
    snackActions.error(i18next.t('config.error_loading_base'));
  }
}

function* watchSetCurrentBase() {
  yield takeLatest([types.SET_CONFIG, types.CHANGE_ACTIVE_BASE], workSetActiveBase);
}

function* workSaveProfile({ params, uploadTheme }) {
  try {
    const user = yield call(patchUser, { bodyItems: { ...params } });
    yield put(saveProfileSuccess(user));
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('theme_qes', user.theme_qes);
    if (user.codeError && user.codeError > 0) {
      snackActions.warning(i18next.t(`profile.profile_saved_warning_${user.codeError}`));
    } else if (!uploadTheme) {
      snackActions.success(i18next.t('profile.profile_saved'));
    }
    if (params?.langue) {
      yield put(refreshFormfields());
    }
  } catch (error) {
    yield put(saveProfileError(error));
    console.error(error);
    snackActions.error(i18next.t('profile.profile_saved_error'));
  }
}

function* watchSaveProfile() {
  yield takeLatest(types.SAVE_PROFILE, workSaveProfile);
}

function* workUploadAvatar({ file }) {
  let message;
  try {
    const results = yield call(uploadAvatar, { fileUpload: true, bodyItems: file });
    message = _.get(results, 'status');
    if (message === 'KO') {
      message = _.get(results, 'error.message');
      throw new Error(message);
    }
    const { path } = results[0];
    const imageName = path.replace(/^.*[\\/]/, '');
    yield put(uploadUserImageSuccess(imageName));
  } catch (error) {
    yield put(uploadUserImageError(error));
    console.error(error);
    snackActions.error(message || i18next.t('profile.upload_avatar_error'));
  }
}

function* watchUploadAvatar() {
  yield takeLatest(types.UPLOAD_USER_IMAGE, workUploadAvatar);
}

function* workSaveAvatar({ params }) {
  try {
    const user = yield call(patchUser, { bodyItems: { ...params } });
    yield put(saveUserImageSuccess(user));
    localStorage.setItem('user', JSON.stringify(user));
    snackActions.success(i18next.t('profile.avatar_saved'));
  } catch (error) {
    yield put(saveUserImageError(error));
    console.error(error);
    snackActions.error(i18next.t('profile.avatar_saved_error'));
  }
}

function* watchSaveAvatar() {
  yield takeLatest(types.SAVE_USER_IMAGE, workSaveAvatar);
}

export default {
  watchRefreshFormFields,
  watchResetFormFields,
  watchSaveAvatar,
  watchSaveProfile,
  watchSetCurrentBase,
  watchUploadAvatar,
};
