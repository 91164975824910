import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import {
  cleanupAnRSources,
  fetchAnRSources,
  openDemandSourceDialog,
} from 'generic/core/anr/actions';
import ResultsSources from 'generic/components/pages/anr/ResultsSources';
import DemandSourceContainer from 'generic/containers/anr/DemandSourceContainer';

const SourcesContainer = () => {
  const loading = useSelector((state) => state.anr.sourcesLoading);
  const responseData = useSelector((state) => state.anr.responseData);
  const categories = useSelector((state) => state.anr.user.folders_categories);
  const partners = useSelector((state) => state.anr.user.partners);
  const sources = useSelector((state) => state.anr.sources);
  const [tableSortOrder, setTableSortOrder] = useState({ name: 'host', direction: 'asc' });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const linkRenderer = useCallback((hostOrUrl) => {
    const url = (hostOrUrl.startsWith('http')) ? hostOrUrl : `http://${hostOrUrl}`;

    return (
      <Link
        underline="none"
        href={url}
        target="_blank"
        rel="noopener"
        sx={{ wordBreak: 'break-word' }}
      >
        {hostOrUrl}
      </Link>
    );
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'categories',
      label: t('anr.categories'),
      options: {
        display: 'excluded',
        filter: true,
        customFilterListOptions: { render: (opt) => `${t('anr.category')} : ${opt}` },
        filterOptions: {
          names: _.map(categories, (category) => category.name),
        },
      },
    },
    {
      name: 'partners',
      label: t('anr.partners'),
      options: {
        display: 'excluded',
        filter: true,
        customFilterListOptions: { render: (opt) => `${t('anr.partner')} : ${opt}` },
        filterOptions: {
          names: _.map(partners, (partner) => partner.name),
        },
      },
    },
    {
      name: 'host',
      label: t('anr.site'),
      options: {
        filter: false,
        customBodyRender: linkRenderer,
      },
    },
    {
      name: 'url',
      label: t('anr.url'),
      options: {
        filter: false,
        customBodyRender: linkRenderer,
      },
    },
    {
      name: 'type',
      label: t('anr.type'),
      options: {
        filter: true,
        customFilterListOptions: { render: (opt) => `Type : ${opt}` },
        filterOptions: {
          names: ['feed', 'site'],
        },
      },
    },
    {
      name: 'articles_count',
      label: t('anr.articles_count'),
      options: {
        filter: false,
      },
    },
  ];

  useEffect(() => {
    // A la création du reducer, sources vaut null,
    // on tente de les charger une première fois ici
    if (sources === null) {
      dispatch(fetchAnRSources());
    }
  }, [sources, dispatch]);

  useEffect(() => (
    // Cleanup d'anr (sources et dossiers) lorsque le composant est "unmount"
    // (donc quand on change de page)
    () => dispatch(cleanupAnRSources())
  ), [dispatch]);

  const computeParams = (tableState) => {
    const {
      sortOrder,
      filterList,
    } = tableState;
    let params = {
      search: tableState.searchText,
      sort: sortOrder.name,
      sort_direction: sortOrder.direction,
    };
    setTableSortOrder(sortOrder);
    const filters = {};
    for (let i = 0; i < filterList.length; i++) {
      if (!_.isEmpty(filterList[i])) {
        const { name } = columns[i];
        const [value] = filterList[i];
        if (name === 'categories') {
          const category = _.find(categories, { name: value });
          filters.category_id = category.id;
        } else if (name === 'partners') {
          const partner = _.find(partners, { name: value });
          filters.partner_id = partner.id;
        } else {
          filters[name] = value;
        }
      }
    }
    if (!_.isEmpty(filters)) {
      params = {
        ...params,
        ...filters,
      };
    }
    return params;
  };

  const handlePageChangePagination = (tableState) => {
    const params = computeParams(tableState);
    const finalParams = {
      offset: tableState.page * 20,
      ...params,
    };
    dispatch(fetchAnRSources(finalParams));
  };

  const handleSearch = (tableState) => {
    const params = computeParams(tableState);
    dispatch(fetchAnRSources(params));
  };

  const handleOpenDemandSourceDialog = () => {
    dispatch(openDemandSourceDialog());
  };

  if (sources === null) {
    return null;
  }
  return (
    <Fragment>
      <ResultsSources
        columns={columns}
        count={responseData.filtered_count}
        handleOpenDemandSourceDialog={handleOpenDemandSourceDialog}
        handlePageChangePagination={handlePageChangePagination}
        handleSearch={handleSearch}
        loading={loading}
        page={(Math.ceil(responseData.offset / responseData.limit))}
        sortOrder={tableSortOrder}
        sources={sources}
      />
      <DemandSourceContainer />
    </Fragment>
  );
};

export default SourcesContainer;
