import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  iconContainer: {
    position: 'relative',
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',

    '& > :first-child': {
      position: 'absolute',
    },
    '& > :last-child': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      transform: 'scale(0.8)',
    },
  },
}));

/**
 * Composant permettant de "superposer" deux icônes MUI.
 * La première est affichée telle quelle
 * La seconde est affichée en bas à droite et est de taille réduite."
 *
 * @component
 * @example
 * return (
 *   <IconsStacker icon1={<Edit />} icon2={<Bolt />} />;
 * )
 */
const IconsStacker = ({ icon1, icon2 }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconContainer}>
      {icon1}
      {icon2}
    </div>
  );
};

IconsStacker.propTypes = {
  icon1: PropTypes.element.isRequired,
  icon2: PropTypes.element.isRequired,
};

export default IconsStacker;
