import { alpha, styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

import { isHeaderBackgroundColorDark } from 'generic/utils/colorUtils';

export default styled(MenuItem, {
  shouldForwardProp: () => true,
  name: 'HeaderMenuItem',
  slot: 'Root',
})(({ theme }) => {
  let menuColors = theme.palette.common.black;
  let menuTransparencyOnHover = 0.1;
  let menuTransparencySelected = 0.15;
  let menuTransparencyOnHoverAndSelected = 0.20;

  if (isHeaderBackgroundColorDark(theme)) {
    menuColors = theme.palette.common.white;
    menuTransparencyOnHover = 0.4;
    menuTransparencySelected = 0.8;
    menuTransparencyOnHoverAndSelected = 0.88;
  }

  return {
    color: menuColors,
    '&:not(.Mui-selected):hover': {
      backgroundColor: alpha(menuColors, menuTransparencyOnHover),
    },
    '&.Mui-selected': {
      backgroundColor: alpha(menuColors, menuTransparencySelected),
      color: theme.palette.header.menuSelected,
    },
    '&.Mui-selected:hover': {
      backgroundColor: alpha(menuColors, menuTransparencyOnHoverAndSelected),
    },
  };
});
