import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Card,
} from '@mui/material';
import { OpenWith } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { types } from 'generic/core/dashboard/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { widgetPropType } from 'generic/core/qes/proptypes';
import EditWidgetDashboardContainer from 'generic/containers/EditWidgetDashboardContainer';

// eslint-disable-next-line react/prop-types
const DragHandle = ({ absolute }) => (
  <Box
    className="drag-handle"
    position={absolute ? 'absolute' : 'relative'}
    zIndex="1300"
    top="15px"
    sx={{ cursor: 'move', userSelect: 'all' }}
    height="20px"
    display="block"
  >
    <OpenWith sx={{ userSelect: 'all', fontSize: '20px', color: 'rgb(102, 102, 102)' }} />
  </Box>
);

const DashboardWidget = ({
  highchartsOptions,
  handleExportWidget,
  componentProps,
  component: Component,
  flat,
  widgetParams,
  canEditDashboard,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEditionOpened, setIsEditionOpened] = useState(false);

  useEffect(() => {
    if (widgetParams.isNew) {
      document.getElementById(widgetParams.dashboard_widget).scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [widgetParams]);

  const handleOpenDeleteDialog = () => {
    dispatch(setConfirmDialogData({
      title: t('dashboard.delete_widget'),
      textButton: 'form.delete',
      message: (
        <Trans>
          {t('dashboard.delete_widget_sure', { widgetTitle: widgetParams.title })}
        </Trans>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_WIDGET_SUCCESS, types.DELETE_WIDGET_ERROR],
      action: {
        type: types.DELETE_WIDGET,
        widgetId: widgetParams.dashboard_widget,
      },
    }));
  };

  let menuItems = [
    'viewFullscreen', 'printChart', 'separator', 'downloadJPEG', 'downloadPNG',
    'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS', 'downloadCSV',
  ];
  let editMethods = {};
  if (canEditDashboard) {
    menuItems = ['editWidget', 'deleteWidget'].concat(menuItems);
    editMethods = {
      editWidget: {
        onclick: () => setIsEditionOpened(true),
        text: t('dashboard.edit_widget_menu'),
      },
      deleteWidget: {
        onclick: handleOpenDeleteDialog,
        text: t('dashboard.delete_widget'),
      },
    };
  }
  if (handleExportWidget) {
    menuItems.push('downloadAllCSV');
  }
  const globalHighChartsOptions = {
    options: {
      accessibility: { enabled: false },
      credits: { enabled: false },
      exporting: {
        menuItemDefinitions: {
          ...editMethods,
          downloadAllCSV: {
            onclick: handleExportWidget,
            text: t('dashboard.download_all_csv'),
          },
        },
        buttons: {
          contextButton: {
            symbolStrokeWidth: 2,
            menuItems,
          },
        },
      },
    },
  };
  const finalOptions = _.merge({}, globalHighChartsOptions, highchartsOptions);

  const cardSx = {};
  if (theme.palette.mode === 'dark') {
    cardSx.border = '1px solid rgb(255 255 255 / 30%)';
    cardSx.boxShadow = 'none';
  }
  return (
    <Box height="100%" width="100%">
      {flat ? (
        <Box>
          {Component == null
            ? ''
            : (
              <Component
                highchartsOptions={finalOptions}
                handleExportWidget={handleExportWidget}
                {...componentProps}
              />
            )}
        </Box>
      ) : (
        <Card
          sx={{
            width: '100%',
            height: '100%',
            ...cardSx,
          }}
        >
          <Box
            position="relative"
            height="calc(100% - 10px)"
            px={isEditionOpened ? 0 : 2}
            pt={0.5}
          >
            {isEditionOpened ? (
              <EditWidgetDashboardContainer
                widgetParams={widgetParams}
                handleClose={() => setIsEditionOpened(false)}
              />
            ) : (
              <Fragment>
                {canEditDashboard && (
                  <DragHandle absolute />
                )}
                <Component
                  highchartsOptions={finalOptions}
                  handleExportWidget={handleExportWidget}
                  handleOpenEdition={canEditDashboard ? () => setIsEditionOpened(true) : null}
                  handleOpenDeleteDialog={canEditDashboard ? handleOpenDeleteDialog : null}
                  {...componentProps}
                />
              </Fragment>
            )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

DashboardWidget.propTypes = {
  component: PropTypes.elementType.isRequired,
  componentProps: PropTypes.shape(),
  flat: PropTypes.bool,
  handleExportWidget: PropTypes.func,
  highchartsOptions: PropTypes.shape(),
  widgetParams: widgetPropType.isRequired,
  canEditDashboard: PropTypes.bool,
};

DashboardWidget.defaultProps = {
  highchartsOptions: {},
  componentProps: {},
  flat: false,
  handleExportWidget: null,
  canEditDashboard: false,
};

export default DashboardWidget;
