import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ExportDialog from 'generic/components/dialogs/ExportDialog';
import { closeDialogExport } from 'generic/core/actions/actions';
import { clearSingleItem } from 'generic/core/selection/actions';
import { submitExport } from 'generic/core/export/actions';

const ExportDialogContainer = ({ scope }) => {
  const activeBase = useSelector((state) => state.config.activeBase);
  const maxNbMassiveExport = useSelector((state) => state.config.settings.nbMaxDocsExportMassif_561);
  const open = useSelector((state) => state.actions.dialogExportOpened);
  const loading = useSelector((state) => state.exportReducer.loading);
  const hasQuickResults = useSelector((state) => !_.isEmpty(state.search.quickResults));
  let checkedItemsKey = 'checkedItems';
  let searchResultsKey = 'results';
  if (hasQuickResults) {
    checkedItemsKey = 'quickCheckedItems';
    searchResultsKey = 'quickResults';
  }
  const exportFields = useSelector((state) => state.search[searchResultsKey].exports);
  const numberResults = useSelector((state) => state.search[searchResultsKey].nbresults);
  const checkedItems = useSelector((state) => state.selection[checkedItemsKey]);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearSingleItem());
    dispatch(closeDialogExport());
  };

  const handleSubmit = (values) => {
    const params = _.merge({ scope }, values);
    dispatch(submitExport(params));
  };

  const exportFormats = [];
  if (activeBase.exportCsv) {
    exportFormats.push({ libelle: 'CSV', code: 'csv' });
    exportFormats.push({ libelle: 'Excel', code: 'excel' });
  }
  if (activeBase.exportPdfWord) {
    exportFormats.push({ libelle: 'PDF', code: 'pdf' });
    exportFormats.push({ libelle: 'Word', code: 'word' });
  }
  const exportMassiveFormats = {};
  if (activeBase.exportCsvMassif) {
    exportMassiveFormats.csv = true;
    exportMassiveFormats.excel = true;
  }
  if (activeBase.exportPdfWordMassif) {
    exportMassiveFormats.pdf = true;
    exportMassiveFormats.word = true;
  }

  if (!open) {
    return null;
  }
  return (
    <ExportDialog
      maxNbMassiveExport={maxNbMassiveExport}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      scope={scope}
      loading={loading}
      exportFields={exportFields}
      exportFormats={exportFormats}
      exportMassiveFormats={exportMassiveFormats}
      checkedItems={checkedItems}
      numberResults={numberResults}
    />
  );
};

ExportDialogContainer.propTypes = {
  scope: PropTypes.oneOf(['results', 'anr', 'cart', 'newsletter']).isRequired,
};

export default ExportDialogContainer;
