import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  closestIndexTo, compareDesc, formatDistanceToNow, parseISO,
} from 'date-fns';
import _ from 'lodash';
import Schedule from 'generic/components/pages/Schedule';
import {
  activateDeactivateSchedules,
  cleanupSchedule,
  createImmediateScheduleExecution,
  fetchSchedule,
  // saveSchedule,
} from 'generic/core/schedules/actions';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { computeSearchFormInitialValues } from 'generic/utils/qesUtils';

const ScheduleContainer = () => {
  const schedule = useSelector((state) => state.schedules.editSchedule);
  const loading = useSelector((state) => state.schedules.loading);
  const saveLoading = useSelector((state) => state.schedules.saveScheduleLoading);
  const fields = useSelector((state) => state.schedules.form.champs);
  const dispatch = useDispatch();
  const { id } = useParams();
  const idSchedule = +id;

  useEffect(() => {
    if (!_.isEmpty(id)) {
      dispatch(fetchSchedule(idSchedule));
    }
  }, [id, idSchedule, dispatch]);

  // Cleanup du schedule lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanupSchedule())
  ), [dispatch]);

  const remarkableDates = useMemo(() => {
    if (_.isEmpty(schedule?.schedule_extensions)) return { nextExecution: undefined, previousExecution: undefined };

    // QES Stock les dates en "français" mais le timezone renvoyé est +0000... il faut s'arranger pour avoir la bonne TZ
    const extensionsConverted = _.map(schedule.schedule_extensions, (ext) => ({
      ...ext,
      date_debut: parseISO(ext.date_debut.replaceAll('+0000', '-0200')),
      date_time_debut: parseISO(ext.date_time_debut.replaceAll('+0000', '-0200')),
    }));

    // le tableau des extensions est normalement trié par date de passage descendant.
    // si le lot vaut 1000, c'est que le schedule n'est pas encore passé
    const now = new Date();
    const closestIndexToNow = closestIndexTo(now, _.map(extensionsConverted, 'date_time_debut'));

    let nextExecution;
    let previousExecution;
    const isTheClosestBeforeNow = compareDesc(
      extensionsConverted[closestIndexToNow].date_time_debut,
      now,
    );

    if (isTheClosestBeforeNow === 1) {
      previousExecution = extensionsConverted[closestIndexToNow].date_time_debut;
      if (extensionsConverted[closestIndexToNow + 1]) {
        nextExecution = extensionsConverted[closestIndexToNow + 1].date_time_debut;
      }
    } else if (isTheClosestBeforeNow === -1) {
      if (extensionsConverted[closestIndexToNow + 1]) {
        previousExecution = extensionsConverted[closestIndexToNow + 1].date_time_debut;
      }
      nextExecution = extensionsConverted[closestIndexToNow].date_time_debut;
    }

    const nextExecutionDistance = nextExecution && formatDistanceToNow(nextExecution, { addSuffix: true });
    const previousExecutionDistance = previousExecution && formatDistanceToNow(previousExecution, { addSuffix: true });
    return {
      nextExecution, previousExecution, previousExecutionDistance, nextExecutionDistance,
    };
  }, [schedule.schedule_extensions]);

  if (loading || _.isEmpty(schedule)) {
    return <GlobalLoading />;
  }

  const handleSave = (/* values */) => {
    // const params = emailsAsArrayFromAutocomplete(['email'], values);
    // if (params.email) {
    //   params.email = params.email.join(',');
    // }
    // dispatch(saveSchedule(params));
  };

  const handleClickActivateDeactivate = (active) => {
    dispatch(activateDeactivateSchedules([idSchedule], active, () => dispatch(fetchSchedule(idSchedule))));
  };

  const handleExecuteNow = (reprise) => () => {
    dispatch(createImmediateScheduleExecution(idSchedule, reprise));
  };

  const formInitialValues = computeSearchFormInitialValues(fields);
  const fieldsAdvanced = [];
  if (fields) {
    if (!_.isEmpty(formInitialValues)) {
      _.each(fields, (field) => {
        fieldsAdvanced.push(field);
      });
    }
  }

  return (
    <Schedule
      {...{
        onSave: handleSave,
        schedule,
        handleClickActivateDeactivate,
        saveLoading,
        formInitialValues,
        fieldsAdvanced,
        handleExecuteNow,
        ...remarkableDates,
      }}
    />
  );
};

export default ScheduleContainer;
