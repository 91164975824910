import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, IconButton } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '40px',
    fontSize: '0.875rem',
    '&:invalid': {
      color: theme.palette.error.dark,
    },
  },
}));

const textFieldMarginMap = {
  small: '0 2px 0 1px',
  medium: '0 6px 0 3px',
  large: '0 6px 0 3px',
};
const inputPaddingMap = {
  small: '3px 0 3px 5px',
  medium: '6px 0 6px 6px',
  large: '10px 0 10px 10px',
};
const fontSizePaddingMap = {
  small: '1.1rem',
  medium: '1.3rem',
  large: '1.4rem',
};
const buttonPaddingMap = {
  small: '2px',
  medium: '4px',
  large: '6px',
};

const PaginationGoToPage = ({
  page,
  maxPage,
  handleGoToPage,
  size,
  ...rest
}) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(page);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.setCustomValidity('');
    setCurrentPage(page);
  }, [page]);

  const handleAction = (event) => {
    if ((event.type === 'click' || event.key === 'Enter') && inputRef.current.checkValidity()) {
      handleGoToPage(event, currentPage);
    }
  };

  const handleChange = (event) => {
    let inputValue = event.target.value;
    inputRef.current.setCustomValidity('');
    if (inputRef.current.checkValidity()) {
      if (_.isEmpty(inputValue)) {
        inputRef.current.setCustomValidity('invalid');
      } else if (Number.isInteger(+inputValue)) {
        inputValue = +inputValue;
        if (inputValue < 1 || inputValue > maxPage) {
          inputRef.current.setCustomValidity('invalid');
        }
      }
    }
    setCurrentPage(inputValue);
  };

  return (
    <TextField
      sx={{ margin: textFieldMarginMap[size], width: 'auto' }}
      value={currentPage}
      onChange={handleChange}
      inputRef={inputRef}
      onKeyDown={handleAction}
      InputProps={{
        endAdornment: (
          <IconButton
            sx={{ padding: buttonPaddingMap[size], marginRight: '3px' }}
            onClick={handleAction}
            color="inherit"
          >
            <KeyboardArrowRight
              sx={{ fontSize: fontSizePaddingMap[size] }}
            />
          </IconButton>
        ),
        inputProps: {
          type: 'text',
          min: 1,
          max: maxPage,
          maxLength: maxPage.toString().length,
          inputMode: 'numeric',
          pattern: '[1-9]?[0-9]*',
          className: classes.input,
          sx: {
            padding: inputPaddingMap[size],
            fontSize: fontSizePaddingMap[size],
          },
        },
        sx: {
          padding: 0,
        },
      }}
      {...rest}
    />
  );
};

PaginationGoToPage.propTypes = {
  page: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
  handleGoToPage: PropTypes.func.isRequired,
  size: PropTypes.string,
};

PaginationGoToPage.defaultProps = {
  size: 'medium',
};

export default PaginationGoToPage;
