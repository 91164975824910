import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addToNewsletter, fetchNewsletters } from 'generic/core/newsletters/actions';
import AddToCartOrNewsletterDialog from 'generic/components/dialogs/AddToCartOrNewsletterDialog';
import { closeDialogAddToNewsletter } from 'generic/core/actions/actions';
import { clearSingleItem } from 'generic/core/selection/actions';

const AddToNewsletterDialogContainer = ({ scope }) => {
  const open = useSelector((state) => state.actions.dialogAddToNewsletterOpened);
  const newsletters = useSelector((state) => state.newsletters.newsletters);
  const themes = useSelector((state) => state.config.themesPanier);
  const loading = useSelector((state) => state.newsletters.loading);
  const addToNewsletterLoading = useSelector((state) => state.newsletters.addToNewsletterLoading);
  const dispatch = useDispatch();
  const [newsletterChosen, setNewsletterChosen] = useState(null);

  const handleAddToNewsletter = (newsletterId) => {
    setNewsletterChosen(newsletterId);
    dispatch(addToNewsletter({ newsletterId }, scope));
  };

  const handleAddToNewNewsletter = (newNewsletterName, theme) => {
    setNewsletterChosen('new');
    dispatch(addToNewsletter({ id: 1000, newNewsletterName, theme }, scope));
  };

  const handleClose = () => {
    dispatch(clearSingleItem());
    dispatch(closeDialogAddToNewsletter());
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchNewsletters());
    }
  }, [open, dispatch]);

  if (!open) {
    return null;
  }
  return (
    <AddToCartOrNewsletterDialog
      addToItemLoading={addToNewsletterLoading}
      handleAddToItem={handleAddToNewsletter}
      handleAddToNewItem={handleAddToNewNewsletter}
      handleClose={handleClose}
      itemChosen={newsletterChosen}
      items={newsletters}
      itemsType="newsletters"
      loading={loading}
      themes={themes}
    />
  );
};

AddToNewsletterDialogContainer.propTypes = {
  scope: PropTypes.oneOf(['qes', 'anr']).isRequired,
};

export default AddToNewsletterDialogContainer;
