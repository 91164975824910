import React from 'react';
import { Backdrop, CircularProgress, Portal } from '@mui/material';

const LoadingOverlay = () => (
  <Portal>
    <Backdrop
      sx={{
        zIndex: 1200,
        color: '#fff',
      }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </Portal>
);

export default LoadingOverlay;
