import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Input,
  InputAdornment,
} from '@mui/material';
import { Close, Upload, Image } from '@mui/icons-material';
import _ from 'lodash';
import { TextField } from 'formik-mui';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { uploadFile } from 'generic/core/files/actions';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const FileUpload = ({
  uploadParams,
  uploadActions,
  fileUploadName,
  fileUploading,
  fileUploadType,
  handleClearField,
  textFieldSx,
  withThumb,
  accept,
  thumbDisplayedLeft,
  thumbAlignTop,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef();

  const { form, field, disabled } = rest;

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();
  const imgSrc = `${API_ENTRY_POINT}/file?type=${fileUploadType}&file=${fileUploadName}&logon=${logon}&key=${key}`;

  const handleFileChange = (target) => {
    if (target.files && target.files.length > 0) {
      const fd = new FormData();
      fd.append('file', target.files[0]);
      dispatch(uploadFile(uploadParams, fd, uploadActions));
    }
  };

  useEffect(() => {
    if (!fileUploading && form) {
      form.setFieldValue(
        field.name,
        fileUploadName,
      );
      if (fileUploadName === '') {
        inputRef.current.value = null;
      }
    }
  // Il ne faut pas mettre field et form dans les dependancies au risque
  // de faire complètement planter l'app
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadName, fileUploading]);

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      flexWrap="wrap"
      flexGrow="1"
      sx={{ gap: '8px 16px' }}
      flexDirection={thumbDisplayedLeft ? 'row-reverse' : 'row'}
      {...thumbAlignTop && { alignItems: 'flex-start', mt: 1 }}
    >
      <TextField
        name={field.name}
        {...rest}
        sx={{
          flexGrow: '1000',
          width: 'auto',
          ...thumbAlignTop ? { mt: 0 } : { mb: 0 },
          ...textFieldSx,
        }}
        InputProps={{
          inputProps: {
            readOnly: 'readonly',
          },
          name: field.name,
          startAdornment: (
            <Fragment>
              <InputAdornment position="start">
                <TooltipButton
                  title={t('form.upload')}
                  disabled={fileUploading || disabled}
                  tag="iconButton"
                  size="small"
                  edge="start"
                >
                  {fileUploading ? <CircularProgress color="inherit" size={20} /> : <Upload />}
                  <Input
                    type="file"
                    sx={{ position: 'absolute', width: '100%', opacity: 0 }}
                    onChange={(event) => handleFileChange(event.target)}
                    disabled={fileUploading || disabled}
                    inputRef={inputRef}
                    inputProps={{
                      accept,
                    }}
                    title=""
                  />
                </TooltipButton>
              </InputAdornment>
              <Divider sx={{ height: 25, mt: '5px', mr: 1 }} orientation="vertical" />
            </Fragment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <TooltipButton
                title={t('form.clear')}
                tag="iconButton"
                size="small"
                edge="start"
                disabled={_.isEmpty(fileUploadName)}
                onClick={handleClearField}
              >
                <Close />
              </TooltipButton>
            </InputAdornment>
          ),
        }}
      />
      {withThumb && (
        <Avatar
          src={imgSrc}
          alt=""
          sx={{
            flexGrow: 1,
            width: 100,
            height: 100,
            borderRadius: '0%',
          }}
        >
          <Image sx={{ fontSize: 80 }} />
        </Avatar>
      )}
    </Box>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.string,
  thumbAlignTop: PropTypes.bool,
  fileUploading: PropTypes.bool,
  fileUploadName: PropTypes.string,
  fileUploadType: PropTypes.number,
  form: PropTypes.shape(),
  handleClearField: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  textFieldSx: PropTypes.shape(),
  thumbDisplayedLeft: PropTypes.bool,
  uploadActions: PropTypes.shape({
    loading: PropTypes.shape({ type: PropTypes.string.isRequired }),
    success: PropTypes.shape({ type: PropTypes.string.isRequired }),
    error: PropTypes.shape({ type: PropTypes.string.isRequired }),
  }),
  uploadParams: PropTypes.shape(),
  withThumb: PropTypes.bool,
};

FileUpload.defaultProps = {
  thumbAlignTop: false,
  accept: '',
  fileUploading: false,
  fileUploadName: '',
  fileUploadType: 0,
  form: null,
  label: '',
  name: '',
  textFieldSx: {},
  thumbDisplayedLeft: false,
  uploadActions: {},
  uploadParams: {},
  withThumb: true,
};

export default FileUpload;
