import { fork, all } from 'redux-saga/effects';
import _ from 'lodash';

import AlertsSagas from 'generic/core/alerts/sagas';
import AnRSagas from 'generic/core/anr/sagas';
import AuthSagas from 'generic/core/auth/sagas';
import CartsSagas from 'generic/core/carts/sagas';
import CollectionsSagas from 'generic/core/collections/sagas';
import CommentSagas from 'generic/core/comment/sagas';
import ConfigSagas from 'generic/core/config/sagas';
import ConfirmDialogSagas from 'generic/core/confirmDialog/sagas';
import DashboardSagas from 'generic/core/dashboard/sagas';
import ExportSagas from 'generic/core/export/sagas';
import FilesSagas from 'generic/core/files/sagas';
import GedSagas from 'generic/core/ged/sagas';
import MailingListsSagas from 'generic/core/mailingLists/sagas';
import MoreLikeThisSagas from 'generic/core/moreLikeThis/sagas';
import NewslettersSagas from 'generic/core/newsletters/sagas';
import SchedulesSagas from 'generic/core/schedules/sagas';
import SearchSagas from 'generic/core/search/sagas';
import SendSagas from 'generic/core/sendToFriend/sagas';
import SummarizeSagas from 'generic/core/summarize/sagas';
import UsersSagas from 'generic/core/users/sagas';

import { CUSTOM_PATH } from 'generic/core/constants';

function* rootSaga() {
  const modules = [
    AlertsSagas,
    AnRSagas,
    AuthSagas,
    CartsSagas,
    CollectionsSagas,
    CommentSagas,
    ConfigSagas,
    ConfirmDialogSagas,
    DashboardSagas,
    ExportSagas,
    FilesSagas,
    GedSagas,
    MailingListsSagas,
    MoreLikeThisSagas,
    NewslettersSagas,
    SchedulesSagas,
    SearchSagas,
    SendSagas,
    SummarizeSagas,
    UsersSagas,
  ];

  if (CUSTOM_PATH) {
    const isDev = (process.env.NODE_ENV === 'development');
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      const { default: customSaga } = require(`custom/${CUSTOM_PATH}/core/redux/sagas`);
      if (isDev) {
        console.info(`CUSTOM PATH LOADED : custom/${CUSTOM_PATH}/core/redux/sagas`);
      }
      modules.push(customSaga);
    } catch (err) {
      _.noop();
    }
  }

  const sagas = modules
    .flatMap((module) => Object.values(module)
      .filter((watcher) => typeof watcher === 'function'));

  yield all(sagas.map((saga) => fork(saga)));
}

export default rootSaga;
