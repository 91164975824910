export const types = {
  UPLOAD_FILE: '@FILES/UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: '@FILES/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: '@FILES/UPLOAD_FILE_ERROR',
  UPLOAD_FILE_TO_CREATE_DOCUMENT: '@FILES/UPLOAD_FILE_TO_CREATE_DOCUMENT',
  UPLOAD_FILE_TO_CREATE_DOCUMENT_SUCCESS: '@FILES/UPLOAD_FILE_TO_CREATE_DOCUMENT_SUCCESS',
  UPLOAD_FILE_TO_CREATE_DOCUMENT_ERROR: '@FILES/UPLOAD_FILE_TO_CREATE_DOCUMENT_ERROR',
  ADD_FILE_TO_UPLOAD: '@FILES/ADD_FILE_TO_UPLOAD',
  REMOVE_FILE_TO_UPLOAD: '@FILES/REMOVE_FILE_TO_UPLOAD',
  CLEAN_FILES_TO_UPLOAD: '@FILES/CLEAN_FILES_TO_UPLOAD',
  GET_FILE: '@FILES/GET_FILE',
  GET_FILE_SUCCESS: '@FILES/GET_FILE_SUCCESS',
  GET_FILE_ERROR: '@FILES/GET_FILE_ERROR',
};

export const uploadFile = (params, file, uploadActions) => ({
  type: types.UPLOAD_FILE,
  params,
  file,
  uploadActions,
});

export const uploadFileSuccess = () => ({
  type: types.UPLOAD_FILE_SUCCESS,
});

export const uploadFileError = (response) => ({
  type: types.UPLOAD_FILE_ERROR,
  response,
});

export const uploadFileToCreateDocument = (params, file, name) => ({
  type: types.UPLOAD_FILE_TO_CREATE_DOCUMENT,
  params,
  file,
  name,
});

export const uploadFileToCreateDocumentSuccess = (name) => ({
  type: types.UPLOAD_FILE_TO_CREATE_DOCUMENT_SUCCESS,
  name,
});

export const uploadFileToCreateDocumentError = (name) => ({
  type: types.UPLOAD_FILE_TO_CREATE_DOCUMENT_ERROR,
  name,
});

export const addFileToUpload = (files) => ({
  type: types.ADD_FILE_TO_UPLOAD,
  files,
});

export const removeFileToUpload = (file) => ({
  type: types.REMOVE_FILE_TO_UPLOAD,
  file,
});

export const cleanFilesToUpload = () => ({
  type: types.CLEAN_FILES_TO_UPLOAD,
});
