import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

export default () => {
  const activeBase = useSelector((state) => state.config.activeBase);
  const defaultBaseId = useSelector((state) => state.config.settings.baseInterne_368);
  const { baseId } = useParams();
  const shouldChangeToDefaultBase = _.isEmpty(baseId) && activeBase.base !== defaultBaseId;
  const shouldChangeToNewBase = baseId && +baseId !== activeBase.base;

  return {
    shouldChangeToDefaultBase,
    shouldChangeToNewBase,
    shouldChangeBase: shouldChangeToDefaultBase || shouldChangeToNewBase,
  };
};
