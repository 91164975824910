import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import {
  Box, Button, MenuItem, Stack,
} from '@mui/material';
import { Select, TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { schedulePropType } from 'generic/core/qes/proptypes';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { format } from 'generic/utils/dateUtils';

const { SCHEDULE } = QES_CONSTANTS;

const ScheduleDetails = ({
  schedule, handleClickActivateDeactivate, handleExecuteNow, onSave, saveLoading,
  nextExecution, previousExecution, previousExecutionDistance, nextExecutionDistance,
}) => {
  const { t } = useTranslation();
  const bases = useSelector((state) => state.config.bases);

  const initialValues = {
    base: schedule.base,
    code: schedule.code,
    date_creation: schedule.date_creation,
    date_modification: schedule.date_modification,
    etat_schedule: schedule.etat_schedule,
    intervenant_creation: schedule.intervenant_creation,
    intervenant_modification: schedule.intervenant_modification,
    libelle: schedule.libelle,
    mode_information: schedule.mode_information,
    mode_rapport: schedule.mode_rapport,
    observations: schedule.observations,
    schedule_erreur: schedule.schedule_erreur,
    schedule: schedule.schedule,
  };

  const etatOptions = SCHEDULE.STATUSES.ALL.map((id) => ({
    code: id,
    libelle: t(`schedules.statuses.${id}`),
  }));

  const baseNameOptions = bases.map((base) => ({
    code: base.base,
    libelle: base.libelle,
  }));

  const modeRapportOptions = Object
    .values(SCHEDULE.MODE_RAPPORT)
    .map((id) => ({ code: id, libelle: t(`schedules.report_modes.${id}`) }));

  const handleToggleStatus = () => {
    const nextStatus = !SCHEDULE.STATUSES.ACTIVE.includes(schedule.etat_schedule);
    handleClickActivateDeactivate(nextStatus);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values);
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
    >
      {(form) => (
        <Form
          className={clsx('displayFlex', 'flexGrow1', 'flexDirectionColumn', 'desktopOnlyOverflow')}
          disabled
        >
          <Box
            flexGrow="1"
            className="desktopOnlyOverflow"
            p={2}
          >
            <Box
              sx={{ mt: 1 }}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap={2}
            >
              <Box flexGrow="1">
                <Field
                  disabled
                  component={TextField}
                  label={t('schedules.tabs.details.name')}
                  autoFocus
                  name="libelle"
                  color="primary"
                />
              </Box>
              <Box width={210}>
                <Field
                  disabled
                  component={DatePicker}
                  inputFormat="dd/MM/yyyy"
                  label={t('schedules.tabs.details.creation_date')}
                  name="date_creation"
                  color="primary"
                />
              </Box>
              <Box width={210}>
                <Field
                  disabled
                  component={DatePicker}
                  inputFormat="dd/MM/yyyy"
                  label={t('schedules.tabs.details.modification_date')}
                  name="date_modification"
                  color="primary"
                />
              </Box>
            </Box>

            <Box
              sx={{ mt: 1 }}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap={2}
            >
              <Box flexGrow={1}>
                <Field
                  disabled
                  component={Select}
                  label={t('schedules.tabs.details.etat_schedule')}
                  name="etat_schedule"
                  // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                  // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                  // override donc juste le onClose pour qu'il ne fasse rien.
                  onClose={_.noop}
                >
                  {
                    _.map(etatOptions, (option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </Box>

              <Box flexGrow={1}>
                <Field
                  disabled
                  component={Select}
                  label={t('schedules.tabs.details.base')}
                  name="base"
                  // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                  // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                  // override donc juste le onClose pour qu'il ne fasse rien.
                  onClose={_.noop}
                >
                  {
                    _.map(baseNameOptions, (option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </Box>

              <Box flexGrow={1}>
                <Field
                  disabled
                  component={Select}
                  label={t('schedules.tabs.details.mode_rapport')}
                  name="mode_rapport"
                  // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                  // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                  // override donc juste le onClose pour qu'il ne fasse rien.
                  onClose={_.noop}
                >
                  {
                    _.map(modeRapportOptions, (option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </Box>
            </Box>

            <Box
              sx={{ mt: 1 }}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap={2}
            >
              <Box flexGrow={1}>
                <Field
                  disabled
                  component={TextField}
                  multiline
                  minRows={3}
                  fullWidth
                  label={t('schedules.tabs.details.observations')}
                  name="observations"
                  color="primary"
                />
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              { previousExecution && (
                <div title={format(previousExecution, 'PPPppp')}>
                  <b>
                    {t('schedules.tabs.details.last_occurence')}
                  </b>
                  {previousExecutionDistance}
                </div>
              )}

              {nextExecution && (
                <div title={format(nextExecution, 'PPPppp')}>
                  <b>
                    {t('schedules.tabs.details.next_occurence')}
                  </b>
                  {nextExecutionDistance}
                </div>
              )}
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  color="secondary"
                  onClick={handleExecuteNow()}
                >
                  {t('schedules.tabs.details.launch_now')}
                </Button>

                <Button
                  size="small"
                  color="secondary"
                  onClick={handleExecuteNow(true)}
                >
                  {t('schedules.tabs.details.launch_now_in_retry_mode')}
                </Button>
              </Stack>
            </Box>
          </Box>

          <Box
            className="bottomBtnsContainer"
          >
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
            >
              <Button
                color="primary"
                onClick={handleToggleStatus}
              >
                {
                  SCHEDULE.STATUSES.ACTIVE.includes(schedule.etat_schedule)
                    ? t('schedules.deactivate')
                    : t('schedules.activate')
                }
              </Button>

              <Button
                color="primary"
                variant="text"
                type="reset"
                onClick={form.resetForm}
              >
                {t('form.reset')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={saveLoading || !form.isValid}
              >
                {t('form.save')}
              </Button>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

ScheduleDetails.propTypes = {
  handleClickActivateDeactivate: PropTypes.func.isRequired,
  handleExecuteNow: PropTypes.func.isRequired,
  nextExecution: PropTypes.instanceOf(Date),
  nextExecutionDistance: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  previousExecution: PropTypes.instanceOf(Date),
  previousExecutionDistance: PropTypes.string,
  saveLoading: PropTypes.bool,
  schedule: schedulePropType.isRequired,
};

ScheduleDetails.defaultProps = {
  saveLoading: false,
  nextExecution: null,
  nextExecutionDistance: null,
  previousExecution: null,
  previousExecutionDistance: null,
};

export default ScheduleDetails;
