import { types } from 'generic/core/carts/actions';

const cartsReducer = (
  state = {
    carts: [],
    loading: true,
    editCart: { loading: true },
    editCartState: {},
    addToCartLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_CARTS: {
      return {
        ...state,
        carts: [],
      };
    }
    case types.FETCH_CARTS: {
      return {
        ...state,
        loading: true,
        carts: [],
      };
    }
    case types.FETCH_CARTS_SUCCESS: {
      return {
        ...state,
        carts: action.carts,
        loading: false,
      };
    }
    case types.FETCH_CARTS_ERROR: {
      return {
        ...state,
        carts: [],
        loading: false,
      };
    }
    case types.CLEANUP_CART: {
      return {
        ...state,
        editCart: { loading: true },
      };
    }
    case types.FETCH_CART: {
      return {
        ...state,
        editCart: { loading: true },
      };
    }
    case types.FETCH_CART_SUCCESS: {
      return {
        ...state,
        editCart: {
          ...action.cart,
          loading: false,
        },
        editCartState: {
          ...state.editCartState,
          fileUploadName: action.cart.image,
          fileUploading: false,
        },
      };
    }
    case types.FETCH_CART_ERROR: {
      return {
        ...state,
        editCart: { loading: false },
      };
    }
    case types.SAVE_CART: {
      return {
        ...state,
        editCartState: {
          ...state.editCartState,
          saveCartLoading: true,
        },
      };
    }
    case types.SAVE_CART_SUCCESS: {
      return {
        ...state,
        editCartState: {
          ...state.editCartState,
          saveCartLoading: false,
        },
      };
    }
    case types.SAVE_CART_ERROR: {
      return {
        ...state,
        responseData: action.response,
        editCartState: {
          ...state.editCartState,
          saveCartLoading: false,
        },
      };
    }
    case types.UPLOAD_CART_IMAGE: {
      return {
        ...state,
        editCartState: {
          ...state.editCartState,
          fileUploading: true,
        },
      };
    }
    case types.UPLOAD_CART_IMAGE_SUCCESS: {
      return {
        ...state,
        editCartState: {
          ...state.editCartState,
          fileUploadName: action.results.filename,
          fileUploading: false,
        },
      };
    }
    case types.UPLOAD_CART_IMAGE_ERROR: {
      return {
        ...state,
        editCartState: {
          ...state.editCartState,
          fileUploading: false,
        },
      };
    }
    case types.CLEAR_UPLOAD_CART_IMAGE: {
      return {
        ...state,
        editCartState: {
          ...state.editCartState,
          fileUploadName: '',
        },
      };
    }
    case types.RESET_CART_IMAGE: {
      return {
        ...state,
        editCartState: {
          ...state.editCartState,
          fileUploadName: state.editCart.image,
          fileUploading: false,
        },
      };
    }
    case types.ADD_TO_CART: {
      return {
        ...state,
        addToCartLoading: true,
      };
    }
    case types.ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        addToCartLoading: false,
      };
    }
    case types.ADD_TO_CART_ERROR: {
      return {
        ...state,
        addToCartLoading: false,
      };
    }
    default:
      return state;
  }
};

export default cartsReducer;
