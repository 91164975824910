let sagaRunner;

const runSaga = (sagaMiddleware, rootSaga) => {
  if (process.env.NODE_ENV === 'development' && module.hot && sagaRunner) {
    sagaRunner.cancel();
  }

  sagaRunner = sagaMiddleware.run(rootSaga);
};

export default runSaga;
