import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import _ from 'lodash';

const selectWidgetById = (state, widgetId) => _.get(state, `dashboard.widgets[${widgetId}]`, null);

const WidgetContainer = ({
  widgetId,
  children,
}) => {
  const widgetData = useSelector((state) => selectWidgetById(state, widgetId));

  if (widgetData === null || widgetData?.loading) {
    return (
      <Box
        height="100%"
        flexShrink="0"
      >
        <Skeleton sx={{ width: '100%', height: '100%', transform: 'inherit' }} />
      </Box>
    );
  }
  const {
    series,
    axisX,
    axisY,
    additionalData,
  } = widgetData;
  return children({
    series,
    axisX,
    axisY,
    additionalData,
  });
};

WidgetContainer.propTypes = {
  widgetId: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
};

export default WidgetContainer;
