import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Box, Typography, useMediaQuery, Divider, Tooltip, Stack, Link,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { Add, Clear, Edit } from '@mui/icons-material';

const MailingLists = ({
  mailingLists,
  handleDeleteMailingList,
  handleOpenMailingListDialog,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const data = mailingLists.map(
    (list) => ({
      id: list.listeDiffusion,
      libelle: list.libelle,
      users: _.map(list.listeDiffusionUtilisateurs, 'email').join(', '),
    }),
  );

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        sort: false,
      },
    },
    {
      name: 'libelle',
      label: t('mailing_lists.name'),
      options: {
        sort: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRender: (value, tableMeta) => (
          <Link
            component={RouterLink}
            underline="none"
            to={`users-mailing-list?idMailingList=${tableMeta.rowData[0]}&mailingList=${tableMeta.rowData[1]}`}
          >
            {value}
          </Link>
        ),
      },
    },
    {
      name: 'users',
      label: t('mailing_lists.users'),
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRender: (value) => (
          <Tooltip title={value}>
            <span>
              {
                _.truncate(value, {
                  length: 150,
                  separator: /,? +/,
                })
              }
            </span>
          </Tooltip>
        ),
        sort: false,
      },
    },
    {
      name: 'actions',
      label: t('actions.actions'),
      options: {
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRender: (value, tableMeta) => (
          <Stack spacing={0.5} direction="row">
            <TooltipButton
              title={t('actions.edit')}
              tag="fab"
              color="primary"
              size="extraSmall"
              onClick={() => handleOpenMailingListDialog(tableMeta.rowData[0], tableMeta.rowData[1])}
            >
              <Edit />
            </TooltipButton>
            <TooltipButton
              title={t('actions.delete')}
              tag="fab"
              color="error"
              size="extraSmall"
              onClick={() => handleDeleteMailingList(
                { data: [{ dataIndex: tableMeta.currentTableData[tableMeta.rowIndex].index }] },
                mailingLists,
              )}
            >
              <Clear />
            </TooltipButton>
          </Stack>
        ),
      },
    },
  ];

  const options = {
    responsive: 'simple',
    textLabels: t('datatable.textLabels', { returnObjects: true }),
    pagination: false,
    fixedHeader: true,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    filterType: 'checkbox',
    sortOrder: {
      name: 'libelle',
      direction: 'desc',
    },
    setTableProps: () => ({
      size: 'small',
    }),
    onRowsDelete: (rowsDeleted) => handleDeleteMailingList(rowsDeleted, mailingLists),
    // eslint-disable-next-line react/no-unstable-nested-components
    customToolbar: () => (
      <Box display="flex">
        <Divider orientation="vertical" sx={{ mx: 1 }} />
        <TooltipButton
          tag="iconButton"
          onClick={() => handleOpenMailingListDialog()}
          title={t('actions.create')}
        >
          <Add />
        </TooltipButton>
      </Box>
    ),
  };

  return (
    <Box
      width="100%"
      m={smallerThanLarge ? 0 : 2}
      id="datatable"
    >
      <MUIDataTable
        title={(
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{t('mailing_lists.list')}</Typography>
          </Box>
        )}
        data={data}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

MailingLists.propTypes = {
  mailingLists: PropTypes.arrayOf(PropTypes.shape({
    listeDiffusion: PropTypes.number,
    libelle: PropTypes.string,
    listeDiffusionUtilisateurs: PropTypes.arrayOf(PropTypes.shape({
      email: PropTypes.string,
    })),
  })).isRequired,
  handleDeleteMailingList: PropTypes.func.isRequired,
  handleOpenMailingListDialog: PropTypes.func.isRequired,
};

export default MailingLists;
