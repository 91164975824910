import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import {
  Facebook,
  LinkedIn,
  Twitter,
  WhatsApp,
} from '@mui/icons-material';
import { articlePropType } from 'generic/core/anr/proptypes';
import TooltipButton from 'generic/components/ui/TooltipButton';
import CopyToClipboard from 'generic/components/ui/CopyToClipboard';

const ShareDialog = ({
  article,
  handleClose,
  handleOpenSharePopup,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      scroll="paper"
      open
    >
      <DialogTitle>
        {t('anr.actions.share')}
      </DialogTitle>
      <DialogContent
        dividers
      >
        <Fragment>
          {t('anr.actions.share_of')}
          &nbsp;
          <b>{article.title}</b>
        </Fragment>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          my={2}
        >
          <TooltipButton
            color="twitter"
            onClick={() => handleOpenSharePopup(article.shareUrlTwitter)}
            size="large"
            tag="iconButton"
            title={t('anr.actions.share_twitter')}
          >
            <Twitter fontSize="large" />
          </TooltipButton>
          <TooltipButton
            color="facebook"
            onClick={() => handleOpenSharePopup(article.shareUrlFacebook)}
            size="large"
            tag="iconButton"
            title={t('anr.actions.share_facebook')}
          >
            <Facebook fontSize="large" />
          </TooltipButton>
          <TooltipButton
            color="linkedin"
            onClick={() => handleOpenSharePopup(article.shareUrlLinkedin)}
            size="large"
            tag="iconButton"
            title={t('anr.actions.share_linkedin')}
          >
            <LinkedIn fontSize="large" />
          </TooltipButton>
          <TooltipButton
            color="whatsapp"
            onClick={() => handleOpenSharePopup(article.shareUrlWhatsapp)}
            size="large"
            tag="iconButton"
            title={t('anr.actions.share_whatsapp')}
          >
            <WhatsApp fontSize="large" />
          </TooltipButton>
          <CopyToClipboard
            textToCopy={article.shareUrl}
            title={t('anr.actions.link_copy')}
            size="large"
            color="primary"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShareDialog.propTypes = {
  article: articlePropType.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpenSharePopup: PropTypes.func.isRequired,
};

export default ShareDialog;
