import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Box,
  Card,
  CardMedia,
} from '@mui/material';
import { Virtuoso } from 'react-virtuoso';

import ImageDetection from 'generic/components/ui/ImageDetection';
import TranscriptionSentence from 'generic/components/ui/TranscriptionSentence';
import Waveform from 'generic/components/ui/WaveSurfer';

import { fetchResults } from 'generic/core/search/actions';

const MediaWithTranscription = ({
  mediaType,
  mediaUrl,
  faces,
  frames,
  patchDocumentInComplete,
  transcriptionJSON,
  displayTranslatedSentences,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mediaRef = useRef(null);
  const virtuoso = useRef(null);
  const manualUserScroll = useRef(false);
  const manualUserEdition = useRef(false);
  const mediaWasPlayingBeforeEdition = useRef(false);
  const [currentMediaTime, setCurrentMediaTime] = useState(0);

  const waveRegions = [];
  _.each(transcriptionJSON, (sentence) => {
    waveRegions.push({
      start: sentence.start,
      end: sentence.end,
      color: `${sentence.spkColor}50`,
    });
  });

  let manualScrollTimeout = null;
  const handleManualScroll = () => {
    clearTimeout(manualScrollTimeout);
    manualUserScroll.current = true;
    manualScrollTimeout = setTimeout(() => {
      manualUserScroll.current = false;
    }, 3000);
  };

  const handlePlayOrPauseMediaWithSpace = (event) => {
    const { activeElement } = document;
    const isInputField = activeElement.tagName === 'INPUT'
      || activeElement.tagName === 'TEXTAREA'
      || activeElement.getAttribute('contenteditable') === 'true';

    if (!isInputField && mediaRef.current && !manualUserEdition.current && event.key === ' ') {
      if (!mediaRef.current.paused) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (mediaRef.current) {
      document.getElementById('transcription').addEventListener('wheel', handleManualScroll);
      document.addEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    }
    return () => {
      if (document.getElementById('transcription')) {
        document.getElementById('transcription').removeEventListener('wheel', handleManualScroll);
      }
      document.removeEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaRef]);

  const handleTranscriptClick = (time) => {
    if (mediaRef.current) {
      manualUserScroll.current = false;
      manualUserEdition.current = false;
      mediaRef.current.currentTime = time;
      if (!mediaRef.current.paused) {
        mediaRef.current.play();
      }
    }
  };

  const handleTimeUpdate = () => {
    if (mediaRef.current && !manualUserScroll.current && !manualUserEdition.current) {
      const { currentTime } = mediaRef.current;
      setCurrentMediaTime(currentTime);

      // Trouver le texte associé au moment actuel
      const activeItemIndex = transcriptionJSON.findIndex((item, index) => {
        const nextItem = transcriptionJSON[index + 1];
        return currentTime >= parseFloat(item.start) && (!nextItem || currentTime < parseFloat(nextItem.start));
      });

      // Faire défiler vers le texte actif
      if (activeItemIndex !== -1) {
        virtuoso.current.scrollToIndex({
          index: activeItemIndex,
          offset: -100,
          behavior: 'instant',
        });
      }
    }
  };

  const toggleManualUserEdition = (toggle) => {
    manualUserEdition.current = toggle;
    if (toggle && !mediaRef.current.paused) {
      mediaRef.current.pause();
      mediaWasPlayingBeforeEdition.current = true;
    } else if (!toggle) {
      if (mediaWasPlayingBeforeEdition.current) {
        mediaRef.current.play();
      }
      mediaWasPlayingBeforeEdition.current = false;
    }
  };

  const handleImageClick = (time) => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = time;
      mediaRef.current.play();
    }
  };

  const handleSimilarityImage = (idChamp) => (itemVector) => {
    const nameChamp = `F_${idChamp}`;
    dispatch(fetchResults({
      bodyItems: {
        premier: 1,
        dernier: 20,
        base: 22801201,
        champs: {
          [nameChamp]: itemVector,
        },
      },
      refreshForm: true,
      clearSelection: true,
      clearResults: true,
    }));
  };

  return (
    <Fragment>
      {/* LECTEUR AUDIO */}
      {mediaType === 'audio' && (
        <Box sx={{ mr: 2 }}>
          <CardMedia
            sx={{
              maxWidth: '500px',
              mt: 2,
              mb: 2,
              flexShrink: 0,
            }}
            component="audio"
            controls
            onTimeUpdate={handleTimeUpdate}
            src={mediaUrl}
            ref={mediaRef}
          />
          <Waveform mediaRef={mediaRef} regions={waveRegions} />
        </Box>
      )}
      {/* LECTEUR VIDEO */}
      <Box sx={{ display: 'flex', gap: '32px' }}>
        {mediaType === 'video' && (
          <Card
            sx={{
              maxWidth: '500px',
              maxHeight: '300px',
              mt: 2,
              mb: 0,
              flexShrink: 0,
            }}
          >
            <CardMedia
              sx={{
                '&.MuiCardMedia-root': {
                  maxHeight: '300px',
                },
              }}
              component="video"
              controls
              src={mediaUrl}
              ref={mediaRef}
              onTimeUpdate={handleTimeUpdate}
            />
          </Card>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'hidden',
          }}
        >
          {/* DETECTION SCENES */}
          {!_.isEmpty(frames) && (
            <ImageDetection
              data={frames}
              title={t('document.frames')}
              handleImageClick={handleImageClick}
            />
          )}
          {/* DETECTION IMAGE */}
          {!_.isEmpty(faces) && (
            <ImageDetection
              data={faces}
              title={t('document.faces')}
              handleImageClick={handleImageClick}
              handleSimilarityImage={handleSimilarityImage(1032000592)}
            />
          )}
        </Box>
      </Box>
      <Box
        mt={2}
        whiteSpace="pre-wrap"
        lineHeight="30px"
        sx={{ clear: 'both' }}
        overflow="auto"
        flexGrow="1"
        id="transcription"
      >
        <Virtuoso
          ref={virtuoso}
          data={transcriptionJSON}
          style={{ height: '100%' }}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(index, sentence) => (
            <TranscriptionSentence
              currentMediaTime={currentMediaTime}
              handleTranscriptClick={handleTranscriptClick}
              index={index}
              patchDocumentInComplete={patchDocumentInComplete}
              sentence={sentence}
              toggleManualUserEdition={toggleManualUserEdition}
              displayHours={parseFloat(_.last(transcriptionJSON).start) >= 3600}
              displayTranslatedSentence={displayTranslatedSentences}
            />
          )}
        />
      </Box>
    </Fragment>
  );
};

MediaWithTranscription.propTypes = {
  displayTranslatedSentences: PropTypes.bool,
  patchDocumentInComplete: PropTypes.func.isRequired,
  transcriptionJSON: PropTypes.arrayOf(PropTypes.shape({
    sentence: PropTypes.string,
    spkid: PropTypes.string,
    spkColor: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    words: PropTypes.arrayOf(PropTypes.shape({
      confidence: PropTypes.number,
      start: PropTypes.number,
      word: PropTypes.string,
    })),
  })),
  mediaType: PropTypes.oneOf(['audio', 'video']).isRequired,
  mediaUrl: PropTypes.string.isRequired,
  faces: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      time: PropTypes.string,
      vector: PropTypes.string,
    }),
  ),
  frames: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      time: PropTypes.string,
    }),
  ),
};

MediaWithTranscription.defaultProps = {
  displayTranslatedSentences: false,
  transcriptionJSON: [],
  faces: [],
  frames: [],
};

export default MediaWithTranscription;
