import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  refineIncludeCriterion,
  refineExcludeCriterion,
  refineRemoveCriterion,
  refineReplaceCriterion,
} from 'generic/core/search/actions';
import Criteria from 'generic/components/ui/Criteria';

const CriteriaContainer = () => {
  const activeCollection = useSelector((state) => state.collections.activeCollection);
  const criteria = useSelector((state) => state.search.results.criteres);
  const isRAGSearch = useSelector((state) => state.search.isRAGSearch);
  const dispatch = useDispatch();

  const handleExclude = (criterion) => {
    dispatch(refineExcludeCriterion(criterion));
  };

  const handleInclude = (criterion) => {
    dispatch(refineIncludeCriterion(criterion));
  };

  const handleRemove = (criterion) => {
    dispatch(refineRemoveCriterion(criterion));
  };

  const handleEdit = (criterion, value) => {
    dispatch(refineReplaceCriterion(criterion, value));
  };

  if (_.isEmpty(criteria)) {
    return null;
  }

  let criteriaAltered = [...criteria];
  if (!_.isEmpty(activeCollection)) {
    criteriaAltered.unshift({
      mouvementChamp: 1000,
      value: activeCollection.libelle,
      operateur: '',
      readOnly: true,
    });
  }
  if (isRAGSearch) {
    criteriaAltered = _.map(
      criteriaAltered,
      (crit) => ({
        ...crit,
        readOnly: true,
      }),
    );
  }

  return (
    <Criteria
      criteria={criteriaAltered}
      handleExclude={handleExclude}
      handleInclude={handleInclude}
      handleRemove={handleRemove}
      handleEdit={handleEdit}
    />
  );
};

export default CriteriaContainer;
