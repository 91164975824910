import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  closeDemandSourceDialog,
  demandNewSource,
  fetchAnRFolders,
} from 'generic/core/anr/actions';
import { treeFlattener } from 'generic/utils/utils';
import CenteredCircularProgress from 'generic/components/ui/CenteredCircularProgress';
import DemandSourceDialog from 'generic/components/dialogs/anr/DemandSourceDialog';

const DemandSourceContainer = () => {
  const user = useSelector((state) => state.anr.user);
  const loading = useSelector((state) => state.anr.dialogDemandSource.loading);
  const dialogOpen = useSelector((state) => state.anr.dialogDemandSource.open);
  const folders = useSelector((state) => state.anr.folders);
  const dispatch = useDispatch();

  useEffect(() => {
    // A la création du reducer, folders vaut null,
    // on tente donc de les charger si ils ne sont
    // pas encore dans Redux
    if (folders === null) {
      dispatch(fetchAnRFolders());
    }
  }, [folders, dispatch]);

  const handleSubmit = (values) => {
    dispatch(demandNewSource(values));
  };

  if (_.isEmpty(folders)) {
    if (loading) {
      return <CenteredCircularProgress />;
    }
    return null;
  }

  const flattenedFolders = treeFlattener(_.flatten(folders));
  const filteredFolders = _.filter(flattenedFolders, { is_root: false });

  if (!dialogOpen) {
    return null;
  }
  return (
    <DemandSourceDialog
      folders={filteredFolders}
      user={user}
      loading={loading}
      open={dialogOpen}
      handleSubmit={handleSubmit}
      handleClose={() => dispatch(closeDemandSourceDialog())}
    />
  );
};

export default React.memo(DemandSourceContainer);
