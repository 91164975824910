import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';

import {
  activateDeactivateSchedulesError,
  activateDeactivateSchedulesSuccess,
  createImmediateScheduleExecutionSuccess,
  createImmediateScheduleExecutionError,
  fetchScheduleError,
  fetchScheduleSuccess,
  fetchSchedulesError,
  fetchSchedulesSuccess,
  types,
  updateScheduleExtensions,
} from 'generic/core/schedules/actions';

import {
  fetchSchedules as fetchSchedulesApi,
  fetchSchedule as fetchScheduleApi,
  updateSchedule,
  saveScheduleComprehension,
  fetchScheduleExtensions,
} from 'generic/api/schedule';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { snackActions } from 'generic/utils/snackbar';

const { SCHEDULE } = QES_CONSTANTS;

function* workFetchSchedules({ status }) {
  try {
    const schedules = yield call(fetchSchedulesApi, { uriParams: { etatSchedule: status } });
    yield put(fetchSchedulesSuccess(schedules));
  } catch (error) {
    yield put(fetchSchedulesError(error));
    console.error(error);
    snackActions.error(i18next.t('schedules.toast.error_fetching_schedules'));
  }
}

function* watchFetchSchedules() {
  yield takeLatest(types.FETCH_SCHEDULES, workFetchSchedules);
}

function* workActivateDeactivateSchedules({ ids, active, afterSave = () => {} }) {
  try {
    const etat = (active) ? SCHEDULE.STATUSES.DEFAULT_ACTIVE : SCHEDULE.STATUSES.DEFAULT_INACTIVE;
    const message = (active) ? 'schedules.toast.schedules_activate' : 'schedules.toast.schedules_deactivate';

    const bodyItems = ids.map((id) => ({
      schedule: id,
      etat_schedule: etat,
    }));

    const results = yield call(updateSchedule, { bodyItems });
    yield put(activateDeactivateSchedulesSuccess(results));
    snackActions.success(i18next.t(message, { count: ids.length }));
    yield call(afterSave);
  } catch (error) {
    yield put(activateDeactivateSchedulesError(error));
    console.error(error);
    snackActions.error(i18next.t('schedules.toast.error_updating_schedule'));
  }
}

function* watchActivateDeactivateSchedules() {
  yield takeLatest(types.ACTIVATE_DEACTIVATE_SCHEDULES, workActivateDeactivateSchedules);
}

function* workFetchSchedule(action) {
  try {
    const schedule = yield call(fetchScheduleApi, action.id);
    yield put(fetchScheduleSuccess(schedule));
  } catch (error) {
    yield put(fetchScheduleError(error));
    console.error(error);
    snackActions.error(i18next.t('schedules.error_fetching_schedule'));
  }
}

function* watchFetchSchedule() {
  yield takeLatest(types.FETCH_SCHEDULE, workFetchSchedule);
}

function* workCreateImmediateScheduleExecution(action) {
  try {
    const bodyItems = {
      periodicite_schedule: 100,
      schedule: action.idSchedule,
      exclure_reprise1: action.exclure,
      jour_semaine: 1,
      mois: 1,
    };

    const scheduleComprehension = yield call(saveScheduleComprehension, { bodyItems });
    yield put(createImmediateScheduleExecutionSuccess(scheduleComprehension));
    const scheduleExtensions = yield call(
      fetchScheduleExtensions,
      '',
      { uriParams: { idSchedule: action.idSchedule } },
    );
    yield put(updateScheduleExtensions(action.idSchedule, scheduleExtensions));
  } catch (error) {
    yield put(createImmediateScheduleExecutionError(error));
    console.error(error);
    snackActions.error(i18next.t('schedules.error_fetching_schedule'));
  }
}

function* watchCreateImmediateScheduleExecution() {
  yield takeLatest(types.CREATE_IMMEDIATE_SCHEDULE_EXECUTION, workCreateImmediateScheduleExecution);
}

export default {
  watchFetchSchedules,
  watchFetchSchedule,
  watchActivateDeactivateSchedules,
  watchCreateImmediateScheduleExecution,
};
