import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import SendToFriendDialog from 'generic/components/dialogs/SendToFriendDialog';
import { closeDialogSendToFriend } from 'generic/core/actions/actions';
import { clearSingleItem } from 'generic/core/selection/actions';
import { doAutocompleteEmail } from 'generic/api/users';
import { buildSendToFriend, submitSendToFriend } from 'generic/core/sendToFriend/actions';
import { emailsAsStringFromAutocomplete } from 'generic/utils/qesUtils';

const SendToFriendDialogContainer = ({ scope, options }) => {
  const open = useSelector((state) => state.actions.dialogSendToFriendOpened);
  const loading = useSelector((state) => state.sendToFriend.loading);
  const building = useSelector((state) => state.sendToFriend.building);
  const sendToFriendData = useSelector((state) => state.sendToFriend.data);
  const [ccFieldVisible, setCcFieldVisible] = useState(false);
  const [bccFieldVisible, setBccFieldVisible] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setCcFieldVisible(false);
    setBccFieldVisible(false);
    dispatch(clearSingleItem());
    dispatch(closeDialogSendToFriend());
  };

  const handleSubmit = (values) => {
    const emailsObj = emailsAsStringFromAutocomplete(['to', 'cc', 'bcc'], values);
    const params = _.merge({ scope }, options, emailsObj);
    dispatch(submitSendToFriend(params));
  };

  useEffect(() => {
    if (open) {
      dispatch(buildSendToFriend(scope, options));
    }
  }, [open, dispatch, scope, options]);

  if (!open) {
    return null;
  }
  return (
    <SendToFriendDialog
      bccFieldVisible={bccFieldVisible}
      building={building}
      ccFieldVisible={ccFieldVisible}
      doAutocomplete={doAutocompleteEmail}
      handleClose={handleClose}
      handleDisplayBccField={() => setBccFieldVisible(true)}
      handleDisplayCcField={() => setCcFieldVisible(true)}
      handleSubmit={handleSubmit}
      loading={loading}
      scope={scope}
      sendToFriendData={sendToFriendData}
    />
  );
};

SendToFriendDialogContainer.propTypes = {
  scope: PropTypes.oneOf(['results', 'anr', 'cart']).isRequired,
  options: PropTypes.shape({
    cartId: PropTypes.number.isRequired,
    idDocs: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
};

SendToFriendDialogContainer.defaultProps = {
  options: {
    cartId: 1000,
    idDocs: [],
  },
};

export default SendToFriendDialogContainer;
