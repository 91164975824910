import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  Skeleton,
} from '@mui/material';

const DocumentsListSkeleton = ({
  nbDocs,
  withHeader,
  withImages,
}) => {
  const rows = [];
  if (withHeader) {
    rows.push(
      <ListItem key="header">
        <Box width="inherit" my={0.5}>
          <Skeleton variant="text" width="100%" height={25} />
        </Box>
      </ListItem>,
    );
  }

  for (let i = 0; i < nbDocs; i += 1) {
    rows.push(
      <ListItem key={`item${i}`}>
        <Box width="inherit" ml={1}>
          <Skeleton variant="text" width="75%" height={34} />
          <Box display="flex" ml={3}>
            {withImages && (
              <Box flexShrink="0" mr={1}>
                <Skeleton variant="rectangular" width={100} height={60} mr={2} />
              </Box>
            )}
            <Box flexGrow="1" lineHeight="1.5rem">
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="75%" height={20} />
            </Box>
          </Box>
        </Box>
      </ListItem>,
    );
  }
  return (
    <List dense>
      {rows}
    </List>
  );
};

DocumentsListSkeleton.propTypes = {
  nbDocs: PropTypes.number,
  withHeader: PropTypes.bool,
  withImages: PropTypes.bool,
};

DocumentsListSkeleton.defaultProps = {
  nbDocs: 3,
  withHeader: false,
  withImages: false,
};

export default DocumentsListSkeleton;
