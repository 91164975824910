import { del, get, post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const fetchComments = ({ ...rest }) => get({ path: '/comment', ...rest }).then(handleComplete);

const createComment = ({ ...rest }) => post({ path: '/comment', ...rest }).then(handleComplete);

const updateCommentAlert = ({ ...rest }) => post({ path: '/comment/update-alert', ...rest }).then(handleComplete);

const deleteComment = ({ ...rest }) => del({ path: '/comment', ...rest }).then(handleComplete);

export {
  fetchComments,
  createComment,
  deleteComment,
  updateCommentAlert,
};
