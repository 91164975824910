export const types = {
  CLEANUP_CART: '@CARTS/CLEANUP_CART',
  CLEANUP_CARTS: '@CARTS/CLEANUP_CARTS',
  FETCH_CARTS: '@CARTS/FETCH_CARTS',
  FETCH_CARTS_SUCCESS: '@CARTS/FETCH_CARTS_SUCCESS',
  FETCH_CARTS_ERROR: '@CARTS/FETCH_CARTS_ERROR',
  FETCH_CART: '@CARTS/FETCH_CART',
  FETCH_CART_SUCCESS: '@CARTS/FETCH_CART_SUCCESS',
  FETCH_CART_ERROR: '@CARTS/FETCH_CART_ERROR',
  DELETE_CARTS: '@CARTS/DELETE_CARTS',
  DELETE_CARTS_SUCCESS: '@CARTS/DELETE_CARTS_SUCCESS',
  DELETE_CARTS_ERROR: '@CARTS/DELETE_CARTS_ERROR',
  DELETE_CART_DOCUMENTS: '@CARTS/DELETE_CART_DOCUMENTS',
  DELETE_CART_DOCUMENTS_SUCCESS: '@CARTS/DELETE_CART_DOCUMENTS_SUCCESS',
  DELETE_CART_DOCUMENTS_ERROR: '@CARTS/DELETE_CART_DOCUMENTS_ERROR',
  SAVE_CART: '@CARTS/SAVE_CART',
  SAVE_CART_SUCCESS: '@CARTS/SAVE_CART_SUCCESS',
  SAVE_CART_ERROR: '@CARTS/SAVE_CART_ERROR',
  UPLOAD_CART_IMAGE: '@CARTS/UPLOAD_CART_IMAGE',
  UPLOAD_CART_IMAGE_SUCCESS: '@CARTS/UPLOAD_CART_IMAGE_SUCCESS',
  UPLOAD_CART_IMAGE_ERROR: '@CARTS/UPLOAD_CART_IMAGE_ERROR',
  CLEAR_UPLOAD_CART_IMAGE: '@CARTS/CLEAR_UPLOAD_CART_IMAGE',
  RESET_CART_IMAGE: '@CARTS/RESET_CART_IMAGE',
  ADD_TO_CART: '@CARTS/ADD_TO_CART',
  ADD_TO_CART_SUCCESS: '@CARTS/ADD_TO_CART_SUCCES',
  ADD_TO_CART_ERROR: '@CARTS/ADD_TO_CART_ERROR',
};

export const cleanupCart = () => ({
  type: types.CLEANUP_CART,
});

export const cleanupCarts = () => ({
  type: types.CLEANUP_CARTS,
});

export const fetchCarts = () => ({
  type: types.FETCH_CARTS,
});

export const fetchCartsSuccess = (carts) => ({
  type: types.FETCH_CARTS_SUCCESS,
  carts,
});

export const fetchCartsError = (response) => ({
  type: types.FETCH_CARTS_ERROR,
  response,
});

export const fetchCart = (id) => ({
  type: types.FETCH_CART,
  id,
});

export const fetchCartSuccess = (cart) => ({
  type: types.FETCH_CART_SUCCESS,
  cart,
});

export const fetchCartError = (response) => ({
  type: types.FETCH_CART_ERROR,
  response,
});

export const deleteCarts = (ids) => ({
  type: types.DELETE_CARTS,
  ids,
});

export const deleteCartsSuccess = () => ({
  type: types.DELETE_CARTS_SUCCESS,
});

export const deleteCartsError = (response) => ({
  type: types.DELETE_CARTS_ERROR,
  response,
});

export const deleteCartDocuments = (id, ids) => ({
  type: types.DELETE_CART_DOCUMENTS,
  id,
  ids,
});

export const deleteCartDocumentsSuccess = () => ({
  type: types.DELETE_CART_DOCUMENTS_SUCCESS,
});

export const deleteCartDocumentsError = (response) => ({
  type: types.DELETE_CART_DOCUMENTS_ERROR,
  response,
});

export const saveCart = (params) => ({
  type: types.SAVE_CART,
  params,
});

export const saveCartSuccess = (results) => ({
  type: types.SAVE_CART_SUCCESS,
  results,
});

export const saveCartError = (response) => ({
  type: types.SAVE_CART_ERROR,
  response,
});

export const clearUploadCartImage = () => ({
  type: types.CLEAR_UPLOAD_CART_IMAGE,
});

export const resetCartImage = () => ({
  type: types.RESET_CART_IMAGE,
});

export const addToCart = (params, scope) => ({
  type: types.ADD_TO_CART,
  params,
  scope,
});

export const addToCartSuccess = () => ({
  type: types.ADD_TO_CART_SUCCESS,
});

export const addToCartError = (response) => ({
  type: types.ADD_TO_CART_ERROR,
  response,
});
