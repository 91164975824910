import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      series: {
        animation: false,
        cursor: 'pointer',
      },
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: { type: 'category', gridLineWidth: 0 },
    chart: { type: 'bar' },
  },
};

const Bar = ({ highchartsOptions, containerId }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const chartRef = useRef();
  useEffect(() => {
    let resizeObserver;
    if (containerId) {
      resizeObserver = new ResizeObserver(() => {
        if (chartRef.current) {
          const { chart } = chartRef.current;
          if (!_.isEmpty(chart)) {
            chart.reflow();
          }
        }
      });

      resizeObserver.observe(document.getElementById(containerId));
    }
    return () => {
      if (resizeObserver) {
        const el = document.getElementById(containerId);
        if (el) {
          resizeObserver.unobserve(el);
        } else {
          resizeObserver.disconnect();
        }
      }
    };
  }, [containerId]);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
      ref={chartRef}
    />
  );
};

Bar.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  containerId: PropTypes.number,
};

Bar.defaultProps = {
  containerId: null,
};

export default Bar;
