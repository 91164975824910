export const types = {
  SET_CONFIRM_DIALOG_LOADING: '@CONFIRMDIALOG/SET_CONFIRM_DIALOG_LOADING',
  UNSET_CONFIRM_DIALOG_LOADING: '@CONFIRMDIALOG/UNSET_CONFIRM_DIALOG_LOADING',
  SET_CONFIRM_DIALOG_DATA: '@CONFIRMDIALOG/SET_CONFIRM_DIALOG_DATA',
  UNSET_CONFIRM_DIALOG_DATA: '@CONFIRMDIALOG/UNSET_CONFIRM_DIALOG_DATA',
  CONFIRM_DIALOG_SUBMIT: '@CONFIRMDIALOG/CONFIRM_DIALOG_SUBMIT',
};

export const setConfirmDialogSumbit = (dialogData) => ({
  type: types.CONFIRM_DIALOG_SUBMIT,
  dialogData,
});

export const setConfirmDialogLoading = () => ({
  type: types.SET_CONFIRM_DIALOG_LOADING,
});

export const unsetConfirmDialogLoading = () => ({
  type: types.UNSET_CONFIRM_DIALOG_LOADING,
});

export const setConfirmDialogData = (payload) => ({
  type: types.SET_CONFIRM_DIALOG_DATA,
  payload,
});

export const unsetConfirmDialogData = () => ({
  type: types.UNSET_CONFIRM_DIALOG_DATA,
});
