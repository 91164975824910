import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';
import {
  types,
  summarizeSuccess,
  summarizeError,
} from 'generic/core/summarize/actions';
import summarize from 'generic/api/summarize';
import { snackActions } from 'generic/utils/snackbar';
import { getActiveMovementId, getActiveSelectionItems } from 'generic/utils/qesUtils';

function* workSummarize({ scope, options }) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let documentitemIds;
    let mouvementId;
    if (scope === 'cart' && options.cartId !== 1000) {
      documentitemIds = options.idDocs;
    } else if (singleItem) {
      documentitemIds = [singleItem.documentitem];
      mouvementId = singleItem?.mouvement;
    } else {
      const checkedItems = yield select((state) => getActiveSelectionItems(state));
      documentitemIds = _.map(
        checkedItems,
        (item) => item.documentitem,
      );
    }
    let data;
    if (scope === 'cart' && options.cartId !== 1000) {
      data = yield call(summarize, {
        uriParams: {
          panier: options.cartId,
          documentitem: documentitemIds,
        },
      });
    } else {
      if (!mouvementId) {
        const searchState = yield select((state) => state.search);
        mouvementId = getActiveMovementId(searchState);
      }
      data = yield call(summarize, {
        uriParams: {
          mouvement: mouvementId,
          documentitem: documentitemIds,
        },
      });
    }
    yield put(summarizeSuccess(data.summary));
  } catch (error) {
    yield put(summarizeError(error));
    console.error(error);
    snackActions.error(i18next.t('summarize.error_summarizing'));
  }
}

function* watchSummarize() {
  yield takeLatest(types.SUMMARIZE, workSummarize);
}

export default {
  watchSummarize,
};
