import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import { changeActiveBase } from 'generic/core/config/actions';
import ResultsWrapper from 'generic/containers/ResultsWrapper';
import DashboardWrapper from 'generic/containers/DashboardWrapper';
import useCheckActiveBase from 'generic/core/hooks/useCheckActiveBase';

const ResultsHOC = () => {
  const defaultBaseId = useSelector((state) => state.config.settings.baseInterne_368);
  const dispatch = useDispatch();
  const { shouldChangeToNewBase, shouldChangeToDefaultBase } = useCheckActiveBase();
  const { baseId } = useParams();

  useEffect(() => {
    if (shouldChangeToDefaultBase) {
      dispatch(changeActiveBase(defaultBaseId));
    }
    if (shouldChangeToNewBase) {
      dispatch(changeActiveBase(+baseId));
    }
  }, [shouldChangeToDefaultBase, shouldChangeToNewBase, baseId, defaultBaseId, dispatch]);

  if (shouldChangeToDefaultBase || shouldChangeToNewBase) {
    return null;
  }
  return (
    <Switch>
      <Route
        path={['/search/results/:baseId']}
        component={ResultsWrapper}
      />
      <Route
        path={['/search/dashboard/:baseId/:dashboardId?']}
        component={DashboardWrapper}
      />
      <Redirect
        to={`/search/dashboard/${defaultBaseId}`}
      />
    </Switch>
  );
};

export default ResultsHOC;
