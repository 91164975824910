export const types = {
  CREATE_MAILING_LIST: '@MAILING_LIST/CREATE_MAILING_LIST',
  CREATE_MAILING_LIST_SUCCESS: '@MAILING_LIST/CREATE_MAILING_LIST_SUCCESS',
  CREATE_MAILING_LIST_ERROR: '@MAILING_LIST/CREATE_MAILING_LIST_ERROR',
  CREATE_USER_MAILING_LIST: '@MAILING_LIST/CREATE_USER_MAILING_LIST',
  CREATE_USER_MAILING_LIST_SUCCESS: '@MAILING_LIST/CREATE_USER_MAILING_LIST_SUCCESS',
  CREATE_USER_MAILING_LIST_ERROR: '@MAILING_LIST/CREATE_USER_MAILING_LIST_ERROR',
  CLEANUP_MAILING_LISTS: '@MAILING_LIST/CLEANUP_MAILING_LISTS',
  CLEANUP_USERS_MAILING_LIST: '@MAILING_LIST/CLEANUP_USERS_MAILING_LIST',
  CLOSE_MAILING_LIST_DIALOG: '@MAILING_LIST/CLOSE_MAILING_LIST_DIALOG',
  CLOSE_USER_MAILING_LIST_DIALOG: '@MAILING_LIST/CLOSE_USER_MAILING_LIST_DIALOG',
  DELETE_MAILING_LISTS: '@MAILING_LIST/DELETE_MAILING_LISTS',
  DELETE_MAILING_LISTS_SUCCESS: '@MAILING_LIST/DELETE_MAILING_LISTS_SUCCESS',
  DELETE_MAILING_LISTS_ERROR: '@MAILING_LIST/DELETE_MAILING_LISTS_ERROR',
  DELETE_USERS_MAILING_LIST: '@MAILING_LIST/DELETE_USERS_MAILING_LIST',
  DELETE_USERS_MAILING_LIST_SUCCESS: '@MAILING_LIST/DELETE_USERS_MAILING_LIST_SUCCESS',
  DELETE_USERS_MAILING_LIST_ERROR: '@MAILING_LIST/DELETE_USERS_MAILING_LIST_ERROR',
  EDIT_MAILING_LIST: '@MAILING_LIST/EDIT_MAILING_LIST',
  EDIT_MAILING_LIST_SUCCESS: '@MAILING_LIST/EDIT_MAILING_LIST_SUCCESS',
  EDIT_MAILING_LIST_ERROR: '@MAILING_LIST/EDIT_MAILING_LIST_ERROR',
  EDIT_USER_MAILING_LIST: '@MAILING_LIST/EDIT_USER_MAILING_LIST',
  EDIT_USER_MAILING_LIST_SUCCESS: '@MAILING_LIST/EDIT_USER_MAILING_LIST_SUCCESS',
  EDIT_USER_MAILING_LIST_ERROR: '@MAILING_LIST/EDIT_USER_MAILING_LIST_ERROR',
  FETCH_MAILING_LISTS: '@MAILING_LIST/FETCH_MAILING_LISTS',
  FETCH_MAILING_LISTS_SUCCESS: '@MAILING_LIST/FETCH_MAILING_LISTS_SUCCESS',
  FETCH_MAILING_LISTS_ERROR: '@MAILING_LIST/FETCH_MAILING_LISTS_ERROR',
  FETCH_USERS_MAILING_LIST: '@MAILING_LIST/FETCH_USERS_MAILING_LIST',
  FETCH_USERS_MAILING_LIST_SUCCESS: '@MAILING_LIST/FETCH_USERS_MAILING_LIST_SUCCESS',
  FETCH_USERS_MAILING_LIST_ERROR: '@MAILING_LIST/FETCH_USERS_MAILING_LIST_ERROR',
  OPEN_MAILING_LIST_DIALOG: '@MAILING_LIST/OPEN_MAILING_LIST_DIALOG',
  OPEN_USER_MAILING_LIST_DIALOG: '@MAILING_LIST/OPEN_USER_MAILING_LIST_DIALOG',
};

/* Actions des listes de diffusion */
export const cleanupMailingLists = () => ({
  type: types.CLEANUP_MAILING_LISTS,
});

export const fetchMailingLists = () => ({
  type: types.FETCH_MAILING_LISTS,
});

export const fetchMailingListsSuccess = (mailingLists) => ({
  type: types.FETCH_MAILING_LISTS_SUCCESS,
  mailingLists,
});

export const fetchMailingListsError = (response) => ({
  type: types.FETCH_MAILING_LISTS_ERROR,
  response,
});

export const openMailingListDialog = (id, name) => ({
  type: types.OPEN_MAILING_LIST_DIALOG,
  id,
  name,
});

export const closeMailingListDialog = () => ({
  type: types.CLOSE_MAILING_LIST_DIALOG,
});

export const createMailingList = (formValues) => ({
  type: types.CREATE_MAILING_LIST,
  formValues,
});

export const createMailingListSuccess = () => ({
  type: types.CREATE_MAILING_LIST_SUCCESS,
});

export const createMailingListError = () => ({
  type: types.CREATE_MAILING_LIST_ERROR,
});

export const editMailingList = (formValues) => ({
  type: types.EDIT_MAILING_LIST,
  formValues,
});

export const editMailingListSuccess = () => ({
  type: types.EDIT_MAILING_LIST_SUCCESS,
});

export const editMailingListError = () => ({
  type: types.EDIT_MAILING_LIST_ERROR,
});

export const deleteMailingListErrors = (ids) => ({
  type: types.DELETE_MAILING_LISTS,
  ids,
});

export const deleteMailingListsSuccess = () => ({
  type: types.DELETE_MAILING_LISTS_SUCCESS,
});

export const deleteMailingListsError = () => ({
  type: types.DELETE_MAILING_LISTS_ERROR,
});

/* Actions des utilisateurs des listes de diffusion */
export const cleanupUsersMailingList = () => ({
  type: types.CLEANUP_USERS_MAILING_LIST,
});

export const fetchUsersMailingList = (goToEmail) => ({
  type: types.FETCH_USERS_MAILING_LIST,
  goToEmail,
});

export const fetchUsersMailingListSuccess = (emails, goToEmail) => ({
  type: types.FETCH_USERS_MAILING_LIST_SUCCESS,
  emails,
  goToEmail,
});

export const fetchUsersMailingListError = () => ({
  type: types.FETCH_USERS_MAILING_LIST_ERROR,
});

export const openUserMailingListDialog = () => ({
  type: types.OPEN_USER_MAILING_LIST_DIALOG,
});

export const closeUSerMailingListDialog = () => ({
  type: types.CLOSE_USER_MAILING_LIST_DIALOG,
});

export const createUserMailingList = (formValues, newUser) => ({
  type: types.CREATE_USER_MAILING_LIST,
  formValues,
  newUser,
});

export const createUserMailingListSuccess = () => ({
  type: types.CREATE_USER_MAILING_LIST_SUCCESS,
});

export const createUserMailingListError = () => ({
  type: types.CREATE_USER_MAILING_LIST_ERROR,
});

export const editUserMailingList = (formValues) => ({
  type: types.EDIT_USER_MAILING_LIST,
  formValues,
});

export const editUSerMailingListSuccess = () => ({
  type: types.EDIT_USER_MAILING_LIST_SUCCESS,
});

export const editUSerMailingListError = () => ({
  type: types.EDIT_USER_MAILING_LIST_ERROR,
});

export const deleteUsersMailingList = (ids) => ({
  type: types.DELETE_USERS_MAILING_LIST,
  ids,
});

export const deleteUsersMailingListSuccess = () => ({
  type: types.DELETE_USERS_MAILING_LIST_SUCCESS,
});

export const deleteUsersMailingListError = () => ({
  type: types.DELETE_USERS_MAILING_LIST_ERROR,
});
