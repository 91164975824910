import {
  get,
  del,
  post,
  patch,
} from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const fetchCarts = ({ ...rest }) => get({ path: '/panier/retrieve-all', ...rest }).then(handleComplete);

const deleteCarts = (ids) => del({ path: `/panier/${ids.join(',')}` }).then(handleComplete);

const fetchCart = (id) => get({ path: `/panier/${id}` }).then(handleComplete);

const deleteCartDocuments = (ids) => del({ path: `/panier-document/${ids.join(',')}` }).then(handleComplete);

const updateCart = ({ ...rest }) => patch({ path: '/panier', ...rest }).then(handleComplete);

const addToCart = ({ ...rest }) => post({ path: '/panier/create-with-documents', ...rest }).then(handleComplete);

export {
  fetchCarts,
  deleteCarts,
  fetchCart,
  deleteCartDocuments,
  updateCart,
  addToCart,
};
