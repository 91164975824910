import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activateDeactivateSchedules, cleanupSchedules, fetchSchedules } from 'generic/core/schedules/actions';
import Schedules from 'generic/components/pages/Schedules';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { SCHEDULE } = QES_CONSTANTS;

const SchedulesContainer = () => {
  const loading = useSelector((state) => state.schedules.loading);
  const schedules = useSelector((state) => state.schedules.schedules);
  const bases = useSelector((state) => state.config.bases);

  const dispatch = useDispatch();

  const [statusFilter, setStatusFilter] = useState(SCHEDULE.STATUSES.ACTIVE.join(','));
  const [selectedRows, setSelectedRows] = useState([]);

  const afterSave = useCallback(() => {
    dispatch(fetchSchedules(statusFilter));
  }, [dispatch, statusFilter]);

  const handleClickActivateDeactivate = (id, active) => {
    setSelectedRows([]);
    dispatch(activateDeactivateSchedules([id], active, afterSave));
  };

  const handleClickActivateDeactivateSelected = (rowsSelected, data, active) => {
    const ids = rowsSelected.data.reduce((acc, row) => {
      if (data[row.dataIndex]) {
        acc.push(data[row.dataIndex][0]);
      }

      return acc;
    }, []);

    setSelectedRows([]);
    dispatch(activateDeactivateSchedules(ids, active, afterSave));
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  useEffect(() => dispatch(fetchSchedules(statusFilter)), [statusFilter, dispatch]);

  // cleanup
  useEffect(() => (() => dispatch(cleanupSchedules())), [dispatch]);

  return (
    <Schedules
      {...{
        bases,
        schedules,
        loading,
        handleStatusChange,
        statusFilter,
        setSelectedRows,
        selectedRows,
        handleClickActivateDeactivate,
        handleClickActivateDeactivateSelected,
      }}
    />
  );
};

export default SchedulesContainer;
