import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FastField,
} from 'formik';
import * as Yup from 'yup';
import {
  CheckboxWithLabel,
  TextField,
  Switch,
  Autocomplete,
} from 'formik-mui';
import {
  Button,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  Tooltip,
  Divider,
  Stack,
  useMediaQuery,
  TextField as TextFieldMUI,
} from '@mui/material';
import {
  Lens,
  RadioButtonUnchecked,
  RemoveCircleOutline,
  Twitter,
  YouTube,
  HelpCenter,
  Spellcheck,
  LinkedIn,
} from '@mui/icons-material';
import { simpleToExpertQuery, expertToSimpleQuery } from 'generic/utils/anrUtils';
import AutocompleteAjax from 'generic/components/forms/AutocompleteAjax';
import Dailymotion from 'generic/components/icons/Dailymotion';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { folderPropType } from 'generic/core/anr/proptypes';
import { snackActions } from 'generic/utils/snackbar';
import { doAutocompleteEmail } from 'generic/api/users';
import clsx from 'clsx';

const FormAnRFolder = ({
  onSubmit,
  onTestSettings,
  optionsRoots,
  loading,
  folder,
  user,
  userMail,
  view,
}) => {
  const [hostsInputValue, setHostsInputValue] = useState('');
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  const idsFields = ['partners', 'categories', 'not_categories'];
  let initialValues = {};
  if (view !== 'new') {
    _.forOwn(folder, (value, key) => {
      if (_.includes(idsFields, key)) {
        initialValues[`${key}_ids`] = _.map(value, 'id');
      } else if (key === 'query') {
        initialValues.queryMust = '';
        initialValues.queryMay = '';
        initialValues.queryMustNot = '';
        initialValues.query = value;
        if (!folder.expert_mode) {
          initialValues = { ...initialValues, ...expertToSimpleQuery(value) };
        }
      } else {
        initialValues[key] = value !== null ? value : '';
      }
    });
  } else {
    initialValues = {
      ...folder,
      title: folder.title || '',
      query: '',
      queryMust: '',
      queryMay: '',
      queryMustNot: '',
      expert_mode: false,
      partners_ids: [],
      categories_ids: [],
      not_categories_ids: [],
      quotes_yt: '',
      quotes_tw: '',
      quotes_dm: '',
      quotes_fb: '',
      quotes_min_followers: 0,
      quotes_link_filter: false,
      in_glimpse: true,
      alerts: false,
      alerts_emails: [],
      focus: false,
      hosts: [],
    };
  }
  const titleInput = useRef();

  useEffect(() => {
    if (titleInput.current) {
      titleInput.current.focus();
    }
  }, [folder]);

  if (_.isEmpty(initialValues.alerts_emails) && !_.isEmpty(userMail)) {
    initialValues.alerts_emails.push(userMail);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t('form.errors.mandatory')),
        quotes_min_followers: Yup.number()
          .typeError(t('form.errors.must_be_integer'))
          .integer(t('form.errors.must_be_integer'))
          .min(0, `${t('form.errors.must_be_more_than')} 0`),
        langs: Yup.array().min(1, t('form.errors.must_select_at_least_one_lang')),
        alerts: Yup.boolean(),
        alerts_emails: Yup.array()
          .when('alerts', {
            is: (val) => val === true,
            then: Yup.array()
              .min(1, t('form.errors.must_contain_email'))
              .of(Yup.mixed()),
            otherwise: Yup.array().strip(),
          }),
      })}
      onSubmit={(values, { setSubmitting }) => {
        if (hostsInputValue === '') {
          onSubmit(values);
        } else {
          snackActions.warning(t('anr.warning_submit_form_host'));
        }
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
    >
      {(form) => (
        <Form
          className={clsx('displayFlex', 'flexGrow1')}
        >
          {loading && (
            <GlobalLoading />
          )}
          <Box
            display={loading ? 'none' : 'flex'}
            flexGrow="1"
            flexDirection="column"
            wrap="nowrap"
          >
            <Box
              overflow="auto"
              flexGrow="1"
              p={2}
            >
              {/* <pre>{JSON.stringify(form.values, 0, 2)}</pre> */}
              <Field
                component={Autocomplete}
                name="title"
                autoFocus
                freeSolo
                options={optionsRoots}
                fullWidth
                color="primary"
                onInputChange={(event, newValue) => form.setFieldValue('title', newValue)}
                onChange={(event, newValue) => form.setFieldValue('title', newValue === null ? '' : newValue)}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    label={t('form.title')}
                    inputRef={titleInput}
                    error={Boolean(form.errors.title)}
                    helperText={form.errors.title}
                  />
                )}
                renderOption={(props, value, { inputValue }) => {
                  const startIndex = value.toLowerCase().indexOf(inputValue.toLowerCase());
                  // Découpe le texte en trois parties : avant, pendant et après le terme de recherche
                  const beforeHighlight = value.slice(0, startIndex);
                  const highlightedText = value.slice(startIndex, startIndex + inputValue.length);
                  const afterHighlight = value.slice(startIndex + inputValue.length);
                  return (
                    <li {...props} key={value} style={{ whiteSpace: 'pre-wrap' }}>
                      {beforeHighlight}
                      <strong>{highlightedText}</strong>
                      {afterHighlight}
                    </li>
                  );
                }}
                filterOptions={(options, { inputValue }) => {
                  if (!_.isEmpty(inputValue) && inputValue.length > 1) {
                    return (options.filter(
                      (option) => option.toLowerCase().includes(inputValue.toLowerCase()),
                    ));
                  }
                  return ([]);
                }}
              />
              <Box mt={2} mb={2}>
                <Card>
                  <CardHeader
                    titleTypographyProps={{
                      variant: 'h6',
                    }}
                    title={(
                      <Box display="flex" alignItems="center">
                        <Box component="span" mr={2}>
                          {t('anr.query')}
                        </Box>
                        <Button
                          color="primary"
                          onClick={() => onTestSettings(form.values)}
                          disabled={loading}
                          startIcon={<Spellcheck />}
                        >
                          {t('form.test')}
                        </Button>
                      </Box>
                    )}
                  />
                  <Divider />
                  <CardContent>
                    <FormControl
                      component="fieldset"
                      className="formFieldset"
                      fullWidth
                    >
                      <FormLabel component="legend">
                        {t('anr.text_query')}
                      </FormLabel>
                      <Box m={1}>
                        <Box ml={1} mb={1}>
                          <FormControlLabel
                            control={(
                              <Field
                                component={Switch}
                                size="small"
                                type="checkbox"
                                name="expert_mode"
                                color="primary"
                                onChange={(e) => {
                                  form.handleChange(e);
                                  if (e.target.checked) {
                                    const queries = {
                                      queryMust: form.values.queryMust,
                                      queryMay: form.values.queryMay,
                                      queryMustNot: form.values.queryMustNot,
                                    };
                                    form.setFieldValue('query', simpleToExpertQuery(queries));
                                  } else {
                                    const queries = expertToSimpleQuery(form.values.query);
                                    _.map(queries, (value, key) => (
                                      form.setFieldValue(key, value)
                                    ));
                                  }
                                }}
                              />
                            )}
                            label={t('anr.expert_mode')}
                          />
                        </Box>
                        {form.values.expert_mode ? (
                          <Field
                            component={TextField}
                            label={t('anr.query')}
                            name="query"
                            fullWidth
                            multiline
                            minRows={4}
                          />
                        ) : (
                          <React.Fragment>
                            <Field
                              component={TextField}
                              label={t('anr.query_at_least')}
                              name="queryMay"
                              fullWidth
                              multiline
                              minRows={3}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <RadioButtonUnchecked />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Field
                              component={TextField}
                              label={t('anr.query_every')}
                              name="queryMust"
                              fullWidth
                              multiline
                              minRows={3}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Lens />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Field
                              component={TextField}
                              label={t('anr.query_banned')}
                              name="queryMustNot"
                              fullWidth
                              multiline
                              minRows={3}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <RemoveCircleOutline />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </React.Fragment>
                        )}
                      </Box>
                    </FormControl>
                    <Field
                      component={Autocomplete}
                      name="hosts"
                      freeSolo
                      multiple
                      options={[]}
                      getOptionLabel={(option) => option}
                      inputValue={hostsInputValue}
                      onInputChange={(event) => setHostsInputValue(_.trim(event.target.value))}
                      onChange={(event, values, reason) => {
                        const urlRegex = /^([a-zA-Z0-9-]+\.){1,}([a-zA-Z]{2,})(\/.*)?$/;
                        if (reason === 'createOption' && !urlRegex.test(event.target.value)) {
                          snackActions.warning(t('anr.warning_validate_host'));
                        } else {
                          setHostsInputValue('');
                          form.setFieldValue('hosts', values);
                        }
                      }}
                      renderInput={(params) => (
                        <TextFieldMUI
                          {...params}
                          label={t('anr.sites')}
                          placeholder={t('anr.placeholder_host')}
                          helperText={t('anr.helperText_host')}
                        />
                      )}
                    />
                    {!_.isEmpty(user.partners) && (
                      <FormControl
                        component="fieldset"
                        className="formFieldset"
                        fullWidth
                      >
                        <FormLabel component="legend">
                          {t('anr.partners')}
                        </FormLabel>
                        <Box
                          ml={1}
                          sx={{ columnCount: smallerThanMedium ? 1 : 3 }}
                        >
                          {_.map(
                            user.partners,
                            (partner) => (
                              <Field
                                component={CheckboxWithLabel}
                                name="partners_ids"
                                key={partner.id}
                                value={partner.id}
                                Label={{
                                  label: partner.name,
                                  sx: { width: '100%' },
                                }}
                                type="checkbox"
                                color="primary"
                              />
                            ),
                          )}
                        </Box>
                      </FormControl>
                    )}
                    {!_.isEmpty(user.folders_categories) && (
                      <FormControl
                        component="fieldset"
                        className="formFieldset"
                        fullWidth
                      >
                        <FormLabel component="legend">
                          {t('anr.categories')}
                        </FormLabel>
                        <Box
                          ml={1}
                          sx={{ columnCount: smallerThanMedium ? 1 : 3 }}
                        >
                          {_.map(
                            user.folders_categories,
                            (category) => (
                              <Field
                                component={CheckboxWithLabel}
                                name="categories_ids"
                                key={category.id}
                                value={category.id}
                                Label={{
                                  label: category.name,
                                  sx: { width: '100%' },
                                }}
                                type="checkbox"
                                color="primary"
                              />
                            ),
                          )}
                        </Box>
                      </FormControl>
                    )}
                    {!_.isEmpty(user.folders_not_categories) && (
                      <FormControl
                        component="fieldset"
                        className="formFieldset"
                        fullWidth
                      >
                        <FormLabel component="legend">
                          {t('anr.not_categories')}
                        </FormLabel>
                        <Box
                          ml={1}
                          sx={{ columnCount: smallerThanMedium ? 1 : 3 }}
                        >
                          {_.map(
                            user.folders_not_categories,
                            (notCategory) => (
                              <Field
                                component={CheckboxWithLabel}
                                name="not_categories_ids"
                                key={notCategory.id}
                                value={notCategory.id}
                                Label={{
                                  label: notCategory.name,
                                  sx: { width: '100%' },
                                }}
                                type="checkbox"
                                color="primary"
                              />
                            ),
                          )}
                        </Box>
                      </FormControl>
                    )}
                  </CardContent>
                </Card>
              </Box>
              {user.rights.quotes && (
                <Box mb={2}>
                  <Card>
                    <CardHeader
                      titleTypographyProps={{
                        variant: 'h6',
                      }}
                      title={t('anr.quotes_query')}
                    />
                    <Divider />
                    <CardContent>
                      <React.Fragment>
                        <Field
                          component={TextField}
                          InputLabelProps={{
                            className: 'inputLabelWithHelpIcon',
                          }}
                          label={(
                            <React.Fragment>
                              {t('anr.query_twitter')}
                              <Tooltip
                                title={(
                                  <Trans>
                                    {t('anr.query_twitter_help')}
                                  </Trans>
                                )}
                              >
                                <HelpCenter />
                              </Tooltip>
                            </React.Fragment>
                          )}
                          name="quotes_tw"
                          fullWidth
                          multiline
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Twitter color="twitter" />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Field
                          component={TextField}
                          label={t('anr.query_twitter_min_followers')}
                          name="quotes_min_followers"
                          fullWidth
                          color="primary"
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        <Field
                          component={TextField}
                          InputLabelProps={{
                            className: 'inputLabelWithHelpIcon',
                          }}
                          label={(
                            <React.Fragment>
                              {t('anr.query_linkedin')}
                              <Tooltip
                                title={(
                                  <Trans>
                                    {t('anr.query_linkedin_help')}
                                  </Trans>
                                )}
                              >
                                <HelpCenter />
                              </Tooltip>
                            </React.Fragment>
                          )}
                          name="quotes_in"
                          fullWidth
                          multiline
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LinkedIn color="linkedin" />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Field
                          component={TextField}
                          InputLabelProps={{
                            className: 'inputLabelWithHelpIcon',
                          }}
                          label={(
                            <React.Fragment>
                              {t('anr.query_youtube')}
                              <Tooltip
                                title={(
                                  <Trans>
                                    {t('anr.query_youtube_help')}
                                  </Trans>
                                )}
                              >
                                <HelpCenter />
                              </Tooltip>
                            </React.Fragment>
                          )}
                          name="quotes_yt"
                          fullWidth
                          multiline
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <YouTube color="youtube" />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Field
                          component={TextField}
                          InputLabelProps={{
                            className: 'inputLabelWithHelpIcon',
                          }}
                          label={(
                            <React.Fragment>
                              {t('anr.query_dailymotion')}
                              <Tooltip
                                title={(
                                  <Trans>
                                    {t('anr.query_dailymotion_help')}
                                  </Trans>
                                )}
                              >
                                <HelpCenter />
                              </Tooltip>
                            </React.Fragment>
                          )}
                          name="quotes_dm"
                          fullWidth
                          multiline
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Dailymotion color="dailymotion" />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box ml={1} mt={1}>
                          <FormControlLabel
                            control={(
                              <Field
                                component={Switch}
                                size="small"
                                type="checkbox"
                                name="quotes_link_filter"
                                color="primary"
                              />
                            )}
                            label={t('anr.filter_links_only')}
                          />
                        </Box>
                      </React.Fragment>
                    </CardContent>
                  </Card>
                </Box>
              )}
              <FormControl
                component="fieldset"
                className="formFieldset"
                fullWidth
              >
                <FormLabel component="legend">
                  {_.capitalize(t('anr.langs'))}
                </FormLabel>
                <Box
                  ml={1}
                  sx={{ columnCount: smallerThanMedium ? 1 : 3 }}
                >
                  {_.map(
                    user.langs,
                    (lang) => (
                      <Field
                        component={CheckboxWithLabel}
                        name="langs"
                        key={lang.id}
                        value={lang.id}
                        Label={{
                          label: lang.name,
                          sx: { width: '100%' },
                        }}
                        type="checkbox"
                        color="primary"
                      />
                    ),
                  )}
                </Box>
              </FormControl>
              <ErrorMessage
                name="langs"
                render={(msg) => (
                  <Box mt="4px" mr="14px" ml="14px">
                    <FormHelperText error>
                      {msg}
                    </FormHelperText>
                  </Box>
                )}
              />
              {user.rights.glimpse && (
                <FormControl
                  component="fieldset"
                  className="formFieldset"
                  fullWidth
                >
                  <FormLabel component="legend">
                    {t('anr.other_options')}
                  </FormLabel>
                  <Box mt={1} mb={1}>
                    <Box ml={2}>
                      <FormControlLabel
                        control={(
                          <Field
                            component={Switch}
                            size="small"
                            type="checkbox"
                            name="in_glimpse"
                            color="primary"
                          />
                        )}
                        label={t('anr.show_in_glimpse')}
                      />
                    </Box>
                    <Box ml={2}>
                      <FormControlLabel
                        control={(
                          <Field
                            component={Switch}
                            size="small"
                            type="checkbox"
                            name="focus"
                            color="primary"
                            disabled={!form.values.in_glimpse}
                          />
                        )}
                        label={t('anr.focus_in_glimpse')}
                      />
                    </Box>
                    <Box ml={2}>
                      <FormControlLabel
                        control={(
                          <Field
                            component={Switch}
                            size="small"
                            type="checkbox"
                            name="alerts"
                            color="primary"
                          />
                        )}
                        label={t('anr.send_daily_summary')}
                      />
                      {form.values.alerts === true && (
                        <Box sx={{ mt: 1, mr: 2 }}>
                          <FastField
                            component={AutocompleteAjax}
                            name="alerts_emails"
                            multiple
                            freeSolo
                            highlight={false}
                            fullWidth
                            doAutocompleteOverride={doAutocompleteEmail}
                            renderOption={(props, option) => {
                              const {
                                email,
                                nom,
                                prenom,
                                utilisateur,
                              } = option;
                              const prenomStr = (prenom) ? `${prenom} ` : '';
                              const emailStr = (email && email !== nom) ? ` (${email})` : '';

                              return (
                                <li {...props} key={utilisateur}>
                                  <div>
                                    {`${prenomStr}${nom}${emailStr}`}
                                  </div>
                                </li>
                              );
                            }}
                            getOptionLabel={(userObjectOrEmail) => {
                              if (typeof userObjectOrEmail === 'string') return userObjectOrEmail;
                              return _.get(userObjectOrEmail, 'email', '');
                            }}
                            textFieldProps={{
                              label: t('anr.recipients_daily_summary'),
                            }}
                            isOptionEqualToValue={(option, value) => {
                              const compareValue = typeof value === 'string' ? value : value?.email;
                              return option.email === compareValue;
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </FormControl>
              )}
            </Box>
            <Box
              className="bottomBtnsContainer"
            >
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Button
                  color="primary"
                  variant="text"
                  type="reset"
                  onClick={form.resetForm}
                >
                  {t('form.reset')}
                </Button>
                <Button
                  color="primary"
                  onClick={form.handleSubmit}
                  disabled={loading || !form.isValid}
                >
                  {t('form.save')}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

FormAnRFolder.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape().isRequired,
  userMail: PropTypes.string.isRequired,
  folder: folderPropType.isRequired,
  onTestSettings: PropTypes.func.isRequired,
  optionsRoots: PropTypes.arrayOf(PropTypes.string).isRequired,
  view: PropTypes.string.isRequired,
};

export default FormAnRFolder;
