import { types } from 'generic/core/confirmDialog/actions';

const confirmDialogReducer = (state = { data: {}, loading: false }, action) => {
  switch (action.type) {
    case types.SET_CONFIRM_DIALOG_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.UNSET_CONFIRM_DIALOG_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.SET_CONFIRM_DIALOG_DATA: {
      return {
        ...state,
        data: {
          ...action.payload,
        },
      };
    }
    case types.UNSET_CONFIRM_DIALOG_DATA: {
      return {
        ...state,
        data: {},
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default confirmDialogReducer;
