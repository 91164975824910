import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';

const SearchHeaderSkeleton = ({ isRAGSearch }) => (
  <Box
    display="flex"
    alignItems="left"
    justifyContent="space-between"
    flexWrap="wrap"
    sx={{ gap: '15px' }}
    mt={1}
    mb={1.5}
    ml={1}
  >
    <Box
      display="flex"
      alignItems="left"
      flexWrap="wrap"
      sx={{ gap: '10px' }}
    >
      {!isRAGSearch && (
        <Skeleton
          variant="rounded"
          height="30px"
          width="166px"
          sx={{
            marginRight: 1,
            borderRadius: '15px',
          }}
        />
      )}
      <Skeleton
        variant="rounded"
        height="30px"
        width="200px"
        sx={{
          marginRight: 1,
          borderRadius: '15px',
        }}
      />
    </Box>
    <Box flexShrink="0" display="flex" flexGrow="1" justifyContent="right">
      {!isRAGSearch && (
        <Skeleton
          variant="rounded"
          height="30px"
          width="160px"
          sx={{
            marginBottom: 0.5,
            borderRadius: '15px 0 0 15px',
          }}
        />
      )}
    </Box>
  </Box>
);

SearchHeaderSkeleton.propTypes = {
  isRAGSearch: PropTypes.bool,
};

SearchHeaderSkeleton.defaultProps = {
  isRAGSearch: false,
};

export default SearchHeaderSkeleton;
