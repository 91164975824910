import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: '24px',
    padding: '3px 10px 3px 15px',
    borderRight: 'none',
    fontWeight: '500',
    fontSize: '0.875rem',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    flexShrink: '0',
  },
}));

const NbResultsStrip = ({
  children,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  );
};

NbResultsStrip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NbResultsStrip;
