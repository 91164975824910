import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  fetchActiveItemResults,
  setFiltersParam,
  setFiltersParams,
  setOpenFilterNav,
  unsetFiltersParams,
} from 'generic/core/anr/actions';
import FormAnRFilters from 'generic/components/forms/FormAnRFilters';
import { treeFlattener } from 'generic/utils/utils';

const FiltersContainer = React.memo(() => {
  const activeItem = useSelector((state) => state.anr.activeItem);
  const { filtersParams, clickFromResults } = useSelector((state) => state.anr.filtersData);
  const folders = useSelector((state) => state.anr.rawFolders);
  const hasFavorites = useSelector((state) => state.anr.hasFavorites);
  const stateOpenFilterNav = useSelector((state) => state.anr.stateOpenFilterNav);

  const dispatch = useDispatch();

  const [searchFolders, setSearchFolders] = useState('');

  const handleOpenFilterNav = () => {
    dispatch(setOpenFilterNav(!stateOpenFilterNav));
  };

  const handleSubmit = (values) => {
    dispatch(setFiltersParams(values));
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  const handleResetForm = () => {
    dispatch(unsetFiltersParams());
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  const handleSearchFolders = (event) => {
    setSearchFolders(event.target.value);
  };

  const handleFilterFolder = (item) => {
    const foldersTitles = filtersParams.folders_titles;
    let alteredFoldersTitles = [...foldersTitles];
    if (item === 'all') {
      alteredFoldersTitles = [item];
    } else if (_.indexOf(foldersTitles, item) === -1) {
      alteredFoldersTitles = [...foldersTitles.filter((folder) => folder !== 'all'), item];
    } else {
      alteredFoldersTitles = alteredFoldersTitles.length === 1
        ? ['all']
        : foldersTitles.filter((folder) => folder !== item);
    }
    dispatch(setFiltersParam({
      folders_titles: alteredFoldersTitles,
    }));
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  if (_.isEmpty(activeItem)) {
    return null;
  }

  const showNewsInputs = _.includes(['glimpse', 'news', 'archives'], activeItem.view);
  const viewGlimpse = _.includes(activeItem.view, 'glimpse');

  const numberParams = _.filter(
    filtersParams,
    (val) => !_.isEmpty(_.toString(val)) && !_.isEqual(val, ['all']),
  ).length;

  // Rassembler le tableau des favoris et des dossiers en un seul tableau & trier par ordre alphabétique des titres
  const filterFoldersIds = _.map(
    treeFlattener(folders).filter(
      (folder) => folder.title.toLowerCase().includes(searchFolders.toLowerCase()),
    ),
    'id',
  );

  // Filtrer les dossier en fonction de la recherche.
  const filterTree = (tree, filter) => tree.map((node) => {
    if (node.children) {
      return ({
        ...node,
        children: filterTree(node.children, filter),
      });
    }
    return ({
      ...node,
    });
  }).filter((node) => {
    let foundInFilterFoldersIds = false;
    // eslint-disable-next-line consistent-return
    _.forEach(filterFoldersIds, (filterFolderId) => {
      if (node.id.indexOf(filterFolderId) !== -1) {
        foundInFilterFoldersIds = true;
        return false;
      }
    });
    return foundInFilterFoldersIds;
  });

  let searchedFolders = folders;
  if (searchFolders.length >= 3) {
    searchedFolders = filterTree(folders, searchFolders);
  }

  return (
    <FormAnRFilters
      initialValues={{ ...filtersParams }}
      numberParams={numberParams}
      stateOpenFilterNav={stateOpenFilterNav}
      showNewsInputs={showNewsInputs}
      viewGlimpse={viewGlimpse}
      hasFavorites={hasFavorites}
      searchedFolders={searchedFolders}
      clickFromResults={clickFromResults}
      handleOpenFilterNav={handleOpenFilterNav}
      handleResetForm={handleResetForm}
      handleSubmit={handleSubmit}
      handleSearchFolders={handleSearchFolders}
      handleFilterFolder={handleFilterFolder}
    />
  );
});

export default FiltersContainer;
