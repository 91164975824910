import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useSingleAndDoubleClick } from 'generic/core/hooks/useSingleAndDoubleClick';

const TranscriptionWord = ({
  currentMediaTime,
  handleTranscriptClick,
  word,
  preventClick,
}) => {
  const theme = useTheme();
  // Le hook useSingleAndDoubleClick permet de définir une action pour le simple ET le
  // double clic. Ici on veut déclencher une action UNIQUEMENT sur le simple clic (donc
  // on ne définit rien pour le second paramètre)
  const clicks = useSingleAndDoubleClick(() => {
    if (!preventClick) {
      handleTranscriptClick(word.start);
    }
  });
  let wordColor = theme.palette.text.primary;
  if (+word.confidence < 0.4) {
    wordColor = theme.palette.text.error;
  }
  return (
    <Box
      component="span"
      display="inline-block"
      height="30px"
      onClick={clicks}
      start={word.start}
      sx={{
        color: wordColor,
        opacity: currentMediaTime > word.start ? 1 : 0.5,
      }}
    >
      {`${word.word.trim()} `}
    </Box>
  );
};

TranscriptionWord.propTypes = {
  currentMediaTime: PropTypes.number,
  handleTranscriptClick: PropTypes.func.isRequired,
  preventClick: PropTypes.bool,
  word: PropTypes.shape({
    confidence: PropTypes.number,
    start: PropTypes.number,
    word: PropTypes.string,
  }).isRequired,
};

TranscriptionWord.defaultProps = {
  currentMediaTime: 0,
  preventClick: false,
};

export default TranscriptionWord;
