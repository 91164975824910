import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { TextField, Select } from 'formik-mui';
import * as Yup from 'yup';
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import AvatarUploadContainer from 'generic/containers/AvatarUploadContainer';

import { CONSTANTS } from 'generic/core/constants';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { EMAIL_REGEX } = CONSTANTS;
const { CIVILITY } = QES_CONSTANTS;

const Profile = ({
  user,
  handleSubmit,
}) => {
  const [openChangePasswordBox, setOpenChangePasswordBox] = useState(false);
  const { t } = useTranslation();

  const textfieldProps = {
    fullWidth: true,
    component: TextField,
    autoComplete: 'off',
  };
  // On ne met dans les initialValues que ce dont on a besoin dans le formulaire
  const initialValues = {
    utilisateur: user.utilisateur,
    civilite: user.civilite,
    nom: user.nom,
    prenom: user.prenom,
    email: user.email,
    langue: user.langue,
    actualPassword: '',
    password: '',
    confPassword: '',
  };
  const yupSchema = {
    nom: Yup.string().required(t('form.errors.mandatory')),
    prenom: Yup.string().required(t('form.errors.mandatory')),
    email: Yup
      .string()
      .email(t('form.errors.not_valid_email'))
      .matches(EMAIL_REGEX, t('form.errors.not_valid_email'))
      .required(t('form.errors.mandatory')),
  };
  let finalYupSchema = { ...yupSchema };
  if (openChangePasswordBox) {
    finalYupSchema.actualPassword = Yup.string().required(t('form.errors.mandatory'));
    finalYupSchema.password = Yup.string().required(t('form.errors.mandatory'));
    finalYupSchema.confPassword = Yup.string().required(t('form.errors.mandatory'))
      .oneOf([Yup.ref('password'), null], t('users.password_not_matching'));
  } else {
    finalYupSchema = { ...yupSchema };
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      className="desktopOnlyOverflow"
    >
      <Box
        className="stickyHeader"
        borderBottom="1px solid"
        borderColor="divider"
        zIndex="1200"
        p={1}
      >
        <Typography
          variant="h5"
          noWrap
        >
          {t('profile.account')}
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(finalYupSchema)}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
        enableReinitialize
        validateOnMount
      >
        {(form) => (
          <Form
            className={
              clsx(
                'displayFlex',
                'flexGrow1',
                'flexDirectionColumn',
                'desktopOnlyOverflow',
              )
            }
          >
            <Box
              display="flex"
              justifyContent="center"
              margin="0 auto"
              flexWrap="wrap"
              className={
                clsx(
                  'displayFlex',
                  'flexGrow1',
                  'desktopOnlyOverflow',
                )
              }
            >
              <Box m={4}>
                <AvatarUploadContainer />
              </Box>
              <Box
                flexGrow="1"
                m={2}
              >
                <Box display="flex" gap={1}>
                  <Field
                    required
                    name="civilite"
                    label={t('users.civility')}
                    component={Select}
                    // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                    // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                    // override donc juste le onClose pour qu'il ne fasse rien.
                    onClose={_.noop}
                  >
                    {
                      _.map(
                        CIVILITY,
                        (id, gender) => (
                          <MenuItem
                            key={id}
                            value={id}
                          >
                            {t(`users.civilities.${gender}`)}
                          </MenuItem>
                        ),
                      )
                    }
                  </Field>
                  <Field
                    required
                    name="langue"
                    label={t('users.language')}
                    component={Select}
                    // Le wrapper Select de formik-mui force un setFieldValue dans son onClose,
                    // ce qu'on ne veut pas (sinon la valeur settée sera une string), on
                    // override donc juste le onClose pour qu'il ne fasse rien.
                    onClose={_.noop}
                  >
                    <MenuItem
                      key="fr"
                      value={1}
                    >
                      {t('users.languages.fr')}
                    </MenuItem>
                    <MenuItem
                      key="en"
                      value={2}
                    >
                      {t('users.languages.en')}
                    </MenuItem>
                  </Field>
                </Box>
                <Field
                  {...textfieldProps}
                  name="prenom"
                  label={t('users.firstname')}
                  required
                />
                <Field
                  {...textfieldProps}
                  name="nom"
                  label={t('users.lastname')}
                  required
                />
                <Field
                  {...textfieldProps}
                  name="email"
                  label={t('users.email')}
                  required
                />
                <Box mt={4}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setOpenChangePasswordBox(!openChangePasswordBox);
                      form.setFieldTouched('nom', true);
                    }}
                  >
                    {openChangePasswordBox ? t('actions.cancel_change_password') : t('actions.change_password')}
                  </Button>
                  {openChangePasswordBox && (
                  <Box mt={3}>
                    <Field
                      {...textfieldProps}
                      name="actualPassword"
                      type="password"
                      sx={{
                        maxWidth: '400px',
                        display: 'block',
                        marginBottom: '1rem',
                      }}
                      required
                      label={t('users.actual_password')}
                    />
                    <Field
                      {...textfieldProps}
                      name="password"
                      type="password"
                      sx={{
                        maxWidth: '400px',
                        display: 'block',
                        marginBottom: '1rem',
                      }}
                      required
                      label={t('users.new_password')}
                    />
                    <Field
                      {...textfieldProps}
                      name="confPassword"
                      type="password"
                      sx={{
                        maxWidth: '400px',
                        display: 'block',
                        marginBottom: '1rem',
                      }}
                      required
                      label={t('users.password_confirm')}
                    />
                  </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              className="bottomBtnsContainer"
            >
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Button
                  color="primary"
                  variant="text"
                  type="reset"
                  onClick={form.resetForm}
                >
                  {t('form.reset')}
                </Button>
                <LoadingButton
                  color="primary"
                  type="submit"
                  disabled={user.saveUserLoading || !form.isValid}
                  loading={user.saveUserLoading}
                >
                  {t('form.save')}
                </LoadingButton>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

Profile.propTypes = {
  user: utilisateurPropType,
  handleSubmit: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  user: [],
};

export default Profile;
