import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Clear } from '@mui/icons-material';
import _ from 'lodash';
import ResultsActions from 'generic/components/ui/ResultsActions';
import { actionsPropType } from 'generic/core/qes/proptypes';

const Selection = ({
  actions,
  canAddToCart,
  canAddToNewsletter,
  canEditInGED,
  canExport,
  canDeleteDocument,
  canSendToFriend,
  checkedItems,
  handleToggleCheckbox,
  handleClearSelection,
  handleOpenAddToCart,
  handleOpenAddToNewsletter,
  handleOpenSendToFriend,
  handleOpenMultipleGed,
}) => {
  const { t } = useTranslation();

  return (
    <Box overflow="auto">
      {!_.isEmpty(checkedItems) && (
        <Box
          overflow="auto"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
            margin={1}
          >
            <Typography
              variant="h5"
              component="h5"
            >
              {t('selection.your_selection')}
            </Typography>
            <ResultsActions
              actions={actions}
              canAddToCart={canAddToCart}
              canAddToNewsletter={canAddToNewsletter}
              canEditInGED={canEditInGED}
              canExport={canExport}
              canDeleteDocument={canDeleteDocument}
              canSendToFriend={canSendToFriend}
              isSelectionActions
              handleOpenAddToCart={handleOpenAddToCart}
              handleOpenAddToNewsletter={handleOpenAddToNewsletter}
              handleOpenSendToFriend={handleOpenSendToFriend}
              handleClearSelection={handleClearSelection}
              handleOpenMultipleGed={handleOpenMultipleGed}
              nbCheckedItems={_.keys(checkedItems).length}
            />
          </Box>
          <List
            dense
            className="overflowAuto"
          >
            {_.map(checkedItems, (item, key) => (
              <ListItem key={key} sx={{ paddingTop: '0' }}>
                <ListItemIcon>
                  <IconButton
                    onClick={() => handleToggleCheckbox(key, item.title)}
                    edge="end"
                    title={t('selection.remove')}
                    size="small"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </ListItemIcon>
                <Tooltip title={item.title}>
                  <ListItemText primaryTypographyProps={{ noWrap: true }}>
                    {item.title}
                  </ListItemText>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

Selection.propTypes = {
  actions: PropTypes.arrayOf(actionsPropType).isRequired,
  canAddToCart: PropTypes.bool.isRequired,
  canAddToNewsletter: PropTypes.bool.isRequired,
  canEditInGED: PropTypes.bool.isRequired,
  canExport: PropTypes.bool.isRequired,
  canDeleteDocument: PropTypes.bool.isRequired,
  canSendToFriend: PropTypes.bool.isRequired,
  checkedItems: PropTypes.shape({
    id: PropTypes.string,
    item: PropTypes.shape({}),
  }),
  handleToggleCheckbox: PropTypes.func.isRequired,
  handleClearSelection: PropTypes.func.isRequired,
  handleOpenAddToCart: PropTypes.func.isRequired,
  handleOpenAddToNewsletter: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleOpenMultipleGed: PropTypes.func.isRequired,
};
Selection.defaultProps = {
  checkedItems: {},
};

export default Selection;
