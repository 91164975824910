import {
  get,
  del,
  post,
  patch,
} from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const fetchNewsletters = () => get({ path: '/newsletter/retrieve-all' })
  .then(handleComplete);

const deleteNewsletters = (ids) => del({ path: `/newsletter/${ids.join(',')}` })
  .then(handleComplete);

const fetchNewsletter = (id) => get({ path: `/newsletter/${id}` })
  .then(handleComplete);

const deleteNewsletterDocuments = (ids) => del({ path: `/panier-document/${ids.join(',')}` })
  .then(handleComplete);

const updateNewsletter = ({ ...rest }) => patch({ path: '/newsletter', ...rest })
  .then(handleComplete);

const addToNewsletter = ({ ...rest }) => post({ path: '/newsletter/create-with-documents', ...rest })
  .then(handleComplete);

const sendNewsletter = (id, { ...rest }) => post({ path: `/newsletter/send/${id}`, ...rest })
  .then(handleComplete);

const sendNewsletterHisto = (id, { ...rest }) => post({ path: `/newsletter-histo/send/${id}`, ...rest })
  .then(handleComplete);

const patchPanierDocuments = ({ ...rest }) => patch({ path: '/panier-document', ...rest })
  .then(handleComplete);

export {
  addToNewsletter,
  deleteNewsletters,
  deleteNewsletterDocuments,
  fetchNewsletter,
  fetchNewsletters,
  sendNewsletter,
  sendNewsletterHisto,
  updateNewsletter,
  patchPanierDocuments,
};
