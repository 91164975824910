import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';

const FacetSkeleton = ({ nbFacets }) => {
  const facets = [];
  for (let i = 0; i < nbFacets; i += 1) {
    facets.push(
      <Box
        borderTop="1px solid"
        borderColor="divider"
        boxSizing="border-box"
        height="48px"
        key={`item${i}`}
      >
        <Skeleton
          variant="rectangular"
          height="46px"
        />
      </Box>,
    );
  }
  return facets;
};

FacetSkeleton.propTypes = {
  nbFacets: PropTypes.number,
};

FacetSkeleton.defaultProps = {
  nbFacets: 6,
};

export default FacetSkeleton;
