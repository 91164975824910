import React, { Fragment } from 'react';
import { Box, Skeleton } from '@mui/material';

const QuickResultsHeaderSkeleton = () => (
  <Fragment>
    <Box
      display="flex"
      alignItems="left"
      justifyContent="space-between"
      flexWrap="wrap"
      sx={{ gap: '15px' }}
      mb={1}
      width="100%"
    >
      <Box
        display="flex"
        alignItems="left"
        flexWrap="wrap"
        sx={{ gap: '10px' }}
      >
        <Skeleton
          variant="rounded"
          height="32px"
          width="250px"
          sx={{
            marginRight: 1,
            borderRadius: '15px',
          }}
        />
      </Box>
      <Box flexShrink="0" display="flex" flexGrow="1" justifyContent="right">
        <Skeleton
          variant="rounded"
          height="32px"
          width="160px"
          sx={{
            marginBottom: 0.5,
            borderRadius: '15px',
          }}
        />
      </Box>
    </Box>
    <Box pl={0.5} width="100%">
      <Skeleton variant="rectangular" height="32px" width="100%" />
    </Box>
  </Fragment>
);

export default QuickResultsHeaderSkeleton;
