import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField as MUITextField,
  Checkbox,
} from '@mui/material';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  Autocomplete,
  TextField,
} from 'formik-mui';
import { LoadingButton } from '@mui/lab';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { CONSTANTS } from 'generic/core/constants';

const UserMailingListDialog = ({
  choiceList,
  openDialog,
  loading,
  handleCloseDialog,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const { EMAIL_REGEX } = CONSTANTS;

  const initialValues = {
    email: '',
    listeDiffusion: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup
          .string()
          .email(t('form.errors.not_valid_email'))
          .matches(EMAIL_REGEX, t('form.errors.not_valid_email'))
          .required(t('form.errors.mandatory')),
        listeDiffusion: Yup.array().min(1, t('users_mailing_list.error_form_mailing_list')),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
              form.handleSubmit();
            }
          }}
        >
          <Dialog
            scroll="paper"
            fullWidth
            maxWidth="lg"
            open={openDialog}
          >
            <DialogTitle>
              {t('users_mailing_list.create_dialog')}
            </DialogTitle>
            <DialogContent dividers>
              <Field
                autoFocus
                component={TextField}
                fullWidth
                label={t('users_mailing_list.email')}
                name="email"
              />
              <Field
                component={Autocomplete}
                name="listeDiffusion"
                options={choiceList}
                getOptionLabel={(option) => option?.libelle || ''}
                isOptionEqualToValue={(option, value) => option.libelle === value.libelle}
                multiple
                disableCloseOnSelect
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    label={t('mailing_lists.list')}
                    error={Boolean(form.errors.listeDiffusion)}
                    helperText={form.errors.listeDiffusion}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      checked={selected}
                      style={{ marginRight: 8 }}
                    />
                    {option.libelle}
                  </li>
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="text"
                onClick={handleCloseDialog}
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.save')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

UserMailingListDialog.propTypes = {
  choiceList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    libelle: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool,
  openDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UserMailingListDialog.defaultProps = {
  loading: false,
};

export default UserMailingListDialog;
