import {
  put,
  call,
  takeLatest,
  fork,
  join,
} from 'redux-saga/effects';
import i18next from 'i18next';

import {
  createMailingListError,
  createMailingListSuccess,
  createUserMailingListError,
  createUserMailingListSuccess,
  deleteMailingListsError,
  deleteMailingListsSuccess,
  deleteUsersMailingListError,
  deleteUsersMailingListSuccess,
  editMailingListError,
  editMailingListSuccess,
  editUSerMailingListError,
  editUSerMailingListSuccess,
  fetchMailingLists,
  fetchMailingListsError,
  fetchMailingListsSuccess,
  fetchUsersMailingList,
  fetchUsersMailingListError,
  fetchUsersMailingListSuccess,
  types,
} from 'generic/core/mailingLists/actions';
import {
  createMailingList,
  createUserMailingList,
  deleteMailingLists,
  deleteUsersMailingList,
  editMailingList,
  editUserMailingList,
  fetchMailingLists as fetchMailingListsApi,
  fetchUsersMailingList as fetchUsersMailingListApi,
} from 'generic/api/mailingList';

import { snackActions } from 'generic/utils/snackbar';

/* Saga des listes de diffusion */
function* workFetchMailingLists() {
  try {
    const mailingLists = yield call(fetchMailingListsApi);
    yield put(fetchMailingListsSuccess(mailingLists));
  } catch (error) {
    yield put(fetchMailingListsError());
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_fetching'));
  }
}

function* watchFetchMailingLists() {
  yield takeLatest(types.FETCH_MAILING_LISTS, workFetchMailingLists);
}

function* workCreateMailingList({ formValues }) {
  try {
    const params = {
      code: new Date().getTime(),
      libelle: formValues.libelle,
      plateforme: 1000,
    };
    yield call(createMailingList, { bodyItems: { ...params } });
    yield put(createMailingListSuccess());
    snackActions.success(i18next.t('mailing_lists.created'));
    yield put(fetchMailingLists());
  } catch (error) {
    yield put(createMailingListError());
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_creating'));
  }
}

function* watchCreateMailingList() {
  yield takeLatest(types.CREATE_MAILING_LIST, workCreateMailingList);
}

function* workEditMailingList({ formValues }) {
  try {
    const params = {
      listeDiffusion: formValues.id,
      libelle: formValues.libelle,
    };
    yield call(editMailingList, { bodyItems: { ...params } });
    yield put(editMailingListSuccess());
    snackActions.success(i18next.t('mailing_lists.edited'));
    yield put(fetchMailingLists());
  } catch (error) {
    yield put(editMailingListError());
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_editing'));
  }
}

function* watchEditMailingList() {
  yield takeLatest(types.EDIT_MAILING_LIST, workEditMailingList);
}

function* workDeleteMailingLists({ ids }) {
  try {
    yield call(deleteMailingLists, ids);
    yield put(deleteMailingListsSuccess());
    snackActions.success(i18next.t('mailing_lists.deleted', { count: ids.length }));
    yield put(fetchMailingLists());
  } catch (error) {
    yield put(deleteMailingListsError(error));
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_deleting', { count: ids.length }));
  }
}

function* watchDeleteMailingLists() {
  yield takeLatest(types.DELETE_MAILING_LISTS, workDeleteMailingLists);
}

/* Saga des utilisateurs des listes de diffusion */
function* workFetchUsersMailingList({ goToEmail }) {
  try {
    const mails = yield call(fetchUsersMailingListApi);
    yield put(fetchUsersMailingListSuccess(mails, goToEmail));
  } catch (error) {
    yield put(fetchUsersMailingListError(error));
    console.error(error);
    snackActions.error(i18next.t('users_mailing_list.error_fetching'));
  }
}

function* watchFetchUsersMailingList() {
  yield takeLatest(types.FETCH_USERS_MAILING_LIST, workFetchUsersMailingList);
}

function* workCreateUserMailingList({ formValues, newUser }) {
  try {
    const tasks = [];

    for (let i = 0; i < formValues.ids.length; i++) {
      const params = {
        listeDiffusion: formValues.ids[i],
        email: formValues.email,
        utilisateur: 1000,
      };

      tasks.push(yield fork(createUserMailingList, { bodyItems: { ...params } }));
    }
    yield join(tasks);
    yield put(createUserMailingListSuccess());
    let goToEmail = null;
    if (newUser) {
      goToEmail = formValues.email;
      snackActions.success(i18next.t('users_mailing_list.created'));
    } else {
      snackActions.success(i18next.t('users_mailing_list.updated'));
    }
    yield put(fetchUsersMailingList(goToEmail));
  } catch (error) {
    yield put(createUserMailingListError());
    console.error(error);
    snackActions.error(i18next.t('users_mailing_list.error_creating'));
  }
}

function* watchCreateUserMailingList() {
  yield takeLatest(types.CREATE_USER_MAILING_LIST, workCreateUserMailingList);
}

function* workEditUserMailingList({ formValues }) {
  try {
    const tasks = [];
    for (let i = 0; i < formValues.idsUser.length; i++) {
      const params = {
        listeDiffusionUtilisateur: formValues.idsUser[i],
        email: formValues.email,
      };
      tasks.push(yield fork(editUserMailingList, { bodyItems: { ...params } }));
    }
    yield join(tasks);
    yield put(editUSerMailingListSuccess());
    snackActions.success(i18next.t('users_mailing_list.edited'));
    yield put(fetchUsersMailingList());
  } catch (error) {
    yield put(editUSerMailingListError());
    console.error(error);
    snackActions.error(i18next.t('users_mailing_list.error_editing'));
  }
}

function* watchEditUserMailingList() {
  yield takeLatest(types.EDIT_USER_MAILING_LIST, workEditUserMailingList);
}

function* workDeleteUsersMailingList({ ids }) {
  try {
    yield call(deleteUsersMailingList, ids);
    yield put(deleteUsersMailingListSuccess());
    snackActions.success(i18next.t('users_mailing_list.deleted'));
    yield put(fetchUsersMailingList());
  } catch (error) {
    yield put(deleteUsersMailingListError(error));
    console.error(error);
    snackActions.error(i18next.t('users_mailing_list.error_deleting'));
  }
}

function* watchDeleteUsersMailingList() {
  yield takeLatest(types.DELETE_USERS_MAILING_LIST, workDeleteUsersMailingList);
}

export default {
  watchCreateMailingList,
  watchCreateUserMailingList,
  watchDeleteMailingLists,
  watchDeleteUsersMailingList,
  watchEditMailingList,
  watchEditUserMailingList,
  watchFetchMailingLists,
  watchFetchUsersMailingList,
};
