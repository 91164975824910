import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ResultsHeader from 'generic/components/ui/ResultsHeader';
import CenteredMessage from 'generic/components/ui/CenteredMessage';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const ResultsHeaderContainer = () => {
  const nbResults = useSelector((state) => state.search.results.nbresults);
  const loading = useSelector((state) => state.search.loading);
  const loadingBlank = useSelector((state) => state.search.loadingBlank);
  const isRAGSearch = useSelector((state) => state.search.isRAGSearch);
  const strategy = useSelector((state) => state.search.results.strategie);
  const fetchError = useSelector((state) => state.search.fetchError);
  const { t } = useTranslation();

  if (!loading && _.isEmpty(strategy)) {
    return (
      <CenteredMessage>
        <Box pt="50px">
          {fetchError ? t('results.error_fetching_results') : t('results.please_make_a_search')}
        </Box>
      </CenteredMessage>
    );
  }
  return (
    <ResultsHeader nbResults={nbResults} loading={loadingBlank} isRAGSearch={isRAGSearch} />
  );
};

export default ResultsHeaderContainer;
