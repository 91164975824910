import {
  get,
  post,
  patch,
  del,
} from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

/* Api des listes de diffusions */
const fetchMailingLists = ({ ...rest }) => get({ path: '/liste-diffusion/retrieve-all', ...rest }).then(handleComplete);

const createMailingList = ({ ...rest }) => post({ path: '/liste-diffusion', ...rest }).then(handleComplete);

const editMailingList = ({ ...rest }) => patch({ path: '/liste-diffusion', ...rest }).then(handleComplete);

const deleteMailingLists = (ids) => del({ path: `/liste-diffusion/${ids.join(',')}` }).then(handleComplete);

/* Api des utilisateurs des listes de diffusions */
const fetchUsersMailingList = ({ ...rest }) => get(
  { path: '/liste-diffusion-utilisateur/retrieve-all-sorted-by-email', ...rest },
).then(handleComplete);

const createUserMailingList = ({ ...rest }) => post({ path: '/liste-diffusion-utilisateur', ...rest })
  .then(handleComplete);

const editUserMailingList = ({ ...rest }) => patch({ path: '/liste-diffusion-utilisateur', ...rest })
  .then(handleComplete);

const deleteUsersMailingList = (ids) => del({ path: `/liste-diffusion-utilisateur/${ids.join(',')}` })
  .then(handleComplete);

export {
  createMailingList,
  createUserMailingList,
  deleteMailingLists,
  deleteUsersMailingList,
  editMailingList,
  editUserMailingList,
  fetchMailingLists,
  fetchUsersMailingList,
};
