export const types = {
  CLEANUP_NEWSLETTER: '@NEWSLETTERS/CLEANUP_NEWSLETTER',
  CLEANUP_NEWSLETTERS: '@NEWSLETTERS/CLEANUP_NEWSLETTERS',

  FETCH_NEWSLETTERS: '@NEWSLETTERS/FETCH_NEWSLETTERS',
  FETCH_NEWSLETTERS_SUCCESS: '@NEWSLETTERS/FETCH_NEWSLETTERS_SUCCESS',
  FETCH_NEWSLETTERS_ERROR: '@NEWSLETTERS/FETCH_NEWSLETTERS_ERROR',

  FETCH_NEWSLETTER: '@NEWSLETTERS/FETCH_NEWSLETTER',
  FETCH_NEWSLETTER_SUCCESS: '@NEWSLETTERS/FETCH_NEWSLETTER_SUCCESS',
  FETCH_NEWSLETTER_ERROR: '@NEWSLETTERS/FETCH_NEWSLETTER_ERROR',

  DELETE_NEWSLETTERS: '@NEWSLETTERS/DELETE_NEWSLETTERS',
  DELETE_NEWSLETTERS_SUCCESS: '@NEWSLETTERS/DELETE_NEWSLETTERS_SUCCESS',
  DELETE_NEWSLETTERS_ERROR: '@NEWSLETTERS/DELETE_NEWSLETTERS_ERROR',

  DELETE_NEWSLETTER_DOCUMENTS: '@NEWSLETTERS/DELETE_NEWSLETTER_DOCUMENTS',
  DELETE_NEWSLETTER_DOCUMENTS_SUCCESS: '@NEWSLETTERS/DELETE_NEWSLETTER_DOCUMENTS_SUCCESS',
  DELETE_NEWSLETTER_DOCUMENTS_ERROR: '@NEWSLETTERS/DELETE_NEWSLETTER_DOCUMENTS_ERROR',

  SAVE_NEWSLETTER: '@NEWSLETTERS/SAVE_NEWSLETTER',
  SAVE_NEWSLETTER_SUCCESS: '@NEWSLETTERS/SAVE_NEWSLETTER_SUCCESS',
  SAVE_NEWSLETTER_ERROR: '@NEWSLETTERS/SAVE_NEWSLETTER_ERROR',

  UPLOAD_NEWSLETTER_IMAGE: '@NEWSLETTERS/UPLOAD_NEWSLETTER_IMAGE',
  UPLOAD_NEWSLETTER_IMAGE_SUCCESS: '@NEWSLETTERS/UPLOAD_NEWSLETTER_IMAGE_SUCCESS',
  UPLOAD_NEWSLETTER_IMAGE_ERROR: '@NEWSLETTERS/UPLOAD_NEWSLETTER_IMAGE_ERROR',

  CLEAR_UPLOAD_NEWSLETTER_IMAGE: '@NEWSLETTERS/CLEAR_UPLOAD_NEWSLETTER_IMAGE',
  RESET_NEWSLETTER_IMAGE: '@CARTS/RESET_NEWSLETTER_IMAGE',

  ADD_TO_NEWSLETTER: '@NEWSLETTERS/ADD_TO_NEWSLETTER',
  ADD_TO_NEWSLETTER_SUCCESS: '@NEWSLETTERS/ADD_TO_NEWSLETTER_SUCCES',
  ADD_TO_NEWSLETTER_ERROR: '@NEWSLETTERS/ADD_TO_NEWSLETTER_ERROR',

  SEND_NEWSLETTER: '@NEWSLETTER/SEND_NEWSLETTER',
  SEND_NEWSLETTER_SUCCESS: '@NEWSLETTER/SEND_NEWSLETTER_SUCCESS',
  SEND_NEWSLETTER_ERROR: '@NEWSLETTER/SEND_NEWSLETTER_ERROR',

  SEND_NEWSLETTER_HISTO_SUCCESS: '@NEWSLETTER/SEND_NEWSLETTER_HISTO_SUCCESS',
  SEND_NEWSLETTER_HISTO_ERROR: '@NEWSLETTER/SEND_NEWSLETTER_HISTO_ERROR',
  SEND_NEWSLETTER_HISTO: '@NEWSLETTER/SEND_NEWSLETTER_HISTO',

  PATCH_PANIER_DOCUMENTS: '@NEWSLETTER/PATCH_PANIER_DOCUMENTS',
  PATCH_PANIER_DOCUMENTS_SUCCESS: '@NEWSLETTER/PATCH_PANIER_DOCUMENTS_SUCCESS',
  PATCH_PANIER_DOCUMENTS_ERROR: '@NEWSLETTER/PATCH_PANIER_DOCUMENTS_ERROR',

  UPDATE_PANIER_DOCUMENTS_ORDER_BY_INDEX: '@NEWSLETTER/UPDATE_PANIER_DOCUMENTS_ORDER_BY_INDEX',
};

export const cleanupNewsletter = () => ({
  type: types.CLEANUP_NEWSLETTER,
});

export const cleanupNewsletters = () => ({
  type: types.CLEANUP_NEWSLETTERS,
});

export const fetchNewsletters = () => ({
  type: types.FETCH_NEWSLETTERS,
});

export const fetchNewslettersSuccess = (newsletters) => ({
  type: types.FETCH_NEWSLETTERS_SUCCESS,
  newsletters,
});

export const fetchNewslettersError = (response) => ({
  type: types.FETCH_NEWSLETTERS_ERROR,
  response,
});

export const fetchNewsletter = (id) => ({
  type: types.FETCH_NEWSLETTER,
  id,
});

export const fetchNewsletterSuccess = (newsletter) => ({
  type: types.FETCH_NEWSLETTER_SUCCESS,
  newsletter,
});

export const fetchNewsletterError = (response) => ({
  type: types.FETCH_NEWSLETTER_ERROR,
  response,
});

export const deleteNewsletters = (ids) => ({
  type: types.DELETE_NEWSLETTERS,
  ids,
});

export const deleteNewslettersSuccess = () => ({
  type: types.DELETE_NEWSLETTERS_SUCCESS,
});

export const deleteNewslettersError = (response) => ({
  type: types.DELETE_NEWSLETTERS_ERROR,
  response,
});

export const deleteNewsletterDocuments = (id, ids) => ({
  type: types.DELETE_NEWSLETTER_DOCUMENTS,
  id,
  ids,
});

export const deleteNewsletterDocumentsSuccess = () => ({
  type: types.DELETE_NEWSLETTER_DOCUMENTS_SUCCESS,
});

export const deleteNewsletterDocumentsError = (response) => ({
  type: types.DELETE_NEWSLETTER_DOCUMENTS_ERROR,
  response,
});

export const saveNewsletter = (params) => ({
  type: types.SAVE_NEWSLETTER,
  params,
});

export const saveNewsletterSuccess = (results) => ({
  type: types.SAVE_NEWSLETTER_SUCCESS,
  results,
});

export const saveNewsletterError = (response) => ({
  type: types.SAVE_NEWSLETTER_ERROR,
  response,
});

export const uploadNewsletterImage = () => ({
  type: types.UPLOAD_NEWSLETTER_IMAGE,
});

export const uploadNewsletterImageSuccess = (results) => ({
  type: types.UPLOAD_NEWSLETTER_IMAGE_SUCCESS,
  results,
});

export const uploadNewsletterImageError = () => ({
  type: types.UPLOAD_NEWSLETTER_IMAGE_ERROR,
});

export const clearUploadNewsletterImage = () => ({
  type: types.CLEAR_UPLOAD_NEWSLETTER_IMAGE,
});

export const resetNewsletterImage = () => ({
  type: types.RESET_NEWSLETTER_IMAGE,
});

export const addToNewsletter = (params, scope) => ({
  type: types.ADD_TO_NEWSLETTER,
  params,
  scope,
});

export const addToNewsletterSuccess = () => ({
  type: types.ADD_TO_NEWSLETTER_SUCCESS,
});

export const addToNewsletterError = (response) => ({
  type: types.ADD_TO_NEWSLETTER_ERROR,
  response,
});

export const sendNewsletter = (id, params = {}) => ({
  type: types.SEND_NEWSLETTER,
  id,
  params,
});

export const sendNewsletterSuccess = (newsletter) => ({
  type: types.SEND_NEWSLETTER_SUCCESS,
  newsletter,
});

export const sendNewsletterError = (response) => ({
  type: types.SEND_NEWSLETTER_ERROR,
  response,
});

export const sendNewsletterHisto = (id, params = {}) => ({
  type: types.SEND_NEWSLETTER_HISTO,
  id,
  params,
});

export const sendNewsletterHistoSuccess = (newsletter) => ({
  type: types.SEND_NEWSLETTER_HISTO_SUCCESS,
  newsletter,
});

export const sendNewsletterHistoError = (response) => ({
  type: types.SEND_NEWSLETTER_HISTO_ERROR,
  response,
});

export const patchPanierDocuments = (panierDocuments) => ({
  type: types.PATCH_PANIER_DOCUMENTS,
  panierDocuments,
});
export const patchPanierDocumentsSuccess = (panierDocuments) => ({
  type: types.PATCH_PANIER_DOCUMENTS_SUCCESS,
  panierDocuments,
});
export const patchPanierDocumentsError = (response) => ({
  type: types.PATCH_PANIER_DOCUMENTS_ERROR,
  response,
});

export const updatePanierDocumentsOrderByIndex = (panierDocuments) => ({
  type: types.UPDATE_PANIER_DOCUMENTS_ORDER_BY_INDEX,
  panierDocuments,
});
