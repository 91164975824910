export const types = {
  FETCH_COMMENT: '@COMMENTS/FETCH_COMMENT',
  FETCH_COMMENT_SUCCESS: '@COMMENTS/FETCH_COMMENT_SUCCESS',
  FETCH_COMMENT_ERROR: '@COMMENTS/FETCH_COMMENT_ERROR',
  CREATE_COMMENT: '@COMMENTS/CREATE_COMMENT',
  CREATE_COMMENT_SUCCESS: '@COMMENTS/CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_ERROR: '@COMMENTS/CREATE_COMMENT_ERROR',
  DELETE_COMMENT: '@COMMENTS/DELETE_COMMENT',
  DELETE_COMMENT_SUCCESS: '@COMMENTS/DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_ERROR: '@COMMENTS/DELETE_COMMENT_ERROR',
  UPDATE_COMMENT_ALERT: '@COMMENTS/UPDATE_COMMENT_ALERT',
  UPDATE_COMMENT_ALERT_SUCCESS: '@COMMENTS/UPDATE_COMMENT_ALERT_SUCCESS',
  UPDATE_COMMENT_ALERT_ERROR: '@COMMENTS/UPDATE_COMMENT_ALERT_ERROR',
};

export const fetchComment = (params) => ({
  type: types.FETCH_COMMENT,
  params,
});

export const fetchCommentSuccess = (comments) => ({
  type: types.FETCH_COMMENT_SUCCESS,
  comments,
});

export const fetchCommentError = (response) => ({
  type: types.FETCH_COMMENT_ERROR,
  response,
});

export const createComment = (params) => ({
  type: types.CREATE_COMMENT,
  params,
});

export const createCommentSuccess = () => ({
  type: types.CREATE_COMMENT_SUCCESS,
});

export const createCommentError = (response) => ({
  type: types.CREATE_COMMENT_ERROR,
  response,
});

export const deleteComment = (params) => ({
  type: types.DELETE_COMMENT,
  params,
});

export const deleteCommentSuccess = () => ({
  type: types.DELETE_COMMENT_SUCCESS,
});

export const deleteCommentError = (response) => ({
  type: types.DELETE_COMMENT_ERROR,
  response,
});

export const updateCommentAlert = (params) => ({
  type: types.UPDATE_COMMENT_ALERT,
  params,
});

export const updateCommentAlertSuccess = () => ({
  type: types.UPDATE_COMMENT_ALERT_SUCCESS,
});

export const updateCommentAlertError = (response) => ({
  type: types.UPDATE_COMMENT_ALERT_ERROR,
  response,
});
