import React from 'react';
import {
  CircularProgress,
  Box,
} from '@mui/material';

const CenteredCircularProgress = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
  >
    <CircularProgress
      variant="indeterminate"
      disableShrink
    />
  </Box>
);

export default CenteredCircularProgress;
