import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Fab,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const tagsMap = {
  button: Button,
  iconButton: IconButton,
  loadingButton: LoadingButton,
  fab: Fab,
};

const DynamicButton = React.forwardRef((props, ref) => {
  const { tag } = props;
  const TagName = tagsMap[tag];
  return <TagName ref={ref} {...props} />;
});

DynamicButton.propTypes = {
  tag: PropTypes.oneOf(Object.keys(tagsMap)).isRequired,
};

export default DynamicButton;
