export const types = {
  CLEANUP_USERS: '@USERS/CLEANUP_USERS',
  FETCH_USERS: '@USERS/FETCH_USERS',
  FETCH_USERS_SUCCESS: '@USERS/FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR: '@USERS/FETCH_USERS_ERROR',
  OPEN_CLOSE_USERS: '@USERS/OPEN_CLOSE_USERS',
  OPEN_CLOSE_USERS_SUCCESS: '@USERS/OPEN_CLOSE_USERS_SUCCESS',
  OPEN_CLOSE_USERS_ERROR: '@USERS/OPEN_CLOSE_USERS_ERROR',
  OPEN_DIALOG_EDIT_USER: '@USERS/OPEN_DIALOG_EDIT_USER',
  CLOSE_DIALOG_EDIT_USER: '@USERS/CLOSE_DIALOG_EDIT_USER',
  SAVE_USER: '@USERS/SAVE_USER',
  SAVE_USER_SUCCESS: '@USERS/SAVE_USER_SUCCESS',
  SAVE_USER_ERROR: '@USERS/SAVE_USER_ERROR',
};

export const cleanupUsers = () => ({
  type: types.CLEANUP_USERS,
});

export const fetchUsers = (params) => ({
  type: types.FETCH_USERS,
  params,
});

export const fetchUsersSuccess = (users) => ({
  type: types.FETCH_USERS_SUCCESS,
  users,
});

export const fetchUsersError = (response) => ({
  type: types.FETCH_USERS_ERROR,
  response,
});

export const openCloseUsers = (ids, open, status) => ({
  type: types.OPEN_CLOSE_USERS,
  ids,
  open,
  status,
});

export const openCloseUsersSuccess = (results) => ({
  type: types.OPEN_CLOSE_USERS_SUCCESS,
  results,
});

export const openCloseUsersError = (response) => ({
  type: types.OPEN_CLOSE_USERS_ERROR,
  response,
});

export const openDialogEditUser = (user) => ({
  type: types.OPEN_DIALOG_EDIT_USER,
  user,
});

export const closeDialogEditUser = () => ({
  type: types.CLOSE_DIALOG_EDIT_USER,
});

export const saveUser = (params) => ({
  type: types.SAVE_USER,
  params,
});

export const saveUserSuccess = (user) => ({
  type: types.SAVE_USER_SUCCESS,
  user,
});

export const saveUserError = (response) => ({
  type: types.SAVE_USER_ERROR,
  response,
});
