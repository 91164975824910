export const types = {
  CLEANUP_SCHEDULE: '@SCHEDULES/CLEANUP_SCHEDULE',
  CLEANUP_SCHEDULES: '@SCHEDULES/CLEANUP_SCHEDULES',
  FETCH_SCHEDULES: '@SCHEDULES/FETCH_SCHEDULES',
  FETCH_SCHEDULES_SUCCESS: '@SCHEDULES/FETCH_SCHEDULES_SUCCESS',
  FETCH_SCHEDULES_ERROR: '@SCHEDULES/FETCH_SCHEDULES_ERROR',
  DELETE_SCHEDULES: '@SCHEDULES/DELETE_SCHEDULES',
  DELETE_SCHEDULES_SUCCESS: '@SCHEDULES/DELETE_SCHEDULES_SUCCESS',
  DELETE_SCHEDULES_ERROR: '@SCHEDULES/DELETE_SCHEDULES_ERROR',
  FETCH_SCHEDULE: '@SCHEDULES/FETCH_SCHEDULE',
  FETCH_SCHEDULE_SUCCESS: '@SCHEDULES/FETCH_SCHEDULE_SUCCESS',
  FETCH_SCHEDULE_ERROR: '@SCHEDULES/FETCH_SCHEDULE_ERROR',
  ACTIVATE_DEACTIVATE_SCHEDULES: '@SCHEDULES/ACTIVATE_DEACTIVATE_SCHEDULES',
  ACTIVATE_DEACTIVATE_SCHEDULES_SUCCESS: '@SCHEDULES/ACTIVATE_DEACTIVATE_SCHEDULES_SUCCESS',
  ACTIVATE_DEACTIVATE_SCHEDULES_ERROR: '@SCHEDULES/ACTIVATE_DEACTIVATE_SCHEDULES_ERROR',
  SAVE_SCHEDULE: '@SCHEDULES/SAVE_SCHEDULE',
  SAVE_SCHEDULE_SUCCESS: '@SCHEDULES/SAVE_SCHEDULE_SUCCESS',
  SAVE_SCHEDULE_ERROR: '@SCHEDULES/SAVE_SCHEDULE_ERROR',
  CREATE_SCHEDULE: '@SCHEDULES/CREATE_SCHEDULE',
  CREATE_SCHEDULE_SUCCESS: '@SCHEDULES/CREATE_SCHEDULE_SUCCESS',
  CREATE_SCHEDULE_ERROR: '@SCHEDULES/CREATE_SCHEDULE_ERROR',
  FETCH_SCHEDULE_FORM: '@SCHEDULES/FETCH_SCHEDULE_FORM',
  FETCH_SCHEDULE_FORM_SUCCESS: '@SCHEDULES/FETCH_SCHEDULE_FORM_SUCCESS',
  FETCH_SCHEDULE_FORM_ERROR: '@SCHEDULES/FETCH_SCHEDULE_FORM_ERROR',
  CREATE_IMMEDIATE_SCHEDULE_EXECUTION: '@SCHEDULES/CREATE_IMMEDIATE_SCHEDULE_EXECUTION',
  CREATE_IMMEDIATE_SCHEDULE_EXECUTION_SUCCESS: '@SCHEDULES/CREATE_IMMEDIATE_SCHEDULE_EXECUTION_SUCCESS',
  CREATE_IMMEDIATE_SCHEDULE_EXECUTION_ERROR: '@SCHEDULES/CREATE_IMMEDIATE_SCHEDULE_EXECUTION_ERROR',
  UPDATE_SCHEDULE_EXTENSIONS: '@SCHEDULES/UPDATE_SCHEDULE_EXTENSIONS',
};

export const cleanupSchedule = () => ({
  type: types.CLEANUP_SCHEDULE,
});

export const cleanupSchedules = () => ({
  type: types.CLEANUP_SCHEDULES,
});

export const fetchSchedules = (status) => ({
  type: types.FETCH_SCHEDULES,
  status,
});

export const fetchSchedulesSuccess = (schedules) => ({
  type: types.FETCH_SCHEDULES_SUCCESS,
  schedules,
});

export const fetchSchedulesError = (response) => ({
  type: types.FETCH_SCHEDULES_ERROR,
  response,
});

export const deleteSchedules = (ids) => ({
  type: types.DELETE_SCHEDULES,
  ids,
});

export const deleteSchedulesSuccess = () => ({
  type: types.DELETE_SCHEDULES_SUCCESS,
});

export const deleteSchedulesError = (response) => ({
  type: types.DELETE_SCHEDULES_ERROR,
  response,
});

export const fetchSchedule = (id) => ({
  type: types.FETCH_SCHEDULE,
  id,
});

export const fetchScheduleSuccess = (schedule) => ({
  type: types.FETCH_SCHEDULE_SUCCESS,
  schedule,
});

export const fetchScheduleError = (response) => ({
  type: types.FETCH_SCHEDULE_ERROR,
  response,
});

/**
 * Permet d'activer ou désactiver un schedule
 * @param {Array<long>} ids tableau contenant les id de schedule à modifier
 * @param {boolean} active la valeur de l'état futur
 * @param {function} afterSave fonction de callback à appeler après le succès du save.
 */
export const activateDeactivateSchedules = (ids, active, afterSave) => ({
  type: types.ACTIVATE_DEACTIVATE_SCHEDULES,
  ids,
  active,
  afterSave,
});

export const activateDeactivateSchedulesSuccess = (results) => ({
  type: types.ACTIVATE_DEACTIVATE_SCHEDULES_SUCCESS,
  results,
});

export const activateDeactivateSchedulesError = (response) => ({
  type: types.ACTIVATE_DEACTIVATE_SCHEDULES_ERROR,
  response,
});

export const saveSchedule = (params) => ({
  type: types.SAVE_SCHEDULE,
  params,
});

export const saveScheduleSuccess = () => ({
  type: types.SAVE_SCHEDULE_SUCCESS,
});

export const saveScheduleError = (response) => ({
  type: types.SAVE_SCHEDULE_ERROR,
  response,
});

export const createSchedule = (params) => ({
  type: types.CREATE_SCHEDULE,
  params,
});

export const createScheduleSuccess = (schedule) => ({
  type: types.CREATE_SCHEDULE_SUCCESS,
  schedule,
});

export const createScheduleError = (response) => ({
  type: types.CREATE_SCHEDULE_ERROR,
  response,
});

export const fetchScheduleForm = (idSchedule) => ({
  type: types.FETCH_SCHEDULE_FORM,
  id: idSchedule,
});

export const fetchScheduleFormSuccess = (results) => ({
  type: types.FETCH_SCHEDULE_FORM_SUCCESS,
  results,
});

export const fetchScheduleFormError = (response) => ({
  type: types.FETCH_SCHEDULE_FORM_ERROR,
  response,
});

export const createImmediateScheduleExecution = (idSchedule, reprise = false) => ({
  type: types.CREATE_IMMEDIATE_SCHEDULE_EXECUTION,
  idSchedule,
  reprise,
});

export const createImmediateScheduleExecutionSuccess = (response) => ({
  type: types.CREATE_IMMEDIATE_SCHEDULE_EXECUTION_SUCCESS,
  response,
});

export const createImmediateScheduleExecutionError = (response) => ({
  type: types.CREATE_IMMEDIATE_SCHEDULE_EXECUTION_ERROR,
  response,
});

/**
 * Permet de mettre à jour les schedule extension dans un schedule déjà présent dans redux.
 * @param {long} idSchedule
 * @param {Array[ScheduleExtension]} response
 */
export const updateScheduleExtensions = (idSchedule, response) => ({
  type: types.UPDATE_SCHEDULE_EXTENSIONS,
  idSchedule,
  extensions: response,
});
