import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { types, unsetConfirmDialogData } from 'generic/core/confirmDialog/actions';
import ConfirmDialog from 'generic/components/dialogs/ConfirmDialog';

const ConfirmDialogContainer = () => {
  const dialogData = useSelector((state) => state.confirmDialog.data);
  const loading = useSelector((state) => state.confirmDialog.loading);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch({
      type: types.CONFIRM_DIALOG_SUBMIT,
      dialogData,
    });
  };

  const handleClose = () => {
    dispatch(unsetConfirmDialogData());
  };

  if (_.isEmpty(dialogData)) {
    return null;
  }
  return (
    <ConfirmDialog
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      title={dialogData.title}
      textButton={dialogData.textButton}
      dialogContent={dialogData.message}
      submitColor={dialogData.submitColor}
      loading={loading}
    />
  );
};

export default ConfirmDialogContainer;
