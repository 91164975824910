import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
} from '@mui/material';
import DynamicButton from 'generic/components/ui/DynamicButton';

const TooltipButton = ({
  title,
  children,
  tag,
  placement,
  ...rest
}) => (
  <Tooltip
    title={title}
    placement={placement}
  >
    <DynamicButton
      tag={tag}
      component="div"
      {...rest}
    >
      {children}
    </DynamicButton>
  </Tooltip>
);

TooltipButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  placement: PropTypes.string,
  tag: PropTypes.oneOf([
    'button',
    'iconButton',
    'loadingButton',
    'fab',
  ]),
  title: PropTypes.string,
};
TooltipButton.defaultProps = {
  placement: 'bottom',
  tag: 'button',
  title: '',
};

export default TooltipButton;
