import { get, post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const getFile = ({ ...rest }) => get({ path: '/file', ...rest }).then(handleComplete);

const uploadFile = ({ ...rest }) => post({ path: '/file', ...rest }).then(handleComplete);

export {
  getFile,
  uploadFile,
};
