import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Criterion from 'generic/components/ui/Criterion';
import _ from 'lodash';

const Criteria = ({
  criteria,
  handleExclude,
  handleInclude,
  handleRemove,
  handleEdit,
}) => (
  <Box
    display="flex"
    alignItems="left"
    justifyContent="flex-start"
    flexWrap="wrap"
    p={1}
    pb={0.5}
  >
    {(
      criteria.map((criterion) => (
        <Criterion
          key={criterion.mouvementChamp}
          criterion={criterion}
          handleExclude={handleExclude}
          handleInclude={handleInclude}
          handleRemove={handleRemove}
          handleRemoveDisabled={_.filter(criteria, (crit) => !crit.readOnly).length === 1}
          handleEdit={handleEdit}
          readOnly={criterion.readOnly}
        />
      ))
    )}
  </Box>
);

Criteria.propTypes = {
  handleExclude: PropTypes.func.isRequired,
  handleInclude: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  criteria: PropTypes.arrayOf(
    PropTypes.shape({
      champ: PropTypes.number,
      istext: PropTypes.bool,
      libelle: PropTypes.string,
      libelleNombre: PropTypes.string,
      mouvementChamp: PropTypes.number,
      operateur: PropTypes.string,
      parentheseFermante: PropTypes.string,
      parentheseOuvrante: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

Criteria.defaultProps = {
  criteria: [],
};

export default Criteria;
