import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  Delete,
  Description,
  Image,
  MoreVert,
} from '@mui/icons-material';

import { utilisateurLightPropType } from 'generic/core/qes/proptypes';
import DropdownMenu from 'generic/components/ui/DropdownMenu';

const Corpus = ({
  corpus,
  isOwner,
  variant,
  handleActivateCollection,
  handleOpenCollectionDialog,
  handleOpenDeleteCollectionConfirmDialog,
}) => {
  const { t } = useTranslation();
  const { API_ENTRY_POINT } = QES_CONSTANTS;

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  let imgSrc = '';
  if (corpus.image) {
    imgSrc = `${API_ENTRY_POINT}/file?type=11&file=${corpus.image}&logon=${logon}&key=${key}`;
  }

  return (
    <Card
      sx={{
        border: corpus.active ? '2px solid' : '1px solid',
        borderColor: corpus.active ? 'primary.main' : 'divider',
        ...(variant === 'full' && {
          mb: 1,
        }),
      }}
    >
      <CardActionArea onClick={() => handleActivateCollection(corpus.collection)}>
        <CardContent
          sx={{
            p: 1,
            ...(variant === 'light' && {
              p: 1.5,
            }),
          }}
        >
          {variant === 'light' && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  width: 'calc(60% - 8px)',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {corpus.libelle}
              </Typography>
              <Chip
                size="small"
                sx={{ width: '40%' }}
                label={(
                  <span style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  }}
                  >
                    {corpus.collection_parente_name}
                  </span>
                )}
              />
            </Box>
          )}
          {variant === 'full' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2.5,
              }}
            >
              <Avatar
                src={imgSrc}
                alt=""
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '0%',
                }}
              >
                <Image sx={{ fontSize: 40 }} />
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                      overflow: 'hidden',
                    }}
                  >
                    {corpus.libelle}
                  </Typography>
                  <DropdownMenu
                    btnChildren={<MoreVert />}
                    btnProps={{
                      sx: { padding: '2px' },
                      component: 'div',
                    }}
                  >
                    <MenuItem
                      onClick={() => handleOpenCollectionDialog(
                        'corpus',
                        'edit',
                        {
                          id: corpus.collection,
                          collection_parente: corpus.collection_parente,
                          owner: corpus.utilisateur_createur,
                          visibility: corpus.visibilite_collection,
                          collaborators: corpus.collaborators,
                          libelle: corpus.libelle,
                          description: corpus.description,
                          image: corpus.image,
                        },
                      )}
                    >
                      <Description fontSize="small" color="primary" sx={{ mr: 1 }} />
                      {isOwner ? t('collections.corpus.edit') : t('collections.corpus.params')}
                    </MenuItem>
                    <Tooltip
                      title={!isOwner ? t('collections.cannotDeleteNotOwner') : ''}
                      placement="right"
                    >
                      <Box>
                        <MenuItem
                          disabled={!isOwner}
                          sx={{ color: 'error.main' }}
                          onClick={() => handleOpenDeleteCollectionConfirmDialog('corpus', [corpus.collection])}
                        >
                          <Delete fontSize="small" color="error" sx={{ mr: 1 }} />
                          {t('collections.corpus.delete')}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </DropdownMenu>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

Corpus.propTypes = {
  corpus: PropTypes.shape({
    active: PropTypes.bool,
    collaborators: PropTypes.arrayOf(utilisateurLightPropType),
    collection: PropTypes.number,
    collection_parente: PropTypes.number,
    collection_parente_name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    libelle: PropTypes.string,
    utilisateur_createur: utilisateurLightPropType.isRequired,
    visibilite_collection: PropTypes.number,
  }).isRequired,
  isOwner: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['full', 'light']),
  handleActivateCollection: PropTypes.func.isRequired,
  handleOpenCollectionDialog: PropTypes.func.isRequired,
  handleOpenDeleteCollectionConfirmDialog: PropTypes.func.isRequired,
};

Corpus.defaultProps = {
  variant: 'full',
};

export default Corpus;
