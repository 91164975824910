export const types = {
  EXPORT_SUBMIT: '@EXPORT/EXPORT_SUBMIT',
  EXPORT_SUBMIT_SUCCESS: '@EXPORT/EXPORT_SUBMIT_SUCCESS',
  EXPORT_SUBMIT_ERROR: '@EXPORT/EXPORT_SUBMIT_ERROR',
};

export const submitExport = (params) => ({
  type: types.EXPORT_SUBMIT,
  params,
});

export const submitExportSuccess = () => ({
  type: types.EXPORT_SUBMIT_SUCCESS,
});

export const submitExportError = (response) => ({
  type: types.EXPORT_SUBMIT_ERROR,
  response,
});
