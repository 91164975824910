import { types } from 'generic/core/dashboard/actions';
import _ from 'lodash';

const dashboardReducer = (state = {
  dashboards: [],
  loadingDashboards: true,
  saveWidgetsLoading: false,
  saveDashboardLoading: false,
  dialogCreateWidgetOpened: false,
  dialogEditOrCreateDashboardOpened: false,
  editDashboard: null,
  widgets: {},
}, action) => {
  switch (action.type) {
    case types.OPEN_DIALOG_CREATE_WIDGET: {
      return {
        ...state,
        dialogCreateWidgetOpened: true,
      };
    }
    case types.CLOSE_DIALOG_CREATE_WIDGET: {
      return {
        ...state,
        dialogCreateWidgetOpened: false,
      };
    }
    case types.OPEN_DIALOG_EDIT_OR_CREATE_DASHBOARD: {
      const newState = {
        ...state,
        dialogEditOrCreateDashboardOpened: true,
      };
      if (!_.isEmpty(action.editDashboard)) {
        newState.editDashboard = action.editDashboard;
      }
      return newState;
    }
    case types.CLOSE_DIALOG_CREATE_DASHBOARD: {
      return {
        ...state,
        dialogEditOrCreateDashboardOpened: false,
        editDashboard: null,
      };
    }
    case types.CLEANUP_DASHBOARDS:
    case types.FETCH_DASHBOARDS: {
      return {
        ...state,
        loadingDashboards: true,
        dashboards: [],
      };
    }
    case types.FETCH_DASHBOARDS_SUCCESS: {
      return {
        ...state,
        dashboards: action.dashboards,
        loadingDashboards: false,
      };
    }
    case types.FETCH_DASHBOARDS_ERROR: {
      return {
        ...state,
        dashboards: [],
        loadingDashboards: false,
      };
    }
    case types.SAVE_WIDGETS: {
      const widgets = { ...state.widgets };
      if (!action.isLayoutChange && action.params.dashboard_widget) {
        widgets[action.params.dashboard_widget].loading = true;
      }
      return {
        ...state,
        saveWidgetsLoading: true,
        widgets,
      };
    }
    case types.STOP_WIDGET_LOADING: {
      return {
        ...state,
        saveWidgetsLoading: false,
      };
    }
    case types.SAVE_WIDGETS_SUCCESS: {
      let actionWidgets = _.cloneDeep(action.widgets);
      if (_.isPlainObject(actionWidgets)) {
        actionWidgets = [actionWidgets];
      }
      const dashboardsCloned = _.cloneDeep(state.dashboards);
      const dashboardToUpdate = _.find(dashboardsCloned, { dashboard: actionWidgets[0].dashboard });
      if (!_.isEmpty(dashboardToUpdate)) {
        if (action.addition) {
          actionWidgets[0].isNew = true;
          dashboardToUpdate.dashboard_widget = _.concat(
            dashboardToUpdate.dashboard_widget.map(
              (widget) => ({
                ...widget,
                isNew: false,
              }),
            ),
            actionWidgets,
          );
        } else {
          dashboardToUpdate.dashboard_widget = dashboardToUpdate.dashboard_widget.map(
            (widget) => {
              const finalWidget = _.find(actionWidgets, { dashboard_widget: widget.dashboard_widget }) || widget;
              return ({
                ...finalWidget,
                isNew: false,
              });
            },
          );
        }
      }
      return {
        ...state,
        dashboards: dashboardsCloned,
      };
    }
    case types.SAVE_WIDGETS_ERROR: {
      return {
        ...state,
      };
    }
    case types.DELETE_WIDGET_SUCCESS: {
      const dashboardsCloned = _.cloneDeep(state.dashboards);
      const dashboardToUpdate = _.find(dashboardsCloned, { dashboard: action.widget.dashboard });
      if (!_.isEmpty(dashboardToUpdate)) {
        dashboardToUpdate.dashboard_widget = _.filter(
          dashboardToUpdate.dashboard_widget,
          (widget) => widget.dashboard_widget !== action.widget.dashboard_widget,
        );
      }
      return {
        ...state,
        dashboards: dashboardsCloned,
      };
    }
    case types.SAVE_DASHBOARD: {
      return {
        ...state,
        saveDashboardLoading: true,
      };
    }
    case types.SAVE_DASHBOARD_SUCCESS: {
      let dashboardsCloned = _.cloneDeep(state.dashboards);
      if (action.addition) {
        dashboardsCloned.push(action.dashboard);
      } else {
        dashboardsCloned = _.map(
          dashboardsCloned,
          (dashboard) => (action.dashboard.dashboard === dashboard.dashboard ? {
            ...dashboard,
            ...(_.omit(action.dashboard, ['dashboard_widget'])),
          } : dashboard),
        );
      }
      return {
        ...state,
        dashboards: dashboardsCloned,
        saveDashboardLoading: false,
      };
    }
    case types.SAVE_DASHBOARD_ERROR: {
      return {
        ...state,
        saveDashboardLoading: false,
      };
    }
    case types.DELETE_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboards: _.filter(
          state.dashboards,
          (dashboard) => dashboard.dashboard !== action.dashboard.dashboard,
        ),
      };
    }
    case types.SET_DASHBOARD_LOADING:
    case types.CLEANUP_DASHBOARD:
    case types.FETCH_WIDGETS: {
      const actionWidgetsIds = _.map(action.widgets, 'dashboard_widget');
      let widgets = action.keepOtherWidgetsData ? _.omit({ ...state.widgets }, actionWidgetsIds) : {};
      if (action.keepAllWidgetsData) {
        widgets = { ...state.widgets };
      }
      return {
        ...state,
        widgets,
      };
    }
    case types.FETCH_WIDGET_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [action.widgetId]: action.widget,
        },
      };
    }
    case types.FETCH_WIDGET_ERROR: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [action.widgetId]: {
            error: action.response,
            series: [],
          },
        },
      };
    }
    case types.SET_CONTEXT_MENU: {
      return {
        ...state,
        contextMenu: {
          ...action.datas,
        },
      };
    }
    case types.UNSET_CONTEXT_MENU: {
      return {
        ...state,
        contextMenu: {},
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
