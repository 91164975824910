import { find, get } from 'lodash';
import { useSelector } from 'react-redux';

/**
 * Renvoi les informations d'un champ QES tel que son nom, son code, sa traduction, ... si disponible dans redux
 * @param {Number|String} code le code complet ou uniquement l'id du champ pour lequel on cherche les informations.
 * @returns l'object champ si trouvé dans la base active de redux
 */
export default (champ) => useSelector((state) => {
  const champs = get(state, 'config.activeBase.champs');
  const tchamp = typeof champ;

  if (tchamp === 'number' || (tchamp === 'string' && /^\d+$/.test(champ))) {
    return find(champs, (c) => new RegExp(`^\\w+_${champ}$`).test(c.code));
  }

  return find(champs, { code: champ });
});
