import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Chip,
} from '@mui/material';
import {
  ArrowLeftOutlined,
  MoreHorizOutlined,
} from '@mui/icons-material';
import Tag from 'generic/components/ui/Tag';
import { getFieldFromFormFields } from 'generic/utils/qesUtils';

const ExpandableTagList = ({
  qesdocument, threshold, displayAll, tags, disableRefine,
}) => {
  const { t } = useTranslation();
  const [isTagsExpanded, setIsTagsExpanded] = useState(false);

  const activeBaseFields = useSelector((state) => state.config.activeBase.champs);

  let totalFiltered = 0;
  let total = 0;

  const myTags = _.map(tags, ({
    fieldName, icon, externalLink, color, variant,
  }) => {
    const baseField = getFieldFromFormFields(activeBaseFields, fieldName);
    const allTags = _.get(qesdocument, fieldName, []).reduce((acc, fieldValue) => (
      acc.concat(...fieldValue.split(';'))
    ), []);
    total += allTags.length;

    let toDisplay = allTags;
    if (!displayAll) {
      toDisplay = allTags.slice(0, threshold);
    }
    totalFiltered += toDisplay.length;

    let fieldId = '';
    let fieldLabel = '';
    if (baseField) {
      [, fieldId] = baseField.code.split('_');
      fieldLabel = baseField.libelle;
    }
    return {
      fieldId: +fieldId,
      fieldName,
      icon,
      externalLink,
      key: `${fieldId}_${fieldName}`,
      allTags,
      toDisplay,
      color,
      title: fieldLabel,
      variant,
    };
  });

  return (
    <Fragment>
      { myTags.map((tag) => {
        const props = {};
        if (tag.icon) {
          const Icon = tag.icon;
          props.icon = <Icon />;
        }

        return (
          <Tag
            {...props}
            disableRefine={disableRefine}
            key={tag.key}
            tags={isTagsExpanded ? tag.allTags : tag.toDisplay}
            idChamp={tag.fieldId}
            externalLink={tag.externalLink}
            color={tag.color}
            title={tag.title}
            variant={tag.variant}
          />
        );
      }) }

      { (total > totalFiltered)
      && (
        <Chip
          key="more-less-trigger"
          size="extraSmall"
          sx={{
            height: '20px',
            mt: '0px',
            mb: '4px',
            '& .MuiChip-icon': {
              ml: '2px',
              mr: '-14px',
            },
          }}
          onClick={() => setIsTagsExpanded(!isTagsExpanded)}
          variant="outlined"
          icon={isTagsExpanded ? <ArrowLeftOutlined /> : <MoreHorizOutlined />}
          title={t(`results.tags.${isTagsExpanded ? 'less' : 'more'}`)}
        />
      )}
    </Fragment>
  );
};

ExpandableTagList.propTypes = {
  disableRefine: PropTypes.bool,
  displayAll: PropTypes.bool,
  qesdocument: PropTypes.shape().isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    externalLink: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),
    fieldId: PropTypes.number,
    fieldName: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
  })).isRequired,
  threshold: PropTypes.number,
};

ExpandableTagList.defaultProps = {
  disableRefine: false,
  displayAll: false,
  threshold: 2,
};

export default ExpandableTagList;
