/**
 * Recherche dans le tableau `reference` tous les éléments ayant un `id`
 * en commun avec les éléments du tableau `nouveaux`, puis les remplace
 * par les éléments correspondants de `nouveaux`.
 *
 * @param {Array<Object>} reference - Le tableau à modifier.
 * @param {Array<Object>} nouveaux - Le tableau contenant les nouveaux éléments.
 * @param {string} idKey - La clé servant d'identifiant pour les objets.
 * @returns {void}
 *
 * @example
 * const reference = [{id: 1, name: 'foo'}, {id: 2, name: 'bar'}];
 * const nouveaux = [{id: 1, name: 'baz'}, {id: 3, name: 'qux'}];
 * replaceObjectsByKey(reference, nouveaux, 'id');
 * // reference vaut maintenant [{id: 1, name: 'baz'}, {id: 2, name: 'bar'}]
 */
const replaceObjectsByKey = (reference, nouveaux, idKey) => {
  nouveaux.forEach((nouvelObjet) => {
    const objetReference = reference.find((objet) => objet[idKey] === nouvelObjet[idKey]);
    if (objetReference) {
      Object.assign(objetReference, nouvelObjet);
    }
  });
};

/**
 * Permet d'intercaler, à la façon de `join`, un élément entre les valeurs d'un tableau.
 * @param {Array<*>} arr le tableau à modifier
 * @param {*} x l'élément à intercaler
 * @returns Un nouveau tableau avec pour chaque paire de valeur, l'élement x intercalé
 * `interleave([1,2,3], 'x') === [1, 'x', 2, 'x', 3]`
 */
const interleave = (arr, x) => arr.flatMap((e) => [e, x]).slice(0, -1);

export {
  replaceObjectsByKey,
  interleave,
};
