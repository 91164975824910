import handleComplete from 'generic/utils/apiUtils';
import {
  del,
  get,
  patch,
  post,
} from 'generic/api/apiGenerator';

const getDocument = ({ ...rest }) => get({ path: '/article', ...rest }).then(handleComplete);

const saveDocument = ({ ...rest }) => patch({ path: '/article', ...rest }).then(handleComplete);

const updateStatusFromResults = ({ ...rest }) => patch({ path: '/article/workflow', ...rest }).then(handleComplete);

const createDocument = ({ ...rest }) => post({ path: '/article', ...rest }).then(handleComplete);

const deleteDocument = ({ ...rest }) => del({ path: '/article', ...rest }).then(handleComplete);

const deleteDocumentsFromResults = ({ ...rest }) => del({ path: '/article/delete', ...rest }).then(handleComplete);

const unlockDocument = ({ ...rest }) => post({ path: '/article/unlock', ...rest }).then(handleComplete);

export {
  getDocument,
  saveDocument,
  updateStatusFromResults,
  createDocument,
  deleteDocument,
  deleteDocumentsFromResults,
  unlockDocument,
};
