import { types } from 'generic/core/export/actions';

const exportReducer = (
  state = {
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case types.EXPORT_SUBMIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.EXPORT_SUBMIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.EXPORT_SUBMIT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.CLOSE_DIALOG_EXPORT: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default exportReducer;
