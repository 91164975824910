import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import DashboardHeader from 'generic/components/ui/DashboardHeader';

const DashboardHeaderContainer = () => {
  const hasReport = useSelector((state) => state.config.user.hasReport);

  const loading = useSelector((state) => {
    const results = _.get(state, 'search.results', []);
    return _.isEmpty(results);
  });

  return (
    <DashboardHeader loading={loading} hasReport={hasReport} />
  );
};

export default DashboardHeaderContainer;
