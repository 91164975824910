import * as types from 'generic/core/auth/actionTypes';

const authenticationReducer = (
  state = {
    logged: false,
    loading: true,
    logonError: false,
    resetPasswordLoading: false,
    resetPasswordError: false,
    resetPasswordConfirmationLoading: false,
    resetPasswordConfirmationError: false,
  },
  action,
) => {
  switch (action.type) {
    case types.RESTORE_STATE_FROM_STORAGE:
      return {
        ...state,
        logged: false,
        loading: true,
      };

    case types.RESTORE_STATE_FROM_STORAGE_FAILURE:
      return {
        ...state,
        logged: false,
        loading: false,
      };

    case types.LOGON:
      return {
        ...state,
        logged: false,
        loading: true,
        logonError: false,
      };

    case types.LOGON_SUCCESS: {
      return {
        ...state,
        token: action.token,
        logged: true,
        loading: false,
        logonError: false,
      };
    }
    case types.LOGON_ERROR:
      return {
        ...state,
        loading: false,
        logonError: true,
      };

    case types.LOGOUT:
      return {
        ...state,
        loading: false,
        logged: false,
        token: undefined,
      };

    case types.SAVE_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          saveUserLoading: true,
        },
      };
    }
    case types.SAVE_PROFILE_SUCCESS: {
      return {
        ...state,
        user: {
          ...action.user,
          saveUserLoading: false,
        },
      };
    }
    case types.SAVE_PROFILE_ERROR: {
      return {
        ...state,
        user: {
          ...state.user,
          saveUserLoading: false,
        },
      };
    }
    case types.UPLOAD_USER_IMAGE: {
      return {
        ...state,
        user: {
          ...state.user,
          imageUploading: true,
        },
      };
    }
    case types.UPLOAD_USER_IMAGE_SUCCESS:
    case types.UPLOAD_USER_IMAGE_ERROR: {
      return {
        ...state,
        user: {
          ...state.user,
          imageUploading: false,
        },
      };
    }
    case types.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordError: false,
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: false,
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: true,
      };
    case types.RESET_PASSWORD_CONFIRMATION:
      return {
        ...state,
        resetPasswordConfirmationLoading: true,
        resetPasswordConfirmationError: false,
      };
    case types.RESET_PASSWORD_CONFIRMATION_SUCCESS:
      return {
        ...state,
        resetPasswordConfirmationLoading: false,
        resetPasswordConfirmationError: false,
      };
    case types.RESET_PASSWORD_CONFIRMATION_ERROR:
      return {
        ...state,
        resetPasswordConfirmationLoading: false,
        resetPasswordConfirmationError: true,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
