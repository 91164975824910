import { types } from 'generic/core/users/actions';

const usersReducer = (
  state = {
    users: [],
    loading: true,
    editDialogOpened: false,
    editUser: {},
    saveUserLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_USERS: {
      return {
        ...state,
        users: [],
      };
    }
    case types.FETCH_USERS: {
      return {
        ...state,
        loading: true,
        users: [],
      };
    }
    case types.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        users: action.users,
        loading: false,
      };
    }
    case types.FETCH_USERS_ERROR: {
      return {
        ...state,
        users: [],
        loading: false,
      };
    }
    case types.OPEN_DIALOG_EDIT_USER: {
      return {
        ...state,
        editDialogOpened: true,
        editUser: action.user,
      };
    }
    case types.CLOSE_DIALOG_EDIT_USER: {
      return {
        ...state,
        editDialogOpened: false,
        editUser: {},
      };
    }
    case types.SAVE_USER: {
      return {
        ...state,
        saveUserLoading: true,
      };
    }
    case types.SAVE_USER_SUCCESS: {
      return {
        ...state,
        users: state.users.map(
          (user) => (
            user.utilisateur === action.user.utilisateur ? action.user : user
          ),
        ),
        saveUserLoading: false,
      };
    }
    case types.SAVE_USER_ERROR: {
      return {
        ...state,
        saveUserLoading: false,
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
