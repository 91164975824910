import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  Folder,
  Dns,
  FolderSpecial,
  ChevronRight,
  ExpandMore,
  Edit,
  Star,
  StarBorder,
  CreateNewFolder,
  Clear,
  MoreVert,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { folderPropType } from 'generic/core/anr/proptypes';

const useStyles = makeStyles(() => ({
  noPadding: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  title: {
    lineHeight: '1.25em',
  },
}));

const FolderItem = ({
  activeItemId,
  canCollapseRoot,
  canCreateFolders,
  currentItemRef,
  handleEditNewFolder,
  handleOpenDeleteConfirmDialog,
  handleToggleFavoriteStatus,
  searchFolders,
  item,
  levelTree,
  levelSearch,
  allHighlight,
  completeRoot,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let rootTitle;
  let initialVisibleChildren = true;
  let linkCondition;

  let itemTitle = item.short_title;
  let setLevelSearch = levelSearch;
  let setAllHighlight = allHighlight;
  let setCompleteRoot = completeRoot;

  if (item.is_root) {
    const { title } = item;
    if (item.is_favorite) {
      rootTitle = `${title}*`;
    } else {
      rootTitle = title;
    }
    const reducedRoots = JSON.parse(localStorage.getItem('reducedRoots') || '[]');
    if (_.some(reducedRoots, (reducedRoot) => reducedRoot === rootTitle)) {
      initialVisibleChildren = false;
    }
  } else {
    linkCondition = item.has_news || (!item.has_news && !item.has_quotes) ? 'news' : 'quotes';
  }

  const [isChildrenVisible, setIsChildrenVisible] = useState(initialVisibleChildren);
  const canEditItem = canCreateFolders && (item.is_root || item.is_his);

  // Fonction qui highlight une partie du texte en fonction de la recherche
  const setHighligthTitle = (textSearch, highlightFromEnd) => {
    const startIndex = highlightFromEnd
      ? item.short_title.toLowerCase().lastIndexOf(textSearch.trim().toLowerCase())
      : item.short_title.toLowerCase().indexOf(textSearch.trim().toLowerCase());
    // Découpe le texte en trois parties : avant, pendant et après le terme de recherche
    const beforeHighlight = item.short_title.slice(0, startIndex);
    const highlightedText = item.short_title.slice(startIndex, startIndex + textSearch.trim().length);
    const afterHighlight = item.short_title.slice(startIndex + textSearch.trim().length);
    return (
      <span style={{ whiteSpace: 'pre-wrap' }}>
        {beforeHighlight}
        <strong>{highlightedText}</strong>
        {afterHighlight}
      </span>
    );
  };

  const arraySearchFolders = searchFolders.split('/').filter((word) => word.trim() !== '');

  // Fonction qui permet de savoir si une root contient les sous chemins directes de la recherche
  const findCompleteRoot = (element) => {
    let findElement = 0;
    const repeatFunction = (setElement) => {
      if (findElement < arraySearchFolders.length) {
        if (
          /* Premier élément Root Search et
          si présence du '/' au début de la recherche, on vérifie que le dossier/root finit bien par le mot ,
          si pas présencu du '/' au début de la recherche, on vérifie que le dossier/root est le même que le mot
          */
          (
            findElement === 0
            && ((
              !_.startsWith(searchFolders.trim(), '/')
              && _.endsWith(setElement.short_title.toLowerCase(), arraySearchFolders[findElement].trim().toLowerCase())
            ) || (
              _.startsWith(searchFolders.trim(), '/')
              && _.isEqual(setElement.short_title.toLowerCase(), arraySearchFolders[findElement].trim().toLowerCase())
            ))
          )
          // Entre le premier et le dernier élément Root Search, on vérifie que le dossier/root est le même que le mot
          || (
            findElement > 0 && findElement < arraySearchFolders.length - 1
            && _.isEqual(setElement.short_title.toLowerCase(), arraySearchFolders[findElement].trim().toLowerCase())
          )
          /* Dernier élément Root Search et
          si présence du '/' à la fin de la recherche, on vérifie que le dossier/root est le même que le mot
          si pas présencu du '/' à la fin de la recherche, on vérifie que le dossier/root commence par le mot
          */
          || (
            findElement === arraySearchFolders.length - 1
            && ((
              !_.endsWith(searchFolders.trim(), '/')
              && _.startsWith(
                setElement.short_title.toLowerCase(),
                arraySearchFolders[findElement].trim().toLowerCase(),
              )
            ) || (
              _.endsWith(searchFolders.trim(), '/')
              && _.isEqual(setElement.short_title.toLowerCase(), arraySearchFolders[findElement].trim().toLowerCase())
            ))
          )
        ) {
          findElement += 1;
          _.forEach(setElement.children, (children) => {
            repeatFunction(children);
          });
        }
      }
    };
    repeatFunction(element);
    if (findElement >= arraySearchFolders.length) {
      setCompleteRoot = true;
    }
  };

  if (searchFolders.length >= 2 && arraySearchFolders.length > 0) {
    /* Est une Root */
    if (_.includes(searchFolders, '/')) {
      if (!setCompleteRoot) {
        if (!(_.startsWith(searchFolders.trim(), '/') && levelTree === 0)) {
          findCompleteRoot(item);
        }
      }
      if (setCompleteRoot && !setAllHighlight) {
        // Premier élément search
        if (levelSearch === 0) {
          if (levelSearch === 0 && !_.startsWith(searchFolders.trim(), '/')) {
            itemTitle = setHighligthTitle(arraySearchFolders[levelSearch], true);
          } else {
            itemTitle = setHighligthTitle(arraySearchFolders[levelSearch]);
          }
          setLevelSearch += 1;
        // Entre le premier et le dernier élément search
        } else if (
          levelSearch > 0 && levelSearch < arraySearchFolders.length - 1
          && _.isEqual(item.short_title.toLowerCase(), arraySearchFolders[levelSearch].trim().toLowerCase())
        ) {
          itemTitle = setHighligthTitle(arraySearchFolders[levelSearch]);
          setLevelSearch += 1;
        // Dernier élement search
        } else if (
          levelSearch === arraySearchFolders.length - 1
          && _.startsWith(item.short_title.toLowerCase(), arraySearchFolders[levelSearch].trim().toLowerCase())
        ) {
          itemTitle = setHighligthTitle(arraySearchFolders[levelSearch]);
          setLevelSearch += 1;
        // Si on trouve pas l'élément de search, on refait la recherche depuis le début
        } else {
          setCompleteRoot = false;
          setLevelSearch = 0;
          findCompleteRoot(item);
          if (setCompleteRoot) {
            itemTitle = setHighligthTitle(arraySearchFolders[setLevelSearch]);
            setLevelSearch += 1;
          }
        }
      }
      // Recherhe complète
      if (setLevelSearch >= arraySearchFolders.length) {
        setAllHighlight = true;
      }
    /* Pas de Root */
    } else if (_.includes(item.short_title.toLowerCase(), arraySearchFolders[levelSearch].trim().toLowerCase())) {
      itemTitle = setHighligthTitle(arraySearchFolders[levelSearch]);
    }
  }

  useEffect(() => {
    if (!canCollapseRoot) {
      setIsChildrenVisible(true);
    } else {
      setIsChildrenVisible(initialVisibleChildren);
    }
  }, [initialVisibleChildren, canCollapseRoot]);

  // Définition du "DropdownMenu" associé à chaque élément de l'arbre
  const ItemDropdown = React.memo(() => (
    <DropdownMenu
      btnChildren={<MoreVert fontSize="small" />}
      btnProps={{
        sx: { padding: '2px' },
      }}
    >
      <Tooltip
        title={t(item.is_favorite ? 'anr.remove_favorite' : 'anr.add_favorite')}
        placement="right"
      >
        <MenuItem
          onClick={() => handleToggleFavoriteStatus(item.id, !item.is_favorite)}
        >
          <ListItemIcon>
            {item.is_favorite ? (
              <Star
                fontSize="small"
                className="yellowStar"
              />
            ) : (
              <StarBorder
                fontSize="small"
              />
            )}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={t('anr.favorite')}
          />
        </MenuItem>
      </Tooltip>
      {canEditItem && (
        item.is_root ? (
          <Tooltip
            title={t('anr.create_in_branch')}
            placement="right"
          >
            <MenuItem
              component={Link}
              to="/anr/folders/0/new"
              onClick={(event) => handleEditNewFolder(
                event,
                { title: `${item.title} / `, add_to_favorites: item.is_favorite },
              )}
            >
              <ListItemIcon>
                <CreateNewFolder
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t('anr.new')}
              />
            </MenuItem>
          </Tooltip>
        ) : (
          <div>
            <MenuItem
              component={Link}
              to={`/anr/folders/${item.id}/settings`}
            >
              <ListItemIcon>
                <Edit
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t('form.edit')}
              />
            </MenuItem>
            {item.type !== 'mails' && (
              <MenuItem
                component={Link}
                to={`/anr/folders/${item.id}/copy`}
                onClick={(event) => handleEditNewFolder(event, null)}
              >
                <ListItemIcon>
                  <Dns
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('anr.duplicate')}
                />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => handleOpenDeleteConfirmDialog(item)}
            >
              <ListItemIcon>
                <Clear
                  fontSize="small"
                  color="error"
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t('form.delete')}
              />
            </MenuItem>
          </div>
        )
      )}
    </DropdownMenu>
  ));

  const handleToggleChildrenVisibility = () => {
    const shouldHideChildren = !isChildrenVisible;
    setIsChildrenVisible(shouldHideChildren);

    let reducedRoots = JSON.parse(localStorage.getItem('reducedRoots') || '[]');

    if (shouldHideChildren) {
      reducedRoots = _.filter(reducedRoots, (rootName) => rootName !== rootTitle);
    } else {
      reducedRoots.push(rootTitle);
    }
    localStorage.setItem('reducedRoots', JSON.stringify(reducedRoots));
  };

  return (
    item.is_root ? (
      <ListItem
        id={item.id}
        sx={{
          py: 0.75,
          pl: 1.5,
          pr: 0,
          display: 'block',
        }}
      >
        <Box
          mr={1.5}
          display="flex"
          alignItems="center"
          onClick={canCollapseRoot ? handleToggleChildrenVisibility : null}
          className={canCollapseRoot ? 'cursorPointer' : ''}
        >
          <ListItemIcon
            sx={{
              mr: 1,
            }}
          >
            {isChildrenVisible ? <ExpandMore /> : <ChevronRight />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={itemTitle}
          />
          <ItemDropdown />
        </Box>
        {item.children && (
          <Collapse
            in={isChildrenVisible}
            sx={{
              ml: 2.5,
            }}
          >
            <List dense className={classes.noPadding} key={item.id}>
              {_.map(item.children, (child) => (
                <FolderItem
                  activeItemId={activeItemId}
                  canCollapseRoot={canCollapseRoot}
                  canCreateFolders={canCreateFolders}
                  currentItemRef={currentItemRef}
                  handleEditNewFolder={handleEditNewFolder}
                  handleOpenDeleteConfirmDialog={handleOpenDeleteConfirmDialog}
                  handleToggleFavoriteStatus={handleToggleFavoriteStatus}
                  searchFolders={searchFolders}
                  item={child}
                  key={child.id}
                  levelSearch={setLevelSearch}
                  levelTree={levelTree + 1}
                  allHighlight={setAllHighlight}
                  completeRoot={setCompleteRoot}
                  sx={{
                    ml: 4,
                  }}
                />
              ))}
            </List>
          </Collapse>
        )}
      </ListItem>
    ) : (
      <ListItemButton
        id={item.id}
        component={Link}
        to={`/anr/folders/${item.id}/${linkCondition}`}
        selected={item.id === activeItemId}
        ref={item.id === activeItemId ? currentItemRef : _.noop}
        sx={{
          px: 1.5,
          py: 0.75,
          fontWeight: (item.id === activeItemId) ? 500 : 400,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <div>
            {item.is_favorite ? (
              <FolderSpecial sx={{ verticalAlign: 'bottom', mr: 1, color: 'text.neutral' }} />
            ) : (
              <Folder sx={{ verticalAlign: 'bottom', mr: 1, color: 'text.neutral' }} />
            )}
            <span className={classes.title}>{itemTitle}</span>
          </div>
          <ItemDropdown />
        </Box>
      </ListItemButton>
    )
  );
};

FolderItem.propTypes = {
  activeItemId: PropTypes.string,
  canCollapseRoot: PropTypes.bool,
  canCreateFolders: PropTypes.bool,
  currentItemRef: PropTypes.shape(),
  handleEditNewFolder: PropTypes.func,
  handleOpenDeleteConfirmDialog: PropTypes.func,
  handleToggleFavoriteStatus: PropTypes.func,
  searchFolders: PropTypes.string.isRequired,
  item: folderPropType.isRequired,
  levelTree: PropTypes.number,
  levelSearch: PropTypes.number,
  allHighlight: PropTypes.bool,
  completeRoot: PropTypes.bool,
};

FolderItem.defaultProps = {
  activeItemId: null,
  canCollapseRoot: true,
  canCreateFolders: false,
  currentItemRef: {},
  handleEditNewFolder: null,
  handleOpenDeleteConfirmDialog: null,
  handleToggleFavoriteStatus: null,
  levelTree: 0,
  levelSearch: 0,
  allHighlight: false,
  completeRoot: false,
};

export default FolderItem;
