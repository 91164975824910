import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Fab,
  Stack,
  Tooltip,
  Zoom,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AddShoppingCart,
  DeleteForever,
  Edit,
  ForwardToInbox,
  MailOutlined,
  Summarize,
  TextRotateVertical,
  Wysiwyg,
} from '@mui/icons-material';
import { Document, Packer, Paragraph } from 'docx';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { setSingleItem } from 'generic/core/selection/actions';
import {
  openDialogAddToCart,
  openDialogAddToNewsletter,
  openDialogSendToFriend,
  openSidenavSummarize,
} from 'generic/core/actions/actions';
import { setMultiple, types } from 'generic/core/ged/actions';
import { setBackPageLocation } from 'generic/core/ux/actions';
import { cleanupResultsComplete } from 'generic/core/search/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { overrideRessource } from 'generic/utils/utils';
import { formatTime } from 'generic/utils/dateUtils';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const ResultSingleActions = ({
  canAddToCart,
  canAddToNewsletter,
  canEditInGED,
  canFastEditInGed,
  canDeleteDocument,
  canSendToFriend,
  canSummarize,
  document: doc,
  handleOpenFastGed,
  visible,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    transcriptionJSON, attachedFile,
  } = useDocumentFields(doc);
  const zoomProps = {
    in: visible,
    timeout: 150,
    unmountOnExit: true,
  };
  const fabProps = {
    size: 'extraSmall',
    color: 'secondary',
  };

  const handleOpenAddToCartSingleItem = () => {
    dispatch(setSingleItem(doc));
    dispatch(openDialogAddToCart());
  };

  const handleOpenAddToNewsletterSingleItem = () => {
    dispatch(setSingleItem(doc));
    dispatch(openDialogAddToNewsletter());
  };

  const handleOpenSendToFriendSingleItem = () => {
    dispatch(setSingleItem(doc));
    dispatch(openDialogSendToFriend());
  };

  const handleSummarizeSingleItem = () => {
    dispatch(setSingleItem(doc));
    dispatch(openSidenavSummarize());
  };

  const handleOpenGed = () => {
    dispatch(setMultiple(false));
    dispatch(setBackPageLocation(location));
    dispatch(cleanupResultsComplete());
    history.push(`/ged/${doc.base}/${encodeURIComponent(doc.idext)}`);
  };

  const handleOpenDeleteSingleArticleDialog = () => {
    dispatch(setSingleItem(doc));
    dispatch(setConfirmDialogData({
      title: t('actions.delete'),
      textButton: 'form.delete',
      message: (
        <Fragment>
          {t('ged.delete_documents', { count: 1 })}
        </Fragment>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_DOCUMENTS_SUCCESS, types.DELETE_DOCUMENTS_ERROR],
      action: {
        type: types.DELETE_DOCUMENTS,
        refresh: true,
      },
    }));
  };

  const handleDownloadTranscriptionAsWord = () => {
    Packer.toBlob(
      new Document({
        sections: [
          {
            children: _.flatMap(
              transcriptionJSON,
              (sentence, index) => ([
                new Paragraph({
                  text: String(index + 1),
                }),
                new Paragraph({
                  // eslint-disable-next-line max-len
                  text: `${formatTime(sentence.start * 1000, true, true)} --> ${formatTime(sentence.end * 1000, true, true)}`,
                }),
                new Paragraph({
                  text: `${sentence.spkid}: ${sentence.sentence.trim()}`,
                }),
                new Paragraph({ children: [] }),
              ]),
            ),
          },
        ],
      }),
    ).then((blob) => {
      let fileName = 'transcription.docx';
      if (attachedFile) {
        fileName = `${attachedFile.substring(0, attachedFile.lastIndexOf('.'))}.docx`;
      }
      saveAs(blob, fileName);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleDownloadTranscriptionAsSRT = () => {
    let content = '';
    _.each(
      transcriptionJSON,
      (sentence, index) => {
        let end;
        if (sentence.end) {
          end = sentence.end;
        } else {
          const lastWordWithStart = _.findLast(sentence.words, (word) => word.start !== null);
          if (lastWordWithStart) {
            end = lastWordWithStart.start + 0.1;
          }
        }
        if (end) {
          content = `${content}${index + 1}\n`;
          content = `${content}${formatTime(sentence.start * 1000, true, true)}`;
          content = `${content} --> `;
          content = `${content}${formatTime(end * 1000, true, true)}\n`;
          content = `${content}${sentence.spkid}: ${sentence.sentence.trim()}\n\n`;
        }
      },
    );
    let fileName = 'transcription.srt';
    if (attachedFile) {
      fileName = `${attachedFile.substring(0, attachedFile.lastIndexOf('.'))}.srt`;
    }
    saveAs(new Blob([content]), fileName);
  };

  if (!visible) return null;

  const actions = [];

  const createButton = (coeff, title, onClick, color, children) => {
    const msDelay = 35;
    const transitionDelay = `${coeff * msDelay}ms`;

    return (
      <Tooltip
        key={`single_action_${coeff}`}
        title={title}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay,
          }}
        >
          <Fab
            {...fabProps}
            color={color}
            onClick={onClick}
          >
            {children}
          </Fab>
        </Zoom>
      </Tooltip>
    );
  };

  if (canAddToCart) {
    const coeff = actions.length + 1;
    const title = t('actions.add_to_cart');
    const onClick = handleOpenAddToCartSingleItem;
    const children = <AddShoppingCart />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canAddToNewsletter) {
    const coeff = actions.length + 1;
    const title = t('actions.add_to_newsletter');
    const onClick = handleOpenAddToNewsletterSingleItem;
    const children = <ForwardToInbox />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canSendToFriend) {
    const coeff = actions.length + 1;
    const title = t('actions.send_to_friend');
    const onClick = handleOpenSendToFriendSingleItem;
    const children = <MailOutlined />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canSummarize) {
    const coeff = actions.length + 1;
    const title = t('actions.summarize');
    const onClick = handleSummarizeSingleItem;
    const children = <Summarize />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canEditInGED) {
    const coeff = actions.length + 1;
    const title = t('form.edit');
    const onClick = handleOpenGed;
    const children = <Edit />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canFastEditInGed) {
    const coeff = actions.length + 1;
    const title = t('form.edit_fast');
    const onClick = handleOpenFastGed;
    const children = <Wysiwyg />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (!_.isEmpty(transcriptionJSON)) {
    const coeff = actions.length + 1;
    const title = t('actions.download_transcription');
    const onClick = handleDownloadTranscriptionAsWord;
    const children = <TextRotateVertical />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canDeleteDocument) {
    const coeff = actions.length + 1;
    const title = t('actions.delete');
    const onClick = handleOpenDeleteSingleArticleDialog;
    const children = <DeleteForever />;
    actions.push(createButton(coeff, title, onClick, 'warning', children));
  }

  return (
    <Stack
      spacing={0.5}
      direction="row"
      component="span"
    >
      {actions}
    </Stack>
  );
};

ResultSingleActions.propTypes = {
  canAddToCart: PropTypes.bool.isRequired,
  canAddToNewsletter: PropTypes.bool.isRequired,
  canEditInGED: PropTypes.bool.isRequired,
  canFastEditInGed: PropTypes.bool.isRequired,
  canDeleteDocument: PropTypes.bool.isRequired,
  canSendToFriend: PropTypes.bool.isRequired,
  canSummarize: PropTypes.bool.isRequired,
  document: PropTypes.shape(),
  handleOpenFastGed: PropTypes.func,
  visible: PropTypes.bool,
};
ResultSingleActions.defaultProps = {
  document: {},
  handleOpenFastGed: null,
  visible: false,
};

const Memoized = React.memo(ResultSingleActions);
export default Memoized;
