import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      column: {
        borderRadius: 3,
      },
      series: {
        animation: false,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '9px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
    },
    xAxis: {
      type: 'categories',
      gridLineWidth: 0,
      labels: {
        align: 'right',
        style: {
          font: 'normal 9px Verdana, sans-serif',
        },
        maxStaggerLines: 1,
      },
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: '',
      },
    },
    chart: {
      type: 'column',
      defaultSeriesType: 'column',
      zoomType: 'x',
    },
  },
};

const Column = ({ highchartsOptions, containerId }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const chartRef = useRef();
  useEffect(() => {
    let resizeObserver;
    if (containerId) {
      resizeObserver = new ResizeObserver(() => {
        if (chartRef.current) {
          const { chart } = chartRef.current;
          if (!_.isEmpty(chart)) {
            chart.reflow();
          }
        }
      });

      resizeObserver.observe(document.getElementById(containerId));
    }
    return () => {
      if (resizeObserver) {
        const el = document.getElementById(containerId);
        if (el) {
          resizeObserver.unobserve(el);
        } else {
          resizeObserver.disconnect();
        }
      }
    };
  }, [containerId]);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
      ref={chartRef}
    />
  );
};

Column.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  containerId: PropTypes.number,
};

Column.defaultProps = {
  containerId: null,
};

export default Column;
