import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import BodyHeaderContainer from 'generic/containers/anr/BodyHeaderContainer';
import { changeActiveItem, setEditFolder } from 'generic/core/anr/actions';
import SettingsContainer from 'generic/containers/anr/SettingsContainer';
import BanSiteContainer from 'generic/containers/anr/BanSiteContainer';
import ShareContainer from 'generic/containers/anr/ShareContainer';
import AddToCartDialogContainer from 'generic/containers/AddToCartDialogContainer';
import AddToNewsletterDialogContainer from 'generic/containers/AddToNewsletterDialogContainer';
import SendToFriendDialogContainer from 'generic/containers/SendToFriendDialogContainer';
import ANR_CONSTANTS from 'generic/core/anr/constants';
import ResultsContainer from 'generic/containers/anr/ResultsContainer';

const { FOLDER_SETTINGS_VIEWS, SETTINGS_VIEWS } = ANR_CONSTANTS;

const BodyContent = () => {
  const folders = useSelector((state) => state.anr.folders);
  const dispatch = useDispatch();
  const {
    id,
    view,
  } = useParams();
  const showSettings = (_.includes(SETTINGS_VIEWS, view));

  useEffect(() => {
    if (!_.isEmpty(folders) && !_.isEmpty(id)) {
      dispatch(changeActiveItem({ id, view }));
      if (_.includes(FOLDER_SETTINGS_VIEWS, view)) {
        dispatch(setEditFolder(id));
      }
    }
  }, [id, view, folders, dispatch]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow="1"
    >
      <BodyHeaderContainer />
      <Box
        display="flex"
        className="desktopOnlyOverflow"
        flexGrow="1"
      >
        {showSettings ? <SettingsContainer /> : <ResultsContainer />}
      </Box>

      {/* Containers d'actions sur les boutons > en général permettent d'ouvrir une dialog */}
      <BanSiteContainer />
      <ShareContainer />
      <AddToCartDialogContainer scope="anr" />
      <AddToNewsletterDialogContainer scope="anr" />
      <SendToFriendDialogContainer scope="anr" />
    </Box>
  );
};

export default BodyContent;
