import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import UploadFiles from 'generic/components/pages/UploadFiles';
import { cleanFilesToUpload, addFileToUpload, removeFileToUpload } from 'generic/core/files/actions';
import useAuthorizedExtensions from 'generic/core/hooks/useAuthorizedExtensions';

const UploadFilesContainer = ({
  filesToUpload,
  accept,
  ...props
}) => {
  const dispatch = useDispatch();

  // Cleanup des fichiers lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanFilesToUpload())
  ), [dispatch]);

  const handleFileChange = (target) => {
    if (target.files && target.files.length > 0) {
      dispatch(addFileToUpload([...target.files]));
    }
  };

  const handleRemoveFile = (file) => {
    dispatch(removeFileToUpload(file));
  };

  const authorizedExtensions = useAuthorizedExtensions().getParametre();
  const finalAccept = accept === null ? authorizedExtensions : accept;

  return (
    <UploadFiles
      {...{
        handleFileChange,
        handleRemoveFile,
        filesToUpload,
        accept: finalAccept,
        ...props,
      }}
    />
  );
};

UploadFilesContainer.propTypes = {
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  accept: PropTypes.string,
};

UploadFilesContainer.defaultProps = {
  filesToUpload: [],
  accept: null,
};

export default UploadFilesContainer;
