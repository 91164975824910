import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import { Button } from '@mui/material';

const SortableItem = ({ id, wrapperStyle, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: isDragging ? 'rgba(255, 255, 255, 0.25)' : 'inherit',
    opacity: isDragging ? '.85' : undefined,
    position: 'relative',
    // permet de faire en sorte que l'élement draggable soit toujours au dessus des autres éléments draggables
    zIndex: isDragging ? 11 : 10,
    display: 'flex',
    flexFlow: 'row',
    ...wrapperStyle,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Button
        color="secondary"
        aria-label="drag"
        size="small"
        variant="text"
        sx={{ cursor: 'grab', '&:active': { cursor: 'grabbing' } }}
        {...listeners}
      >
        <DragIndicator />
      </Button>
      {children}
    </div>
  );
};

SortableItem.propTypes = {
  id: PropTypes.number.isRequired,
  wrapperStyle: PropTypes.shape(),
  children: PropTypes.node.isRequired,
};

SortableItem.defaultProps = {
  wrapperStyle: {},
};

export default SortableItem;
