import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { cleanupAnRUser, fetchAnRUser } from 'generic/core/anr/actions';
import SourcesContainer from 'generic/containers/anr/SourcesContainer';
import BannedSitesContainer from 'generic/containers/anr/BannedSitesContainer';
import FoldersWrapper from 'generic/containers/anr/FoldersWrapper';
import GlobalLoading from 'generic/components/pages/GlobalLoading';

const RoutesWrapper = () => {
  const loading = useSelector((state) => state.anr.loading);
  const user = useSelector((state) => state.anr.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnRUser());
    return () => dispatch(cleanupAnRUser());
  }, [dispatch]);

  if (_.isEmpty(user)) {
    if (loading) {
      return <GlobalLoading />;
    }
    return null;
  }

  return (
    <Switch>
      <Route
        path={['/anr/folders/:id?/:view?/:scroll?']}
        component={FoldersWrapper}
      />
      <Route
        exact
        path={['/anr/sources']}
        component={SourcesContainer}
      />
      <Route
        exact
        path={['/anr/banned']}
        component={BannedSitesContainer}
      />
      <Redirect
        to="/anr/folders"
      />
    </Switch>
  );
};

export default RoutesWrapper;
