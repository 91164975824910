import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  TextField,
} from '@mui/material';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { useTranslation } from 'react-i18next';
import { format } from 'generic/utils/dateUtils';

const FormikDatePicker = ({ textFieldProps, ...rest }) => {
  const { t } = useTranslation();
  const {
    field,
    form,
    minDate,
    maxDate,
  } = rest;

  return (
    <DatePicker
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          {..._.omit(textFieldProps, ['inputProps'])}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps.inputProps,
          }}
          // Le helperText n'affiche l'erreur que si elle existe dans formik,
          // sinon il affiche le helperText passé dans les textFieldProps,
          // si il existe
          helperText={
            !_.isEmpty(form.errors[field.name]) ? (
              form.errors[field.name]
            ) : (
              _.get(textFieldProps, 'helperText', null)
            )
          }
        />
      )}
      onChange={(date) => form.setFieldValue(field.name, date, false)}
      onError={(error) => {
        // Le onError récupère en paramètre la "raison" de l'erreur,
        // on la teste donc pour setter la bonne erreur dans formik
        // et ainsi afficher le message qui va bien en fonction de
        // l'erreur
        switch (error) {
          case 'disablePast':
            form.setFieldError(
              field.name,
              t('form.errors.no_past_dates'),
            );
            break;
          case 'disableFuture':
            form.setFieldError(
              field.name,
              t('form.errors.no_future_dates'),
            );
            break;
          case 'minDate':
            form.setFieldError(
              field.name,
              `${t('form.errors.min_date_message')} ${format(minDate)}`,
            );
            break;
          case 'maxDate':
            form.setFieldError(
              field.name,
              `${t('form.errors.max_date_message')} ${format(maxDate)}`,
            );
            break;
          case 'invalidDate':
            form.setFieldError(
              field.name,
              t('form.errors.invalid_date'),
            );
            break;
          default:
            // Si on passe ici c'est qu'à priori on est dans le cas d'un
            // "y a pu d'erreur" et error vaut null, on vire alors l'erreur
            // dans formik
            form.setErrors(
              _.omit(
                { ...form.errors },
                [field.name],
              ),
            );
        }
      }}
    />
  );
};

FormikDatePicker.propTypes = {
  textFieldProps: PropTypes.shape({
    label: PropTypes.string,
    helperText: PropTypes.string,
    inputProps: PropTypes.shape(),
  }).isRequired,
};

export default FormikDatePicker;
