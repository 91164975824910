import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import DropdownMenu from 'generic/components/ui/DropdownMenu';

const ResponsiveButtonGroupToMenu = ({
  dropdownBtnTag,
  dropdownBtnChildren,
  dropdownBtnProps,
  shrink,
  items,
  variant,
}) => {
  const buttons = _.map(items, (item, i) => {
    const {
      title, loading, tag, icon: Icon, ...rest
    } = item;
    if (shrink) {
      return (
        <MenuItem key={`menuBtn${i}`} {...rest}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>

          <ListItemText primary={title} />
        </MenuItem>
      );
    }

    return (
      <TooltipButton
        key={`menuBtn${i}`}
        {...{
          title, loading, tag, ...rest,
        }}
        sx={{ borderColor: 'primary' }}
      >
        <Icon />
      </TooltipButton>
    );
  });

  return (
    <Fragment>
      {shrink && (
        <DropdownMenu
          btnTag={dropdownBtnTag}
          btnChildren={dropdownBtnChildren}
          btnProps={dropdownBtnProps}
        >
          {buttons}
        </DropdownMenu>
      )}
      {!shrink && (
        <ButtonGroup
          sx={{ marginLeft: 1 }}
          variant={variant}
          disableElevation
        >
          {buttons}
        </ButtonGroup>
      )}
    </Fragment>
  );
};

ResponsiveButtonGroupToMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.elementType,
    title: PropTypes.string,
    loading: PropTypes.bool,
    tag: PropTypes.oneOf([
      'button',
      'iconButton',
      'loadingButton',
      'fab',
    ]),
  })).isRequired,
  dropdownBtnChildren: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  dropdownBtnProps: PropTypes.shape(),
  dropdownBtnTag: PropTypes.string,
  shrink: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

ResponsiveButtonGroupToMenu.defaultProps = {
  dropdownBtnChildren: <MoreVert size="small" />,
  dropdownBtnProps: {},
  dropdownBtnTag: 'iconButton',
  shrink: false,
  variant: 'contained',
};

export default ResponsiveButtonGroupToMenu;
