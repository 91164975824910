import React from 'react';
import UserMailingListDialog from 'generic/components/dialogs/UserMailingListDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeUSerMailingListDialog,
  createUserMailingList,
} from 'generic/core/mailingLists/actions';
import _ from 'lodash';

const UserMailingListDialogContainer = () => {
  const mailingLists = useSelector((state) => state.mailingLists.mailingLists);
  const { loading, open: openDialog } = useSelector((state) => state.mailingLists.dialogUserMailingList);

  const dispatch = useDispatch();

  const choiceList = _.map(_.orderBy(mailingLists, ['libelle'], ['asc']), (list) => ({
    id: list.listeDiffusion,
    libelle: list.libelle,
  }));

  const handleCloseDialog = () => {
    dispatch(closeUSerMailingListDialog());
  };

  const handleSubmit = (formValues) => {
    const ids = _.map(formValues.listeDiffusion, 'id');
    dispatch(createUserMailingList({ ids, email: formValues.email }, true));
  };

  if (!openDialog) {
    return null;
  }
  return (
    <UserMailingListDialog
      choiceList={choiceList}
      loading={loading}
      openDialog={openDialog}
      handleSubmit={handleSubmit}
      handleCloseDialog={handleCloseDialog}
    />
  );
};

export default UserMailingListDialogContainer;
