import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    chart: {
      polar: true,
    },
    credits: { enabled: false },
    title: { text: 'Titre Polar' },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.',
      },
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
    },
    xAxis: {
      lineWidth: 0,
      categories: [],
      tickmarkPlacement: 'on',
    },
    yAxis: {
      gridLineColor: 'rgba(164, 177, 205, 0.2)',
      alternateGridColor: 'rgba(164, 177, 205, 0.05)',
      gridLineInterpolation: 'polygon',
      allowDecimals: false,
      lineWidth: 0,
      endOnTick: false,
      labels: {
        enabled: false,
      },
    },
    series: [{
      showInLegend: false,
      type: 'line',
      name: 'Line',
      data: [],
    }],
  },
};

const Polar = ({ highchartsOptions, containerId }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const chartRef = useRef();
  useEffect(() => {
    let resizeObserver;
    if (containerId) {
      resizeObserver = new ResizeObserver(() => {
        if (chartRef.current) {
          const { chart } = chartRef.current;
          if (!_.isEmpty(chart)) {
            chart.reflow();
          }
        }
      });

      resizeObserver.observe(document.getElementById(containerId));
    }
    return () => {
      if (resizeObserver) {
        const el = document.getElementById(containerId);
        if (el) {
          resizeObserver.unobserve(el);
        } else {
          resizeObserver.disconnect();
        }
      }
    };
  }, [containerId]);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
      ref={chartRef}
    />
  );
};

Polar.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  containerId: PropTypes.number,
};

Polar.defaultProps = {
  containerId: null,
};

export default Polar;
