import React from 'react';
import { useSelector } from 'react-redux';
import DashboardResults from 'generic/components/ui/DashboardResults';

const DashboardResultsContainer = () => {
  const nbResults = useSelector((state) => state.search.results.nbresults);

  return (
    <DashboardResults nbResults={nbResults} />
  );
};

export default DashboardResultsContainer;
