export const types = {
  SEND_TO_FRIEND_BUILD: '@SEND_TO_FRIENDS/SEND_TO_FRIEND_BUILD',
  SEND_TO_FRIEND_BUILD_SUCCESS: '@SEND_TO_FRIENDS/SEND_TO_FRIEND_BUILD_SUCCESS',
  SEND_TO_FRIEND_BUILD_ERROR: '@SEND_TO_FRIENDS/SEND_TO_FRIEND_BUILD_ERROR',
  SEND_TO_FRIEND_SUBMIT: '@SEND_TO_FRIENDS/SEND_TO_FRIEND_SUBMIT',
  SEND_TO_FRIEND_SUBMIT_SUCCESS: '@SEND_TO_FRIENDS/SEND_TO_FRIEND_SUBMIT_SUCCESS',
  SEND_TO_FRIEND_SUBMIT_ERROR: '@SEND_TO_FRIENDS/SEND_TO_FRIEND_SUBMIT_ERROR',
};

export const buildSendToFriend = (scope, options) => ({
  type: types.SEND_TO_FRIEND_BUILD,
  scope,
  options,
});

export const buildSendToFriendSuccess = (data) => ({
  type: types.SEND_TO_FRIEND_BUILD_SUCCESS,
  data,
});

export const buildSendToFriendError = (response) => ({
  type: types.SEND_TO_FRIEND_BUILD_ERROR,
  response,
});

export const submitSendToFriend = (params) => ({
  type: types.SEND_TO_FRIEND_SUBMIT,
  params,
});

export const submitSendToFriendSuccess = () => ({
  type: types.SEND_TO_FRIEND_SUBMIT_SUCCESS,
});

export const submitSendToFriendError = (response) => ({
  type: types.SEND_TO_FRIEND_SUBMIT_ERROR,
  response,
});
