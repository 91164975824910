export const subRegionIdToName = {
  142: 'Asia',
  53: 'Australia and New Zealand',
  143: 'Central Asia',
  30: 'Eastern Asia',
  151: 'Eastern Europe',
  419: 'Latin America and the Caribbean',
  54: 'Melanesia',
  57: 'Micronesia',
  15: 'Northern Africa',
  21: 'Northern America',
  154: 'Northern Europe',
  61: 'Polynesia',
  35: 'South-eastern Asia',
  34: 'Southern Asia',
  39: 'Southern Europe',
  202: 'Sub-Saharan Africa',
  145: 'Western Asia',
  155: 'Western Europe',
};

export const alpha2ToSubRegionId = {
  AF: 34,
  AX: 154,
  AL: 39,
  DZ: 15,
  AS: 61,
  AD: 39,
  AO: 202,
  AI: 419,
  AG: 419,
  AR: 419,
  AM: 145,
  AW: 419,
  AU: 53,
  AT: 155,
  AZ: 145,
  BS: 419,
  BH: 145,
  BD: 34,
  BB: 419,
  BY: 151,
  BE: 155,
  BZ: 419,
  BJ: 202,
  BM: 21,
  BT: 34,
  BO: 419,
  BQ: 419,
  BA: 39,
  BW: 202,
  BV: 419,
  BR: 419,
  IO: 202,
  BN: 35,
  BG: 151,
  BF: 202,
  BI: 202,
  CV: 202,
  KH: 35,
  CM: 202,
  CA: 21,
  KY: 419,
  CF: 202,
  TD: 202,
  CL: 419,
  CN: 30,
  CX: 53,
  CC: 53,
  CO: 419,
  KM: 202,
  CG: 202,
  CD: 202,
  CK: 61,
  CR: 419,
  CI: 202,
  HR: 39,
  CU: 419,
  CW: 419,
  CY: 145,
  CZ: 151,
  DK: 154,
  DJ: 202,
  DM: 419,
  DO: 419,
  EC: 419,
  EG: 15,
  SV: 419,
  GQ: 202,
  ER: 202,
  EE: 154,
  SZ: 202,
  ET: 202,
  FK: 419,
  FO: 154,
  FJ: 54,
  FI: 154,
  FR: 155,
  GF: 419,
  PF: 61,
  TF: 202,
  GA: 202,
  GM: 202,
  GE: 145,
  DE: 155,
  GH: 202,
  GI: 39,
  GR: 39,
  GL: 21,
  GD: 419,
  GP: 419,
  GU: 57,
  GT: 419,
  GG: 154,
  GN: 202,
  GW: 202,
  GY: 419,
  HT: 419,
  HM: 53,
  VA: 39,
  HN: 419,
  HK: 30,
  HU: 151,
  IS: 154,
  IN: 34,
  ID: 35,
  IR: 34,
  IQ: 145,
  IE: 154,
  IM: 154,
  IL: 145,
  IT: 39,
  JM: 419,
  JP: 30,
  JE: 154,
  JO: 145,
  KZ: 143,
  KE: 202,
  KI: 57,
  KP: 30,
  KR: 30,
  KW: 145,
  KG: 143,
  LA: 35,
  LV: 154,
  LB: 145,
  LS: 202,
  LR: 202,
  LY: 15,
  LI: 155,
  LT: 154,
  LU: 155,
  MO: 30,
  MG: 202,
  MW: 202,
  MY: 35,
  MV: 34,
  ML: 202,
  MT: 39,
  MH: 57,
  MQ: 419,
  MR: 202,
  MU: 202,
  YT: 202,
  MX: 419,
  FM: 57,
  MD: 151,
  MC: 155,
  MN: 30,
  ME: 39,
  MS: 419,
  MA: 15,
  MZ: 202,
  MM: 35,
  NA: 202,
  NR: 57,
  NP: 34,
  NL: 155,
  NC: 54,
  NZ: 53,
  NI: 419,
  NE: 202,
  NG: 202,
  NU: 61,
  NF: 53,
  MK: 39,
  MP: 57,
  NO: 154,
  OM: 145,
  PK: 34,
  PW: 57,
  PS: 145,
  PA: 419,
  PG: 54,
  PY: 419,
  PE: 419,
  PH: 35,
  PN: 61,
  PL: 151,
  PT: 39,
  PR: 419,
  QA: 145,
  RE: 202,
  RO: 151,
  RU: 151,
  RW: 202,
  BL: 419,
  SH: 202,
  KN: 419,
  LC: 419,
  MF: 419,
  PM: 21,
  VC: 419,
  WS: 61,
  SM: 39,
  ST: 202,
  SA: 145,
  SN: 202,
  RS: 39,
  SC: 202,
  SL: 202,
  SG: 35,
  SX: 419,
  SK: 151,
  SI: 39,
  SB: 54,
  SO: 202,
  ZA: 202,
  GS: 419,
  SS: 202,
  ES: 39,
  LK: 34,
  SD: 15,
  SR: 419,
  SJ: 154,
  SE: 154,
  CH: 155,
  SY: 145,
  TW: 30,
  TJ: 143,
  TZ: 202,
  TH: 35,
  TL: 35,
  TG: 202,
  TK: 61,
  TO: 61,
  TT: 419,
  TN: 15,
  TR: 145,
  TM: 143,
  TC: 419,
  TV: 61,
  UG: 202,
  UA: 151,
  AE: 145,
  GB: 154,
  US: 21,
  UM: 57,
  UY: 419,
  UZ: 143,
  VU: 54,
  VE: 419,
  VN: 35,
  VG: 419,
  VI: 419,
  WF: 61,
  EH: 15,
  YE: 145,
  ZM: 202,
  ZW: 202,
};
