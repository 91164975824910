export const FETCH_ERROR = '@AUTH/FETCH_ERROR';

export const LOGON = '@AUTH/LOGON';
export const LOGON_ERROR = '@AUTH/LOGON_ERROR';
export const LOGON_SUCCESS = '@AUTH/LOGON_SUCCESS';

export const LOGOUT = '@AUTH/LOGOUT';
export const LOGOUT_ERROR = '@AUTH/LOGOUT_ERROR';
export const LOGOUT_SUCCESS = '@AUTH/LOGOUT_SUCCESS';

export const RESET_PASSWORD = '@AUTH/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '@AUTH/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = '@AUTH/RESET_PASSWORD_ERROR';

export const RESET_PASSWORD_CONFIRMATION = '@AUTH/RESET_PASSWORD_CONFIRMATION';
export const RESET_PASSWORD_CONFIRMATION_SUCCESS = '@AUTH/RESET_PASSWORD_CONFIRMATION_SUCCESS';
export const RESET_PASSWORD_CONFIRMATION_ERROR = '@AUTH/RESET_PASSWORD_CONFIRMATION_ERROR';

export const RESTORE_STATE_FROM_STORAGE = '@AUTH/RESTORE_STATE_FROM_STORAGE';
export const RESTORE_STATE_FROM_STORAGE_FAILURE = '@AUTH/RESTORE_STATE_FROM_STORAGE_FAILURE';

export const SAVE_PROFILE = '@AUTH/SAVE_PROFILE';
export const SAVE_PROFILE_SUCCESS = '@AUTH/SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_ERROR = '@AUTH/SAVE_PROFILE_ERROR';

export const UPLOAD_USER_IMAGE = '@AUTH/UPLOAD_USER_IMAGE';
export const UPLOAD_USER_IMAGE_SUCCESS = '@AUTH/UPLOAD_USER_IMAGE_SUCCESS';
export const UPLOAD_USER_IMAGE_ERROR = '@AUTH/UPLOAD_USER_IMAGE_ERROR';
