import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Add, Remove } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const TextTruncator = React.memo(({
  buttonSize,
  length,
  text,
}) => {
  const isTooLong = text.length >= length;
  const [showFullText, setShowFullText] = useState(false);

  useEffect(() => {
    setShowFullText(!isTooLong);
  }, [isTooLong, text]);

  let displayedText = text;
  if (!showFullText && isTooLong) {
    displayedText = _.truncate(text, {
      length,
      separator: /,? +/,
    });
  }
  return (
    <Fragment>
      {displayedText}
      {isTooLong && (
        <IconButton
          color="primary"
          component="span"
          sx={{ ml: '4px', p: '0' }}
          size={buttonSize}
          onClick={() => setShowFullText(!showFullText)}
        >
          {showFullText ? <Remove fontSize="inherit" /> : <Add fontSize="inherit" />}
        </IconButton>
      )}
    </Fragment>
  );
});

TextTruncator.propTypes = {
  buttonSize: PropTypes.oneOf(['small', 'medium', 'large']),
  length: PropTypes.number,
  text: PropTypes.string,
};

TextTruncator.defaultProps = {
  buttonSize: 'small',
  length: 250,
  text: '',
};

export default TextTruncator;
