import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
} from '@mui/material';
import {
  AddShoppingCart,
  ArrowDropDown,
  CloudDownloadOutlined,
  DeleteOutlined,
  ForwardToInbox,
  MailOutlined,
} from '@mui/icons-material';
import ResponsiveButtonGroupToMenu from 'generic/components/ui/ResponsiveButtonGroupToMenu';

const BulkArticlesActions = ({
  collectLoading,
  handleOpenAddToCartDialog,
  handleOpenAddToNewsletterDialog,
  handleOpenSendToFriend,
  handleCollectCheckedItems,
  handleOpenDeleteArticlesDialog,
  handleToggleCheckboxAll,
  hasAllArticlesInListChecked,
  hasCheckedItems,
  hasSomeArticlesInListChecked,
  hasUnvalidatedChecked,
  loading,
  shrinkMenu,
}) => {
  const { t } = useTranslation();
  const btnProps = {
    color: 'secondary',
    size: 'small',
  };

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <Checkbox
        sx={{ margin: 0, padding: '4px 4px 4px 5px' }}
        disabled={loading}
        checked={hasAllArticlesInListChecked}
        indeterminate={hasSomeArticlesInListChecked && !hasAllArticlesInListChecked}
        onChange={handleToggleCheckboxAll}
      />

      <ResponsiveButtonGroupToMenu
        shrink={shrinkMenu}
        dropdownBtnTag="button"
        dropdownBtnChildren={t('actions.actions')}
        dropdownBtnProps={{
          color: 'secondary',
          endIcon: <ArrowDropDown />,
          disabled: !hasCheckedItems,
        }}
        items={[
          {
            ...btnProps,
            disabled: loading || !hasUnvalidatedChecked || collectLoading,
            icon: CloudDownloadOutlined,
            loading: collectLoading,
            onClick: handleCollectCheckedItems,
            tag: 'loadingButton',
            title: t('anr.actions.collect'),
          },
          {
            ...btnProps,
            disabled: loading || !hasCheckedItems || collectLoading,
            icon: AddShoppingCart,
            onClick: handleOpenAddToCartDialog,
            title: t('actions.add_to_cart'),
          },
          {
            ...btnProps,
            disabled: loading || !hasCheckedItems || collectLoading,
            icon: ForwardToInbox,
            onClick: handleOpenAddToNewsletterDialog,
            title: t('actions.add_to_newsletter'),
          },
          {
            ...btnProps,
            disabled: loading || !hasCheckedItems,
            icon: MailOutlined,
            onClick: handleOpenSendToFriend,
            title: t('actions.send_to_friend'),
          },
          {
            ...btnProps,
            disabled: loading || !hasCheckedItems || collectLoading,
            icon: DeleteOutlined,
            onClick: handleOpenDeleteArticlesDialog,
            title: t('anr.actions.delete'),
          },
        ]}
      />
    </Box>
  );
};

BulkArticlesActions.propTypes = {
  collectLoading: PropTypes.bool,
  handleOpenAddToCartDialog: PropTypes.func.isRequired,
  handleOpenAddToNewsletterDialog: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleCollectCheckedItems: PropTypes.func.isRequired,
  handleOpenDeleteArticlesDialog: PropTypes.func.isRequired,
  handleToggleCheckboxAll: PropTypes.func.isRequired,
  hasAllArticlesInListChecked: PropTypes.bool,
  hasCheckedItems: PropTypes.bool,
  hasSomeArticlesInListChecked: PropTypes.bool,
  hasUnvalidatedChecked: PropTypes.bool,
  loading: PropTypes.bool,
  shrinkMenu: PropTypes.bool,
};

BulkArticlesActions.defaultProps = {
  collectLoading: false,
  hasAllArticlesInListChecked: false,
  hasCheckedItems: false,
  hasSomeArticlesInListChecked: false,
  hasUnvalidatedChecked: false,
  loading: false,
  shrinkMenu: false,
};

export default BulkArticlesActions;
