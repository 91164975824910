import { types } from 'generic/core/summarize/actions';

const summarizeReducer = (
  state = {
    summary: '',
    building: false,
  },
  action,
) => {
  switch (action.type) {
    case types.SUMMARIZE: {
      return {
        ...state,
        building: true,
      };
    }
    case types.SUMMARIZE_SUCCESS: {
      return {
        ...state,
        building: false,
        summary: action.summary,
      };
    }
    case types.SUMMARIZE_ERROR: {
      return {
        ...state,
        building: false,
        summary: '',
      };
    }
    default:
      return state;
  }
};

export default summarizeReducer;
