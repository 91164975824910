import {
  get,
  del,
  patch,
  put,
  post,
} from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const fetchSchedules = ({ ...rest }) => get({ path: '/schedule', ...rest }).then(handleComplete);

const fetchSchedule = (ids) => {
  const sIds = Array.isArray(ids) ? ids.join(',') : ids;
  return get({ path: `/schedule/${sIds}?format=1` }).then(handleComplete);
};

const deleteSchedules = (ids) => {
  const sIds = Array.isArray(ids) ? ids.join(',') : ids;
  return del({ path: `/schedule/${sIds}` }).then(handleComplete);
};

const saveSchedule = ({ ...rest }) => put({ path: '/schedule', ...rest }).then(handleComplete);

const updateSchedule = ({ ...rest }) => patch({ path: '/schedule', ...rest }).then(handleComplete);

const saveScheduleComprehension = ({ ...rest }) => post({ path: '/schedule_comprehension', ...rest })
  .then(handleComplete);

const fetchScheduleExtensions = (ids, options) => {
  const sIds = Array.isArray(ids) ? ids.join(',') : ids;
  return get({ path: `/schedule_extension/${sIds}?format=1`, ...options }).then(handleComplete);
};

export {
  deleteSchedules,
  fetchSchedule,
  fetchScheduleExtensions,
  fetchSchedules,
  saveSchedule,
  saveScheduleComprehension,
  updateSchedule,
};
