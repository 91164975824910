import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  cleanupNewsletter,
  clearUploadNewsletterImage,
  fetchNewsletter,
  resetNewsletterImage,
  saveNewsletter,
  types,
} from 'generic/core/newsletters/actions';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { emailsAsArrayFromAutocomplete } from 'generic/utils/qesUtils';

const Newsletter = lazy(() => import('generic/components/pages/Newsletter'));

const NewsletterContainer = () => {
  const newsletter = useSelector((state) => state.newsletters.editNewsletter);
  const newsletterState = useSelector((state) => state.newsletters.editNewsletterState);
  const themesPanier = useSelector((state) => state.config.themesPanier);

  const dispatch = useDispatch();
  const { id } = useParams();
  const uploadActions = {
    loading: { type: types.UPLOAD_NEWSLETTER_IMAGE },
    success: { type: types.UPLOAD_NEWSLETTER_IMAGE_SUCCESS },
    error: { type: types.UPLOAD_NEWSLETTER_IMAGE_ERROR },
  };

  useEffect(() => {
    if (!_.isEmpty(id)) {
      dispatch(fetchNewsletter(id));
    }
  }, [dispatch, id]);

  // Cleanup du panier lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => function cleanup() {
    dispatch(cleanupNewsletter());
  }, [dispatch]);

  const handleSave = (values) => {
    const params = emailsAsArrayFromAutocomplete(['destinataires'], values);
    dispatch(saveNewsletter(params));
  };

  const handleClearUploadField = () => dispatch(clearUploadNewsletterImage());

  const handleResetUploadField = () => dispatch(resetNewsletterImage());

  if (newsletter.loading) {
    return <GlobalLoading />;
  }

  return (
    <Newsletter
      fileUploading={newsletterState.fileUploading}
      fileUploadName={newsletterState.fileUploadName}
      handleClearUploadField={handleClearUploadField}
      handleResetUploadField={handleResetUploadField}
      newsletter={newsletter}
      onSave={handleSave}
      saveNewsletterLoading={newsletterState.saveNewsletterLoading}
      uploadActions={uploadActions}
      themeOptions={themesPanier}
    />
  );
};

export default NewsletterContainer;
