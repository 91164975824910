import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeShareDialog,
} from 'generic/core/anr/actions';
import ShareDialog from 'generic/components/dialogs/anr/ShareDialog';

const ShareContainer = () => {
  const dialogOpen = useSelector((state) => state.anr.dialogShare.open);
  const article = useSelector((state) => state.anr.dialogShare.article);
  const dispatch = useDispatch();

  const handleOpenSharePopup = (shareUrl) => {
    const size = 700;
    const left = (window.screen.width / 2) - (size / 2);
    const top = (window.screen.height / 2) - (size / 2);
    window.open(
      shareUrl,
      'partage',
      `toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      scrollbars=no,
      resizable=no,
      copyhistory=no,
      width=${size},
      height=${size},
      top=${top},
      left=${left}`,
    );
  };

  if (!dialogOpen) {
    return null;
  }
  return (
    <ShareDialog
      article={article}
      handleClose={() => dispatch(closeShareDialog())}
      handleOpenSharePopup={handleOpenSharePopup}
    />
  );
};

export default ShareContainer;
