import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveWidgets } from 'generic/core/dashboard/actions';
import FormDashboardWidget from 'generic/components/forms/FormDashboardWidget';
import _ from 'lodash';

const EditWidgetDashboardContainer = ({
  widgetParams,
  handleClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dashboard.saveWidgetsLoading);
  const hasSubmitted = useRef(false);

  const handleSubmit = (values) => {
    const alteredValues = { ..._.omit(values, ['width', 'height']) };
    if (alteredValues.aggregates) {
      alteredValues.aggregates = _.map(alteredValues.aggregates.split(','), _.trim);
    } else if (alteredValues.aggregates === '') {
      alteredValues.aggregates = [];
    }
    if (alteredValues.pivots) {
      alteredValues.pivots = _.map(alteredValues.pivots.split(','), _.trim);
    } else if (alteredValues.pivots === '') {
      alteredValues.pivots = [];
    }
    dispatch(saveWidgets({
      json: JSON.stringify(alteredValues),
      dashboard_widget: widgetParams.dashboard_widget,
      dashboard: widgetParams.dashboard,
    }));
    hasSubmitted.current = true;
  };

  useEffect(() => {
    if (hasSubmitted.current === true && !loading) {
      handleClose();
    }
  }, [handleClose, loading]);

  const initialValues = {
    ...JSON.parse(widgetParams.json),
  };
  if (initialValues.aggregates) {
    initialValues.aggregates = widgetParams.aggregates.toString();
  }
  if (initialValues.pivots) {
    initialValues.pivots = widgetParams.pivots.toString();
  }
  return (
    <FormDashboardWidget
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      loading={loading}
      formTitle={`${t('dashboard.edit_widget')} "${widgetParams.title}"`}
    />
  );
};

EditWidgetDashboardContainer.propTypes = {
  widgetParams: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditWidgetDashboardContainer;
