/**
 * QES est rempli de constantes obsolètes, de doublons, de code mort, ...
 * Il serait idiot de copier TOUTES les constantes issue de l'historique QES
 * On ajoutera au fil de l'eau les constantes nécessaires.
 * Pour simplifier l'accès aux informations, merci de préciser en commentaire
 * au dessus de la constante les requêtes SQL qui ont permis de retrouver l'info
 */

import _ from 'lodash';
import { CUSTOM_PATH } from 'generic/core/constants';

const dateIntervalComparator = {
  less_than: 1,
  equal: 2,
  more_than: 3,
  between: 4,
  unknown: 1000,
};

const openUserStatus = 1;

let constants = {
  API_ENTRY_POINT: process.env.REACT_APP_API_ENTRY_POINT,

  // en dur dans le code de qes
  FACET_POSITION_MAPPING: {
    left: 1,
    right: 2,
    undefined: 1000,
  },

  // requête par défaut pour les recherches globales sur la base (dashboard, entrée sur la page de résultat en direct, ...)
  DEFAULT_SEARCH_PER_BASE: {
    default: {
      DATE_141_comp: dateIntervalComparator.less_than,
      DATE_141_from: '01/01/2100',
    },
  },

  UPLOAD_FILE_SETTINGS: {
    UPLOAD_FILE_TYPE: 13, // FILE_UPLOAD_FORMATS.qtaDepositThenProcess
    ENABLE: false,
    BY_GED: false,
    BASE: 22801209,
  },

  // SELECT * FROM Format_saisie;
  INPUT_FORMAT: {
    date_with_comparator: 7,
    number: 13,
    text: 19,
    dropdown_list: 20,
    autocomplete_column_direct_regex: 38,
  },

  // SELECT * FROM Format_document;
  DOCUMENT_FORMAT: {
    COMPLET: 1,
    VEILLE: 3,
    RESUME: 4,
    TITRE: 5,
    FIL: 9,
    NOTIFICATION_AU_SERVEUR: 10,
    NON_PRECISE: 1000,
  },

  // SELECT * FROM Etat_document;
  ETAT_DOCUMENT: {
    NOUVEAU: 1,
    DOUBLON: 2,
    REJETE: 3,
    COLLECTE: 4,
    ERREUR: 5,
    VALIDE: 50,
    PUBLIE: 100,
    SUPPRIMER: 203,
    LIER: 204,
    FUSIONNER: 205,
    COMMENTER: 206,
    DUPLIQUER: 207,
    RANKING: 220,
    DEDOUBLONNER: 230,
    FAVORI: 240,
    NON_PRECISE: 1000,
    A_COMPLETER: 1001,
  },

  // en dur dans le controller du back
  FILE_UPLOAD_FORMATS: {
    csv: 1,
    refman: 2,
    cart: 3,
    newsletter: 3,
    trombi: 4,
    plateform: 5,
    technicalDeposit: 10,
    collection: 11,
    qtaDeposit: 12,
    qtaDepositThenProcess: 13,
  },

  // en dur dans le controller du back
  FILE_RETRIEVE_FORMATS: {
    cart: 0,
    csv: 1,
    pdf: 2,
    pdfWithQESThreadConcat: 3,
    pdfWord: 4,
    googleImaker: 5,
    trombi: 6,
    xmlNotice: 7,
    logsSchedules: 8,
    plateform: 9,
    pathIMR: 10,
    collections: 11,
  },

  // SELECT * FROM Civilite;
  CIVILITY: {
    mrs: 1,
    miss: 2,
    mr: 3,
    unknown: 1000,
  },

  // SELECT * FROM Etat_veille
  // SELECT * FROM Type_veille
  // On ne garde que les typologies intéressantes pour le front
  ALERT: {
    STATUSES: {
      DEFAULT_ACTIVE: 2,
      DEFAULT_INACTIVE: 5,
      ACTIVE: [1, 2],
      INACTIVE: [5],
    },
    TYPES: {
      ALERT: 1,
      REQUEST: 6,
    },
  },

  OPENED_USER_REQUEST: '1',
  CLOSED_USER_REQUEST: '2,3,4',
  OPEN_USER_STATUS: openUserStatus,
  CLOSED_USER_STATUS: 3,

  TYPE_PJ_WITHOUT: '1',

  SCHEDULE: {
    // SELECT * From qwam_common_webmonitor.Etat_schedule;
    STATUSES: {
      DEFAULT_ACTIVE: 1,
      DEFAULT_INACTIVE: 2,
      ACTIVE: [1],
      INACTIVE: [2, 1000],
      ALL: [1, 2, 1000],
    },
    // SELECT * From qwam_common_webmonitor.Mode_rapport;
    MODE_RAPPORT: {
      SYSTEMATIQUE: 1,
      SI_ERREUR: 2,
      SI_TRAITEMENT: 3,
      AUCUN: 1000,
    },
  },

  // SELECT * FROM Raffinage;
  REFINING_STRATEGY: {
    adjacency: 1,
    semantics: 2,
    exact_expression: 10,
    as_is: 1000,
  },

  // SELECT * FROM Comparateur;
  DATE_INTERVAL_COMPARATOR: dateIntervalComparator,

  // SELECT * FROM Visibilite_panier;
  CART_VISIBILITY: {
    private: 1000,
    all: 3,
    collaborative: 1,
    company: 2,
    profile: 4,
  },
  COLLECTION_VISIBILITY: {
    private: 1000,
    public: 2,
    collaborative: 3,
  },
  NEWSLETTER_VISIBILITY: {
    private: 1000,
    all: 3,
    collaborative: 1,
    company: 2,
    profile: 4,
  },

  DEFAULT_USER: {
    civilite: 1,
    email: '',
    logon: '',
    utilisateur: null,
    nom: '',
    prenom: '',
    etat_utilisateur: openUserStatus,
  },

  CRITERES: {
    AND: 1,
    OR: 2,
    NOT: 3,
  },

  /* ---------- PARAMETRES ERGONOMIQUES A OVERRIDER ---------- */
  // Option pour modifier le comportement du clic sur un titre d'article dans le search :
  // 'newTab' > ouvre l'info dans un nouvel onglet (pas de bouton pour ouvrir dans le complet)
  // 'complete' > ouvre le complet
  CLICK_ON_DOCS_TITLE_BEHAVIOR: 'complete',
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: true,
  // Taille en "unité" de Grid MUI pour les colonnes des facettes
  MAX_FACET_COLUMN_WIDTH: 2,
  // Nombre de facettes affichées quand on est en mode "shrinked"
  MAX_FACET_DISPLAYED_WHEN_SHRINKED: 10,

  DEFAULT_WIDGETS_FORMS_PARAMS: {
    pie: {
      facets: 'QES_Company.verbatim',
      aggregates: '',
      monoColors: false,
      tones: false,
      humanizeLangs: false,
      totalunique: false,
    },
    spline: {
      facets: 'PUB_DATE#auto_date_histogram|300 _key:asc',
      aggregates: '',
      width: 6,
    },
    treemap: {
      facets: 'QES_Company.verbatim',
      aggregates: '',
      humanizeDocState: false,
      humanizeLangs: false,
      totalunique: false,
    },
    solidgauge: {
      facets: 'QES_Company.verbatim',
      aggregates: '',
      humanizeDocState: false,
      humanizeLangs: false,
      totalunique: false,
    },
    bar: {
      facets: 'QES_Company.verbatim',
      aggregates: '',
      totalunique: false,
    },
    map: {
      facets: 'QES_CountryTopicCode.verbatim',
      aggregates: '',
      facetmax: 10000,
      facetmax2: 10000,
      totalunique: false,
    },
    documentlist: {
      additionalQuery: 'ETAT:100',
      slice: 25,
      sort: 'DATE_COLLECT Desc',
    },
    sankey: {
      facets: 'QES_Person.verbatim, QES_Company.verbatim',
      aggregates: '',
      facetmax: 5,
      facetmax2: 10,
    },
    wordcloud: {
      facets: 'QES_Company.verbatim',
      aggregates: '',
      facetmax: 50,
      totalunique: false,
    },
    datatable: {
      facets: 'QES_Company.verbatim',
      aggregates: '',
      facetmax: 50,
      totalunique: false,
    },
    relations: {
      facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
      facetmax: 200,
      facetmax2: 5,
      width: 6,
      height: 2,
      withExploration: true,
    },
    cooccurrences: {
      facetmax: 200,
      facetmax2: 5,
      width: 6,
      height: 2,
    },
    sunburst: {
      facets: 'FOLDER_ID.verbatim, SOURCE.verbatim',
      aggregates: '',
      pivots: '',
      list: 1001701270, // Utile uniquement pour un graphe "folder" de Ask'n'Read
    },
  },

  DASHBOARDS_PARAMS_PER_BASE: {
    default: {
      COOCCURRENCES_FIELDS: [
        { name: 'Personnes', value: 'QES_Person.verbatim', active: true },
        { name: 'Sociétés', value: 'QES_Company.verbatim', active: true },
        { name: 'Concepts', value: 'QES_ConceptCategorized.verbatim', active: true },
      ],
    },
  },
  DYNAMIC_DASHBOARD: true,

};

if (CUSTOM_PATH) {
  const isDev = (process.env.NODE_ENV === 'development');
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: customConstants } = require(`custom/${CUSTOM_PATH}/core/qes/constants`);
    if (isDev) {
      console.info(`CUSTOM PATH LOADED : custom/${CUSTOM_PATH}/core/qes/constants`);
    }
    constants = _.merge(constants, customConstants);
    if (!_.isEmpty(customConstants.override_all)) {
      constants = {
        ...constants,
        ...customConstants.override_all,
      };
    }
  } catch (err) {
    _.noop();
  }
}

const QES_CONSTANTS = constants;
export default QES_CONSTANTS;
