import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { FILE_UPLOAD_FORMATS } = QES_CONSTANTS;

const useAuthorizedExtensions = () => useSelector((state) => {
  const extAuthorized = get(state, 'config.settings.extensionsAutorisees');

  const uploadCorrespondence = {
    [FILE_UPLOAD_FORMATS.csv]: 'file_upload_csv',
    [FILE_UPLOAD_FORMATS.refman]: 'file_upload_refman',
    [FILE_UPLOAD_FORMATS.cart]: 'file_upload_panier',
    [FILE_UPLOAD_FORMATS.newsletter]: 'file_upload_panier',
    [FILE_UPLOAD_FORMATS.trombi]: 'file_upload_trombi',
    [FILE_UPLOAD_FORMATS.plateform]: 'file_upload_plateforme',
    [FILE_UPLOAD_FORMATS.collection]: 'file_upload_collection',
    [FILE_UPLOAD_FORMATS.qtaDeposit]: 'file_upload_qta',
    [FILE_UPLOAD_FORMATS.qtaDepositThenProcess]: 'file_upload_qta',
  };

  /**
   * S'assure de transformer le tableau d'exentions autorisées en une string compréhensible par les navigateurs web.
   * @param {string[]} extensions from api
   * @returns {string} une string représentant les formats d'upload autorisées, il s'agit en général de valeurs séparées
   * par des virgules
   */
  const ensureAcceptFormat = (extensions) => {
    const startsWithDotOrContainsMimeTypeRegexp = /^\.|[/\\*]/;
    return extensions
      // eslint-disable-next-line no-bitwise
      .map((ext) => (~ext.search(startsWithDotOrContainsMimeTypeRegexp) ? ext : `.${ext}`))
      .join(', ');
  };

  /**
   * Permet de s'assurer d'avoir une valeur par défaut
   * @param {string[]} extensions le tableau issue des paramètre du back
   * @returns si la tableau d'extensions reçu est vide, on s'assure de restreindre avec les valeurs par défaut du front
   */
  const ifEmptyReturnsFrontDefaults = (extensions) => (isEmpty(extensions) ? ['txt'] : extensions);

  /**
   * Permet récupérer les extensions autorisées générales de l'application
   * @returns {string} une string représentant les formats d'upload autorisées, il s'agit en général de valeurs séparées
   * par des virgules
   */
  const getParametre = () => ensureAcceptFormat(ifEmptyReturnsFrontDefaults(get(extAuthorized, 'parametre', [])));

  /**
   * Permet de trouver, pour un champ de GED en général, les extensions autorisées pour un champ sur une base QES donnée
   * @param {number} base id de base
   * @param {number} baseMisePage id de base mise page concerné, généralement un champ upload de la GED
   * @returns {string} une string représentant les formats d'upload autorisées, il s'agit en général de valeurs séparées
   * par des virgules
   */
  const getBaseMisePage = (base, baseMisePage) => ensureAcceptFormat(
    ifEmptyReturnsFrontDefaults(
      get(extAuthorized, `base_${base}_${baseMisePage}`, []),
    ),
  );

  /**
   * Permet de trouver les extensions autorisées pour un envoi de fichier sur le back
   * @param {FILE_UPLOAD_FORMATS} uploadType il s'agit du type d'upload, il permet de savoir où stocker le fichier
   * @param {number} base certains formats ont besoins de l'id de base et de baseMisePage
   * @param {number} baseMisePage certains formats ont besoins de l'id de base et de baseMisePage
   * @returns {string} une string représentant les formats d'upload autorisées, il s'agit en général de valeurs séparées
   * par des virgules
   */
  const getFileUpload = (uploadType, base = null, baseMisePage = null) => {
    // these types needs base and basemisepage
    const strangeBehaviorTypes = [
      FILE_UPLOAD_FORMATS.technicalDeposit,
    ];

    let res = [];
    if (strangeBehaviorTypes.includes(uploadType) && baseMisePage && base) {
      res = getBaseMisePage(base, baseMisePage);
    } else {
      res = ensureAcceptFormat(ifEmptyReturnsFrontDefaults(get(extAuthorized, uploadCorrespondence[uploadType], [])));
    }

    return res;
  };

  return {
    getParametre,
    getBaseMisePage,
    getFileUpload,
  };
});

/**
 * Renvois des méthodes utilitaires permettant la récupérations des extensions autorisées dans différents use-case.
 */
export default useAuthorizedExtensions;
