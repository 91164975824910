import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  CircularProgress,
  Input,
  TextField,
} from '@mui/material';
import { Autocomplete } from 'formik-mui';
import { matchSorter } from 'match-sorter';
import makeStyles from '@mui/styles/makeStyles';
import { doAutocompleteUser } from 'generic/api/users';

const wordOperators = ['@'];
const limitOperators = [' '];
const operators = _.concat(wordOperators, limitOperators);

const handleRenderOption = (props, option) => {
  const {
    email,
    nom,
    prenom,
    utilisateur,
  } = option;
  const prenomStr = (prenom) ? `${prenom} ` : '';
  const emailStr = (email && email !== nom) ? ` (${email})` : '';

  return (
    <li {...props} key={utilisateur}>
      <div>
        {`${prenomStr}${nom}${emailStr}`}
      </div>
    </li>
  );
};

const handleGetOptionLabel = (userObjectOrEmail) => {
  if (typeof userObjectOrEmail === 'string') return userObjectOrEmail;
  const nom = _.get(userObjectOrEmail, 'nom', '');
  const prenom = _.get(userObjectOrEmail, 'prenom', '');
  return _.compact([prenom, nom]).join(' ');
};

const useStyles = makeStyles(() => ({
  inputRoot: {
    '&::before, &:hover': {
      border: 'none',
    },
    borderRadius: '20px',
    padding: '9px 8px 8px 18px',
    marginLeft: '10px',
  },
  inputInput: {

  },
}));

const AutocompleteAjaxComment = ({
  minChars,
  textFieldProps,
  submitLoading,
  autoFocusInput,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [endValue, setEndValue] = useState('');
  const [loading, setLoading] = useState(open && options.length === 0);
  const { field, form } = rest;
  // useEffect au changement de "endValue" (valeur à la fin de l'input,
  // après le dernier opérateur) :
  // permet de lancer la requête ajax pour récupérer les propositions
  useEffect(() => {
    let active = true;

    if (!endValue || endValue.length < minChars || !endValue.startsWith('@')) {
      setOpen(false);
      return undefined;
    }

    setLoading(true);
    (async () => {
      const values = await doAutocompleteUser({
        uriParams: {
          filtre: `${endValue.substring(1, endValue.length).trim()}`,
        },
      });

      setLoading(false);
      if (active) {
        setOptions(values);
      }
    })();

    return () => {
      active = false;
    };
  }, [minChars, endValue, field.name]);

  // useEffect pour virer les options lorsque le "dropdown" des
  // résultats est fermé
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Fragment>
      <Autocomplete
        {...rest}
        open={open}
        onOpen={(event) => {
          // On ferme le "dropdown" si l'input est vide quand on clique dedans
          if (!_.isEmpty(event.target.value)) {
            setOpen(true);
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        filterOptions={(opts) => (
          // On utilise matchSorter pour trier les résultats et surtout
          // filtrer uniquement sur "endValue"
          matchSorter(
            opts,
            endValue.substring(1, endValue.length).trim(),
            { keys: ['nom', 'logon', 'prenom', 'email'] },
          )
        )}
        value={field.value}
        onChange={(e, value, reason) => {
          // Le onChange de Autocomplete s'execute pour différentes raisons bien
          // précises
          if (reason === 'selectOption') {
            // Si on a choisi une option dans la liste des propositions
            // On met de côté la valeur de "début", c'est à dire tout ce qu'il
            // y a avant le dernier opérateur
            const beginValue = field.value.substring(0, field.value.lastIndexOf(endValue));
            // On ajoute si besoin les guillemets autour de la valeur choisie
            // en fonction de si l'utilisateur a précisé des guillemets lui-même
            // ou non
            const appendValue = !_.isEmpty(beginValue) && beginValue.trim().slice(-1) === '"'
              ? value.logon : `@${value.logon} `;
            // On set la valeur du field dans formik avec la valeur "début" plus
            // l'option finale avec ou sans guillemets autour
            let userTagged = form.values.user_tagged;
            userTagged = !_.isEmpty(userTagged) ? `${userTagged};` : '';
            form.setFieldValue('user_tagged', `${userTagged}${value.utilisateur}`);
            form.setFieldValue(field.name, beginValue + appendValue);
          } else if (reason === 'clear') {
            // Si on a vidé entièrement le champ (via backspace ou la croix en fin
            // d'input), on set la valeur dans formik à vide
            form.setFieldValue(field.name, '');
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.inputRoot,
                input: classes.inputInput,
              },
              endAdornment: (
                <Fragment>
                  {loading || submitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            autoFocus={autoFocusInput}
            onKeyUp={(event) => {
              // Si la valeur n'a pas changé (on a appuyé sur les flèches par
              // exemple, on ne fait rien)
              if (field.value === event.target.value) {
                return;
              }
              // On garde de côté la valeur de l'input
              const inputValue = !_.isEmpty(event.target.value) ? event.target.value : '';
              let index = -1;
              if (!_.isEmpty(inputValue)) {
                // On va regarder si on trouve le filterTrigger dans le texte de l'input
                // l'idée étant de trouver le filterTrigger plus proche de la fin du texte
                // afin de conserver dans le state React le reste du texte (ce qui se trouve
                // après cet opérateur)
                _.some(operators, (operator) => {
                  // Si il s'agit d'un opérateur "mot" (@), on va chercher uniquement
                  // leur présence avec un espaces devant (on ne veut pas couper au milieu
                  // des mots)
                  const operatorString = _.includes(wordOperators, operator) ? ` ${operator}` : operator;
                  // On garde de côté l'index éventuellement trouvé de l'opérateur + sa longueur
                  const searchedIndex = inputValue.lastIndexOf(operatorString);
                  // Si l'opérateur est trouvé dans le texte et qu'il est plus proche
                  // de la fin que les autres opérateurs, on stocke son index
                  index = inputValue.lastIndexOf(operatorString) !== -1
                    && searchedIndex > index ? searchedIndex : index;
                });
              }
              // On met de côté la fin du texte (avec le filterTrigger)
              const finalValue = index > 0 ? (
                inputValue.substring(index, inputValue.length).trim()
              ) : inputValue;
              // On set dans le state React local la valeur "endValue"
              setEndValue(finalValue);
              // on set dans formik la valeur complète du field
              form.setFieldValue(field.name, inputValue);
            }}
          />
        )}
        getOptionLabel={handleGetOptionLabel}
        renderOption={(props, option) => (
          // handleRenderOption sur "endValue" permet de mettre en valeur
          // (en gras par exemple) le texte de fin de l'input (après le
          // dernier opérateur) dans les résultats du dropdown
          handleRenderOption(props, option, endValue)
        )}
      />
      <Input
        name="user_tagged"
        type="hidden"
        sx={{ display: 'none' }}
      />
    </Fragment>
  );
};

AutocompleteAjaxComment.propTypes = {
  minChars: PropTypes.number,
  textFieldProps: PropTypes.shape({
    label: PropTypes.string,
  }),
  submitLoading: PropTypes.bool.isRequired,
  autoFocusInput: PropTypes.bool,
};

AutocompleteAjaxComment.defaultProps = {
  minChars: 2,
  textFieldProps: {},
  autoFocusInput: true,
};

export default AutocompleteAjaxComment;
