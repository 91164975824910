import _ from 'lodash';
import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import { snackActions } from 'generic/utils/snackbar';
import {
  searchFacet,
  getNetworkGraphData,
  searchResult,
  fetchDashboards,
  createWidget,
  patchWidgets,
  deleteWidget,
  deleteDashboard,
  createDashboard,
  patchDashboard,
} from 'generic/api/dashboard';
import QES_CONSTANTS from 'generic/core/qes/constants';
import {
  closeDialogCreateWidget,
  closeDialogEditOrCreateDashboard,
  deleteDashboardError,
  deleteDashboardSuccess,
  deleteWidgetError,
  deleteWidgetSuccess,
  fetchDashboardsError,
  fetchDashboardsSuccess,
  fetchWidgetError,
  fetchWidgetSuccess,
  fetchWidgets,
  saveDashboardError,
  saveDashboardSuccess,
  saveWidgetsError,
  saveWidgetsSuccess,
  stopWidgetLoading,
  types,
} from 'generic/core/dashboard/actions';
import { buildBarStacked, buildPolar, buildSankey } from 'generic/utils/qes2highcharts';
import {
  getTokenFromStorage,
  getLogonFromStorage,
} from 'generic/utils/utils';
import { getActiveBaseDashboardParams } from 'generic/utils/qesUtils';
import { push, replace } from 'connected-react-router';

const { API_ENTRY_POINT } = QES_CONSTANTS;

function* workFetchWidget(widgetId, widget, params, keepAllWidgetsData, exploreFrom) {
  try {
    let results = {};
    const newParams = { ...params };
    if (!_.isEmpty(params.additionalQuery)) {
      newParams.query = `${newParams.query} AND ${newParams.additionalQuery}`;
    }

    if (['relations', 'cooccurrences'].includes(widget.type)) {
      results = yield call(getNetworkGraphData, newParams);
    } else if (widget.type === 'documentlist') {
      results = yield call(searchResult, newParams);
    } else {
      results = yield call(searchFacet, newParams);
    }

    let widgetData = {};
    const series = [];
    let axisXCategories = [];
    let axisYCategories = [];
    const additionalData = {
      facets: widget.facets,
    };
    let { aggregates, pivots } = widget;
    // On tente de build aggregates et pivots si nécessaire
    if (widget.facets) {
      if (Array.isArray(aggregates) && _.isEmpty(aggregates)) {
        const firstFacet = widget.facets.split(',')[0];
        let firstFacetCleaned = _.trim(firstFacet);
        if (firstFacet.indexOf('#') !== -1) {
          firstFacetCleaned = firstFacet.substring(0, firstFacet.indexOf('#'));
        }
        aggregates = [`agg_${firstFacetCleaned}`];
      }
      if (Array.isArray(pivots) && _.isEmpty(pivots)) {
        const secondFacet = widget.facets.split(',')[1];
        let firstFacetCleaned = _.trim(secondFacet);
        if (secondFacet.indexOf('#') !== -1) {
          firstFacetCleaned = secondFacet.substring(0, secondFacet.indexOf('#'));
        }
        pivots = [`agg_${firstFacetCleaned}`];
      }
    }
    if (!_.isEmpty(results)) {
      if (newParams.exportWidget && results.response.fichier) {
        const url = new URL(`${API_ENTRY_POINT}/export`);
        const obj = {
          key: getTokenFromStorage(),
          logon: getLogonFromStorage(),
          fichier: results.response.fichier,
        };
        url.search = new URLSearchParams(obj);
        window.open(url, '_self').focus();
      } else if (widget.type === 'bar_stacked') {
        const result = buildBarStacked(results, aggregates[0], pivots[0]);
        axisXCategories = result.xAxis.categories;
        axisYCategories = result.yAxis.categories;
        _.merge(series, result.series);
      } else if (widget.type === 'documentlist') {
        const elasticResults = _.get(results, 'response.data.docs', []);
        series.push(elasticResults);
      } else {
        const elasticFacets = _.get(results, 'response.facets', {});
        for (let aggregateIndex = 0; aggregateIndex < aggregates.length; aggregateIndex++) {
          const aggregateName = aggregates[aggregateIndex];
          const serieName = _.get(widget, ['seriesNames', aggregateIndex], '');
          const level1Aggregate = elasticFacets[aggregateName];

          let totalUnique;
          if (widget.totalunique) {
            const facetsList = _.map(widget.facets.split(';'), _.trim);
            const uniqueName = `unique_${facetsList[aggregateIndex]}`;
            totalUnique = _.get(elasticFacets, [uniqueName, 'value'], 0);
          }
          let data = [];
          let nodes = [];

          if (widget.type === 'polar') {
            const uniqCatX = new Set();
            _.each(level1Aggregate, (agg1) => {
              uniqCatX.add(agg1.key);
            });
            axisXCategories = [...uniqCatX].sort();
            series.push({
              name: serieName,
              data: buildPolar(results, aggregateName),
            });
          } else if (widget.type === 'sankey') {
            series.push({
              name: serieName,
              data: buildSankey(results, aggregateName),
              keys: ['from', 'to', 'weight'],
            });
          } else if (pivots) {
            for (let pivotIndex = 0; pivotIndex < pivots.length; pivotIndex++) {
              const pivotName = pivots[pivotIndex];
              let point = {};

              switch (widget.type) {
                case 'sunburst': {
                  const rootNode = {
                    id: '0.0',
                    parent: '',
                    name: i18next.t('dashboard.root'),
                  };
                  const level1 = [];
                  const level2 = [];

                  _.each(level1Aggregate, (agg1) => {
                    point = {
                      id: agg1.key,
                      name: agg1.lib,
                      value: agg1.doc_count,
                      parent: rootNode.id,
                    };
                    level1.push(point);

                    _.each(agg1[pivotName]?.buckets, (agg2) => {
                      point = {
                        name: agg2.key,
                        value: agg2.doc_count,
                        parent: agg1.key,
                      };

                      level2.push(point);
                    });
                  });

                  series.push({
                    data: [
                      rootNode,
                      ...level1,
                      ...level2,
                    ],
                  });
                  break;
                }

                case 'scatter3d': {
                  const seriesData = [];
                  _.each(level1Aggregate, (agg1) => {
                    _.each(agg1[pivotName].buckets, (agg2) => {
                      point = [
                        agg2.key,
                        agg2.doc_count,
                        agg1.key,
                      ];
                      seriesData.push(point);
                    });
                  });

                  series.push({
                    custom: seriesData.reduce((acc, cur) => ({
                      minMaxX: [
                        Math.min(_.get(acc, 'minMaxX[0]', cur[0]), cur[0]),
                        Math.max(_.get(acc, 'minMaxX[1]', cur[0]), cur[0]),
                      ],
                      minMaxY: [
                        Math.min(_.get(acc, 'minMaxY[0]', cur[1]), cur[1]),
                        Math.max(_.get(acc, 'minMaxY[1]', cur[1]), cur[1]),
                      ],
                      minMaxZ: [
                        Math.min(_.get(acc, 'minMaxZ[0]', cur[2]), cur[2]),
                        Math.max(_.get(acc, 'minMaxZ[1]', cur[2]), cur[2]),
                      ],
                    }), {
                      minMaxX: [undefined, undefined],
                      minMaxY: [undefined, undefined],
                      minMaxZ: [undefined, undefined],
                    }),
                    data: seriesData,
                  });
                  break;
                }

                case 'heatmap': {
                  const uniqCatY = new Set();
                  const uniqCatX = new Set();

                  _.each(level1Aggregate, (agg1) => {
                    uniqCatX.add(agg1.key);
                    _.each(agg1[pivotName]?.buckets, (agg2) => {
                      uniqCatY.add(agg2.key);
                    });
                  });

                  axisXCategories = [...uniqCatX].sort();
                  axisYCategories = [...uniqCatY].sort();

                  const serieData = [];
                  for (let index = 0; index < level1Aggregate.length; index++) {
                    const agg1 = level1Aggregate[index];
                    for (let indexY = 0; indexY < axisYCategories.length; indexY++) {
                      const categoryYName = axisYCategories[indexY];
                      const y = indexY;
                      const buckets = agg1[pivotName]?.buckets || {};
                      const value = _.find(buckets, ['key', categoryYName], {})?.doc_count || null;
                      serieData.push([index, y, value]);
                    }
                  }

                  point = {
                    name: serieName,
                    data: serieData,
                  };

                  series.push(point);
                  break;
                }

                case 'bar':
                case 'line':
                case 'spline': {
                  const serieData = level1Aggregate.map((aggregate) => (
                    [
                      aggregate.key,
                      aggregate[pivotName].value,
                    ]
                  ));

                  series.push({
                    name: serieName,
                    data: serieData,
                  });
                  break;
                }

                default: {
                  point = {};
                  data = [];
                }
              }
            }
          } else {
            let minFreq;
            let maxFreq;

            switch (widget.type) {
              case 'spline': {
                data = level1Aggregate.map((aggregate) => ([
                  aggregate.key, // timestamp
                  aggregate.doc_count, // value
                ]));
                break;
              }

              case 'relations':
              case 'cooccurrences': {
                additionalData.linkCounts = {};
                additionalData.nodeCounts = {};
                additionalData.iterations = yield select((state) => _.get(
                  state,
                  `dashboard.widgets.${widgetId}.additionalData.iterations`,
                  [],
                ));
                let iteration;
                if (keepAllWidgetsData) {
                  const existingData = yield select((state) => _.filter(_.get(
                    state,
                    `dashboard.widgets.${widgetId}.series[0].data`,
                    [],
                  )), (existingLink) => existingLink?.type !== 'multipleLinks');
                  iteration = (_.max(_.map(existingData, 'iteration')) + 1) || 1;
                  const newLinks = [];
                  _.get(results, 'dataHighcharts', []).forEach(
                    (newLink) => {
                      if (!_.some(existingData, { from: newLink.from, to: newLink.to, type: newLink.type })) {
                        newLinks.push({
                          ...newLink,
                          iteration,
                        });
                      }
                    },
                  );
                  if (newLinks.length === 0) {
                    snackActions.warning(i18next.t('dashboard.widget.no_new_links_found', { widgetId }));
                    return;
                  }
                  additionalData.iterations.push({
                    nodeId: exploreFrom,
                    iteration,
                  });
                  data = _.concat(existingData, newLinks);
                } else {
                  data = _.get(results, 'dataHighcharts', []);
                }
                if (data.length > 0 && data[0].type) {
                  // Dans le cadre d'un graphe de relation, pour traiter au mieux les liens
                  // on va leur ajouter un id
                  const ids = [];
                  data = data.map(
                    (link) => {
                      let generatedId = crypto.randomUUID();
                      while (ids.includes(generatedId)) {
                        generatedId = crypto.randomUUID();
                      }
                      return {
                        ...link,
                        generatedId,
                      };
                    },
                  );
                  const multiplesLinksData = [];
                  // On va ensuite comparer les liens entre eux pour trouver les liens "communs" entre
                  // chaque paire de nodes.
                  // Objectif: crééer un noeud qui sera "par dessus" les autres, de couleur noire,
                  // et qui contiendra la liste de tous les liens communs
                  data.forEach(
                    (link) => {
                      additionalData.linkCounts[link.type] = additionalData.linkCounts[link.type] || 0;
                      additionalData.linkCounts[link.type]++;
                      // Si on a pas encore créé de lien multiple "spécial" avec les noeuds communs
                      if (!_.some(multiplesLinksData, (m) => _.isEqual(m.itemsTitles, [link.from, link.to]))) {
                        const relatedLinks = [];
                        let isBidirectional = false;
                        let relatedIteration = 0;
                        // On compare le lien avec tous les autres
                        data.forEach(
                          (compareLink) => {
                            // On vérifie si le lien comparé n'est pas lui même et qu'on a bien
                            // une équivalence entre les deux noeuds (dans un sens ou dans l'autre)
                            if (link.generatedId !== compareLink.generatedId
                              && (
                                (compareLink.from === link.from && compareLink.to === link.to)
                                || (compareLink.from === link.to && compareLink.to === link.from)
                              )
                            ) {
                              // On met dans les liens communs le lien de départ, pour commencer
                              if (relatedLinks.length === 0) {
                                relatedLinks.push(link);
                                // On ajoute une prop au lien de départ pour son opacification au
                                // survol de la "légende"
                                // eslint-disable-next-line no-param-reassign
                                link.hasSiblings = true;
                              }
                              // eslint-disable-next-line no-param-reassign
                              compareLink.hasSiblings = true;
                              // On ajoute le lien comparé aux liens communs
                              relatedLinks.push(compareLink);
                              if (compareLink.iteration && compareLink.iteration > relatedIteration) {
                                relatedIteration = compareLink.iteration;
                              }
                              // On va ensuite tester si le lien peut être considéré comme "bidirecionnel"
                              if ((!isBidirectional && link.bidirectional)
                                || compareLink.bidirectional
                                || (compareLink.from === link.to && compareLink.to === link.from)
                              ) {
                                isBidirectional = true;
                              }
                            }
                          },
                        );
                        if (relatedLinks.length > 0) {
                          // Enfin, si on a trouvé des liens communs, on ajoute dans notre tableau
                          // de liens multiples le lien qui sera par dessus tout le monde dans le
                          // graphe
                          multiplesLinksData.push({
                            ...link,
                            type: 'multipleLinks',
                            itemsTitles: [link.from, link.to],
                            iteration: relatedIteration,
                            bidirectional: isBidirectional,
                            hasSiblings: true,
                            relatedLinks,
                          });
                        }
                      }
                    },
                  );
                  // On ajoute la tableau de liens multiples à la fin de notre tableau de liens
                  data = _.concat(data, multiplesLinksData);
                }
                if (keepAllWidgetsData) {
                  let existingNodes = yield select((state) => _.get(
                    state,
                    `dashboard.widgets.${widgetId}.series[0].nodes`,
                    [],
                  ));
                  existingNodes = _.map(
                    existingNodes,
                    (node) => {
                      let finalNode = { ...node };
                      if (node.id === exploreFrom) {
                        finalNode = {
                          ...node,
                          lastExplored: true,
                          marker: {
                            ...node.marker,
                            radius: node.marker.radius + 15,
                          },
                          iteration,
                        };
                      }
                      return finalNode;
                    },
                  );
                  const newNodes = [];
                  _.get(results, 'nodesHighcharts', []).forEach(
                    (newNode) => {
                      if (!_.some(existingNodes, { id: newNode.name })) {
                        newNodes.push({
                          ...newNode,
                          iteration,
                        });
                      }
                    },
                  );
                  nodes = _.concat(existingNodes, newNodes);
                } else {
                  nodes = _.get(results, 'nodesHighcharts', []);
                }
                nodes.forEach((node) => {
                  additionalData.nodeCounts[node.group] = additionalData.nodeCounts[node.group] || 0;
                  additionalData.nodeCounts[node.group]++;
                });
                break;
              }

              case 'columnhisto':
              case 'column': {
                data = _.reduce(level1Aggregate, (acc, aggregate) => {
                  if (aggregate.doc_count > 0) {
                    acc.push(aggregate.doc_count);
                  }
                  return acc;
                }, []);
                axisXCategories = _.reduce(level1Aggregate, (acc, aggregate) => {
                  if (aggregate.doc_count > 0) {
                    acc.push(aggregate.key);
                  }
                  return acc;
                }, []);
                break;
              }

              case 'solidgauge': {
                const total = _.sumBy(level1Aggregate, 'doc_count');
                const circleWidth = Math.min(Math.trunc(100 / level1Aggregate.length), 25);
                axisXCategories = level1Aggregate.map((aggregate, index) => ({
                  outerRadius: `${112 - (index * circleWidth) - 1}%`,
                  innerRadius: `${112 - circleWidth - (index * circleWidth)}%`,
                  borderWidth: 0,
                }));
                // eslint-disable-next-line no-loop-func
                data = level1Aggregate.map((aggregate, index) => {
                  let name = aggregate.key;
                  if (widget.humanizeLangs) {
                    [name] = i18next.t(`dashboard.languages.${name.toLowerCase()}`).split(';');
                  }
                  if (widget.humanizeDocState) {
                    name = i18next.t(`ged.document.status.${name}`);
                  }
                  return ({
                    name,
                    data: [{
                      radius: axisXCategories[index].outerRadius,
                      innerRadius: axisXCategories[index].innerRadius,
                      name: aggregate.key,
                      y: Math.round((aggregate.doc_count / total) * 100),
                    }],
                  });
                });
                break;
              }

              case 'bar':
              case 'datatable':
              case 'map':
              case 'treemap':
              case 'wordcloud': {
                const docsStates = {};
                data = level1Aggregate.map((aggregate) => {
                  minFreq = Math.min(minFreq || aggregate.doc_count, aggregate.doc_count);
                  maxFreq = Math.max(maxFreq || aggregate.doc_count, aggregate.doc_count);
                  let name = aggregate.lib || aggregate.key;
                  if (widget.humanizeLangs) {
                    [name] = i18next.t(`dashboard.languages.${aggregate.key.toLowerCase()}`).split(';');
                  }
                  if (widget.humanizeDocState) {
                    name = i18next.t(`ged.document.status.${name}`);
                    if (!docsStates[name]) {
                      docsStates[name] = aggregate.key;
                    }
                  }
                  return [
                    name, // name
                    aggregate.doc_count, // value
                  ];
                });
                if (!_.isEmpty(docsStates)) {
                  additionalData.docsStates = docsStates;
                }
                break;
              }

              case 'pie': {
                data = level1Aggregate.map((aggregate) => {
                  minFreq = Math.min(minFreq || aggregate.doc_count, aggregate.doc_count);
                  maxFreq = Math.max(maxFreq || aggregate.doc_count, aggregate.doc_count);
                  let name = aggregate.lib || aggregate.key;
                  if (widget.humanizeLangs) {
                    [name] = i18next.t(`dashboard.languages.${aggregate.key.toLowerCase()}`).split(';');
                  }
                  if (widget.humanizeDocState) {
                    name = i18next.t(`ged.document.status.${name}`);
                  }
                  return {
                    name,
                    y: aggregate.doc_count, // value
                    strategy: aggregate.key, // strategy for refining
                  };
                });
                break;
              }

              default: {
                data = [];
                nodes = [];
              }
            }

            series.push({
              name: serieName,
              data,
              nodes,
              minFreq,
              maxFreq,
              totalUnique,
            });
          }
        }
      }
    }

    if (newParams.exportWidget) {
      return;
    }
    if (widget.splitPerSerie) {
      for (let i = 0; i < series.length; i++) {
        yield put(fetchWidgetSuccess(
          `${widgetId}_${series[i].name}`,
          { series: series[i] },
        ));
      }
    } else {
      widgetData = {
        axisX: {
          categories: axisXCategories,
        },
        axisY: {
          categories: axisYCategories,
        },
        series,
        additionalData,
      };

      yield put(fetchWidgetSuccess(widgetId, widgetData));
    }
  } catch (response) {
    console.error(response);
    yield put(fetchWidgetError(widgetId, response));
    snackActions.error(i18next.t('dashboard.error_fetching_dashboard_widget', { widgetTitle: widget.title }));
  }
}

function* workFetchWidgets({
  query,
  widgets,
  keepAllWidgetsData,
  exploreFrom,
  baseId,
}) {
  if (widgets) {
    let activeBaseId = baseId;
    if (!baseId) {
      activeBaseId = yield select((state) => state.config.activeBase.base);
    }
    for (let i = 0; i < widgets.length; i++) {
      const widgetId = widgets[i].dashboard_widget;
      const {
        facets,
        relations,
        list,
        facetmax,
        facetmax2,
        mindoccount,
        additionalQuery,
        slice,
        sort,
        exportWidget,
        totalunique,
      } = widgets[i];

      yield call(
        workFetchWidget,
        widgetId,
        widgets[i],
        {
          widgetId,
          facets,
          relations,
          list,
          query,
          facetmax,
          facetmax2,
          mindoccount,
          baseId: activeBaseId,
          additionalQuery,
          slice,
          sort,
          exportWidget,
          totalunique,
        },
        keepAllWidgetsData,
        exploreFrom,
      );
    }
  }
}

function* watchFetchWidgets() {
  // Petite subtilité : on utilise le takeLatest pour que tout nouveau dispatch
  // d'une des actions "écoutées" tue la thread précédente. Du coup, lorsqu'on
  // dispatch cleanupDashboard() (lorsque DashboardWrapper est unMount), le
  // takeLatest prend la main et tue le fetchWidgets en cours (ce qui empêche
  // les requêtes de chargement des widgets de continuer et le reducer d'être
  // populé)
  yield takeLatest([types.FETCH_WIDGETS, types.CLEANUP_DASHBOARD], workFetchWidgets);
}

function* workFetchDashboards() {
  try {
    const dashboards = yield call(fetchDashboards);
    yield put(fetchDashboardsSuccess(dashboards));
  } catch (error) {
    yield put(fetchDashboardsError(error));
    console.error(error);
    snackActions.error(i18next.t('dashboard.error_fetching_dashboards'));
  }
}

function* watchFetchDashboards() {
  yield takeLatest(types.FETCH_DASHBOARDS, workFetchDashboards);
}

function* workSaveWidgets({ params, isLayoutChange }) {
  try {
    let endpoint = createWidget;
    let addition = true;
    if (params.dashboard_widget || isLayoutChange) {
      endpoint = patchWidgets;
      addition = false;
    }
    const results = yield call(endpoint, { bodyItems: params });
    if (!_.isEmpty(results)) {
      yield put(saveWidgetsSuccess(results, addition));
      if (!isLayoutChange) {
        const keepOtherWidgetsData = true;
        const strategy = yield select((state) => state.search.results.strategie);
        const jsonParams = JSON.parse(results.json);
        const fetchParams = {
          dashboard_widget: results.dashboard_widget,
          ...jsonParams,
        };

        if (['cooccurrences', 'relations'].includes(jsonParams.type)) {
          const activeBaseId = yield select((state) => state.config.activeBase.base);
          const {
            COOCCURRENCES_FIELDS,
            RELATIONS_NODES_FIELDS,
            RELATIONS_LINKS_FIELDS,
          } = getActiveBaseDashboardParams(activeBaseId);

          if (jsonParams.type === 'cooccurrences' && COOCCURRENCES_FIELDS) {
            const facetsArray = _.map(_.filter(COOCCURRENCES_FIELDS, 'active'), 'value');
            fetchParams.facets = facetsArray.join(',');
            fetchParams.aggregates = facetsArray;
          }
          if (jsonParams.type === 'relations' && RELATIONS_NODES_FIELDS && RELATIONS_LINKS_FIELDS) {
            const relationsArray = _.map(_.filter(RELATIONS_LINKS_FIELDS, 'active'), 'value');
            fetchParams.relations = relationsArray.join(',');
            fetchParams.aggregates = relationsArray;
            const nodes = _.map(_.filter(RELATIONS_NODES_FIELDS, 'active'), 'value');
            const nodesTypesSources = [];
            const nodesTypesDestinations = [];
            _.forEach(
              nodes,
              (nodeTypeValue) => {
                nodesTypesSources.push(
                  `QES_Relation_Source_Type:${nodeTypeValue}`,
                );
                nodesTypesDestinations.push(
                  `QES_Relation_Destination_Type:${nodeTypeValue}`,
                );
              },
            );
            if (nodesTypesSources) {
              fetchParams.additionalQuery = `
                (${nodesTypesSources.join(' OR ')}) AND (${nodesTypesDestinations.join(' OR ')})
              `;
            }
          }
        }

        yield put(fetchWidgets(strategy, [fetchParams], keepOtherWidgetsData));
        snackActions.toast(
          i18next.t('dashboard.widget_saved'),
          { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 1000 },
        );
        if (addition) {
          yield put(closeDialogCreateWidget());
        }
      } else {
        snackActions.toast(
          i18next.t('dashboard.layout_saved'),
          { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 1000 },
        );
      }
    }
  } catch (error) {
    yield put(saveWidgetsError(error));
    console.error(error);
    snackActions.error(i18next.t('dashboard.widget_saved_error'));
  } finally {
    yield put(stopWidgetLoading());
  }
}

function* watchSaveWidgets() {
  yield takeLatest(types.SAVE_WIDGETS, workSaveWidgets);
}

function* workDeleteWidget({ widgetId }) {
  try {
    const results = yield call(deleteWidget, widgetId);
    yield put(deleteWidgetSuccess(results));
    snackActions.toast(
      i18next.t('dashboard.widget_deleted'),
      { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 1000 },
    );
  } catch (error) {
    yield put(deleteWidgetError(error));
    console.error(error);
    snackActions.error(i18next.t('dashboard.widget_deleted_error'));
  }
}

function* watchDeleteWidget() {
  yield takeLatest(types.DELETE_WIDGET, workDeleteWidget);
}

function* workSaveDashboard({ params }) {
  try {
    let endpoint = createDashboard;
    let addition = true;
    if (params.dashboard) {
      endpoint = patchDashboard;
      addition = false;
    }
    const results = yield call(endpoint, { bodyItems: params });
    yield put(saveDashboardSuccess(results, addition));
    yield put(closeDialogEditOrCreateDashboard());
    snackActions.toast(
      i18next.t('dashboard.dashboard_saved'),
      { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 1000 },
    );
    if (addition) {
      const activeBaseId = yield select((state) => state.config.activeBase.base);
      const search = yield select((state) => state.router.location.search);
      yield put(push(`/search/dashboard/${activeBaseId}/${results.dashboard}${search || ''}`));
    }
  } catch (error) {
    yield put(saveDashboardError(error));
    console.error(error);
    snackActions.error(i18next.t('dashboard.dashboard_saved_error'));
  }
}

function* watchSaveDashboard() {
  yield takeLatest(types.SAVE_DASHBOARD, workSaveDashboard);
}

function* workDeleteDashboard({ dashboardId }) {
  try {
    const results = yield call(deleteDashboard, dashboardId);
    yield put(deleteDashboardSuccess(results));
    yield put(replace('/search/dashboard'));
    snackActions.toast(
      i18next.t('dashboard.dashboard_deleted'),
      { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 1000 },
    );
  } catch (error) {
    yield put(deleteDashboardError(error));
    console.error(error);
    snackActions.error(i18next.t('dashboard.dashboard_deleted_error'));
  }
}

function* watchDeleteDashboard() {
  yield takeLatest(types.DELETE_DASHBOARD, workDeleteDashboard);
}

export default {
  watchDeleteDashboard,
  watchDeleteWidget,
  watchFetchDashboards,
  watchFetchWidgets,
  watchSaveDashboard,
  watchSaveWidgets,
};
