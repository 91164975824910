import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeMailingListDialog, createMailingList, editMailingList } from 'generic/core/mailingLists/actions';
import MailingListDialog from 'generic/components/dialogs/MailingListDialog';

const MailingListDialogContainer = () => {
  const {
    idMailingList,
    nameMailingList,
    open: dialogOpen,
    loading,
  } = useSelector((state) => state.mailingLists.dialogMailingList);
  const dispatch = useDispatch();

  const handleSubmit = (formValues) => {
    if (idMailingList) {
      dispatch(editMailingList({ ...formValues, id: idMailingList }));
    } else {
      dispatch(createMailingList(formValues));
    }
  };

  if (!dialogOpen) {
    return null;
  }
  return (
    <MailingListDialog
      editOrCreate={idMailingList ? 'edit' : 'create'}
      loading={loading}
      open={dialogOpen}
      nameMailingList={nameMailingList}
      handleSubmit={handleSubmit}
      handleClose={() => dispatch(closeMailingListDialog())}
    />
  );
};

export default MailingListDialogContainer;
