import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
} from '@mui/material';
import DynamicButton from 'generic/components/ui/DynamicButton';
import { MoreVert } from '@mui/icons-material';

const DropdownMenu = ({
  btnChildren,
  btnProps,
  children,
  btnTag,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <DynamicButton
        tag={btnTag}
        onClick={handleClick}
        className="minWidthAuto"
        size="small"
        {...btnProps}
      >
        {btnChildren}
      </DynamicButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </React.Fragment>
  );
};

DropdownMenu.propTypes = {
  btnChildren: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  btnProps: PropTypes.shape(),
  btnTag: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DropdownMenu.defaultProps = {
  btnChildren: <MoreVert size="small" />,
  btnProps: {},
  btnTag: 'iconButton',
};

export default DropdownMenu;
