import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Check, ContentCopy } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { snackActions } from 'generic/utils/snackbar';
import i18next from 'i18next';

const CopyToClipboard = ({
  textToCopy,
  title,
  size,
  color,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const printError = (error) => {
    console.error(error.message);
    snackActions.error(t('actions.error_copying_to_clipboard'));
  };

  const handleCopyToClipboard = (text) => {
    try {
      navigator
        .clipboard
        .writeText(text)
        .then(() => setCopied(true))
        .catch(printError);
    } catch (error) {
      printError(error);
    } finally {
      setTimeout(() => setCopied(false), 2000);
    }
  };

  let dynamicTooltipProps;
  if (copied) {
    dynamicTooltipProps = {
      title: t('ui.copied_to_clipboard'),
      children: <Check color="success" fontSize={size} />,
    };
  } else {
    dynamicTooltipProps = {
      title,
      onClick: () => handleCopyToClipboard(textToCopy),
      disabled: !textToCopy,
      children: <ContentCopy fontSize={size} />,
    };
  }

  return (
    <TooltipButton
      tag="iconButton"
      size={size}
      color={color}
      edge="end"
      {...dynamicTooltipProps}
    />
  );
};

CopyToClipboard.propTypes = {
  textToCopy: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  textToCopy: '',
  title: i18next.t('ui.copy_to_clipboard'),
  size: 'small',
  color: 'default',
};

export default CopyToClipboard;
