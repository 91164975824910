import { post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const generateReport = ({ ...rest }) => post({ path: '/export/report', ...rest }).then(handleComplete);
const submitExport = ({ ...rest }) => post({ path: '/export', ...rest }).then(handleComplete);

export {
  generateReport,
  submitExport,
};
