import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { openDialogCreateAlert } from 'generic/core/actions/actions';
import QuickResultsHeader from 'generic/components/ui/QuickResultsHeader';

const QuickResultsHeaderContainer = () => {
  const activeBase = useSelector((state) => state.config.activeBase);
  const originalResultsCriteria = useSelector((state) => state.search.results.criteres);
  const quickResultsCriteria = useSelector((state) => state.search.quickResults.criteres);
  const loadingBlank = useSelector((state) => state.search.quickLoadingBlank);
  const nbResults = useSelector((state) => state.search.quickResults.nbresults);
  const dispatch = useDispatch();

  const criteria = _.map(
    quickResultsCriteria,
    (criterion, index) => ({
      ...criterion,
      isNew: originalResultsCriteria?.[index]?.champ !== criterion.champ
        || originalResultsCriteria?.[index]?.value !== criterion.value,
    }),
  );
  return (
    <QuickResultsHeader
      canCreateAlerts={activeBase.veille}
      criteria={criteria}
      handleOpenCreateAlert={() => dispatch(openDialogCreateAlert())}
      loadingBlank={loadingBlank}
      nbResults={nbResults}
    />
  );
};

export default QuickResultsHeaderContainer;
