import React from 'react';
import { useSelector } from 'react-redux';
import { overrideRessource } from 'generic/utils/utils';
import { isEmpty, some } from 'lodash';

const Header = overrideRessource('components/ui/Header');

const HeaderContainer = () => {
  const logged = useSelector((state) => state.auth.logged);
  const user = useSelector((state) => state.config.user);

  const hasCarts = useSelector((state) => some(state.config.bases, { panier: true }));
  const hasNewsletters = useSelector((state) => some(state.config.bases, { newsletter: true }));
  const hasWatches = useSelector((state) => some(state.config.bases, { veille: true }));
  const activeCollection = useSelector((state) => state.collections.activeCollection);
  const withCollections = useSelector((state) => state.config.settings?.withCollections);

  let hasCollections = false;
  let hasActiveCollection = true;
  if (withCollections) {
    hasCollections = true;
    if (isEmpty(activeCollection)) {
      hasActiveCollection = false;
    }
  }

  if (!logged) {
    return '';
  }

  return (
    <Header
      user={user}
      hasActiveCollection={hasActiveCollection}
      hasCarts={hasCarts}
      hasCollections={hasCollections}
      hasNewsletters={hasNewsletters}
      hasWatches={hasWatches}
    />
  );
};

export default React.memo(HeaderContainer);
