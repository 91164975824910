import PropTypes from 'prop-types';

const isNumber = (value) => {
  const number = parseInt(value, 10);
  return !Number.isNaN(number);
};

/**
 * Permet de valider qu'une props est une String représentant un nombre OU un tableau de String représentant des nombres
 * @returns null si valide ou une Erreur en cas de valeur non autorisée
 */
const validatesNumericOrNumericArrayType = (props, propName, componentName) => {
  const propValue = props[propName];

  if (
    (Array.isArray(propValue) && propValue.every(isNumber))
    || isNumber(propValue)
  ) {
    return null;
  }

  return new Error(
    `Invalid prop '${propName}' supplied to '${componentName}'. `
    + 'Expected an array of strings representing numbers or a string representing a number.',
  );
};

const stringOrArrayOfStrings = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

export {
  stringOrArrayOfStrings,
  validatesNumericOrNumericArrayType,
};
