import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { closeArticle } from 'generic/core/anr/actions';
import ArticleView from 'generic/components/pages/anr/ArticleView';
import { testBeforeUnload, treeFlattener } from 'generic/utils/utils';
import ANR_CONSTANTS from 'generic/core/anr/constants';

const { ARTICLE_VIEW_ANCHOR } = ANR_CONSTANTS;

const ArticleViewContainer = () => {
  const articles = useSelector((state) => state.anr.articles);
  const article = _.find(treeFlattener(articles, 'duplicates'), { opened: true });
  const articleId = _.get(article, 'id', null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(articleId)) {
      window.addEventListener('beforeunload', testBeforeUnload);
    }

    // Le cleanup est lancé quand le composant est unmount
    // ou avant que ce useEffect soit relancé
    return function cleanup() {
      window.removeEventListener('beforeunload', testBeforeUnload);
    };
  }, [articleId]);

  useEffect(() => {
    if (!_.isEmpty(articleId)) {
      setLoading(true);
    }
  }, [articleId]);

  const handleIsLoaded = () => {
    setLoading(false);
  };

  const handleClose = () => {
    dispatch(closeArticle());
  };

  return (
    <ArticleView
      article={article}
      loading={loading}
      handleIsLoaded={handleIsLoaded}
      handleClose={handleClose}
      anchor={ARTICLE_VIEW_ANCHOR}
    />
  );
};

export default ArticleViewContainer;
