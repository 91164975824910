import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import {
  LocalizationProvider,
  frFR as pickersFrLocale,
  enUS as pickersEnLocale,
} from '@mui/x-date-pickers';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import {
  frFR,
  enUS,
} from '@mui/material/locale';
import makeStyles from '@mui/styles/makeStyles';
import i18next from 'i18next';
import { CONSTANTS } from 'generic/core/constants';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';

const { LANG_MAPPING } = CONSTANTS;
// classes utilisables dans toute l'application

const ConfigWrapper = ({ children }) => {
  const [currentDateLocale, setCurrentDateLocale] = useState(frLocale);
  const [currentThemeLocale, setCurrentThemeLocale] = useState(frFR);
  const [currentPickersLodale, setCurrentPickersLodale] = useState(pickersFrLocale);
  const logged = useSelector((state) => state.auth.logged);
  const lang = useSelector((state) => (state.auth.logged ? state.config.user.langue : 1));
  const dispatch = useDispatch();
  const currentTheme = useColorSchemeDetector();

  const useStyles = makeStyles(() => currentTheme.GLOBAL_STYLES);
  useStyles();

  useEffect(() => {
    if (logged) {
      const localeDateMapping = {
        1: frLocale,
        2: enLocale,
      };
      const localeThemeMapping = {
        1: frFR,
        2: enUS,
      };
      const localePickersMapping = {
        1: pickersFrLocale,
        2: pickersEnLocale,
      };
      setCurrentDateLocale(localeDateMapping[lang]);
      setCurrentThemeLocale(localeThemeMapping[lang]);
      setCurrentPickersLodale(localePickersMapping[lang]);

      window.QWAM = {
        localeId: lang,
      };
      i18next.changeLanguage(LANG_MAPPING[lang], (err) => {
        if (err) {
          console.warn('un problème est survenu au changement de langue', err);
        }
      });
    }
  }, [dispatch, logged, lang]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={responsiveFontSizes(createTheme(currentTheme.THEME, currentThemeLocale))}>
        <CssBaseline enableColorScheme />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={currentDateLocale}
          localeText={currentPickersLodale.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ConfigWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConfigWrapper;
