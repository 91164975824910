import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import HeaderContainer from 'generic/containers/HeaderContainer';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import GEDModalContainer from 'generic/containers/GEDModalContainer';

const Layout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start"
        wrap="nowrap"
        height="100%"
        spacing={0}
      >
        <HeaderContainer />

        <Grid
          container
          zIndex="1"
          wrap="nowrap"
          flexGrow="1"
          className="desktopOnlyOverflow"
        >
          <Suspense fallback={<GlobalLoading />}>
            <Component {...matchProps} />
          </Suspense>
        </Grid>
        <GEDModalContainer />
      </Grid>
    )}
  />
);

Layout.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default Layout;
